import React, { useEffect, useState } from "react";
import styles from "./AddTax.module.css";
import { useNavigate } from "react-router-dom";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { addTax, getCountryList } from "../../Services/api";

const validationSchema = yup.object().shape({
  name: yup.string().required("Tax name is required."),
  value: yup.number().required("Tax Value is required."),
  state: yup.string().required("State is required."),
  country_id: yup.string().required("Country is required."),
});
const AddTax = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  console.log('data: ', data);
  
  const getCountryData = () =>{
    getCountryList().then(res=>{
      console.log('res: ', res);
      console.log('res?.data?.data: ', res?.data?.data);
      if (res?.data?.data?.length > 0){
        setData(res?.data?.data)
      }
    })
  }
useEffect(()=>{
  getCountryData()
},[''])
  const formik = useFormik({
    initialValues: {
      name: "",
      value: "",
      state: "",
      country_id:""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values: ", values);
      setLoading(true);
      addTax(values)
        .then((res) => {
          console.log(res);
          if (res?.message === "Data created successfully") {
            navigate("/user/tax-management");
            formik.resetForm();
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });
  return (
    <form className="add-user-form" onSubmit={formik.handleSubmit}>
      <div className={styles.Funnels}>
        <div className={styles.FunnelsCreate}>
          <h1 className={styles.FunnelsHead}> Japan</h1>
          <div className={styles.Button}>
            <button onClick={() => navigate("/user/tax-management")}>
              Back to List
            </button>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.subcontent}>
            <label htmlFor="state">All State</label>
            <div className={styles.FunnelsSearchDrop}>
              <div className={styles.inputField}>
                <Select
                  style={{
                    backgroundColor: "#fff",
                    fontSize: "13px",
                    width: "100%",
                    color: "#000",
                    fontFamily: "Avenir",
                    fontStyle: "normal",
                    fontWeight: "500",
                  }}
                  sx={{
                    "& fieldset": { border: "none", fontSize: "13px" },
                    height: "24px",
                  }}
                  labelId="demo-select-large"
                  id="country_id"
                  name="country_id"
                  label="Last 30 days"
                  value={formik.values.country_id}
                  onChange={formik.handleChange}
                >
                  {
                    data.length > 0 && data.map((obj)=>{
                      return (<MenuItem
                        style={{
                          fontSize: "13px",
                          margin: "10px",
                          borderBottom: "1px solid #000",
                        }}
                        value={obj?._id}
                      >
                        {obj?.country_name}
                      </MenuItem>)
                    })
                  }
               
                  
                </Select>
              </div>
            </div>
          </div>
          <div className={styles.subcontent}>
            <label htmlFor="name">State Name</label>
            <input
              type="text"
              name="state"
              id="state"
              value={formik.values.state}
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.subcontent}>
            <label htmlFor="name">Tax Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.subcontent}>
            <label htmlFor="value">Tax Value</label>
            <input
              type="number"
              name="value"
              id="value"
              value={formik.values.value}
              onChange={formik.handleChange}
            />
            <span> &nbsp;&nbsp;%</span>
          </div>
        
          <div className={styles.subcontent}>
            <label htmlFor="state">Status</label>
            <div className={styles.FunnelsSearchDrop}>
              <div className={styles.inputField}>
                <Select
                  style={{
                    backgroundColor: "#fff",
                    fontSize: "13px",
                    width: "100%",
                    color: "#000",
                    fontFamily: "Avenir",
                    fontStyle: "normal",
                    fontWeight: "500",
                  }}
                  sx={{
                    "& fieldset": { border: "none", fontSize: "13px" },
                    height: "24px",
                  }}
                  labelId="demo-select-large"
                  id="status"
                  name="status"
                  label="Last 30 days"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                >
                   <MenuItem
                        style={{
                          fontSize: "13px",
                          margin: "10px",
                          borderBottom: "1px solid #000",
                        }}
                        value={'Active'}
                      >
                    Active
                      </MenuItem>
                  <MenuItem
                    style={{
                      fontSize: "13px",
                      margin: "10px",
                      borderBottom: "1px solid #000",
                    }}
                    value={'Inactive'}
                  >
                    Inactive
                  </MenuItem>
                  


                </Select>
              </div>
            </div>
          </div>
          <div
            style={{
              margin: "10px 0px 0px 352px",
              width: "227px",
              height: "41px",
            }}
            className={styles.Button}
          >
            {" "}
            <button disabled={loading} className="proceed-btn" type="submit">
              {loading ? (
                <CircularProgress
                  style={{
                    width: "30px",
                    color: "#fff",
                    margin: "3px 95px",
                    height: "30px",
                  }}
                />
              ) : (
                "Add New"
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddTax;
