import React, { useEffect, useState } from 'react'
import styles from "./Setting.module.css"
import useProvider from '../../../Context/Context'
import { RxCross2 } from "react-icons/rx"
import { Autocomplete, Box, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { GiDoughRoller } from 'react-icons/gi'
import { BsCurrencyRupee } from 'react-icons/bs'
import { TbCurrencyDinar, TbCurrencyDollarSingapore, TbCurrencyDong, TbCurrencyEuro, TbCurrencyHryvnia, TbCurrencyKroneCzech, TbCurrencyKroneDanish, TbCurrencyPound, TbCurrencyReal, TbCurrencyShekel, TbCurrencyYen } from 'react-icons/tb'
import { MdCurrencyRuble } from 'react-icons/md'
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),

];
function Settings(props) {
    const sourceList = [
        { label: "Indian Rupee", icon: 'BsCurrencyRupee' },
        { label: "Israeli Shekel", icon: 'TbCurrencyShekel' },
        { label: "Kuwaiti Dinar", icon: 'TbCurrencyDinar' },
        { label: "Singapore Dollar", icon: 'TbCurrencyDollarSingapore' },
        { label: "Russian Ruble", icon: 'MdCurrencyRuble' },
        { label: "Brazilian Real", icon: 'TbCurrencyReal' },
        { label: "US Dollar", icon: 'TbCurrencyReal' },
        { label: "Ukrainian Hryvnia", icon: 'TbCurrencyHryvnia' },
        { label: "Vietnamese Dong", icon: 'TbCurrencyDong' },
        { label: "Yen", icon: 'TbCurrencyYen' },
        { label: "Euro", icon: 'TbCurrencyEuro' },
        { label: "Yuan Renminbi", icon: 'TbCurrencyYen' },
        { label: "Danish Krone", icon: 'TbCurrencyKroneDanish' },
        { label: "Australian Dollar", icon: 'GiDoughRoller' },
        { label: "Canadian Dollar", icon: 'GiDoughRoller' },
        { label: "Chilean Peso", icon: 'GiDoughRoller' },
        { label: "Czech Koruna", icon: 'TbCurrencyKroneCzech' },
        { label: "Egyptian Pound", icon: 'TbCurrencyPound' },
    ]
    const sourceList1 = [
        { label: "Indian Rupee", icon: <BsCurrencyRupee /> },
        { label: "Israeli Shekel", icon: <TbCurrencyShekel /> },
        { label: "Kuwaiti Dinar", icon: <TbCurrencyDinar /> },
        { label: "Singapore Dollar", icon: <TbCurrencyDollarSingapore /> },
        { label: "Russian Ruble", icon: <MdCurrencyRuble /> },
        { label: "Brazilian Real", icon: <TbCurrencyReal /> },
        { label: "US Dollar", icon: <TbCurrencyReal /> },
        { label: "Ukrainian Hryvnia", icon: <TbCurrencyHryvnia /> },
        { label: "Vietnamese Dong", icon: <TbCurrencyDong /> },
        { label: "Yen", icon: <TbCurrencyYen /> },
        { label: "Euro", icon: <TbCurrencyEuro /> },
        { label: "Yuan Renminbi", icon: <TbCurrencyYen /> },
        { label: "Danish Krone", icon: <TbCurrencyKroneDanish /> },
        { label: "Australian Dollar", icon: <GiDoughRoller /> },
        { label: "Canadian Dollar", icon: <GiDoughRoller /> },
        { label: "Chilean Peso", icon: <GiDoughRoller /> },
        { label: "Czech Koruna", icon: <TbCurrencyKroneCzech /> },
        { label: "Egyptian Pound", icon: <TbCurrencyPound /> },     
    ]

    const list = ['one', 'two']
    const [priceType, setPriceType] = useState("")
    const [isShow2, setIsShow2] = useState(false)
    const [sourceValue, setSourceValue] = useState('');
    const {
        SettingsModel2, setShowSettingsModel2
    } = useProvider();
    const [canvasSettings, setCanvasSettings] = useState({})
    console.log("canvas setting=>", canvasSettings)
    const handleSave = () =>{
       setShowSettingsModel2(false)
    }
    return (
        <div
            onClick={() => setShowSettingsModel2(false)}
            className={`${styles.summaryModel} ${SettingsModel2 ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Settings</p>
                    < RxCross2 onClick={() => setShowSettingsModel2(false)} />
                </div>
               
                {/* <div style={{ marginTop: "10px" }}>
                    <p>Merchant Account Fees</p>
                </div> */}
                <div className={styles.inputField}>
                    <p>Notify When Orders</p>
                    <input value={canvasSettings?.processingRate} onChange={(e) => setCanvasSettings({ ...canvasSettings, processingRate: e.target.value })} type="number" />
                </div>
                <div className={styles.inputField}>
                    <p>Notify When CVR</p>
                    <input value={canvasSettings?.transactionFee} onChange={(e) => setCanvasSettings({ ...canvasSettings, transactionFee: e.target.value })} type="number" />
                </div>
                <div className={styles.inputField}>
                    <p>Sent to Mail Address</p>
                    <input value={canvasSettings?.email} onChange={(e) => setCanvasSettings({ ...canvasSettings, email: e.target.value })} type="email" />
                </div>
                <div style={{ display: "flex", gap: "30px", marginTop: '8px', width: "100%", justifyContent: "flex-end" }}>
                    <button onClick={() => setShowSettingsModel2(false)} className='closebuttn'>Close</button>
                    <button className='addbutton' onClick={handleSave}>Save</button>
                </div>
            </div>
        </div>
    );
}

export default Settings