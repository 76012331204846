import { useEffect, useRef, useState } from "react";
import ActionNode from "./ActionNode";
import ColorSelectorNode from "./ColorSelectorNode";
import CustomEdge from "./CustomeEdge";
import PageNode from "./PageNode2";
import TrafficNode from "./TrafficNode";
import { Background, BackgroundVariant, ReactFlow, ReactFlowProvider, useEdgesState, useNodesState } from "reactflow";
import RightSidebar from "./RightSideBar/RightSidebar3";

const edgeTypes = {
    custom: CustomEdge,
};
const initialNodes = [];
const panOnDrag = [0, 1];
const nodeTypes = {
    selectorNode: ColorSelectorNode,
    trafficNode: TrafficNode,
    pageNode: PageNode,
    actionNode: ActionNode,
};
const ActualFunnel = (props) => {
    console.log('props: ', props);
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    console.log('nodes11: ', nodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [gridShow, setGridShow] = useState(true);
    useEffect(()=>{
        setNodes(props.nodes)
        setEdges(props.edges)
    },[props])
    useEffect(() => {
        if(nodes?.length > 0){
            nodes.map((obj)=>{
                console.log('obj: ', obj);
                let temp = props.pageViews?.filter((obj1 => obj?.pageData && (obj?.pageData?.id === obj1?._id)))
                console.log('temp: ', temp);
                if (temp?.length > 0){
                    obj.views = temp[0].views
                }
            })
            setNodes(nodes)
        }
    }, [props.pageViews])
    return (<>
        <ReactFlowProvider>

            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    // onNodesChange={onNodesChange}
                    // onEdgesChange={onEdgesChange}
                    // onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    // onDrop={onDrop}
                    // fitView={fitScreen}
                    // onDragOver={onDragOver}
                    nodeTypes={nodeTypes}
                    panOnScroll
                    selectionOnDrag
                    // panOnDrag={panOnDrag}
                    // selectionMode={SelectionMode.Partial}
                    edgeTypes={edgeTypes}
                // onSelectionChange={onSelectionChange}
                >
                    {gridShow && (
                        <div>
                            <Background
                                id="1"
                                gap={20}
                                color="#f1f1f1"
                                variant={BackgroundVariant.Lines}
                            />
                        </div>
                    )}
                </ReactFlow>

            </div>
            <RightSidebar data={props.data} />

        </ReactFlowProvider>

    </>)
}
export default ActualFunnel