import React from 'react';

import { ModalSettings } from './ModalSettings';

import { Resizer } from '../Resizer';
import { Box, Grid, Modal } from '@mui/material';
import usePageBuilder from '../../../../Context/pageBuilder';
import { Element, useNode } from '@craftjs/core';
import Container from '../Container';
import Texts from '../texts';
import Buttons from '../buttons';

// export type ContainerProps = {
//   background: Record<'r' | 'g' | 'b' | 'a', number>;
//   color: Record<'r' | 'g' | 'b' | 'a', number>;
//   flexDirection: string;
//   alignItems: string;
//   justifyContent: string;
//   fillSpace: string;
//   width: string;
//   height: string;
//   padding: string[];
//   margin: string[];
//   marginTop: number;
//   marginLeft: number;
//   marginBottom: number;
//   marginRight: number;
//   shadow: number;
//   children: React.ReactNode;
//   radius: number;
// };

const defaultProps = {
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  padding: ['0', '0', '0', '0'],
  margin: ['0', '0', '0', '0'],
  background: { r: 256, g: 256, b: 256, a: 1 },
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  firstShadow: 0,
  secondShadow: 0,
  thirdShadow: 0,
  radius: 0,
  width: '100%',
  height: 'auto'
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};
export const SModal = (props) => {

  const { bgChoosen, setBgChoosen } = usePageBuilder()
  props = {
    ...defaultProps,
    ...props,
  };
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    radius,
    children,
    gap,
    firstShadow,
    secondShadow,
    thirdShadow,
    bgurl,
    visibility,
    Flexproperty,
    close
  } = props;
  console.log('props: ', props);
  console.log('close: ', close);

  const { setOpenPopup,openPopup } = usePageBuilder()

  const handleOpen = () => setOpenPopup(true);
  const handleClose = () => {
    console.log('close: ', close);
    console.log("call close");
    if (close === "yes"){
      setOpenPopup(false)
    }
    // setOpenPopup(false)
  };
  const {
    connectors: { connect },
  } = useNode();
  return (

    <Modal
      ref={connect} 
      canvas
      open={openPopup}
      disableBackdropClick
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Element canvas id='container' is={Container}>
          <Texts text="Title" margin={[10, 0, 10, 10]} fontSize={'32px'} fontWeight="bold" />
          <Buttons text = {'close'}/>
        </Element>
      </Box>
    </Modal>
  );
};

SModal.craft = {
  displayName: 'Modal',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ModalSettings,
  },
};
export default SModal;
