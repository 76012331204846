import React, { useEffect, useState } from 'react'
import "./Login.css"
import axios from 'axios'
import { common } from '../../BaseUrl'
import { useNavigate } from 'react-router-dom'
import useProvider from '../../Context/Context'
function Login() {
  const { setAuthToken } = useProvider()
  const [isLoading, setIsLoading] = useState(false)
  const [loginPayload, setLoginPayload] = useState({
    email: 'khushboo@gmail.com',
    password: '123456'
  })
  const navigate = useNavigate()
  const login = () => {
    setIsLoading(true)
    axios({
      method: "post",
      url: `${common}/auth/login`,
      data: {
        email: loginPayload.email,
        password: loginPayload.password
      }

    }).then(responce => {
      console.log(responce.data)
      if (responce.data?.token) {
        setAuthToken(responce.data.token)
        setIsLoading(false)
        let url = window.location.protocol + "//" + "myspacef93e71e99aad" + "." + window.location.host + '/user/dashboard?token=' + responce.data.token

        // navigate(`/user/funnels/${url}`)
        window.location.href = url
      }

    })
  }


  return (
    <div className='login_container'>
      <div data-aos="flip-up" className='login_body'>
        <input value={loginPayload.email} type="email" name="" id="" />
        <input value={loginPayload.password} type="password" name="" id="" />
        {isLoading ? <button className='addbutton2' style={{position:"relative"}}>
          <div class="spinner center">
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
          </div>
        </button> :
          <button onClick={login} className='addbutton2'>Login</button>}
      </div>
    </div>
  )
}

export default Login