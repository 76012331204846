import React, { useEffect, useState } from 'react'
import "./RightSide.css"
import Dropdown from '../DropDown/DropDwon'
import { CategoryScale, ArcElement, Tooltip, Legend } from "chart.js";
import { MdOutlineArrowLeft } from "react-icons/md"
import useProvider from '../../../Context/Context'
import { AiFillSetting } from 'react-icons/ai'
import Chart from "chart.js/auto";

import PieChart from './Chart'
import { Pie } from 'react-chartjs-2';
Chart.register(ArcElement, Tooltip, Legend);


const products = [
  {
    label: "test($0)",
    value: "$0.00",
  },
  {
    label: "test Copy($0)",
    value: "$0.00",
  },
  {
    label: "Total",
    value: "$0.00",
  },
];

const selectedOption = { label: "Products" }
const RightSidebar = ({ data }) => {
  const [showSidebar, setShowSidebar] = useState(false)
  const {
    showSummryModel,
    setShowSummryModel,
    selectedSummary,
    setSelectedSummary,
    allNodes,
    prodCost,
    setProdCost,
    setIsSimulate,
    setShowSimulateData,
    simulateData,
  } = useProvider();
  console.log('simulateData: ', simulateData);
  const [products, setProducts] = useState([])
  useEffect(() => {
    if (data && data?.length > 0) {
      data?.map((obj) => {
        let json = {}
        json.label = obj?.prdName[0]?.name;
        json.value = obj?.sales?.$numberDecimal
        setProducts([...products, json])
      })
    }
  }, [data])
  const [expence, setExpences] = useState([
    {
      label: "Traffic Cost",
      value: "0"
    },
    {
      label: "Merchant Fees",
      value: "0"
    },
    {
      label: "Product Cost",
      value: "0"
    },
    {
      label: "Refunds",
      value: "0"
    },
    {
      label: "Other",
      value: "0"
    },
  ])
  const details = [
    {
      id: 1,
      year: 2016,
      TrafficCost: 80000,
      userLost: 823
    },
    {
      id: 2,
      year: 2017,
      MerchantFees: 45677,
      userLost: 345
    },
    {
      id: 3,
      year: 2018,
      ProductCost: prodCost,
      userLost: 555
    },
    {
      id: 4,
      year: 2019,
      userGain: 90000,
      userLost: 4555
    },
    {
      id: 5,
      year: 2020,
      userGain: 4300,
      userLost: 234
    }
  ];
  const [datas, setData] = useState([])
  const [chartData, setChartData] = useState({
    datasets: [
      {
        label: simulateData?.expenses && simulateData?.expenses.map((data) => data.label),
        data: simulateData?.expenses && simulateData?.expenses.map((data) => data.value),
        backgroundColor: [
          "rgba(140, 194, 62, 0.8)",
          "#8CC23E",
          "rgba(140, 194, 62, 0.6)",
          "rgba(140, 194, 62, 0.4)",
        ],
        borderColor: [
          "rgba(140, 194, 62, 0.8)",
          "#8CC23E",
          "rgba(140, 194, 62, 0.6)",
          "rgba(140, 194, 62, 0.4)",
        ],
        borderWidth: 2,
      },
    ],
  });

  const trafficSource = [
    {
      name: "Google",
      id: 1
    },
    {
      name: "Facebook",
      id: 2
    },
    {
      name: "Others",
      id: 3
    },
  ]

  const Simulate = () => {
    let cost = 0;
    // allNodes?.[0]?.products?.forEach((product) => {
    //   cost = cost + product?.product_cost;
    //   //console.log("prodcost=>", product); 
    // });
    // setProdCost(cost);
    setIsSimulate(true);
    setShowSimulateData(true);
  }

  useEffect(() => {
    if (simulateData?.expenses) {
      setChartData({
        labels: simulateData?.expenses.map((data) => data.label),
        datasets: [
          {
            // label: 'Expense',
            data: simulateData?.expenses.map((data) => data.value),
            backgroundColor: [
              "rgba(140, 194, 62, 0.8)",
              "#8CC23E",
              "rgba(140, 194, 62, 0.6)",
              "rgba(140, 194, 62, 0.4)",
            ],
            borderColor: [
              "rgba(140, 194, 62, 0.8)",
              "#8CC23E",
              "rgba(140, 194, 62, 0.6)",
              "rgba(140, 194, 62, 0.4)",
            ],
            borderWidth: 1,
          },
        ],
      });
      let datas = [];
      simulateData?.expenses?.map((obj, i) => {
        if (obj?.value) {
          let json = {};
          json["label"] = obj.label;
          json["data"] = [obj.value];
          if (i % 2 === 0) {
            json.backgroundColor = [
              "rgba(140, 194, 62, 0.8)",
              "#8CC23E",
              "rgba(140, 194, 62, 0.6)",
              "rgba(140, 194, 62, 0.4)",
            ];
            json.borderColor = [
              "rgba(140, 194, 62, 0.8)",
              "#8CC23E",
              "rgba(140, 194, 62, 0.6)",
              "rgba(140, 194, 62, 0.4)",
            ];
            json.borderWidth = i + 1;
          } else {
            json.backgroundColor = [
              "red",
              "red",
              "red",
              "red",
            ];
            json.borderColor = ["blue", "blue", "blue", "blue"];
            json.borderWidth = i + 1;
          }

          datas.push(json);
        }

      })
      // setChartData({datasets:datas});
      // setData(datas);
    }
  }, [simulateData?.expenses]);
  console.log('selectedSummaryrs: ', simulateData);
  //console.log(showSidebar)

  return (
    <div
      className={`right__sidebar ${showSidebar ? "showSideBar2" : "hideSideBar"
        }`}
    >
      <div
        onClick={() => setShowSidebar(!showSidebar)}
        className="right_side_open"
      >
        <MdOutlineArrowLeft className={showSidebar ? "rotate" : ""} />
      </div>
      <div className="sidebarr_inner">
        <div style={{ marginTop: "10px" }}>
          <Dropdown
            // options={products ? products : []}
            options={simulateData?.products ? simulateData?.products : []}
            selectedOption="Sales"
          />
          <Dropdown
            options={simulateData?.traffic ? simulateData?.traffic : []}
            selectedOption="Traffic Sources"
          />
          <Dropdown
            options={simulateData?.expenses ? simulateData?.expenses : []}
            selectedOption="Expenses"
          />
          <div
            onClick={() => setShowSummryModel(!showSummryModel)}
            className="summary"
          >
            <p>Summary</p>
            <AiFillSetting />
          </div>
        </div>

        <div>
          <div className="pieChart">
            <Pie
              style={{ height: "212px" }}
              data={chartData}
              options={{
                plugins: {
                  title: {
                    display: false,
                    text: "Expense Chart",
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
          {simulateData?.summary && selectedSummary?.map((item, index) => {
            return (
              <div key={index} className="butons_right_sidebar">
                <p>{item}</p>
                <p>
                  {simulateData?.summary[item]
                    ? simulateData?.summary[item]
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div
        onClick={Simulate}
        className={`simulateButton ${showSidebar ? "" : "hideSimButton"}`}
      >
        <h2>Simulate</h2>
      </div>
    </div>
  );
}

export default RightSidebar