import React, { useEffect, useState } from 'react'
import { Canvas, Editor, Element, Frame, useEditor, useNode } from '@craftjs/core'
import TextComponent from './TextComponent'
import { getPageById } from "../../Services/api"
import { Container } from './Components/Container'
import DragableContainer from './Components/DragableContainer'
import { SModal } from './Components/modal'
import { Video } from './Components/Video'
import Inputs from './Components/forms/input'
import Selects from './Components/forms/select'
import Radios from './Components/forms/radio'
import RadioLabel from './Components/forms/radioLabel'
import Text from "./Components/texts";
import FormContainer from "./Components/forms/form";
import Card, { CardTop, CardBottom } from "./Components/Card";
import MButton from './Components/Button';
import ImageTag from './Components/imageTag'
import Slider from './Components/sliderBox'
import SButton from './Components/buttons'
import Texts from "./Components/Text";
import Product from "./Components/product";
import Template from './Components/Template'
import Paypal from './Components/PaypalButton'
import Testimonial from "./Components/Testimonials"
import GridContainer from "./Components/Grid"
import Loader from '../../Loader'
import axios from 'axios'
import { common } from '../../BaseUrl'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import usePageBuilder from '../../Context/pageBuilder'
// import json from "./page.json"
const Preview = ({ page_id }) => {
const {funeelIdForPayment,setFuneelIdForPayment} = usePageBuilder()
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [jsonData, setJsonData] = useState([])
    // const[pageId, setPageId] = useState("")
    useEffect(() => {
        getPage();
        console.log('json dta', jsonData);
    }, [])
    const getPage = () => {
        setIsLoading(true)

        axios({
            method: "post",
            url: `${common}/page-builder/pageRenderForUsers`,
            data: {
                url: window.location.href
            }
        }).then(resp => {
            console.log("pageRender", resp.data)
            // setPageId(resp.data?.data?.pageId)
            localStorage.setItem('funelId',resp.data?.data?.funnelId)
            setFuneelIdForPayment(resp.data?.data?.funnelId)
            countTrafic(resp.data?.data?.pageId,resp.data?.data?.funnelId)

            setTimeout(() => {
                setJsonData(JSON.parse(resp.data?.data?.content))
                setShow(true)
                setIsLoading(false)
            }, 1000);
        }).catch(err => {
            setShow(true)
        })

    }

    useEffect(() => {
        // Check if a user identifier exists in cookies
        let userId = Cookies.get('userId');

        if (!userId) {
            // Generate a unique identifier if it doesn't exist
            userId = uuidv4();
            // Set the user identifier in cookies
            Cookies.set('userId', userId, { expires: 365 });
        }

        // Use the user identifier for further actions or API calls
        console.log('User ID:', userId);
    }, []);


   
    const countTrafic = (pageId,funnelId) => {
        
       axios.get('https://api.ipify.org?format=json').then(resp => {
            
            console.log(resp.data.ip,"ip")
            axios({
                method: "post",
                url: `${common}/traffic/store`,
                data: {
                    pageId: pageId,
                    ip_address: resp.data.ip,
                    unique_identity: Cookies.get('userId'),
                    funnelId:funnelId
                }
    
            })
        })

      

    }
    return <>

        {
            isLoading ? <Loader idShow={true} /> : <Editor
                resolver={{
                    TextComponent,
                    Container,
                    FormContainer,
                    Card,
                    MButton,
                    CardTop,
                    CardBottom,
                    Text,
                    ImageTag,
                    SButton,
                    Texts,
                    Video,
                    Inputs,
                    Selects,
                    RadioLabel,
                    Radios,
                    Slider,
                    Template,
                    SModal,
                    Paypal,
                    Product,
                    DragableContainer,
                    Testimonial,
                    GridContainer


                }}
                enabled={false}
            >
                <Frame json={jsonData}>
                </Frame>

            </Editor>
        }
    </>
}

export default Preview