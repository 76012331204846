import React, { useEffect, useRef } from "react";
import Konva from "konva";
import { Stage, Layer, Path, Circle } from "react-konva";
import { Html } from "react-konva-utils";
import { IoIosGitNetwork } from "react-icons/io";
import { CgTemplate } from "react-icons/cg";
import { BsCartCheck, BsThreeDots } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";

function WarRoom() {
  const dragUrl = React.useRef();
  const stageRef = React.useRef();
  const stageWrapperRef = React.useRef();
  const [images, setImages] = React.useState([]);
  const [currentDragId, setCurrentDragId] = React.useState(-1);
  const [stage, setStage] = React.useState({
    stageScale: 1,
    stageX: 0,
    stageY: 0,
  });

  const pathRef = useRef(null);
  const [position1, setPosition1] = React.useState({ x: 10, y: 10 });
  const [position2, setPosition2] = React.useState({ x: 10, y: 10 });
  const bezierWeight = 0.675;

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    setStage({
      stageScale: newScale,
      stageX:
        -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      stageY:
        -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });
  };

  const positionUpdate = (image, x, y, index) => {
    const copyImage = image;
    const copyImages = images;
    //console.log("eee", y, stageWrapperRef.current.getBoundingClientRect()?.y);
    copyImage.x = x - stageWrapperRef.current.getBoundingClientRect()?.x;
    copyImage.y = y - stageWrapperRef.current.getBoundingClientRect()?.y;
    copyImages.splice(index, 1, copyImage);
    setImages(copyImages);
  };

  const handleDelete = (id) => {
    const copyImages = images?.filter((e) => e.id !== id);
    setImages(copyImages);
  };

  useEffect(() => {
    const path = pathRef.current;

    const x1 = position1.x;
    const y1 = position1.y;

    const x4 = position2.x;
    const y4 = position2.y;

    const dx = Math.abs(x4 - x1) * bezierWeight;

    const x2 = x1 - dx;
    const x3 = x4 + dx;

    // set path properties
    path.data(`M${x1} ${y1} C ${x2} ${y1} ${x3} ${y4} ${x4} ${y4}`);
    path.stroke("black");
    path.strokeWidth(5);

    // add path to layer
    const layer = path.getLayer();
    layer.batchDraw();
  }, [position1, position2]);

  const handleDragEnd1 = (e) => {
    // update circle position
    const circle = e.target;
    const layer = circle.getLayer();
    const pos = circle.absolutePosition();

    

    const shapes = layer?.children.filter(
      (node) => node.getClassName() === "Group"
    );
    shapes.forEach((shape) => {
      const overlapping = Konva.Util.haveIntersection(
        e.target.getClientRect(),
        shape.getClientRect()
      );
      if (overlapping) {
        //console.log("shapes overlap", shape);
      }
    });

    setPosition1(pos);
    circle.setAbsolutePosition(pos);
    layer.batchDraw();
  };
  const handleDragEnd2 = (e) => {
    // update circle position
    const circle = e.target;
    const layer = circle.getLayer();
    const pos = circle.absolutePosition();

    setPosition2(pos);
    circle.setAbsolutePosition(pos);
    layer.batchDraw();
  };

  return (
    <div>
      <div className="box-wrapper">
        <div
          className="box"
          draggable="true"
          onDragStart={(e) => {
            dragUrl.current = { id: images?.length + 1, type: "TRAFFIC" };
          }}
        >
          Traffic
        </div>
        <div
          className="box"
          draggable="true"
          onDragStart={(e) => {
            dragUrl.current = { id: images?.length + 1, type: "TEMPLATE" };
          }}
        >
          Page
        </div>
        <div
          className="box"
          draggable="true"
          onDragStart={(e) => {
            dragUrl.current = { id: images?.length + 1, type: "ACTION" };
          }}
        >
          Action
        </div>
      </div>
      <div
        ref={stageWrapperRef}
        onDrop={(e) => {
          e.preventDefault();
          // register event position
          stageRef.current.setPointersPositions(e);

          // add image
          if (images?.filter((e) => e.id === currentDragId).length === 0) {
            //console.log("ref", dragUrl.current);
            setImages(
              images.concat([
                {
                  ...stageRef.current.getPointerPosition(),
                  ...dragUrl.current,
                },
              ])
            );
          }
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        <Stage
          width={window.innerWidth}
          height={window.innerHeight}
          style={{ border: "1px solid grey" }}
          ref={stageRef}
          onWheel={handleWheel}
          scaleX={stage.stageScale}
          scaleY={stage.stageScale}
          x={stage.stageX}
          y={stage.stageY}
        >
          <Layer>
            <Path ref={pathRef} />
            <Circle
              x={position2.x}
              y={position2.y}
              radius={10}
              fill="blue"
              draggable
              onDragMove={handleDragEnd2}
            />
            <Circle
              x={position1.x}
              y={position1.y}
              radius={10}
              fill="red"
              draggable
              onDragMove={handleDragEnd1}
            />
            {images.map((image, index) => {
              return (
                <Html key={index} groupProps={{ x: image.x, y: image.y }}>
                  {image.type === "TRAFFIC" ? (
                    <div
                      className=""
                      draggable
                      onDragStart={(e) => {
                        setCurrentDragId(index + 1);
                      }}
                      onDrag={(e) => {
                        positionUpdate(image, e.clientX, e.clientY, index);
                        //console.log("mov", e);
                      }}
                      onDragEnd={(e) => {
                        positionUpdate(image, e.clientX, e.clientY, index);
                        setCurrentDragId(-1);
                      }}
                    >
                      <div className="box-internal-wrapper">
                        <button
                          className="del-btn"
                          onClick={() => handleDelete(image.id)}
                        >
                          <TiDelete />
                        </button>
                        <div className="box-body">
                          <span className="icon-container">
                            <IoIosGitNetwork />
                          </span>
                        </div>
                        <div className="action-bar">
                          Traffic{" "}
                          <button className="action-btn">
                            <BsThreeDots />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {image.type === "TEMPLATE" ? (
                    <div
                      className=""
                      draggable
                      onDragStart={(e) => {
                        setCurrentDragId(index + 1);
                      }}
                      onDrag={(e) => {
                        positionUpdate(image, e.clientX, e.clientY, index);
                        //console.log("mov", e);
                      }}
                      onDragEnd={(e) => {
                        positionUpdate(image, e.clientX, e.clientY, index);
                        setCurrentDragId(-1);
                      }}
                    >
                      <div className="box-internal-wrapper">
                        <button
                          className="del-btn"
                          onClick={() => handleDelete(image.id)}
                        >
                          <TiDelete />
                        </button>
                        <div className="box-body">
                          <span className="icon-container">
                            <CgTemplate />
                          </span>
                        </div>
                        <div className="action-bar">
                          Page
                          <button className="action-btn">
                            <BsThreeDots />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {image.type === "ACTION" ? (
                    <div
                      className=""
                      draggable
                      onDragStart={(e) => {
                        setCurrentDragId(index + 1);
                      }}
                      onDrag={(e) => {
                        positionUpdate(image, e.clientX, e.clientY, index);
                        //console.log("mov", e);
                      }}
                      onDragEnd={(e) => {
                        positionUpdate(image, e.clientX, e.clientY, index);
                        setCurrentDragId(-1);
                      }}
                    >
                      <div className="box-internal-wrapper">
                        <button
                          className="del-btn"
                          onClick={() => handleDelete(image.id)}
                        >
                          <TiDelete />
                        </button>
                        <div className="box-body">
                          <span className="icon-container">
                            <BsCartCheck />
                          </span>
                        </div>
                        <div className="action-bar">
                          Action
                          <button className="action-btn">
                            <BsThreeDots />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Html>
              );
            })}
          </Layer>
        </Stage>
      </div>
    </div>
  );
}

export default WarRoom;
