import React, { useEffect, useState } from 'react'
import styles from "./EditProduct.module.css"
import { BsFillLightningChargeFill, BsImage, BsPaypal } from 'react-icons/bs'
import { AiOutlineInfoCircle, AiOutlinePlusCircle, AiOutlineUpload } from 'react-icons/ai'
import useProvider from '../../Context/Context'
import { useLocation } from 'react-router-dom'
import { common } from '../../BaseUrl'
import axios from 'axios'
import { BiPlus } from 'react-icons/bi'
import { toast } from 'react-toastify'
import { FaEdit, FaStripeS } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { getProduct } from '../../Services/api'
import Loader from '../../Loader'
function loadXHR(url) {

    return new Promise(function (resolve, reject) {
        try {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.onerror = function () { reject("Network error.") };
            xhr.onload = function () {
                if (xhr.status === 200) { resolve(xhr.response) }
                else { reject("Loading error:" + xhr.statusText) }
            };
            xhr.send();
        }
        catch (err) { reject(err.message) }
    });
}
function EditProduct() {
    const prodId = useLocation().state?.id
    const { setProdPayload, ProdPayload, assetsModel, setShowaAssetsModel, allPrice, setAllPrice, setShowAddPriceModel, SetEditOnePriceModel, SetEditSubPriceModel, SetEditPayPriceModel, setPricePayload } = useProvider()
    //console.log("prod id =>", allPrice)
    const [isLoading, setIsloading] = useState(false)
    const [uploadedImage, setUploadedImage] = useState(null)
    const [Filename, setFileName] = useState('')
    const [prices, setPrices] = useState(['1', '1', '1'])
    const handleChange = (file) => {
        setFileName(file.name)
        const fr = new FileReader()
        fr.readAsArrayBuffer(file)
        fr.onload = function () {
            const blob = new Blob([fr.result])
            //console.log("blob", blob)
        }
        const imageUrl = URL.createObjectURL(file)
        setUploadedImage(imageUrl)
        if (!file) {
            setUploadedImage(null)
            return
        }
        loadXHR(imageUrl).then(result => {
            let formData = new FormData()
            //console.log(result)
            formData.append('file', result, "Asset.png")
            //console.log('form data', formData)
            axios({
                method: "post",
                url: `${common}/products/uploadImage/`,
                data: {
                    productId: prodId,
                    image: result
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(res => {
                console.log(res.data)
                toast.success("Image uploaded")
                getProduct(prodId).then(res => {
                    console.log(res)
                    setProdPayload(res.data.data)
                    setAllPrice(res.data.data.prices)
                }).catch(error => {
                    toast.error("Something Wrong while fetching products")
                })
            }).catch(err => {
                toast.error("Something wrong")
            })

        })
    }
    useEffect(() => {
        setIsloading(true)
        getProduct(prodId).then(res => {
            console.log(prodId)
            console.log(res)
            setIsloading(false)
            setProdPayload(res.data.data)
            setAllPrice(res.data.data.prices)
        }).catch(error => {
            toast.error("Something Wrong while fetching products")
        })
    }, [])


    const updateProd = () => {
        axios({
            method: "put",
            url: `${common}/products/update/${prodId}`,
            data: ProdPayload,
            headers: {
                'Content-Type': "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            //console.log(res)
            setProdPayload({ ...ProdPayload, name: res.data.data.name, description: res.data.data.description })
            toast.success('Updated successfully')

        })
    }


    const getPrice = (id) => {

        axios({
            method: "get",
            url: `${common}/products/getPrice/${id}`,
            headers: {
                'Content-Type': "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            //console.log(res)
            setPricePayload(res.data.data)

        })
    }

    const handleEditModel = (plan, priceId) => {
        getPrice(priceId)
        if (plan === 'Onetime') {
            SetEditOnePriceModel(true)
        }
        if (plan === 'Subscription') {
            SetEditSubPriceModel(true)
        }
        if (plan === 'PaymentPlan') {
            SetEditPayPriceModel(true)
        }

    }
    //console.log("prod payload=>", ProdPayload)



    const addToPayMethod = (id) => {
        axios({
            method: "post",
            url: `${common}/products/addPriceToPaypal`,
            data: {
                id: id,
                paypal_prod_id: ProdPayload?.paypal_prod_id
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(resp => {
            toast.success("Price added successfully")
        }).catch(err => {

            toast.error(err?.message)
        })
    }
    const addToStripe = (id) => {
        axios({
            method: "post",
            url: `${common}/products/addPriceToStripe`,
            data: {
                id: id,
                stripe_prod_id: ProdPayload?.stripe_prod_id
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(resp => {
            toast.success("Price added successfully")
        }).catch(err => {

            toast.error(err?.message)
        })
    }
    const addToChargebee = (id) => {
        axios({
            method: "post",
            url: `${common}/products/addPriceToChargeBee`,
            data: {
                id: id,
                product_id: ProdPayload?.id
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(resp => {
            toast.success("Price added successfully")
        }).catch(err => {

            toast.error(err?.message)
        })
    }
    return (
        <div className={styles.Funnels}>
            {
                isLoading ? <Loader /> : <div className={styles.RightSide}>

                    <div className={styles.productDescription}>
                        <div className={styles.prodHead}>
                            <h1 className={styles.FunnelsHead}>{ProdPayload.name}</h1>
                            <div className={styles.buttons}>
                                <button className={styles.discard}>Discard</button>
                                <button className={styles.Update} onClick={updateProd}>Update Product</button>
                            </div>
                        </div>
                        <div className={styles.EditProductInputField}>
                            <p>Name</p>
                            <input value={ProdPayload.name} onChange={(e) => setProdPayload({ ...ProdPayload, name: e.target.value })} type="text" name="" id="" />
                        </div>
                        <div className={`${styles.EditProductInputField} ${styles.description}`}>
                            <p>Description</p>
                            <textarea onChange={(e) => setProdPayload({ ...ProdPayload, description: e.target.value })} value={ProdPayload.description} type="text" name="" id="" />
                        </div>
                        <div className={styles.ImageUpload}>
                            <p>Image</p>
                            <div className={styles.uploadContainer}>
                                {!ProdPayload?.image && <div className={styles.image}>
                                    <input onChange={(e) => handleChange(e.target.files[0])} type="file" name="" id="" />
                                    <div className={styles.iconsAndText}>
                                        <AiOutlineUpload />
                                        {Filename ? <p>{Filename}</p> : <p>Select image or drop image here</p>}
                                    </div>
                                </div>}
                                {ProdPayload?.image && <img src={ProdPayload?.image} />}
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.productDescription}`}>
                        <div className={styles.productHeadBUtton}>
                            <p>Pricing</p>
                            <button onClick={() => setShowAddPriceModel(true)} className='closebuttn'>Add Price</button>
                        </div>
                        <div className={styles.productPrice}>
                            <div className={styles.priceContainer}>
                                <p>Price</p>
                                <p>Compare at amount</p>
                                <p>Status</p>
                                <div className={styles.visibleButton}>
                                    <p>Visible</p>
                                    <AiOutlineInfoCircle />
                                </div>
                            </div>
                            {
                                allPrice?.map(price => {
                                    return (
                                        <div className={styles.priceContainer2}>
                                            <p className={styles.priceEdit}>{price?.amount}
                                                <MdEdit
                                                    onClick={() => handleEditModel(price.price_plan, price._id)}
                                                /> </p>
                                            <p>{price?.compare_amount}</p>
                                            <p> <span></span> Active</p>
                                            <input style={{ accentColor: `${price?.is_visible ? '#8CC23E' : "red"}` }} type="checkbox" checked={price?.is_visible} name="" id="" />
                                            <div style={{ display: "flex", gap: "5px" }}>
                                                {ProdPayload?.paypal_prod_id && <BsPaypal color={price?.paypal_price_id ? "#3b7bbf" : "gray"} className={price?.paypal_price_id ? "no-drop-cursor" : ""} size={20} onClick={() => {
                                                    if (!(price?.paypal_prod_id)) {
                                                        addToPayMethod(price._id)
                                                    }
                                                }} />}
                                                {ProdPayload?.stripe_prod_id && <FaStripeS 
                                                
                                                color={price?.stripe_price_id ? "#3b7bbf" : "gray"} className={price?.stripe_price_id ? "no-drop-cursor" : ""}
                                                onClick={() => {
                                                    if (!(price?.stripe_prod_id)) {
                                                        addToStripe(price.id)
                                                    }
                                                }} size={20}

                                                // onClick={() => addToStripeMethod(item._id)}
                                                />}
                                                {ProdPayload?.id &&<BsFillLightningChargeFill color='#008cdd' size={20}
                                                onClick={() => addToChargebee(price._id)} 
                                                />}
                                            </div>


                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={`${styles.productDescription} ${styles.purchaseconatiner}`}>
                        <p>Purchase actions</p>
                        <div className={styles.EditProductPurchase}>
                            <p>Digital asset access</p>
                            <AiOutlinePlusCircle onClick={() => setShowaAssetsModel(true)} />
                        </div>
                    </div>

                </div>
            }


        </div>
    )
}

export default EditProduct