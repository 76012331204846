import { useNode, useEditor } from '@craftjs/core';
import React, { useEffect } from 'react';
import ContentEditable from 'react-contenteditable';

import { TextSettings } from './TextSettings';
import Container from '../Container';
import usePageBuilder from '../../../../Context/pageBuilder';
import store from '../../../../redux/store';


export const CustomCode = ({
  fontSize,
  textAlign,
  fontWeight,
  color,
  shadow,
  text,
  margin,
  fontFamily,
  fontStyle,
  noContainer,
  html,
  TimingFunction, animationDuration, animationType, animationDelay, animateOnce
}) => {
  const {
    connectors: { connect },
    setProp,
  } = useNode();
  const { setAnimationType, globalFont, setGlobalFont, } = usePageBuilder()

  useEffect(() => {
    setAnimationType(animationType)

  }, [animationType, TimingFunction, animationDuration, animationDelay, animateOnce])
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  console.log("animation Type", globalFont)
  const getCode = () => {

    return
  }
  return (
    <div canvas ref={connect}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

CustomCode.craft = {
  displayName: 'CustomCode',
  props: {
    fontSize: '15',
    textAlign: 'left',
    fontWeight: '500',
    color: { r: 92, g: 90, b: 90, a: 1 },
    margin: [0, 0, 0, 0],
    shadow: 0,
    text: 'Text',
    html: "123S"
  },
  related: {
    toolbar: TextSettings,
  },
};
export default CustomCode