
import React, { useState } from 'react'
import useProvider from '../../Context/Context'
import "./PageDrawer.css"

import pageTemp from "../../assets/logo/pageTemp.svg"
import { RxCross1 } from 'react-icons/rx'
function PagesDrwer(props) {
    const { pageDrawer, SetPageDrawer, pageByCategory } = useProvider()

    const onDragStart = (event, nodeType, nodeName, id, pageData) => {
        const data = {
            type: nodeType,
            name: nodeName,
            id: id,
            pageData: pageData
        };
        event.dataTransfer.setData("application/reactflow", JSON.stringify(data));
        event.dataTransfer.effectAllowed = "move";
    };
    console.log("pageDrawer", pageByCategory)
    return (
        <div onClick={(e) => { e.stopPropagation(); SetPageDrawer(false) }} className={` pageDrawer ${pageDrawer ? 'opaneDawer' : "closerDrawer"}`}>
            <div onClick={(e) => { e.stopPropagation() }} className='pageDrawer__body'>
                <div className="pageDrawer__body__inner">

                    {
                        pageByCategory?.map((page, index) => {
                            return (
                                <div style={{position:"relative", borderRadius:"10px"}} >
                                     {index == 0 && <RxCross1 onClick={() => SetPageDrawer(false)} className='closePageDarwer' color='black' size={30} />}
                                    <div
                                        style={{ marginTop: `${index === 0 && pageByCategory?.length >= 6 ? '800px' : index === 0 && pageByCategory?.length <= 6 ? "200px" : ""}`, cursor: "grabbing" }}
                                        // onClick={() => SetPageDrawer(!pageDrawer)}
                                        className="newPages"
                                        onDragStart={(event) => onDragStart(event, "pageNode", page?.category?.[0]?.name, page._id, page)}
                                        draggable
                                    >
                                       
                                        <img src={`${page?.thumbnail ? page.thumbnail : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9YYh5Fk1u9VsWWr1MhkyQeOzeNbtnnMO96g&usqp=CAU'}`} alt="" />
                                        <p>{page?.name}</p>


                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default PagesDrwer