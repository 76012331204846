/* eslint-disable no-unused-expressions */
import React from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';
import styled from 'styled-components';


const SidebarItemDiv = styled.div`
  height: ${(props) =>
    props.visible && props.height && props.height !== 'full'
      ? `${props.height}`
      : 'auto'};
  flex: ${(props) =>
    props.visible && props.height && props.height === 'full' ? `1` : 'unset'};
  color: #545454;
`;

const Chevron = styled.a`
  transform: rotate(${(props) => (props.visible ? 180 : 0)}deg);
  svg {
    width: 8px;
    height: 8px;
  }
`;

// export type SidebarItemProps = {
//   title: string;
//   height?: string;
//   icon: string;
//   visible?: boolean;
//   onChange?: (bool: boolean) => void;
//   children?: React.ReactNode;
// };

const HeaderDiv = styled.div`
  color: #615c5c;
  height: 45px;
  svg {
    fill: #707070;
  }
`;

export const SidebarItem = (props) => {
  return (
    <SidebarItemDiv
      visible={props?.visible}
      height={props?.height}
      className="flex flex-col"
    >
      <HeaderDiv
        onClick={(e) => {
          if (props?.onChange) props?.onChange(!props?.visible);
        }}
        className={`cursor-pointer bg-white border-b last:border-b-0 flex items-center px-2 ${
          props?.visible ? "shadow-sm" : ""
        }`}
      >
        <div className="flex-1 flex items-center">
          {props?.icon}
          {/* , { className: "w-4 h-4 mr-2" })} */}
          <h2 className="text-xs uppercase">{props?.title}</h2>
        </div>
        <Chevron visible={props?.visible}>
          <AiOutlineArrowDown />
        </Chevron>
      </HeaderDiv>
      {props?.visible ? (
        <div className="w-full flex-1 overflow-auto">{props?.children}</div>
      ) : null}
    </SidebarItemDiv>
  );
};
export default SidebarItem;