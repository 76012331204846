import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { IoIosGitNetwork } from "react-icons/io";
import { CgTemplate } from "react-icons/cg";
import { BsCartCheck, BsThreeDots } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";
import "./PageNode.css"
import "./ActionNode.css"
import useProvider from "../../Context/Context";

export default memo(({ id, data, isConnectable }) => {
  const handleDelete = () => {
    data.onDelete(id);
  };
  const {setDelId,DelId} = useProvider()
  return (
    <div style={{position:"relative"}} onClick={(e) => { e.stopPropagation(); setDelId(id)}} >
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
        className="ActionHandle"
      />
      <div>
        <div>
          <div  className={`box-internal-wrapper ${id===DelId?"box-internal-wrapper_active":""}`}>

            <div className="box-body">
              <span className="icon-container">
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.25 14.2059V1.01471C17.25 0.456618 16.7934 0 16.2353 0H1.01471C0.456618 0 0 0.456618 0 1.01471V14.2059C0 14.764 0.456618 15.2206 1.01471 15.2206H16.2353C16.7934 15.2206 17.25 14.764 17.25 14.2059ZM2.02941 2.02941H15.2206V6.08823H2.02941V2.02941ZM3.04412 3.04412V5.07353H6.08824V3.04412H3.04412ZM7.10294 3.04412V5.07353H10.1471V3.04412H7.10294ZM11.1618 3.04412V5.07353H14.2059V3.04412H11.1618ZM2.53676 8.11765C2.82088 8.11765 3.04412 8.34088 3.04412 8.625C3.04412 8.90912 2.82088 9.13235 2.53676 9.13235C2.25265 9.13235 2.02941 8.90912 2.02941 8.625C2.02941 8.34088 2.25265 8.11765 2.53676 8.11765ZM4.05882 8.11765H8.11765V9.13235H4.05882V8.11765ZM10.1471 8.11765H15.2206V13.1912H10.1471V8.11765ZM2.53676 10.1471C2.82088 10.1471 3.04412 10.3703 3.04412 10.6544C3.04412 10.9385 2.82088 11.1618 2.53676 11.1618C2.25265 11.1618 2.02941 10.9385 2.02941 10.6544C2.02941 10.3703 2.25265 10.1471 2.53676 10.1471ZM4.05882 10.1471H8.11765V11.1618H4.05882V10.1471ZM11.1618 10.1471V12.1765H14.2059V10.1471H11.1618ZM2.53676 12.1765C2.82088 12.1765 3.04412 12.3997 3.04412 12.6838C3.04412 12.9679 2.82088 13.1912 2.53676 13.1912C2.25265 13.1912 2.02941 12.9679 2.02941 12.6838C2.02941 12.3997 2.25265 12.1765 2.53676 12.1765ZM4.05882 12.1765H8.11765V13.1912H4.05882V12.1765Z" fill="white" />
                </svg>
              </span>
              <p>{data.label}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
