import React, { memo, useEffect, useState } from "react";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { IoIosGitNetwork } from "react-icons/io";
import { CgTemplate } from "react-icons/cg";
import { BsCartCheck, BsThreeDots } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";
import { AiFillCheckSquare } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import "./PageNode.css"
import useProvider from "../../Context/Context";
import { SALES_PAGE } from "../../Configs";




export default memo(({ id, data, isConnectable, edges, ...props }) => {
  console.log("props: ", data);
  const updateNodeInternals = useUpdateNodeInternals();

  const {
    setOpenModel,
    pageColor,
    showNport,
    showCalculation,
    copyNode,
    setCopyNode,
    setDelId,
    DelId,
    nodesSelected,
    seNodesSelected,
    editNodeId,
    setEditNodeId,
    settingPayload,
    setSettingPayload,
    allNodes,
    showSimulateData,
    setAllNodes, setPageLabel,
    selectedProd, setSelectedProd,
    allProducts,
    openModel,
    saveFunnel,
    pageLabel
  } = useProvider();
  useEffect(() => {
    const time = setTimeout(() => {
      updateNodeInternals(id)

      //console.log('consoleId', id)
    }, 10)

    return () => clearTimeout(time)
  }, [id, selectedProd, edges, openModel])


  //console.log("allNodes: ", allNodes);
  const [currentPage, setCurrentPage] = useState();
  const [leads, setLeads] = useState(134);
  const [traffic, setTraffic] = useState(500);

  //console.log("currentPage: ", currentPage);
  //console.log("node data=>", settingPayload);
  const handleDelete = (e) => {
    //console.log("key=>", e);
    if (e.key === "Delete") {
      data.onDelete(id);
    }
  };

  useEffect(() => {
    if (allNodes.find((item) => item.id == id)) {
      setCurrentPage(allNodes?.filter((item) => item.id == id)[0]);
    }
    // if(allNodes.)
  }, [allNodes]);
  // useEffect(() => {
  //   const startSelection = () => {
  //     return nodesSelected.filter((item) => item.id === id);
  //   };
  //   // const savedNode = allNodes.find(item => item.id == id)
  //   // setCurrentPage(savedNode)
  // }, [settingPayload]);
  const modelOpen = () => {
    setOpenModel(true);
    setEditNodeId(id);
    const savedNode = allNodes.find((item) => item.id == id);
    console.log("saved node=>", savedNode)
    seNodesSelected(savedNode)
    if (savedNode?.products){
      setSelectedProd(savedNode?.products)
    }
    setPageLabel(savedNode?.data.label)
    if (savedNode?.userData) {
      setSettingPayload(savedNode.userData);
    } else {
      console.log("savednodedata", savedNode?.userData)
      setSettingPayload({
        page_name: "",
        checkout_rate: "",
        is_home: false,
        n_port: true,
        notes: "",
        object_Name: "",
        theme_Color: "",
        url: "",
        thumnail: ""
      });
    }
  };
  //console.log("current page =>", currentPage);

  const handles = () => {

  }
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setDelId(id);
      }}
      onKeyDown={(e) => handleDelete(e)}
      style={{ position: "relative" }}
      onDoubleClick={modelOpen}
    >
      {showSimulateData && (
        <div className="rete__Container">
          <div className="rate__box">
            <p>Leads</p>
            <p>
              {currentPage?.traffic && currentPage?.userData?.checkout_rate
                ? Math.round(
                  (currentPage?.userData?.checkout_rate *
                    currentPage?.traffic) /
                  100
                )
                : 0}
            </p>
          </div>
          <div className="rate__box">
            <p>Traffic</p>
            <p>{currentPage?.traffic ? currentPage?.traffic : 0}</p>
          </div>
        </div>
      )}
      {showNport && (
        <Handle
          type="source"
          id="page_no"
          position={Position.Bottom}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={isConnectable}
          className="HandleNO"
          onConnectStop
        ></Handle>
      )}
      <p className="norate">
        {currentPage?.userData?.checkout_rate
          ? 100 - +currentPage?.userData?.checkout_rate
          : 0}
        %
      </p>

      <Handle
        isConnectableStart={false}
        type="target"
        position={Position.Left}
        onMouseDown={(e) => console.log("mouse down", e.target)}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
        className="target__point"
        aria-disabled={false}
      />
      <div>
        <div
          className={`box-internal-wrapper ${id === DelId ? "box-internal-wrapper_active" : ""
            }`}
        >
          <div className="box-body">
            <span className="icon-container">
              <img src={data?.icon} alt="" />

            </span>
            <p>{data.label}</p>
          </div>
        </div>
      </div>
      {(currentPage?.data?.label != SALES_PAGE) && (
        <Handle
          id="page_yes"
          type="source"
          position={Position.Right}
          onConnect={(params, page) => console.log("handle onConnect", params)}
          isConnectable={isConnectable}
          className="HandleYes"
        />
      )}
      {currentPage?.data?.label == SALES_PAGE &&
       ( // allNodes?.map((prod, index) => {
          // console.log('currentPage: ', currentPage);  
          // console.log("mapNodes", prod)
          // updateNodeInternals(id)

        currentPage?.products?.map((item, index) => {
            // console.log("items=>", index)

            // return
           return  (
              <div>
                <Handle
                  id={item?._id}
                  type="source"
                  key={"handle" + index}
                  position={Position.Right}
                  style={{ top: `${currentPage?.products?.length === 1 ? 50 : currentPage?.products?.length === 2 ? (index === 0 ? 30 : index == 1 ? 70 : "") : currentPage?.products?.length === 3 ? (index === 0 ? 25 : index == 1 ? 50 : index == 2 ? 75 : "") : currentPage?.products?.length === 4 ? (index === 0 ? 20 : index == 1 ? 40 : index == 2 ? 60 : index == 3 ? 80 : "") : currentPage?.products?.length === 5 ? (index === 0 ? 10 : index == 1 ? 30 : index == 2 ? 50 : index == 3 ? 70 : index == 4 ? 90 : 100) : ""}%` }}
                  // onConnect={(params, page) => //console.log("handle onConnect", params)}
                  isConnectable={true}
                  className="HandleYes"
                />
                <p
                  style={{
                    position: "absolute",
                    top: `${currentPage?.products?.length === 1 ? 50 : currentPage?.products?.length === 2 ? (index === 0 ? 30 : index == 1 ? 70 : "") : currentPage?.products?.length === 3 ? (index === 0 ? 25 : index == 1 ? 50 : index == 2 ? 75 : "") : currentPage?.products?.length === 4 ? (index === 0 ? 20 : index == 1 ? 40 : index == 2 ? 60 : index == 3 ? 80 : "") : currentPage?.products?.length === 5 ? (index === 0 ? 10 : index == 1 ? 30 : index == 2 ? 50 : index == 3 ? 70 : index == 4 ? 90 : 100) : ""}%`,
                    left: "111%",
                    fontSize: "8px",
                  }}
                >
                  {item?.split_rate}%
                 { console.log('item?.split_rate: ', item?.split_rate)}
                </p>
              </div>
            )
          })
        // })
       )
        }
        {console.log('currentPage?.data?.label: ', currentPage?.data?.label)}
      {(currentPage?.data?.label != SALES_PAGE) && (
        <p
          style={{
            right:
              currentPage?.userData?.checkout_rate?.toString().length > 2
                ? "-45%"
                : "-30%",
          }}
          className="yesrate"
        >
          {currentPage?.userData?.checkout_rate
            ? currentPage?.userData?.checkout_rate
            : 0}
          %
        </p>
      )}
    </div>
  );
});
