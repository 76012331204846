import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  useOnSelectionChange,
  ControlButton,
  useStoreApi,
  Background,
  BackgroundVariant,
  useReactFlow,
  SelectionMode,
  MarkerType,
  useUpdateNodeInternals,
} from "reactflow";
import "reactflow/dist/style.css";
import Sidebar from "./Sidebar";
import Navbar from "../../Components/Header/Nav";
import ColorSelectorNode from "./ColorSelectorNode";
import TrafficNode from "./TrafficNode";
import PageNode from "./PageNode";
import ActionNode from "./ActionNode";
import useProvider from "../../Context/Context";
import EditNode from "./models/EditNode";
import RightSidebar from "./RightSideBar/RightSidebar2";
import "./WarRoom.css";
import {
  BsArrowLeft,
  BsFillGrid3X3GapFill,
  BsPlusSquareDotted,
} from "react-icons/bs";
import {
  AiFillSetting,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from "react-icons/ai";
import { MdOutlineFileDownload } from "react-icons/md";
import { BiRedo, BiUndo } from "react-icons/bi";
import { ImCopy } from "react-icons/im";
import Controls from "./Controls";
import CustomEdge from "./CustomeEdge";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { FaCalculator } from "react-icons/fa";
import TrafficModel from "./models/TrafficModel";
import axios from "axios";
import { common } from "../../BaseUrl";
import { json, useLocation, useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { RiCursorFill } from "react-icons/ri";
import SummaryModel from "./models/SummaryModel";
import ProductModel from "./models/ProductModel";
import AddProductModel from "./models/AddProductModel";
import AddTrafficModel from "./models/AddTrafficModel";
import ExpencesModel from "./models/ExpencesModel";
import Settings from "./models/Settings2";
import AddExpences from "./models/AddExpences";
import EditProdWar from "../Products/EditProdWar";
import AddMainTraffic from "./models/AddMainTraffic";
import { toast } from "react-toastify";
import { idID } from "@mui/material/locale";
import PagesDrwer from "./PagesDrwer";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { ORDER_FORM, SALES_PAGE, ICON } from "../../Configs";
import { getFunnlePerf, getPageCategories, getPageViews, getProducts, getPublishFunnle } from "../../Services/api";
import ChoosePage from "./models/ChoosePage";
import { MenuItem, Select } from "@mui/material";
import ActualFunnel from "./ActualFunnle";

const edgeTypes = {
  custom: CustomEdge,
};
const initialNodes = [];
const panOnDrag = [0, 1];
const nodeTypes = {
  selectorNode: ColorSelectorNode,
  trafficNode: TrafficNode,
  pageNode: PageNode,
  actionNode: ActionNode,
};

let genId = 0;
const getId = () => `dndnode_${genId++}`;

const Comparison = () => {
  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  // const funnelId = useLocation().state?.id;
  const isNew = useLocation().state?.isNew;
  //console.log("funnel id=>", useLocation().state);
  const pathame = useLocation().pathname
  console.log("/user/page-builder", pathame)
  const reactFlowWrapper = useRef(null);
  const [showAside, setShwoAside] = useState(false);
  const [icon, setIcon] = useState("")
  const [select, setSelect] = useState("1");
  const [funnles, setFunnles] = useState([]);
  const [actualF, setActualF] = useState("")
  const [pageViews, setPageViews] = useState("")
  console.log('funnles: ', funnles);
  const [funnelId, setFunnelId] = useState("1");
  const {
    openModel,
    setOpenModel,
    fitScreen,
    showCalculation,
    setShowCalculation,
    saveFunnel,
    DelId,
    setDelId,
    nodesSelected,
    seNodesSelected,
    allNodes,
    setAllNodes,
    allPages,
    updateFunnel,
    edgeSelected,
    setEdgeSelected,
    setShowSettingsModel2,
    SettingsModel2,
    isSimulate,
    setIsSimulate,
    showSimulateData,
    setShowSimulateData,
    setShowTrafficModel,
    setDropeedNodeName,
    selectedProd,
    canvasSettings,
    setSimulateData,
    setTrafficPayload,
    trafficPayload,
    expencespayLLoad,
    setSettingPayload,
    SetPageDrawer,
    setAllPageCategories,
    setAllProducts,
    allPageCategories
  } = useProvider();
  const navigate = useNavigate()
  //console.log("all pages top=>", genId);

  useEffect(() => {
    getProducts().then(res => {
      setAllProducts(res.data?.data)
      localStorage.setItem('allProducts', JSON.stringify(res.data?.data))
    })
    getPublishFunnle({ status: "Published" }).then((res) => {
      console.log('res: ', res);
      if (res?.data?.data) {
        setFunnles(res?.data?.data)
      }
    })
  }, [])
  useEffect(() => {
    getPageCategories().then(responce => {
      console.log("pagestype", responce.data.data)
      setAllPageCategories(responce.data?.data)
    }).catch(err => {
      if (err) {

        toast.error("Server error")
      }
    })
  }, [])
  const [gridShow, setGridShow] = useState(true);

  const [isSelect, setIsSelect] = useState(false);
  const [funnelName, setFunnelName] = useState("")
  //console.log(fitScreen);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  console.log('nodes: ', nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [dataState, setDataState] = useState([
    {
      nodes,
      edges,
    },
  ]);
  const [undo, setUndo] = useState([]);
  console.log("undos: ", undo);
  const [redo, setRedo] = useState([]);
  console.log("redos: ", redo);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  //console.log("json file=>", reactFlowInstance?.getEdges());
  const onConnect = useCallback((params, page) => {
    //console.log("page check", params);
    const edgeParams = params;
    edgeParams.animated =
      params.sourceHandle === "traffic_source" ? true : false;
    edgeParams.type = "smoothstep";
    edgeParams.btn = <button>X</button>;
    // edgeParams.type = "straight"
    edgeParams.markerEnd = {
      type: MarkerType.ArrowClosed,
    };
    if (edgeParams?.source === edgeParams?.target) {
      return
    }
    console.warn("edgeParams ", edgeParams);
    setEdges((eds) => addEdge(edgeParams, eds));
    const newUndo1 = {
      nodes: [...nodes],
      edges: [...edges],
    };
    setTimeout(() => {
      setUndo([...undo, newUndo1]);
    }, 100);
  }, []);
  useEffect(() => {
    setAllNodes(nodes);
  }, [nodes]);
  useEffect(() => {
    console.log("changed");
  }, [nodes, onNodesChange]);
  console.log("adddd: ", nodes, edges);
  useEffect(() => {
    const source = nodes?.find((item) => item?.pageData?.category?.[0]?.name === SALES_PAGE);
    const target = nodes?.find((item) => item?.pageData?.category?.[0]?.name === ORDER_FORM);
    const params = {
      animated: false,
      source: source?.id,
      sourceHandle: "page_Yes",
      target: target?.id,
    };
    //console.log("dndnode_", params);
    // onConnect(params)
  }, [nodes, selectedProd]);

  // calculate the page node data
  useEffect(() => {

    // setAllNodes(nodes);
    //console.log("edges: ", edges, nodes);
    if (edges) {
    }
    if (nodes && nodes?.length > 0) {
      console.log("isSimulate: ", isSimulate);
      console.log("showSimulateData: ", showSimulateData);
      if (isSimulate && showSimulateData) {
        nodes && nodes.map((obj, i) => {
          //console.log("obj: ", obj);
          if (obj?.type === "pageNode") {
            let connectedEdges = edges.filter(
              (obj1) => obj1.target === obj?.id
            );
            //console.log("connectedEdges: ", connectedEdges);
            let traffic = 0;

            if (
              connectedEdges?.length > 1 &&
              connectedEdges.every(
                (obj5) => obj5?.source === connectedEdges[0]?.source
              )
            ) {
              let sourceNode = allNodes?.filter(
                (node1) => node1.id === connectedEdges[0]?.source
              )[0];
              //console.log('sourceNode: ', sourceNode);
              // if(sourceNode?.)
              // sourceNode
              sourceNode.products && sourceNode.products.map((pro) => {
                traffic +=
                  (Number(sourceNode?.traffic) *
                    Number(sourceNode?.userData?.checkout_rate) *
                    Number(pro?.split_rate)) /
                  10000;
              });
              console.log("trafficn: ", traffic);
            } else {
              connectedEdges && connectedEdges.map((edge) => {
                //console.log("edge: ", edge);
                if (edge?.sourceHandle === "traffic_source") {
                  let node = allNodes?.filter(
                    (node1) => node1.id === edge?.source
                  )[0];
                  if (node?.userData?.monthlyVisitor) {
                    // console.log('node?.userData?.monthlyVisitor: ', node?.userData?.monthlyVisitor);
                    traffic =
                      traffic +
                        node?.userData?.monthlyVisitor ? Number(
                          node?.userData?.monthlyVisitor
                        ) : 0;
                  }
                } else {
                  let node = allNodes?.filter(
                    (node1) => node1.id === edge?.target
                  )[0];
                  let sourceNode = allNodes?.filter(
                    (node1) => node1.id === edge?.source
                  )[0];
                  //console.log("node3: ", node);

                  traffic =
                    traffic +
                    Number(
                      (sourceNode?.traffic *
                        sourceNode?.userData?.checkout_rate) /
                      100
                    );
                }
              });
            }

            //console.log("traffic: ", traffic);
            nodes[i] = Object.assign(nodes[i], {
              traffic: Math.round(traffic),
            });
            setNodes(nodes);
            const newUndo = {
              nodes: [...nodes],
              edges: [...edges],
            };
            setUndo([...undo, newUndo]);
            setIsSimulate(false);
            // console.log("adddd: ", newUndo);

            // setShowSimulateData(false);
          }
        });
        // let EPC = 0;
        let pageNode = nodes?.filter(
          (obj) => obj?.type === "pageNode" && obj?.traffic
        );
        let trafficNode = nodes?.filter((obj) => obj?.type === "trafficNode");
        //console.log("trafficNode: ", trafficNode);
        let trafficCost = 0;
        let campCost = 0;
        let trafficData = [];

        trafficNode && trafficNode.map((obj) => {
          trafficCost += Number(obj?.userData?.monthlyVisitor);
          campCost +=
            Number(obj?.userData?.monthlyVisitor) * Number(obj?.userData?.cpc);
          console.log("trafficCost: ", trafficCost);
          trafficData.push({
            label: obj?.data?.label,
            value: Number(obj?.userData?.monthlyVisitor),
          });
        });
        console.log("campCost: ", campCost);
        console.log("trafficCost: ", trafficCost);
        console.log("pageNode: ", pageNode);
        if (pageNode?.length > 0) {
          let monthlyRevenue = 0;
          let lastNode = pageNode.reduce((prev, curr) =>
            prev.traffic < curr.traffic ? prev : curr
          );
          //console.log("lastNode: ", lastNode);
          let saleNode = pageNode.filter(
            (obj) => obj?.data?.label === SALES_PAGE
          )?.[0];
          console.log("saleNode: ", saleNode);
          let productCost = 0;
          let products = [];
          let productData = [];
          if (!saleNode?.products) {
            return toast.warn("Please add sales page")
          }
          saleNode?.products && saleNode?.products.map((obj) => {
            productCost +=
              obj?.price?.product_cost *
              (obj?.split_rate / 100) *
              ((Number(lastNode?.userData?.checkout_rate) * lastNode?.traffic) /
                100);
            monthlyRevenue +=
              obj?.price?.amount *
              (obj?.split_rate / 100) *
              ((Number(lastNode?.userData?.checkout_rate) * lastNode?.traffic) /
                100);

            //console.log("productCost1: ", productCost);
            productData.push({
              label: obj?.name + ` ($${obj?.prices[0]?.amount})`,
              value: (
                obj?.price?.amount *
                (obj?.split_rate / 100) *
                ((Number(lastNode?.userData?.checkout_rate) *
                  lastNode?.traffic) /
                  100)
              )?.toFixed(2),
            });
          });
          //console.log("productCost: ", productCost);
          //console.log("monthlyRevenue: ", monthlyRevenue);
          let other = 0;
          //console.log("lastNode: ", lastNode);
          console.log("expencespayLLoad: ", expencespayLLoad);
          if (expencespayLLoad && expencespayLLoad?.length > 0) {
            other = expencespayLLoad.reduce((total, obj) => {
              let amount = Number(obj?.amount);
              if (obj?.billinFrequency === "Yearly") {
                amount = (amount / 12).toFixed(2);
              }
              return amount + total;
            }, 0);
          }

          let totalVisitors = trafficNode.reduce(
            (total, obj) => Number(obj.userData?.monthlyVisitor) + total,
            0
          );
          console.log("totalVisitors: ", totalVisitors);
          console.log('trafficCost: ', trafficCost);
          console.log('monthlyRevenue: ', monthlyRevenue);
          let EPC = (monthlyRevenue / totalVisitors)?.toFixed(2);
          let CPL = (campCost / saleNode?.traffic)?.toFixed(2);
          let CPA = (
            campCost /
            ((Number(lastNode?.userData?.checkout_rate) * lastNode?.traffic) /
              100)
          )?.toFixed(2);
          let AOV = (
            monthlyRevenue /
            ((Number(lastNode?.userData?.checkout_rate) * lastNode?.traffic) /
              100)
          )?.toFixed(2);
          let ROAS = (monthlyRevenue / campCost)?.toFixed(2); //cpc remaining
          let merchantFees =
            (monthlyRevenue * canvasSettings?.processingRate) / 100 +
            canvasSettings?.transactionFee *
            ((Number(lastNode?.userData?.checkout_rate) * lastNode?.traffic) /
              100);
          let expensesData = [
            {
              label: "Traffic Cost",
              value: campCost,
            },
            {
              label: "Product Cost",
              value: productCost,
            },
            {
              label: "Merchant Fees",
              value: merchantFees,
            },
            {
              label: "Other",
              value: other,
            },
          ];
          console.log("AOC: ", AOV);
          let summary = {
            products: productData,
            productData: saleNode?.products,
            traffic: trafficData,
            trafficCost,
            expenses: expensesData,
            costs: {
              trafficCost,
              merchantFees,
              productCost,
              other,
            },
            summary: {
              EPC,
              CPL,
              CPA,
              AOV,
              ROAS,
              "REVENUE (Monthly)": monthlyRevenue?.toFixed(2),
              "PROFIT (Monthly)": (
                monthlyRevenue -
                expensesData.reduce(
                  (value, curr) => value + Number(curr?.value),
                  0
                )
              )?.toFixed(2),
              "REVENUE (Yearly)": (12 * monthlyRevenue)?.toFixed(2),
              "PROFIT (Yearly)": (
                12 *
                (monthlyRevenue -
                  expensesData.reduce(
                    (value, curr) => value + Number(curr?.value),
                    0
                  ))
              )?.toFixed(2),
            },
          };
          console.log("summary: ", summary);
          setSimulateData(summary);
          //  let cpl =
        }
      }
    }
  }, [edges, nodes, isSimulate, showSimulateData]);
  //console.log("pan on drag=>", SelectionMode);
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const removeNode = (nodeId) => {
    setNodes((els) => els.filter((el) => el.id !== nodeId));

    setTimeout(() => {
      const newUndo = {
        nodes: [...nodes],
        edges: [...edges],
      };
      setUndo([...undo, newUndo]);
    }, 1000);
  };

  useEffect(() => {
    seNodesSelected(genId);
  }, []);
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const { type, name, id, icon, pageData } =
        event.dataTransfer.getData("application/reactflow") &&
        JSON.parse(event.dataTransfer.getData("application/reactflow"));
      console.log("trafficNode", type, name, id, icon);
      setIcon(icon)
      setDropeedNodeName(name);
      console.log("idvalue", icon);
      let userData;
      if (
        name === "Facebook" ||
        name === "Tik Tok" ||
        name === "You Tube" ||
        name === "Instagram" ||
        name === "Google"
      ) {
        userData = {
          // tranficIcon: icon,
          trafficSources: name,
        };
      }
      if (
        type === 'trafficNode'
      ) {
        userData = {
          campaignName: "",
          entryPoint: null,
          monthlyVisitor: 0,
          acquisitionType: "Clicks",
          cpc: 1,
          conversionStrength: 0,
        };
      }
      //console.log("yu", event.dataTransfer.getData("application/reactflow"));
      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        pageId: id,
        position,
        data: {
          label: `${name}`,
          onDelete: (id) => removeNode(id),
          icon: icon
        },
        userData: userData,
        deletable: true,
        initialNodes,
        icon: icon
      };

      console.log("all pages =>", pageData);
      if (name == SALES_PAGE) {

        let page = localStorage.getItem("allpages")
          ? JSON.parse(localStorage.getItem("allpages"))
          : [];
        let products = localStorage.getItem("allProducts")
          ? JSON.parse(localStorage.getItem("allProducts"))
          : [];
        newNode.products = products && products?.length > 0 && [products[0]];
        newNode.products[0].price = products[0]?.prices?.length > 0 && products[0]?.prices[0];
        newNode.products[0].split_rate = 100;
        console.log("selectedProd1: ", newNode, products);
        setNodes((nds) => nds.concat(newNode));
        const newUndo = {
          nodes: [...nodes],
          edges: [...edges],
        };
        setUndo([...undo, newUndo]);
        console.log("adddd: ", newUndo);

        const pageId = page?.find((page) => page?.name == ORDER_FORM);
        console.log("paggggggggg", pageId);
        let combinenode = {
          id: getId(),
          pageId: pageId?._id,
          pageData: pageId,
          type,
          icon: "https://page-builder.fra1.cdn.digitaloceanspaces.com/categories/order.png",
          position: { ...position, x: position.x + 200, y: position.y },
          data: {
            label: ORDER_FORM,
            onDelete: (id) => removeNode(id),
            icon: "https://page-builder.fra1.cdn.digitaloceanspaces.com/categories/order.png"

          },
          deletable: true,
          initialNodes,
        };
        setNodes((nds) => nds.concat(combinenode));
        const newUndo1 = {
          nodes: [...nodes],
          edges: [...edges],
        };
        setUndo([...undo, newUndo1]);
        console.log("adddd: ", newUndo1);

        const params = {
          animated: false,
          labelBgStyle: {
            fill: "#fff",
            color: "#fff",
            fillOpacity: 1,
            border: "1px solid black",
          },
          source: newNode?.id,
          sourceHandle: "page_yes",
          target: combinenode?.id,
          targetHandle: null,
        };
        onConnect(params);


        return;
      }


      setNodes((nds) => {
        console.log('nds: ', nds);
        if (nds) {
          const newUndo1 = {
            nodes: nds,
          };
          setEdges((ed) => {
            console.log('ed: ', ed);
            newUndo1.edges = ed

            setUndo([...undo, newUndo1]);
            return ed
          })
          console.log('newUndo1: ', newUndo1);

          return nds?.concat(newNode);
        }

      });
    },
    [reactFlowInstance]
  );
  //console.log("nodes =>", nodes);

  function downloadImage(dataUrl) {
    const a = document.createElement("a");

    a.setAttribute("download", "reactflow.jpeg");
    a.setAttribute("href", dataUrl);
    a.click();
  }

  const DownloadButton = () => {
    toPng(document.querySelector(".react-flow"), {
      filter: (node) => {
        // we don't want to add the minimap and the controls to the image
        if (
          node?.classList?.contains("react-flow__minimap") ||
          node?.classList?.contains("react-flow__controls")
        ) {
          return false;
        }

        return true;
      },
    }).then(downloadImage);
  };

  useEffect(() => {
    if(funnelId && funnelId !== "1") getFunnel();
  }, [funnelId]);
  const getFunnel = () => {
    getFunnlePerf(funnelId).then((res)=>{
      console.log('res: ', res);
      if (res.data?.data) {
        setActualF(res?.data?.data)
      }
    })
    getPageViews(funnelId).then(res=>{
      console.log('res: ', res);
if(res.data?.data){
  setPageViews(res?.data?.data)
}
    })
    axios({
      method: "GET",
      url: `${common}/funnel/getFunnel/${funnelId}`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((responce) => {
      console.log("responce11=>", responce);
      if (responce?.data?.data) {
        genId = responce.data?.data?.last_node;
        const nodes = responce.data?.data?.content && JSON.parse(responce.data?.data?.content)?.nodes;
        const edges = responce.data?.data?.content && JSON.parse(responce.data?.data?.content)?.edges;
        setNodes(nodes);
        setEdges(edges);
        setFunnelName(responce.data?.data?.name)
      }

      // const newUndo1 = {
      //   nodes: [...nodes],
      //   edges: [...edges],
      // };
      // setUndo([...undo, newUndo1]);
    });
  };

  const handleDelete = (e) => {
    if (e.code === "Delete") {
      nodesSelected && nodesSelected.forEach((element) => {
        removeNode(element.id);
      });
    }
  };

  const onSelectionChange = (params) => {
    //console.log(params, "params");
    seNodesSelected(params.nodes);
    setEdgeSelected(params.edges);
  };
  const deleteNodes = () => {
    if (nodesSelected?.length >= 1) {
      nodesSelected && nodesSelected.forEach((element) => {
        removeNode(element.id);
      });
    }
    if (edgeSelected?.length >= 1) {
    }
  };
  const copySelection = () => {
    setNodes((nds) => nds.concat(nodesSelected));
    const newUndo1 = {
      nodes: [...nodes],
      edges: [...edges],
    };
    setUndo([...undo, newUndo1]);
  };
  //console.log("selected nodes=>", nodesSelected);
  const handleSelect = () => {
    setIsSelect(!isSelect);
    panOnDrag[0] = 2;
  };
  const handledeSelect = () => {
    setIsSelect(!isSelect);
    panOnDrag[0] = 0;
  };

  const saveNodeData = async (nodeId, data, selectedProd) => {
    // const matched = nodes.find(item=>item.userData?.trafficSources === data?.trafficSources)
    // //console.log("data ke under=>", matched)
    // if(matched) {
    //   return toast.warn("Traffic source allready exist")
    // }
    if (data)
      await setNodes(
        nodes.map((item) =>
          item.id == nodeId
            ? { ...item, userData: data, products: selectedProd }
            : item
        )
      );
    const newUndo1 = {
      nodes: [...nodes],
      edges: [...edges],
    };
    setSettingPayload({
      page_name: "",
      checkout_rate: "",
      is_home: false,
      n_port: true,
      notes: "",
      object_Name: "",
      theme_Color: "",
      url: "",
      thumnail: ""
    })

    // setUndo([...undo, newUndo1]);
    // console.log("adddd: ", newUndo1);
  };

  const getEdgeId = (e) => {
    //console.log("edge id=>", e);
  };
  const undoChanges = () => {
    console.log("undo: ", undo, nodes, edges);
    const lastElement = undo[undo.length - 1];
    console.log("lastElement: ", lastElement);
    if (lastElement) {
      const copyOfUndo = [...undo];
      console.log("copyOfUndo: ", copyOfUndo);

      // Update redo to be able to rollback
      setRedo([...redo, { nodes, edges }]);

      // Remove the last element from undo
      if (undo?.length === 0) {
        // Set the previous values to Schedule and Loads
        setNodes(lastElement.nodes);
        setEdges(lastElement.edges);
        setUndo([]);
      } else {
        // Set the previous values to Schedule and Loads
        setNodes(lastElement.nodes);
        setEdges(lastElement.edges);
        console.log(
          "undo.slice(0,undo.length - 2): ",
          undo.slice(0, undo.length - 2)
        );
        undo.pop();
        setUndo(undo);
      }
      console.log(
        "undolast: ",
        undo.splice(1, undo.length - 1),
        undo,
        nodes,
        edges
      );
    }
  };
  const redoChanges = () => {
    const lastElement = redo[redo.length - 1];
    console.log("redo: ", redo);
    console.log("lastElement: ", lastElement);
    if (lastElement) {
      const copyOfUndo = [...redo];

      // Update undo to be able to rollback
      setUndo([...undo, { nodes, edges }]);

      // Remove the last element from redo
      if (redo?.length === 0) {
        // Set the previous values to Schedule and Loads
        setNodes(lastElement.nodes);
        setEdges(lastElement.edges);
        setRedo([]);
      } else {
        // Set the previous values to Schedule and Loads
        setNodes(lastElement.nodes);
        setEdges(lastElement.edges);
        redo.pop();
        setRedo(redo);
      }
    }
  };
  return (
    <>
      <SummaryModel />
      <ProductModel />
      <AddProductModel />
      <AddTrafficModel />
      <ExpencesModel />
      <Settings />
      <AddExpences />
      <EditProdWar />
      {/* <ChoosePage setNodes={setNodes} Nodes={nodes} /> */}
      <TrafficModel nodes={nodes} saveNodeData={saveNodeData} />
      <AddMainTraffic />
      <PagesDrwer />
      <div className="war_room">
        <div className="war_room_head_container">
          <Navbar />
          <div className="war_room_head">
            <div className="war_room_head_left">
              {pathame === "/comparison" && <BsArrowLeft onClick={() => navigate("/user/dashboard")} />}
              <input
                autoFocus
                onChange={(e) => setFunnelName(e.target.value)}
                type="text"
                placeholder="Funnel Comparison"
                value={funnelName}
              />
            </div>
            <div className="war_room_head_right">
              <Tooltip
                onClick={() => setShowSettingsModel2(!SettingsModel2)}
                title={
                  <React.Fragment>
                    <Typography fontSize={10} color="inherit">
                      Settings
                    </Typography>
                  </React.Fragment>
                }
                arrow
                placement="bottom"
              >
                <IconButton>
                  <AiFillSetting
                    color="#1B2948"

                  />
                </IconButton>
              </Tooltip>
              <Select
                style={{
                  backgroundColor: "#1b2948",
                  fontSize: "13px",
                  width: "220px",
                  color: "#fff",
                  borderRadius: "10px"
                }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "13px" },
                  height: "38px",
                }}
                labelId="demo-select-large"
                id="demo-select-large"
                placeholder="Calendar"
                defaultValue={"1"}
                value={funnelId && funnelId}
                onChange={(e) => setFunnelId(e.target.value)}
              >
                <MenuItem style={{ fontSize: "13px" }} value="1">
                  Select Funnels
                </MenuItem>
                {funnles && funnles?.length > 0 && funnles?.map(obj=>{
                  return (<MenuItem style={{ fontSize: "13px" }} value={obj?._id}>
                   {obj?.name}
                  </MenuItem>)
                })}
                {/* <MenuItem style={{ fontSize: "13px" }} value="Last 7 days">
                  Funnels 1
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Last 15 days">
                  Funnels 2
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Last 30 days">
                  Funnels 3
                </MenuItem> */}
              </Select>
              <input type="date" className="date_input" format="yyyy/dd/MM" />
              <input type="date" className="date_input" format="yyyy/dd/MM" />

              <Select
                style={{
                  backgroundColor: "#1b2948",
                  fontSize: "13px",
                  width: "220px",
                  color: "#fff",
                  borderRadius: "10px"
                }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "13px" },
                  height: "38px",
                }}
                labelId="demo-select-large"
                id="demo-select-large"
                placeholder="Calendar"
                defaultValue={"1"}
                value={select && select}
                onChange={(e) => setSelect(e.target.value)}
              >
                <MenuItem style={{ fontSize: "13px" }} value="1">
                  Calender
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Last 7 days">
                  Last 7 days
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Last 15 days">
                  Last 15 days
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Last 30 days">
                  Last 30 days
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="comparison_header">
              <p>     Actual Performance</p>
            </div>

            <div
              className="dndflow"
              onClick={(e) => {
                setDelId("");
                SetPageDrawer(false)
              }}
              onKeyDown={(e) => handleDelete(e)}
            >
              {/* <ReactFlowProvider>
                 <EditNode
                  nodes={nodes}
                  saveNodeData={saveNodeData}
                  setOpenModel={setOpenModel}
                  openModel={openModel}
                  saveFunnel={saveFunnel}
                  genId={genId}
                  content={reactFlowInstance?.toObject()}
                  funnelName={funnelName}
                  onConnect={onConnect}
                /> 

                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    fitView={fitScreen}
                    onDragOver={onDragOver}
                    nodeTypes={nodeTypes}
                    panOnScroll
                    selectionOnDrag
                    panOnDrag={panOnDrag}
                    selectionMode={SelectionMode.Partial}
                    edgeTypes={edgeTypes}
                    onSelectionChange={onSelectionChange}
                  >
                    {gridShow && (
                      <div>
                        <Background
                          id="1"
                          gap={20}
                          color="#f1f1f1"
                          variant={BackgroundVariant.Lines}
                        />
                      </div>
                    )}
                  </ReactFlow>
                </div>

                <Controls showAside={showAside} />

                <RightSidebar />
              </ReactFlowProvider> */}
              <ActualFunnel nodes={nodes} edges={edges} pageViews={pageViews} data={actualF} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="comparison_header">
              <p>Selected Funnel </p>
            </div>

            <div
              className="dndflow"
              onClick={(e) => {
                setDelId("");
                SetPageDrawer(false)
              }}
              onKeyDown={(e) => handleDelete(e)}
            >
              <ReactFlowProvider>
                {/* <EditNode
                  nodes={nodes}
                  saveNodeData={saveNodeData}
                  setOpenModel={setOpenModel}
                  openModel={openModel}
                  saveFunnel={saveFunnel}
                  genId={genId}
                  content={reactFlowInstance?.toObject()}
                  funnelName={funnelName}
                  onConnect={onConnect}
                /> */}

                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    fitView={fitScreen}
                    onDragOver={onDragOver}
                    nodeTypes={nodeTypes}
                    panOnScroll
                    selectionOnDrag
                    panOnDrag={panOnDrag}
                    selectionMode={SelectionMode.Partial}
                    edgeTypes={edgeTypes}
                    onSelectionChange={onSelectionChange}
                  >
                    {gridShow && (
                      <div>
                        <Background
                          id="1"
                          gap={20}
                          color="#f1f1f1"
                          variant={BackgroundVariant.Lines}
                        />
                      </div>
                    )}
                  </ReactFlow>
                </div>

                <Controls showAside={showAside} />

                <RightSidebar />
              </ReactFlowProvider>
            </div>
          </Grid>
        </Grid>

      </div>
    </>
  );
};

export default Comparison;
