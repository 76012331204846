import { useNode, useEditor } from '@craftjs/core';
import React from 'react';
import ContentEditable from 'react-contenteditable';

import { TextSettings } from '../../texts/TextSettings';
import { TextField } from '@mui/material';
import { InputSettings } from './InputSettings';

const defaultProps = {
    fillSpace: 'no',
    padding: ['0', '0', '0', '0'],
    margin: ['0', '0', '0', '0'],
    background: { r: 256, g: 256, b: 256, a: 1 },
    color: { r: 0, g: 0, b: 0, a: 1 },
    shadow: 0,
    firstShadow: 0,
    secondShadow: 0,
    thirdShadow: 0,
    radius: 0,
    width: '100%',
    height: 'auto',
    name: 'name',
    text: '',
    Placeholder:""

};
export const Inputs = ({
    text, name,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    radius,
    children,
    gap,
    firstShadow,
    secondShadow,
    thirdShadow,
    bgurl,
    visibility,
    Flexproperty,
    position,
    zIndex,
    bgRepeat,
    bgattachment,
    width,
    placeholder,
    outline,
    height
}) => {
    const {
        connectors: { connect },
        setProp,
    } = useNode();
    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    console.log("Placeholder",placeholder)
    return (
        <>
            {/* <ContentEditable
            innerRef={connect}
            html={text} // innerHTML of the editable div
            disabled={!enabled}
            onChange={(e) => {
                setProp((prop) => (prop.text = e.target.value), 500);
            }} // use true to disable editing
            tagName="p" // Use a custom HTML tag (uses a div by default)
            style={{
                width: "100%",
                margin:
                    margin &&
                    margin?.length > 0 &&
                    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                color: color && `rgba(${Object.values(color)})`,
                fontSize: fontSize && `${fontSize}px`,
                textShadow: shadow && `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
                fontWeight,
                textAlign,
                fontFamily: fontFamily && fontFamily
            }}
        /> */}
            <input ref={connect} value={text}
                placeholder={placeholder}
                name={name}
                onChange={(e) => {
                    setProp((prop) => (prop.text = e.target.value), 500);
                }}
                style={{
                    width:width,
                    background: background && `rgba(${Object.values(background)})`,
                    color: color && `rgba(${Object.values(color)})`,
                    padding:
                        padding &&
                        padding?.length >
                        0 && `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
                    margin:
                        margin &&
                        margin?.length >
                        0 && `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                    boxShadow:
                        shadow && shadow === 0
                            ? "none"
                            : `${firstShadow}px ${secondShadow}px ${thirdShadow}px ${shadow}px rgba(0, 0, 0, .4)`,
                    borderRadius: radius && `${radius}px`,
                    flex: fillSpace === "yes" ? 1 : "unset",
                    gap: gap ? `${gap}px` : '2px',
                    flexWrap: Flexproperty ? `${Flexproperty}` : 'wrap',
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, ${visibility * 0.01 || 0}), rgba(0, 0, 0, ${visibility * 0.01 || 0})), url(${bgurl})`,
                    backgroundSize: '100% 100%',
                    position: position ? `${position}` : "relative",
                    zIndex: `${zIndex}`,
                    border: 'none',
                    height: height,
                    backgroundAttachment: bgattachment && bgattachment,
                    backgroundRepeat: bgRepeat && bgRepeat,
                    backgroundPosition: "center",
                    outline:outline&&outline
                    
                }}


            >

            </input>
        </>
    );
};

Inputs.craft = {
    displayName: 'Input',
    props: defaultProps,
    related: {
        toolbar: InputSettings,
    },
};
export default Inputs