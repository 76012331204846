import React, { useEffect, useState } from 'react';

import { ToolbarRadio } from '../../Toolbar/ToolbarRadio';
import { ToolbarItem, ToolbarSection } from '../../Toolbar';
import { Button, MenuItem } from '@mui/material';
import { getProduct, getProducts } from '../../../../Services/api';
const types = [{
  id: 'button', name: 'Button'
}, { id: 'submit', name: 'Submit' }]
const clickType = [{
  id: 'redirect', name: 'Go to...',
}, {
  id: 'openpopup', name: 'Open Popup',
},
{
  id: 'closepopup', name: 'Close Popup',
}]
export const ButtonSettings = () => {
const [allProducts, setAllProducts] = useState([])

  useEffect(() => {
    getProducts().then(res => {
      console.log("all products", res.data)
      setAllProducts(res.data?.data)
    })
  }, [])
  return (
    <React.Fragment>
      <ToolbarSection
        title="Dimensions"
        props={['width', 'height']}
        summary={({ width, height }) => {
          return `${width || 0} x ${height || 0}`;
        }}
      >
        <ToolbarItem propKey="width" type="text" label="Width" />
        <ToolbarItem propKey="height" type="text" label="Height" />

      </ToolbarSection>
      <ToolbarSection
        title="Product"
        props={['width', 'height']}
        summary={({ bgurl }) => {
          return `${bgurl || ""}`;
        }}
      >
        <ToolbarItem propKey="Product" type="select" label="Products" >
          {allProducts && allProducts.length > 0 && allProducts.map((obj) => {
            return <MenuItem className='menu-items' style={{ zIndex: 180000 }} value={obj}>{obj.name}</MenuItem>
          })}
        </ToolbarItem>
      </ToolbarSection>


    </React.Fragment>
  );
};
