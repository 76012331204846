import { useEditor } from "@craftjs/core";
import usePageBuilder from "../../Context/pageBuilder";
import React , { useEffect } from "react";



const EditorWrapper = ({ children }) => {
  const { connectors, query } = useEditor();
  const { setQuery } = usePageBuilder()
  useEffect(() => {
    setQuery(query)
  }, [])


  return (
    <div
      className="craftjs-renderer"
   
      ref={(ref) => connectors.select(connectors.hover(ref, null), null)}
    >
      {children}
    </div>
  );
}
export default EditorWrapper;