import React, { useEffect, useState } from 'react'
import styles from "./AddModel.module.css"
import useProvider from '../../Context/Context'
import { RxCross2 } from "react-icons/rx"
import { MenuItem, Select } from '@mui/material'
import axios from 'axios'
import { common } from '../../BaseUrl'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function AddModel() {


    const navigate = useNavigate()

    const {
        createProduct, setShowCreateProduct, ProdPayload, setProdPayload
    } = useProvider();

    useEffect(() => {
        setProdPayload({
            name: "",
            description: "",
            product_type: "",
            image: ""
        })
    }, [])
    const saveProduct = () => {
        axios({
            method: "post",
            url: `${common}/products/create/`,
            data: { name: ProdPayload.name, description: ProdPayload.description, product_typ: ProdPayload.product_type },
            headers: {
                'Content-Type': "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            //console.log(res)
            toast.success("Product created successfully")
            setProdPayload({
                name: "",
                description: "",
                product_type: "",
                image: ""
            })
            setShowCreateProduct(false)
            navigate('/user/products/edit', { state: { id: res.data?.data?.id } })




        })
    }


    return (
        <div
            onClick={() => setShowCreateProduct(false)}
            className={`${styles.summaryModel} ${createProduct ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Create Products</p>
                    < RxCross2 onClick={() => setShowCreateProduct(false)} />
                </div>
                <div className={styles.inputBody}>
                    <div className={styles.inputField}>
                        <p>Product Name</p>
                        <input value={ProdPayload?.name} onChange={(e) => setProdPayload({ ...ProdPayload, name: e.target.value })} type="text" />
                    </div>
                    <div className={styles.inputField2}>
                        <p>Product Type</p>

                        <Select
                            style={{ backgroundColor: "#fff", fontSize: "8px", marginTop: "10px" }}
                            sx={{
                                "& fieldset": { border: "none", fontSize: "8px" }, height: "24px", width: 191.5
                            }}
                            labelId="demo-select-large"
                            id="demo-select-large"
                            value={ProdPayload.product_type}

                            onChange={(e) => setProdPayload({ ...ProdPayload, product_type: e.target.value })}


                        >

                            <MenuItem style={{ fontSize: "8px" }} value="Digital">Digital</MenuItem>
                            <MenuItem style={{ fontSize: "8px" }} value="Physical">Physical</MenuItem>

                        </Select>
                    </div>


                    <div className={styles.inputField}>
                        <p>Description</p>
                        <textarea value={ProdPayload?.description} onChange={(e) => setProdPayload({ ...ProdPayload, description: e.target.value })} type="text" />
                    </div>
                </div>
                <div style={{ display: "flex", gap: "30px", marginTop: '8px', width: "100%", justifyContent: "flex-end" }}>
                    <button onClick={() => setShowCreateProduct(false)} className='closebuttn'>Close</button>
                    <button onClick={saveProduct} className='addbutton'>Create</button>
                </div>
            </div>
        </div>
    );
}

export default AddModel