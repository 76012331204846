import React, { useEffect, useState } from "react";
import styles from "./Contact.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdPricetags } from "react-icons/io";
import { getContactId } from "../../Services/api";
import axios from "axios";
import { common } from "../../BaseUrl";
import { Badge, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { GiCancel } from "react-icons/gi";
import { AiOutlinePlusCircle } from "react-icons/ai";

function EditContact() {
  const navigate = useNavigate();
  const contactId = useLocation().state?.id;
  console.log(contactId);
  const [skipedContact, setSkipedContact] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [tag, setTag] = useState([]);
  console.log("tag==>", tag);
  const [value, setValue] = useState("");
  console.log("data: ", data);

  useEffect(() => {
    getContactData(contactId);
  }, [skipedContact]);
  const getContactData = () => {
    getContactId(contactId).then((res) => {
      console.log('res: ', res);
      if(res?.data?.data){
        setData(res?.data?.data);
if(res?.data?.data?.tags){
  setTag(res?.data?.data?.tags);
}}
      
    });
  };

  const updateContact = () => {
    setLoading(true);
    data.tags = tag;

    axios({
      method: "put",
      url: `${common}/contacts/update/${contactId}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      console.log(res);
      setData(data);
      navigate("/user/contact");
      setLoading(false);
    });
  };
  const add = (e) => {
    setTag([...tag, value]);
    setValue("");
  };
  const removetag = (id) => {
    const newTag = tag.filter((item) => {
      return id != item;
    });
    setTag(newTag);
  };
  return (
    <div className={styles.Funnels}>
      <div className={styles.FunnelsCreate}>
        <h1 className={styles.FunnelsHead}> Edit Contact</h1>
        <div className={styles.Button}>
          <button onClick={() => navigate("/user/contact")}>Discard</button>
          <button onClick={updateContact}>Update</button>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.input}>
          <div className={styles.inputone}>
            <label htmlFor="first_name">
              First name
              <input
                type="text"
                name="first_name"
                id="first_name"
                value={data?.first_name}
                onChange={(e) =>
                  setData({ ...data, first_name: e.target.value })
                }
              />
            </label>
            <label htmlFor="last_name">
              Last name
              <input
                type="text"
                name="last_name"
                id="last_name"
                value={data?.last_name}
                onChange={(e) =>
                  setData({ ...data, last_name: e.target.value })
                }
              />
            </label>
          </div>
          <label htmlFor="email">
            E-mail address
            <input
              type="email"
              name="email"
              id="email"
              value={data?.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </label>
          <label htmlFor="phone_no">
            Phone number
            <input
              type="number"
              name="phone_no"
              id="phone_no"
              value={data?.phone_no}
              onChange={(e) => setData({ ...data, phone_no: e.target.value })}
            />
          </label>
          <label htmlFor="timezone">
            Time zone
            <input
              type="text"
              name="timezone"
              id="timezone"
              value={data?.timezone}
              onChange={(e) => setData({ ...data, timezone: e.target.value })}
            />
          </label>
          <label htmlFor="organization">
            Organization
            <input
              type="text"
              name="organization"
              id="organization"
              value={data?.organization}
              onChange={(e) => setData({ ...data, organization: e.target.value })}
            />
          </label>
        </div>
        <div className={styles.side}>
          <div className={styles.manager}>
            <IoMdPricetags className={styles.icon} /> <h5>Tag Manager</h5>
          </div>
          <p>Add and delete tags on users</p>
          <div className={styles.tags}>
            <input
              type="text"
              name="tags"
              id="tags"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              style={{
                border: "2px solid black",
                borderRadius: "5px",
                marginTop: "4px",
              }}
            />
            <AiOutlinePlusCircle onClick={add} />
          </div>
          {tag?.length > 0 &&
            tag?.map((item) => {
              return (
                <Badge>
                  {/* {item} */}
                  {/* <button onClick={() => removetag(item)}> delete</button> */}
                  <ListItem
                    disablePadding
                    style={{
                      backgroundColor: "#eff0f8",
                      border: "1px solid #eff0f8",
                      borderRadius: "5px",
                      margin: "5px",
                    }}
                  >
                    <ListItemButton style={{ padding: "0px 3px" }}>
                      <ListItemText primary={item} />
                      <GiCancel
                        style={{ color: "#c93737", marginLeft: "3px" }}
                        onClick={() => removetag(item)}
                      />
                    </ListItemButton>
                  </ListItem>{" "}
                </Badge>
              );
            })}{" "}
        </div>
      </div>
    </div>
  );
}

export default EditContact;
