import { useNode, useEditor } from '@craftjs/core';
import React, { useEffect } from 'react';
import ContentEditable from 'react-contenteditable';

import { TextSettings } from './TextSettings';
import Container from '../Container';
import usePageBuilder from '../../../../Context/pageBuilder';


export const Texts = ({
  fontSize,
  textAlign,
  fontWeight,
  color,
  shadow,
  text,
  margin,
  fontFamily,
  fontStyle,
  noContainer,
  TimingFunction, animationDuration, animationType, animationDelay, animateOnce
}) => {
  const {
    connectors: { connect },
    setProp,
  } = useNode();
  const { setAnimationType, globalFont, setGlobalFont, } = usePageBuilder()

  useEffect(() => {
    setAnimationType(animationType)

  }, [animationType, TimingFunction, animationDuration, animationDelay, animateOnce])
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  console.log("animation Type", globalFont)
  return (

    <ContentEditable
      data-aos={`${animationType?.toString()}`}
      data-aos-offset="10"
      data-aos-delay={`${animationDelay * 10}`}
      data-aos-easing={`${TimingFunction ? TimingFunction?.toString() : 'linear'}`}
      data-aos-duration={`${animationDuration * 10}`}
      data-aos-once={`${animateOnce === "yes" ? true : false}`}
      innerRef={connect}
      html={text} // innerHTML of the editable div
      disabled={!enabled}
      onChange={(e) => {
        setProp((prop) => (prop.text = e.target.value), 500);
      }} // use true to disable editing
      tagName="h2" // Use a custom HTML tag (uses a div by default)
      style={{
        width: "auto",
        height: "auto",
        margin:
          margin &&
          margin?.length > 0 &&
          `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
        color: color && `rgba(${Object.values(color)})`,
        fontSize: fontSize && `${fontSize}px`,
        textShadow: shadow && `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
        fontWeight: fontWeight,
        textAlign,
        fontFamily: globalFont?globalFont:fontFamily,
        fontStyle: fontStyle
      }}

    />



  );
};

Texts.craft = {
  displayName: 'Text',
  props: {
    fontSize: '15',
    textAlign: 'left',
    fontWeight: '500',
    color: { r: 92, g: 90, b: 90, a: 1 },
    margin: [0, 0, 0, 0],
    shadow: 0,
    text: 'Text',
  },
  related: {
    toolbar: TextSettings,
  },
};
export default Texts