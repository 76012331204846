import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import styles from "./PageSideBar.module.css"
import { GiHamburgerMenu } from 'react-icons/gi'
import { FiPlus } from 'react-icons/fi'
import { RxGrid } from 'react-icons/rx'
import { CgNotes } from 'react-icons/cg'
import { MdOutlineTextFields } from 'react-icons/md'
import { TiDocumentText } from 'react-icons/ti'
import { BsCardImage } from 'react-icons/bs'
import { BiCopy } from 'react-icons/bi'
import usePageBuilder from '../../Context/pageBuilder'
import TextComponent from './TextComponent'
import { useEditor } from '@craftjs/core'
function PageSideBar() {
    const { showAddDrawer, setShowAddDrawer, showProdDrawer, setShowProdDrawer,setSectionProdDrawer,showSectionDrawer } = usePageBuilder()
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));
    return (
      <div className={styles.PageSideBar}>
        <div className={styles.PageSideBar__inner} style={{display: !enabled && 'none'}}>
       
           <FiPlus   onClick={() => setShowProdDrawer(!showProdDrawer)} color="white" />
          <Tooltip
            
            title={
              <React.Fragment>
                <Typography fontSize={10} color="inherit">
                  Menu
                </Typography>
              </React.Fragment>
            }
            arrow
            placement="top"
          >
            <IconButton>
              <GiHamburgerMenu color="white" />
            </IconButton>
          </Tooltip>
         
          <Tooltip
            title={
              <React.Fragment>
                <Typography fontSize={10} color="inherit">
                  Section
                </Typography>
              </React.Fragment>
            }
            arrow
            placement="top"
          >
            <IconButton>
              <RxGrid color="white" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              <React.Fragment>
                <Typography fontSize={10} color="inherit">
                  Pages
                </Typography>
              </React.Fragment>
            }
            arrow
            placement="top"
          >
            <IconButton>
              <TiDocumentText color="white" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              <React.Fragment>
                <Typography fontSize={10} color="inherit">
                  Design
                </Typography>
              </React.Fragment>
            }
            arrow
            placement="top"
          >
            <IconButton>
              <MdOutlineTextFields color="white" />
            </IconButton>
          </Tooltip>
          <Tooltip
          onClick={() => setShowAddDrawer(!showAddDrawer)}
            title={
              <React.Fragment>
                <Typography fontSize={10} color="inherit">
                  Media
                </Typography>
              </React.Fragment>
            }
            arrow
            placement="top"
          >
            <IconButton>
              <BsCardImage color="white" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              <React.Fragment>
                <Typography fontSize={10} color="inherit">
                  Popup
                </Typography>
              </React.Fragment>
            }
            arrow
            placement="top"
          >
            <IconButton>
              <BiCopy color="white" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
}

export default PageSideBar