import React from 'react';

import { ToolbarItem, ToolbarSection } from "../../Toolbar";
import ToolbarRadio from "../../Toolbar/ToolbarRadio";
import { Button, MenuItem } from '@mui/material';
import usePageBuilder from '../../../../Context/pageBuilder';
import {animateFunction,animations} from "../buttons/ButtonSettings"
export const ContainerSettings = () => {
  const { showBgModel, setShowBgModel, bgChoosen } = usePageBuilder()
  return (
    <React.Fragment>
      <ToolbarSection
        title="Dimensions"
        props={['width', 'height']}
        summary={({ width, height }) => {
          return `${width || 0} x ${height || 0}`;
        }}
      >
        <ToolbarItem propKey="width" type="text" label="Width" />
        <ToolbarItem propKey="height" type="text" label="Height" />

      </ToolbarSection>
      <ToolbarSection
        title="Background Image"
        props={['width', 'height']}
        summary={({ bgurl }) => {
          return `${bgurl || ""}`;
        }}
      >

        <ToolbarItem propKey="bgurl" type="text" label="Background image" />
        <ToolbarItem propKey="visibility" background index={3} type="slider" label="Backround opacity" />
        <ToolbarItem propKey="bgattachment" type="radio" label="background-attachment">
          <ToolbarRadio value="fixed" label="Fixed" />
          <ToolbarRadio value="scroll" label="Scroll" />
          <ToolbarRadio value="local" label="Local" />
          <ToolbarRadio value="scroll, local" label="Scroll, Local" />
          <ToolbarRadio value="local, scroll" label="Local, Scroll" />
        </ToolbarItem>
        <ToolbarItem propKey="bgRepeat" type="radio" label="background-repeat">
          <ToolbarRadio value="repeat-x" label="repeat-x" />
          <ToolbarRadio value="repeat" label="repeat" />
          <ToolbarRadio value="space" label="space" />
          <ToolbarRadio value="round" label="round" />
          <ToolbarRadio value="no-repeat" label="no-repeat" />
          <ToolbarRadio value="space repeat" label="space repeat" />
        </ToolbarItem>
        <Button onClick={() => setShowBgModel(!showBgModel)} variant='outline' >Add BG Image</Button>
      </ToolbarSection>

      <ToolbarSection
        title="Animation"
        props={['buttonType', 'clickType', 'link']}
      >
        <ToolbarItem propKey="animationType" type="select" label="Animation Type" >
          {animations && animations.length > 0 && animations.map((obj) => {
            return <MenuItem style={{ zIndex: 180000 }} value={obj.id}>{obj.name}</MenuItem>
          })}
        </ToolbarItem>
        <ToolbarItem propKey="TimingFunction" type="select" label="Timing function" >
          {animateFunction && animateFunction.length > 0 && animateFunction.map((obj) => {
            return <MenuItem className='menu-items' style={{ zIndex: 180000 }} value={obj.id}>{obj.name}</MenuItem>
          })}
        </ToolbarItem>
        <ToolbarItem
          full={true}
          propKey="animationDuration"
          type="slider"
          label="Duration"
        />
        <ToolbarItem
          full={true}
          propKey="animationDelay"
          type="slider"
          label="Delay"
        />
        <ToolbarItem propKey="animateOnce" type="radio" label="Only animate once">
          <ToolbarRadio value="yes" label="Yes" />
          <ToolbarRadio value="no" label="No" />
        </ToolbarItem>
      </ToolbarSection>
      <ToolbarSection
        title="Colors"
        props={['background', 'color']}
        summary={({ background, color }) => {
          return (
            <div className="flex flex-row-reverse">
              <div
                style={{
                  background:
                    background && `rgba(${Object.values(background)})`,
                }}
                className="shadow-md flex-end w-6 h-6 text-center flex items-center rounded-full bg-black"
              >
                <p
                  style={{
                    color: color && `rgba(${Object.values(color)})`,
                  }}
                  className="text-white w-full text-center"
                >
                  T
                </p>
              </div>
            </div>
          );
        }}
      >
        <ToolbarItem
          full={true}
          propKey="background"
          type="bg"
          label="Background"
        />
        <ToolbarItem full={true} propKey="color" type="color" label="Text" />
      </ToolbarSection>
      <ToolbarSection
        title="Margin"
        props={['margin']}
        summary={({ margin }) => {
          return `${margin[0] || 0}px ${margin[1] || 0}px ${margin[2] || 0}px ${margin[3] || 0
            }px`;
        }}
      >
        <ToolbarItem propKey="margin" index={0} type="slider" label="Top" />
        <ToolbarItem propKey="margin" index={1} type="slider" label="Right" />
        <ToolbarItem propKey="margin" index={2} type="slider" label="Bottom" />
        <ToolbarItem propKey="margin" index={3} type="slider" label="Left" />
      </ToolbarSection>
      <ToolbarSection
        title="Padding"
        props={['padding']}
        summary={({ padding }) => {
          return `${padding[0] || 0}px ${padding[1] || 0}px ${padding[2] || 0
            }px ${padding[3] || 0}px`;
        }}
      >
        <ToolbarItem propKey="padding" index={0} type="slider" label="Top" />
        <ToolbarItem propKey="padding" index={1} type="slider" label="Right" />
        <ToolbarItem propKey="padding" index={2} type="slider" label="Bottom" />
        <ToolbarItem propKey="padding" index={3} type="slider" label="Left" />
      </ToolbarSection>
      <ToolbarSection title="Decoration" props={['radius', 'shadow', 'gap']}>
        <ToolbarItem
          full={true}
          propKey="radius"
          type="slider"
          label="Radius"
        />
        <ToolbarItem
          full={true}
          propKey="shadow"
          type="slider"
          label="Shadow"
        />
        <ToolbarItem
          full={true}
          propKey="firstShadow"
          type="slider"
          label="First Shadow"
        />
        <ToolbarItem
          full={true}
          propKey="secondShadow"
          type="slider"
          label="Second Shadow"
        />
        <ToolbarItem
          full={true}
          propKey="thirdShadow"
          type="slider"
          label="Third Shadow"
        />
        <ToolbarItem
          full={true}
          propKey="gap"
          type="slider"
          label="Gap"
        />
      </ToolbarSection>
      <ToolbarSection title="Alignment">
        <ToolbarItem
          propKey="flexDirection"
          type="radio"
          label="Flex Direction"
        >
          <ToolbarRadio value="row" label="Row" />
          <ToolbarRadio value="column" label="Column" />
        </ToolbarItem>
        <ToolbarItem propKey="fillSpace" type="radio" label="Fill space">
          <ToolbarRadio value="yes" label="Yes" />
          <ToolbarRadio value="no" label="No" />
        </ToolbarItem>

        <ToolbarItem propKey="alignItems" type="radio" label="Align Items">
          <ToolbarRadio value="flex-start" label="Flex start" />
          <ToolbarRadio value="center" label="Center" />
          <ToolbarRadio value="flex-end" label="Flex end" />


        </ToolbarItem>



        <ToolbarItem propKey="Flexproperty" type="radio" label="Flex Wrap">
          <ToolbarRadio value="wrap" label="Warp" />
          <ToolbarRadio value="nowrap" label="No wrap" />



        </ToolbarItem>
        <ToolbarItem
          propKey="justifyContent"
          type="radio"
          label="Justify Content"
        >
          <ToolbarRadio value="flex-start" label="Flex start" />
          <ToolbarRadio value="center" label="Center" />
          <ToolbarRadio value="flex-end" label="Flex end" />
          <ToolbarRadio value="space-between" label="Space between" />
        </ToolbarItem>
      </ToolbarSection>

      <ToolbarSection title="Position">
        <ToolbarItem propKey="position" type="radio" label="Position">
          <ToolbarRadio value="relative" label="Relative" />
          <ToolbarRadio value="absolute" label="Absolute" />
          <ToolbarRadio value="fixed" label="Fixed" />
          <ToolbarRadio value="sticky" label="Sticky" />
          <ToolbarRadio value="static" label="Static" />


        </ToolbarItem>
      </ToolbarSection>

      <ToolbarSection title="Z Index">

        <ToolbarItem
          full={true}
          propKey="zIndex"
          type="slider"
          label="Third Shadow"
        />



      </ToolbarSection>
    </React.Fragment>
  );
};
export default ContainerSettings;