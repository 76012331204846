import { FormControlLabel, Radio } from "@mui/material";
import React from 'react';

// Inspired by blueprintjs
function StyledRadio(props) {
  // const classes = useStyles({});

  return (
    <Radio
      disableRipple
      color="default"
      // checkedIcon={
      //   <span className="checkedIcon"  />
      // }
      // icon={<span style={useStyles.icon} />}
      {...props}
    />
  );
}


export const ToolbarRadio = ({ value, label }) => {
  // const classes = useLabelStyles({});
  return (
    <FormControlLabel
      value={value}
      control={<StyledRadio />}
      label={label}
    />
  );
};
export default ToolbarRadio;
