import React from 'react'
import styles from "./AddTraffic.module.css"
import useProvider from '../../../Context/Context'
import { RxCross2 } from "react-icons/rx"
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { MdEdit } from 'react-icons/md'
import { ImCopy } from 'react-icons/im'
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),

];
function AddTrafficModel() {
    const {
        showAddTrafficModel, setShowAddTrafficModel, setShowTrafficModel, setShowMainTrafficModel, newTrafficPayload, allNodes,setTrafficPayload,trafficPayload,setEditNodeId
    } = useProvider();
    return (
        <div
            onClick={() => setShowAddTrafficModel(false)}
            className={`${styles.summaryModel} ${showAddTrafficModel ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>All Traffic Sources</p>
                    < RxCross2 onClick={() => setShowAddTrafficModel(false)} />
                </div>
                <Table sx={{ minWidth: 250 }} style={{ backgroundColor: "#fff", borderRadius: "5px" }} aria-label="simple table">
                    <TableHead style={{ textAlign: "center" }}>
                        <TableRow>
                            <TableCell style={{ fontSize: "10px", fontWeight:"bold" }} align='left'>Campaign Name </TableCell>
                            <TableCell style={{ fontSize: "10px", fontWeight:"bold" }} align="left">Source </TableCell>

                            <TableCell style={{ fontSize: "10px", fontWeight:"bold" }} align="left">No. Visitors</TableCell>
                            <TableCell style={{ fontSize: "10px", fontWeight:"bold" }} align="left"></TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allNodes?.filter(item => item?.type === "trafficNode").map((row, index) =>
                        (
                            row?.userData && <TableRow
                                key={row.name}

                            >
                                <TableCell style={{ fontSize: "12px" }} align='left' component="th" scope="row">
                                    {row?.userData?.campaignName}
                                </TableCell>
                                <TableCell style={{ fontSize: "12px" }} align="left">{row?.userData?.trafficSources}</TableCell>

                                <TableCell style={{ fontSize: "12px" }} align="left">{row?.userData?.monthlyVisitor}</TableCell>
                                <TableCell style={{ display: "flex", gap: "5px", fontSize: "12px" }} align="left" className={styles?.svgIcons}>

                                    <Tooltip
                                        style={{ padding: "0px" }}
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">
                                                    Edit
                                                </Typography>

                                            </React.Fragment>
                                        }
                                        arrow
                                        placement="top"
                                    >
                                        <IconButton>
                                            <MdEdit
                                                size={20}
                                                onClick={() => {
                                                    setShowTrafficModel(true); setEditNodeId(row?.userData?.nodeId);
                                                    const savedNode = allNodes.find((item) => item.id == row?.userData?.nodeId);
                                                    console.log("findeTraffic",savedNode)
                                                    if (savedNode?.userData) {
                                                      setTrafficPayload(savedNode?.userData);
                                                      return
                                                    } else {
                                                      setTrafficPayload({ ...trafficPayload });
                                                    }
                                                  }}
                                        
                                            />
                                        </IconButton>
                                    </Tooltip>



                                </TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div style={{ display: "flex", gap: "30px", marginTop: '8px', width: "100%", justifyContent: "flex-end" }}>
                    <button onClick={() => setShowAddTrafficModel(false)} className='closebuttn'>Close</button>
                    {/* <button className='addbutton' onClick={()=>{setShowMainTrafficModel(true)}}>Add</button> */}
                </div>
            </div>
        </div>
    )
}

export default AddTrafficModel