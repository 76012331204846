import React, { useState } from 'react'
import styles from "./AddNew.module.css"
import usePageBuilder from '../../../Context/pageBuilder'
import { Box, CardContent, Fab, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { RxCross1 } from 'react-icons/rx'
import { BsCardImage } from 'react-icons/bs'
import { common } from '../../../BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import { RiVideoUploadFill } from 'react-icons/ri'
import { useEditor } from '@craftjs/core'
function loadXHR(url) {

    return new Promise(function (resolve, reject) {
        try {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.onerror = function () { reject("Network error.") };
            xhr.onload = function () {
                if (xhr.status === 200) { resolve(xhr.response) }
                else { reject("Loading error:" + xhr.statusText) }
            };
            xhr.send();
        }
        catch (err) { reject(err.message) }
    });
}
function AddNew() {
    // asset/uploadAsset
    const { showAddDrawer, setShowAddDrawer } = usePageBuilder()

    const [assetName, setAssetName] = useState("")
    const [uploadAsset, setAsset] = useState(null)
    // const addAsset = (e) => {

    //     setAsset(e.target.files[0])
    //     // const imageUrl = URL.createObjectURL(e.target.files[0])

    //     let formData = new FormData();
    //     formData.append("file", e.target.files[0])


    //     axios({
    //         method: "post",
    //         url: `${common}/asset/uploadAsset`,
    //         data: {
    //             formData,
    //             imageType: 'Gallery'
    //         },

    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             Authorization: `Bearer ${localStorage.getItem('token')}`
    //         }
    //     }).then(res => {
    //         toast.success("Asset added succsessfully")
    //     }).catch(errr => {
    //         toast.error("Something wrong")
    //     })




    // }



    const addAsset = (e) => {

        const imageUrl = URL.createObjectURL(e.target.files[0])
        const formdata = new FormData()
        formdata.append('file', e.target.files[0])
        formdata.append("imageType", "Gallery");
        loadXHR(imageUrl).then(result => {
            axios({
                method: "post",
                url: `${common}/asset/uploadAsset`,
                data: formdata,

                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(res => {
                toast.success("Asset added succsessfully")
            }).catch(errr => {
                toast.error("Something wrong")
            })
        })

    }

    const addVideo = (e) => {

        const imageUrl = URL.createObjectURL(e.target.files[0])
        const formdata = new FormData()
        formdata.append('file', e.target.files[0])
        formdata.append("imageType", "Video");
        loadXHR(imageUrl).then(result => {
            axios({
                method: "post",
                url: `${common}/asset/uploadAsset`,
                data: formdata,

                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(res => {
                toast.success("Asset added succsessfully")
            }).catch(errr => {
                toast.error("Something wrong")
            })
        })



    }
    console.log("AddassetsModel", uploadAsset)
   
    return (
        <div style={{ width: "300px"  }} className={`${styles.addNewDrawer} ${showAddDrawer ? styles.showAddDrawer : ""}`}>
            <Box display={'flex'} alignItems={"center"} justifyContent={"space-between"}>
                <p className={styles.menuText}>Menu</p>

                <Tooltip
                    onClick={() => setShowAddDrawer(false)}
                    title={
                        <React.Fragment>
                            <Typography fontSize={10} color="inherit">
                                Close
                            </Typography>
                        </React.Fragment>
                    }
                    arrow
                    placement="top"
                >
                    <IconButton>
                        <RxCross1 size={25} color='black' />
                    </IconButton>
                </Tooltip>

            </Box>
            <CardContent >
                <Stack direction='row'>
                    <Grid container justify="center" alignItems="center">
                        <input

                            accept="video/*"

                            style={{ display: "none" }}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={addVideo}
                        />
                        <label htmlFor="contained-button-file">
                            <Fab component="span" >
                                <RiVideoUploadFill size={25} color='black' />
                            </Fab>
                        </label>

                    </Grid>
                    <Grid container justify="center" alignItems="center">
                        <input

                            accept="image/*"

                            style={{ display: "none" }}
                            id="contained-button-files"
                            multiple
                            type="file"
                            onChange={addAsset}
                        />
                        <label htmlFor="contained-button-files">
                            <Fab component="span" >
                                <BsCardImage size={30} />
                            </Fab>
                        </label>

                    </Grid>
                </Stack>
            </CardContent>
        </div>
    )
}

export default AddNew