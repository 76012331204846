import React, { useEffect, useState } from 'react'
import styles from "./Setting.module.css"
import useProvider from '../../../Context/Context'
import { RxCross2 } from "react-icons/rx"
import { Autocomplete, Box, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { GiDoughRoller } from 'react-icons/gi'
import { BsCurrencyRupee } from 'react-icons/bs'
import { TbCurrencyDinar, TbCurrencyDollarSingapore, TbCurrencyDong, TbCurrencyEuro, TbCurrencyHryvnia, TbCurrencyKroneCzech, TbCurrencyKroneDanish, TbCurrencyPound, TbCurrencyReal, TbCurrencyShekel, TbCurrencyYen } from 'react-icons/tb'
import { MdCurrencyRuble } from 'react-icons/md'
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),

];
function Settings(props) {
    const sourceList = [
        { label: "Indian Rupee", icon: 'BsCurrencyRupee' },
        { label: "Israeli Shekel", icon: 'TbCurrencyShekel' },
        { label: "Kuwaiti Dinar", icon: 'TbCurrencyDinar' },
        { label: "Singapore Dollar", icon: 'TbCurrencyDollarSingapore' },
        { label: "Russian Ruble", icon: 'MdCurrencyRuble' },
        { label: "Brazilian Real", icon: 'TbCurrencyReal' },
        { label: "US Dollar", icon: 'TbCurrencyReal' },
        { label: "Ukrainian Hryvnia", icon: 'TbCurrencyHryvnia' },
        { label: "Vietnamese Dong", icon: 'TbCurrencyDong' },
        { label: "Yen", icon: 'TbCurrencyYen' },
        { label: "Euro", icon: 'TbCurrencyEuro' },
        { label: "Yuan Renminbi", icon: 'TbCurrencyYen' },
        { label: "Danish Krone", icon: 'TbCurrencyKroneDanish' },
        { label: "Australian Dollar", icon: 'GiDoughRoller' },
        { label: "Canadian Dollar", icon: 'GiDoughRoller' },
        { label: "Chilean Peso", icon: 'GiDoughRoller' },
        { label: "Czech Koruna", icon: 'TbCurrencyKroneCzech' },
        { label: "Egyptian Pound", icon: 'TbCurrencyPound' },
    ]
    const sourceList1 = [
        { label: "Indian Rupee", icon: <BsCurrencyRupee /> },
        { label: "Israeli Shekel", icon: <TbCurrencyShekel /> },
        { label: "Kuwaiti Dinar", icon: <TbCurrencyDinar /> },
        { label: "Singapore Dollar", icon: <TbCurrencyDollarSingapore /> },
        { label: "Russian Ruble", icon: <MdCurrencyRuble /> },
        { label: "Brazilian Real", icon: <TbCurrencyReal /> },
        { label: "US Dollar", icon: <TbCurrencyReal /> },
        { label: "Ukrainian Hryvnia", icon: <TbCurrencyHryvnia /> },
        { label: "Vietnamese Dong", icon: <TbCurrencyDong /> },
        { label: "Yen", icon: <TbCurrencyYen /> },
        { label: "Euro", icon: <TbCurrencyEuro /> },
        { label: "Yuan Renminbi", icon: <TbCurrencyYen /> },
        { label: "Danish Krone", icon: <TbCurrencyKroneDanish /> },
        { label: "Australian Dollar", icon: <GiDoughRoller /> },
        { label: "Canadian Dollar", icon: <GiDoughRoller /> },
        { label: "Chilean Peso", icon: <GiDoughRoller /> },
        { label: "Czech Koruna", icon: <TbCurrencyKroneCzech /> },
        { label: "Egyptian Pound", icon: <TbCurrencyPound /> },     
    ]

    const list = ['one', 'two']
    const [priceType, setPriceType] = useState("")
    const [isShow2, setIsShow2] = useState(false)
    const [sourceValue, setSourceValue] = useState('');
    const {
        SettingsModel, setShowSettingsModel, canvasSettings, setCanvasSettings
    } = useProvider();
    console.log("canvas setting=>", canvasSettings)
    return (
        <div
            onClick={() => setShowSettingsModel(false)}
            className={`${styles.summaryModel} ${SettingsModel ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Canvas Settings</p>
                    < RxCross2 onClick={() => setShowSettingsModel(false)} />
                </div>
                <div className={styles.settingBody}>
                    <div className={styles.inputField2}>
                        <p>Simulation Currency</p>
                        <Autocomplete
                            onClick={() => setIsShow2(!isShow2)}
                            disablePortal

                            className={styles.inputField}
                            id="combo-box-demo"
                            options={sourceList}
                            sx={{ width: 201.5, fontSize: "8px", "& fieldset": { border: "none", fontSize: "8px" } }}
                            size='8px'
                            value={canvasSettings?.currency}
                            InputProps={{
                                classes: { notchedOutline: styles.noBorder },
                            }}
                            //   InputProps={{
                            //     endAdornment: <MdArrowDropDown />, // <== adjusted this
                            //   }}
                            onChange={(e, value) => {
                                setCanvasSettings({ ...canvasSettings, currency: value?.label })
                            }}
                            // renderOption={(props, option) => (
                            //     <Box component="li" gap={1} sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            //       {/* {option.icon} */}
                            //      {/* {option.icon} */}
                            //       {option.label}
                            //     </Box>
                            //   )}
                            renderInput={(params) => (
                                <TextField
                                    style={{ fontSize: "8px" }}
                                    sx={{
                                        "& fieldset": { border: "none", fontSize: "8px" }, fontSize: "8px"

                                    }}
                                    
                                    {...params}
                                    label=""
                                />
                            )}
                        />

                    </div>
                </div>
                <div className={styles.inputField}>
                    <p>Language</p>
                    <Select
                
                        size='10px'
                        style={{ backgroundColor: "#fff", fontSize: "8px" }}
                        sx={{
                            "& fieldset": { border: "none", fontSize: "8px" }, height: "24px", width: 201.5
                        }}
                        labelId="demo-select-large"
                        id="demo-select-large"
                        value={'English'}

                        onChange={(e) => setCanvasSettings({ ...canvasSettings, language: e.target.value })}


                    >

                        <MenuItem style={{ fontSize: "8px" }} value="English">English</MenuItem>

                    </Select>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <p>Merchant Account Fees</p>
                    <hr />
                </div>
                <div className={styles.inputField}>
                    <p>Processing Rate (%)</p>
                    <input defaultValue={10} value={canvasSettings?.processingRate} onChange={(e) => setCanvasSettings({ ...canvasSettings, processingRate: e.target.value })} type="number" />
                </div>
                <div className={styles.inputField}>
                    <p>Per Transaction Fee ($)</p>
                    <input defaultValue={1} value={canvasSettings?.transactionFee} onChange={(e) => setCanvasSettings({ ...canvasSettings, transactionFee: e.target.value })} type="number" />
                </div>
                <div style={{ display: "flex", gap: "30px", marginTop: '8px', width: "100%", justifyContent: "flex-end" }}>
                    <button onClick={() => setShowSettingsModel(false)} className='closebuttn'>Close</button>
                    <button className='addbutton' onClick={() => setShowSettingsModel(false)}>Save</button>
                </div>
            </div>
        </div>
    );
}

export default Settings