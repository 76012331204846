import React from 'react';

import { ToolbarRadio } from '../../Toolbar/ToolbarRadio';
import { ToolbarItem, ToolbarSection } from '../../Toolbar';
import { MenuItem } from '@mui/material';
const types = [{
  id: 'button', name: 'Button'
}, { id: 'submit', name: 'Submit' }]
const clickType = [{
  id: 'redirect', name: 'Go to...',
}, {
  id: 'openpopup', name: 'Open Popup',
},
{
  id: 'closepopup', name: 'Close Popup',
}]
export const animations = [
  { id: "fade", name: "fade" },
  { id: "fade-up", name: "fade-up" },
  { id: "fade-down", name: "fade-down" },
  { id: "fade-left", name: "fade-left" },
  { id: "fade-right", name: "fade-right" },
  { id: "fade-up-right", name: "fade-up-right" },
  { id: "fade-up-left", name: "fade-up-left" },
  { id: "fade-down-right", name: "fade-down-right" },
  { id: "fade-down-left", name: "fade-down-left" },
  { id: "flip-up", name: "flip-up" },
  { id: "flip-down", name: "flip-down" },
  { id: "flip-left", name: "flip-left" },
  { id: "flip-right", name: "flip-right" },
  { id: "slide-up", name: "slide-up" },
  { id: "slide-down", name: "slide-down" },
  { id: "slide-left", name: "slide-left" },
  { id: "slide-right", name: "slide-right" },
  { id: "zoom-in", name: "zoom-in" },
  { id: "zoom-in-up", name: "zoom-in-up" },
  { id: "zoom-in-down", name: "zoom-in-down" },
  { id: "zoom-in-left", name: "zoom-in-left" },
  { id: "zoom-in-right", name: "zoom-in-right" },
  { id: "zoom-out", name: "zoom-out" },
  { id: "zoom-out-up", name: "zoom-out-up" },
  { id: "zoom-out-down", name: "zoom-out-down" },
  { id: "zoom-out-left", name: "zoom-out-left" },
  { id: "zoom-out-right", name: "zoom-out-right" },
];
export const animateFunction = [
  { id: "linear", name: "linear" },
  { id: "ease", name: "ease" },
  { id: "linease-inear", name: "ease-in" },
  { id: "ease-out", name: "ease-out" },
  { id: "ease-in-out", name: "ease-in-out" },
  { id: "ease-in-back", name: "ease-in-back" },
  { id: "ease-out-back", name: "ease-out-back" },
  { id: "ease-in-out-back", name: "ease-in-out-back" },
  { id: "ease-in-sine", name: "ease-in-sine" },
  { id: "ease-out-sine", name: "ease-out-sine" },
  { id: "ease-in-out-sine", name: "ease-in-out-sine" },
  { id: "ease-in-quad", name: "ease-in-quad" },
  { id: "ease-out-quad", name: "ease-out-quad" },
  { id: "ease-in-out-quad", name: "ease-in-out-quad" },
  { id: "ease-in-cubic", name: "ease-in-cubic" },
  { id: "ease-out-cubic", name: "ease-out-cubic" },
  { id: "ease-in-out-cubic", name: "ease-in-out-cubic" },
  { id: "ease-in-quart", name: "ease-in-quart" },
  { id: "ease-out-quart", name: "ease-out-quart" },
  { id: "ease-in-out-quart", name: "ease-in-out-quart" }

];
export const ButtonSettings = () => {
  return (
    <React.Fragment>
      <ToolbarSection
        title="Settings"
        props={['buttonType', 'clickType', 'link']}
      >
        <ToolbarItem propKey="buttonType" type="select" label="Button Type" >
          {types && types?.length > 0 && types.map((obj) => {
            return <MenuItem value={obj?.id}>{obj?.name}</MenuItem>
          })}
        </ToolbarItem>
        <ToolbarItem propKey="clickType" type="select" label="Click Type" >
          {clickType && clickType?.length > 0 && clickType.map((obj) => {
            return <MenuItem value={obj?.id}>{obj?.name}</MenuItem>
          })}

        </ToolbarItem>

        <ToolbarItem
          full={true}
          propKey="link"
          type="text"
          label="Link"
        />
        <ToolbarItem propKey="target" type="radio" label="Open in new tab">
          <ToolbarRadio value="yes" label="Yes" />
          <ToolbarRadio value="no" label="No" />
        </ToolbarItem>
      </ToolbarSection>

      <ToolbarSection
        title="Dimensions"
        props={['width', 'height']}
        summary={({ width, height }) => {
          return `${width || 0} x ${height || 0}`;
        }}
      >
        <ToolbarItem propKey="width" type="text" label="Width" />
        <ToolbarItem propKey="height" type="text" label="Height" />

      </ToolbarSection>
      <ToolbarSection
        title="Animation"
        props={['buttonType', 'clickType', 'link']}
      >
        <ToolbarItem propKey="animationType" type="select" label="Animation Type" >
          {animations && animations.length > 0 && animations.map((obj) => {
            return <MenuItem style={{ zIndex: 180000 }} value={obj.id}>{obj.name}</MenuItem>
          })}
        </ToolbarItem>
        <ToolbarItem propKey="TimingFunction" type="select" label="Timing function" >
          {animateFunction && animateFunction.length > 0 && animateFunction.map((obj) => {
            return <MenuItem className='menu-items' style={{ zIndex: 180000 }} value={obj.id}>{obj.name}</MenuItem>
          })}
        </ToolbarItem>
        <ToolbarItem
          full={true}
          propKey="animationDuration"
          type="slider"
          label="Duration"
        />
        <ToolbarItem
          full={true}
          propKey="animationDelay"
          type="slider"
          label="Delay"
        />
        <ToolbarItem propKey="animateOnce" type="radio" label="Only animate once">
          <ToolbarRadio value="yes" label="Yes" />
          <ToolbarRadio value="no" label="No" />
        </ToolbarItem>
      </ToolbarSection>
      <ToolbarSection
        title="Colors"
        props={['background', 'color']}
        summary={({ background, color }) => {
          return (
            <div className="flex flex-row-reverse">
              <div
                style={{
                  background:
                    background && `rgba(${Object.values(background)})`,
                }}
                className="shadow-md flex-end w-6 h-6 text-center flex items-center rounded-full bg-black"
              >
                <p
                  style={{
                    color: color && `rgba(${Object.values(color)})`,
                  }}
                  className="text-white w-full text-center"
                >
                  T
                </p>
              </div>
            </div>
          );
        }}
      >
        <ToolbarItem
          full={true}
          propKey="background"
          type="bg"
          label="Background"
        />
        <ToolbarItem full={true} propKey="color" type="color" label="Text" />
      </ToolbarSection>
      <ToolbarSection
        title="Margin"
        props={['margin']}
        summary={({ margin }) => {
          return `${margin[0] || 0}px ${margin[1] || 0}px ${margin[2] || 0}px ${margin[3] || 0
            }px`;
        }}
      >
        <ToolbarItem propKey="margin" index={0} type="slider" label="Top" />
        <ToolbarItem propKey="margin" index={1} type="slider" label="Right" />
        <ToolbarItem propKey="margin" index={2} type="slider" label="Bottom" />
        <ToolbarItem propKey="margin" index={3} type="slider" label="Left" />
      </ToolbarSection>
      <ToolbarSection title="Decoration">
        <ToolbarItem propKey="buttonStyle" type="radio" label="Style">
          <ToolbarRadio value="full" label="Full" />
          <ToolbarRadio value="outline" label="Outline" />
        </ToolbarItem>
      </ToolbarSection>
      <ToolbarSection title="Text">
        <ToolbarItem propKey="texttranform" type="radio" label="Style">
          <ToolbarRadio value="capitalize" label="capitalize" />
          <ToolbarRadio value="uppercase" label="uppercase" />
          <ToolbarRadio value="lowercase" label="lowercase" />
          <ToolbarRadio value="none" label="none" />
        </ToolbarItem>
      </ToolbarSection>

      <ToolbarSection title="Shadows" props={['shadow','firstShadow','secondShadow','thirdShadow']}>

        <ToolbarItem
          full={true}
          propKey="shadow"
          type="slider"
          label="Shadow"
        />
        <ToolbarItem
          full={true}
          propKey="firstShadow"
          type="slider"
          label="First Shadow"
        />
        <ToolbarItem
          full={true}
          propKey="secondShadow"
          type="slider"
          label="Second Shadow"
        />
        <ToolbarItem
          full={true}
          propKey="thirdShadow"
          type="slider"
          label="Third Shadow"
        />
      </ToolbarSection>
      <ToolbarSection title="Radius" props={['radius']}>

        <ToolbarItem
          full={true}
          propKey="radius"
          type="slider"
          label="Radius"
        />
      </ToolbarSection>
    </React.Fragment>
  );
};
