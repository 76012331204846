import { useNode, useEditor } from '@craftjs/core';
import React from 'react';
import ContentEditable from 'react-contenteditable';
import Text from '../../texts';

import { TextSettings } from '../../texts/TextSettings';
import { FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { RadioSettings } from './RadioSettings';

export const RadioLabel = (props) => {
    const {name, text, textComponent, color, labelPlacement, ...otherProps } = props;

    const {
        connectors: { connect },
        setProp,
    } = useNode();
    return <FormControlLabel name={name} labelPlacement={labelPlacement ? labelPlacement : 'end'} ref={connect} value={text} control={<Radio />} label={
        <Text {...textComponent} text={text} color={props.color} />
    } />
}

RadioLabel.craft = {
    displayName: 'Radio Label',
    props: {
        labelPlacement:'end',
        name:'name'
    },
    related: {
        toolbar: RadioSettings,
    },
};
export default RadioLabel