import React, { useEffect, useState } from 'react'
import styles from "./AddProductModel.module.css"
import useProvider from '../../../Context/Context'
import { RxCross2 } from "react-icons/rx"
import { Autocomplete, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'

function AddProduct() {
    const sourceList = [{ label: "Facebook" }, { label: "Twitter" }, { label: "Instagram" }]
    const list = ['one', 'two']
    const [priceType, setPriceType] = useState("")
    const [isShow2, setIsShow2] = useState(false)
    const [sourceValue, setSourceValue] = useState('');
    const {
        showAddProductModel,
        setShowAddProductModel
    } = useProvider();
    //console.log("show", showAddProductModel)
    return (
        <div
            onClick={() => setShowAddProductModel(false)}
            className={`${styles.summaryModel} ${showAddProductModel ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Summary Options</p>
                    < RxCross2 onClick={() => { setShowAddProductModel(false)}} />
                </div>
                <div className={styles.inputBody}>
                    <div className={styles.inputField}>
                        <p>Product Name</p>
                        <input type="text" />
                    </div>
                    <div className={styles.inputField2}>
                        <p>Product Type</p>
                        <Autocomplete
                            onClick={() => setIsShow2(!isShow2)}
                            disablePortal
                            className={styles.inputField}
                            id="combo-box-demo"
                            options={sourceList}
                            sx={{ width: 201.5 }}
                            value={{ label: sourceValue }}
                            InputProps={{
                                classes: { notchedOutline: styles.noBorder },
                            }}
                            //   InputProps={{
                            //     endAdornment: <MdArrowDropDown />, // <== adjusted this
                            //   }}
                            renderInput={(params) => (
                                <TextField
                                    sx={{
                                        "& fieldset": { border: "none" },
                                    }}
                                    {...params}
                                    label=""
                                />
                            )}
                        />

                    </div>
                    <div className={styles.inputField}>
                        <p>Product Price ($)</p>
                        <input type="number" />
                    </div>
                    <div className={styles.inputField}>
                        <p>Price Type</p>
                        <Select
                        style={{backgroundColor:"#fff", fontSize:"8px"}}
                            sx={{
                                "& fieldset": { border: "none", fontSize: "8px" },height: "24px",width:201.5
                            }}
                            labelId="demo-select-large"
                            id="demo-select-large"
                            value={priceType}

                            onChange={(e) => setPriceType(e.target.value)}


                        >
                            <MenuItem style={{ fontSize: "8px" }} value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem style={{ fontSize: "8px" }} value="One-Tome">One-Tome</MenuItem>
                            <MenuItem style={{ fontSize: "8px" }} value="Monthly">Monthly</MenuItem>
                            <MenuItem style={{ fontSize: "8px" }} value="Yearly">Yearly</MenuItem>
                        </Select>
                    </div>
                    {priceType === 'Monthly' && <div className={styles.inputField}>
                        <p>Stick Rate</p>
                        <input type="number" />
                    </div>}
                    <div className={styles.inputField}>
                        <p>Product Cost ($)</p>
                        <input type="number" />
                    </div>
                </div>
                <div className={styles.closeButtons}>
                    <button className='closebuttn' onClick={() => setShowAddProductModel(false)}  >Close</button>
                    <button className='addbutton' >Add</button>
                </div>
            </div>
        </div>
    );
}

export default AddProduct