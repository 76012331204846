/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import useProvider from '../../Context/Context'
import { RxCross2 } from 'react-icons/rx'
import styles from "./AddPrice.module.css"
import { ImInfo } from 'react-icons/im'
import { MenuItem, Select } from '@mui/material'
import { useLocation } from 'react-router'
import axios from 'axios'
import { common } from '../../BaseUrl'
import { toast } from 'react-toastify'

function AddPriceModel() {
    const { state } = useLocation()
    const { addPriceModel, setShowAddPriceModel, PricePayload, setPricePayload } = useProvider()

    //console.log("price payload=>", PricePayload)
    const [setUpFee, setSetupFee] = useState(false)
    const [trailTab, setTrailTab] = useState("free")
    const [isTrail, setIsTrail] = useState(false)
    const [activeTab, setActiveTab] = useState('settings');
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const AddPrice = () => {
        var payload = {}
        if (PricePayload?.price_plan == 'Onetime') {
            payload = {
                amount: PricePayload?.amount,
                compare_amount: PricePayload?.compare_amount,
                product_cost: PricePayload?.product_cost,
                is_visible: PricePayload?.is_visible,
                price_plan: PricePayload?.price_plan

            }
        }
        if (PricePayload?.price_plan == 'Subscription') {
            payload = {
                amount: PricePayload?.amount,
                compare_amount: PricePayload?.compare_amount,
                product_cost: PricePayload?.product_cost,
                is_visible: PricePayload?.is_visible,
                price_plan: PricePayload?.price_plan,
                interval: PricePayload?.interval,
                is_trial: PricePayload?.is_trial,
                trial_type: PricePayload?.trial_type,
                trial_interval: PricePayload?.trial_interval,
                trial_days: PricePayload?.trial_days,
                setup_fee: PricePayload?.setup_fee


            }
        }
        if (PricePayload?.price_plan == 'PaymentPlan') {
            payload = {
                amount: PricePayload?.amount,
                compare_amount: PricePayload?.compare_amount,
                product_cost: PricePayload?.product_cost,
                is_visible: PricePayload?.is_visible,
                price_plan: PricePayload?.price_plan,
                interval: PricePayload?.interval,
                is_trial: PricePayload?.is_trial,
                trial_type: PricePayload?.trial_type,
                trial_interval: PricePayload?.trial_interval,
                trial_days: PricePayload?.trial_days,
                setup_fee: PricePayload?.setup_fee


            }
        }
        axios({
            method: "post",
            url: `${common}/products/addPrice`,
            data: { ...payload, productId: state?.id },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            //console.log(res);
            toast.success("Price added successfully");
            setPricePayload({
                productId: "",
                price_plan: "",
                amount: "",
                compare_amount: "",
                price_name: "",
                product_cost: "",
                interval: "",
                interval_price: "",
                is_trial: false,
                trial_type: "",
                trial_days: "",
                trial_amount: "",
                setup_fee: "",
                is_visible: false,
            });
            setShowAddPriceModel(false);
        });
    }


    return (
        <div
            onClick={() => setShowAddPriceModel(false)}
            className={`${styles.summaryModel} ${addPriceModel ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Add price</p>
                    < RxCross2 onClick={() => setShowAddPriceModel(false)} />
                </div>
                <div>
                    <div className="top_head">
                        <div className="modal-tabs">
                            <button
                                className={`modal-tab ${activeTab === "settings" ? "active" : ""
                                    }`}
                                onClick={() => { handleTabClick("settings"); setPricePayload({ ...PricePayload, price_plan: "Onetime" }) }}
                            >
                                One Time
                            </button>
                            <button
                                className={`modal-tab ${activeTab === "options" ? "active" : ""}`}
                                onClick={() => { handleTabClick("options"); setPricePayload({ ...PricePayload, price_plan: "Subscription" }) }}
                            >
                                Subscription
                            </button>
                            <button
                                className={`modal-tab ${activeTab === "customize" ? "active" : ""
                                    }`}
                                onClick={() => { handleTabClick("customize"); setPricePayload({ ...PricePayload, price_plan: "PaymentPlan" }) }}
                            >
                                Payment plan
                            </button>
                        </div>

                    </div>
                </div>
                {
                    activeTab === "settings" && <div>
                        <div className={styles.EditProductInputField}>
                            <p>Amount</p>
                            <input value={PricePayload?.amount} onChange={(e) => setPricePayload({ ...PricePayload, amount: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                        </div>
                        <div className={styles.EditProductInputField}>
                            <p className={styles.infoStyle}>Compare at amount <ImInfo /></p>
                            <input value={PricePayload?.compare_amount} onChange={(e) => setPricePayload({ ...PricePayload, compare_amount: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                        </div>
                        <div className={styles.EditProductInputField}>
                            <p className={styles.infoStyle}>Product cost</p>
                            <input value={PricePayload?.product_cost} onChange={(e) => setPricePayload({ ...PricePayload, product_cost: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                        </div>
                        <div className={styles.EditProductInputField2}>
                            <input checked={PricePayload?.is_visible} value={PricePayload?.is_visible} onChange={(e) => setPricePayload({ ...PricePayload, is_visible: !PricePayload?.is_visible })} type="checkbox" name="" id="" />
                            <p>Visible</p>
                        </div>
                    </div>
                }
                {
                    activeTab === "options" && <div>
                        <div className={styles.EditProductInputField}>
                            <p>Amount</p>
                            <input value={PricePayload.amount} onChange={(e) => setPricePayload({ ...PricePayload, amount: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                        </div>
                        <div className={styles.EditProductInputField}>
                            <p className={styles.infoStyle}>Compare at amount <ImInfo /></p>
                            <input value={PricePayload?.compare_amount} onChange={(e) => setPricePayload({ ...PricePayload, compare_amount: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                        </div>

                        <div className={styles.EditProductInputField}>
                            <p className={styles.infoStyle}>Charge customer every</p>
                            <Select
                                style={{ backgroundColor: "#fff", fontSize: "15px", marginTop: "10px" }}
                                sx={{
                                    "& fieldset": { border: "none", fontSize: "15px" }, height: "40px", width: "101.5%"
                                }}
                                labelId="demo-select-large"
                                id="demo-select-large"
                                value={PricePayload?.interval}

                                onChange={(e) => setPricePayload({ ...PricePayload, interval: e.target.value })}


                            >

                                <MenuItem style={{ fontSize: "15px" }} value="Day">Days</MenuItem>
                                <MenuItem style={{ fontSize: "15px" }} value="Week">Week</MenuItem>
                                <MenuItem style={{ fontSize: "15px" }} value="Month">Months</MenuItem>
                                <MenuItem style={{ fontSize: "15px" }} value="Quarters">Quarters</MenuItem>
                                <MenuItem style={{ fontSize: "15px" }} value="Year">Years</MenuItem>
                            </Select>
                        </div>
                        <div className={styles.EditProductInputField}>
                            <p className={styles.infoStyle}>Product cost</p>
                            <input value={PricePayload?.product_cost} onChange={(e) => setPricePayload({ ...PricePayload, product_cost: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                        </div>
                        <div className={styles.EditProductInputField3}>
                            <div className={styles.isSelected}>
                                <input value={PricePayload?.is_trial} checked={PricePayload?.is_trial} onChange={(e) => setPricePayload({ ...PricePayload, is_trial: !PricePayload.is_trial })} type="checkbox" name="" id="" />
                                <p>Product has a trial</p>
                            </div>
                            {
                                PricePayload?.is_trial && <div>
                                    <div className={styles.trailTabs}>
                                        <button className={` closebuttn ${trailTab === 'free' ? styles.activeFree : ""}`} onClick={() => { setTrailTab('free'); setPricePayload({ ...PricePayload, trial_type: 'Free' }) }} >Free trial</button>


                                        <button className={` closebuttn ${trailTab === 'paid' ? styles.activeFree : ""}`} onClick={() => { setTrailTab('paid'); setPricePayload({ ...PricePayload, trial_type: 'Paid' }) }}>Paid trial</button>


                                    </div>
                                    {
                                        trailTab === 'free' && <div className={styles.EditProductInputField}>
                                            <p className={styles.infoStyle}>Trial length</p>

                                            <div className={styles.trialAmount}>
                                                <input value={PricePayload?.trial_days} onChange={(e) => setPricePayload({ ...PricePayload, trial_days: e.target.value })} placeholder='00' type="number" min={0} name="" id="" />
                                                <Select
                                                    style={{ border: "1px solid #1B2948", backgroundColor: "#fff", fontSize: "15px" }}
                                                    sx={{
                                                        "& fieldset": { fontSize: "15px", border: "none" }, height: "100%", width: "91.5%"
                                                    }}
                                                    labelId="demo-select-large"
                                                    id="demo-select-large"
                                                    value={PricePayload?.trial_interval}

                                                    onChange={(e) => setPricePayload({ ...PricePayload, trial_interval: e.target.value })}


                                                >
                                                    <MenuItem style={{ fontSize: "15px" }} value="Day">Days</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Week">Week</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Month">Months</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Quarters">Quarters</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Year">Years</MenuItem>
                                                </Select>
                                            </div>

                                        </div>
                                    }
                                    {
                                        trailTab === 'paid' && <div className={styles.EditProductInputField}>
                                            <p className={styles.infoStyle}>Trial length
                                            </p>
                                            <div className={styles.trialAmount}>
                                                <input value={PricePayload?.trial_days} onChange={(e) => setPricePayload({ ...PricePayload, trial_days: e.target.value })} placeholder='00' type="number" min={0} name="" id="" />
                                                <Select
                                                    style={{ border: "1px solid #1B2948", backgroundColor: "#fff", fontSize: "15px" }}
                                                    sx={{
                                                        "& fieldset": { fontSize: "15px", border: "none" }, height: "100%", width: "91.5%"
                                                    }}
                                                    labelId="demo-select-large"
                                                    id="demo-select-large"
                                                    value={PricePayload?.trial_interval}

                                                    onChange={(e) => setPricePayload({ ...PricePayload, trial_interval: e.target.value })}


                                                >
                                                    <MenuItem style={{ fontSize: "15px" }} value="Day">Days</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Week">Week</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Month">Months</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Quarters">Quarters</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Year">Years</MenuItem>
                                                </Select>
                                            </div>
                                            <div className={styles.EditProductInputField}>
                                                <p className={styles.infoStyle}>Trial cost</p>
                                                <input value={PricePayload?.trial_amount} onChange={(e) => setPricePayload({ ...PricePayload, trial_amount: e.target.value })} style={{ border: "1px solid #1B2948" }} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                                            </div>
                                        </div>
                                    }
                                </div>
                            }


                        </div>
                        <div className={styles.EditProductInputField3}>
                            <div className={styles.isSelected}>
                                <input value={setUpFee} checked={setUpFee} onChange={(e) => setSetupFee(!setUpFee)} type="checkbox" name="" id="" />
                                <p>Setup fee</p>
                            </div>
                            {
                                setUpFee && <div className={styles.EditProductInputField}>
                                    <p className={styles.infoStyle}>One time setup fee</p>
                                    <input value={PricePayload?.setup_fee} onChange={(e) => setPricePayload({ ...PricePayload, setup_fee: e.target.value })} style={{ border: "1px solid #1B2948" }} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                                </div>
                            }
                        </div>
                        <div className={styles.EditProductInputField2}>
                            <input value={PricePayload?.is_visible} onChange={(e) => setPricePayload({ ...PricePayload, is_visible: !PricePayload?.is_visible })} type="checkbox" name="" id="" />
                            <p>Visible</p>
                        </div>
                    </div>
                }
                {
                    activeTab === "customize" && <div>
                        <div className={styles.EditProductInputField}>
                            <p>Amount</p>
                            <input value={PricePayload?.amount} onChange={(e) => setPricePayload({ ...PricePayload, amount: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                        </div>
                        <div className={styles.EditProductInputField}>
                            <p className={styles.infoStyle}>Compare at amount <ImInfo /></p>
                            <input value={PricePayload?.compare_amount} onChange={(e) => setPricePayload({ ...PricePayload, compare_amount: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                        </div>

                        <div className={styles.EditProductInputField}>
                            <p className={styles.infoStyle}>Charge customer every</p>
                            <Select
                                style={{ backgroundColor: "#fff", fontSize: "15px", marginTop: "10px" }}
                                sx={{
                                    "& fieldset": { border: "none", fontSize: "15px" }, height: "40px", width: "101.5%"
                                }}
                                labelId="demo-select-large"
                                id="demo-select-large"
                                value={PricePayload?.interval}

                                onChange={(e) => setPricePayload({ ...PricePayload, interval: e.target.value })}


                            >

                                <MenuItem style={{ fontSize: "15px" }} value="Day">Days</MenuItem>
                                <MenuItem style={{ fontSize: "15px" }} value="Week">Week</MenuItem>
                                <MenuItem style={{ fontSize: "15px" }} value="Month">Months</MenuItem>
                                <MenuItem style={{ fontSize: "15px" }} value="Quarters">Quarters</MenuItem>
                                <MenuItem style={{ fontSize: "15px" }} value="Year">Years</MenuItem>
                            </Select>
                        </div>
                        <div className={styles.EditProductInputField}>
                            <p className={styles.infoStyle}>Amount of payments</p>
                            <input value={PricePayload?.product_cost} onChange={(e) => setPricePayload({ ...PricePayload, product_cost: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                        </div>
                        <div className={styles.EditProductInputField3}>
                            <div className={styles.isSelected}>
                                <input value={isTrail} checked={isTrail} onChange={(e) => setIsTrail(!isTrail)} type="checkbox" name="" id="" />
                                <p>Product has a trial</p>
                            </div>
                            {
                                isTrail && <div>
                                    <div className={styles.trailTabs}>
                                        <button className={` closebuttn ${trailTab === 'free' ? styles.activeFree : ""}`} onClick={() => setTrailTab('free')} >Free trial</button>


                                        <button className={` closebuttn ${trailTab === 'paid' ? styles.activeFree : ""}`} onClick={() => setTrailTab('paid')}>Paid trial</button>


                                    </div>
                                    {
                                        trailTab === 'free' && <div className={styles.EditProductInputField}>
                                            <p className={styles.infoStyle}>Trial length</p>
                                            <div className={styles.trialAmount}>
                                                <input value={PricePayload?.trial_days} onChange={(e) => setPricePayload({ ...PricePayload, trial_days: e.target.value })} placeholder='00' type="number" min={0} name="" id="" />
                                                <Select
                                                    style={{ border: "1px solid #1B2948", backgroundColor: "#fff", fontSize: "15px" }}
                                                    sx={{
                                                        "& fieldset": { fontSize: "15px", border: "none" }, height: "100%", width: "91.5%"
                                                    }}
                                                    labelId="demo-select-large"
                                                    id="demo-select-large"
                                                    value={PricePayload?.trial_interval}

                                                    onChange={(e) => setPricePayload({ ...PricePayload, trial_interval: e.target.value })}


                                                >

                                                    <MenuItem style={{ fontSize: "15px" }} value="Day">Days</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Week">Week</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Month">Months</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Quarters">Quarters</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Year">Years</MenuItem>
                                                </Select>
                                            </div>

                                        </div>
                                    }
                                    {
                                        trailTab === 'paid' && <div className={styles.EditProductInputField}>
                                            <p className={styles.infoStyle}>Trial length
                                            </p>
                                            <div className={styles.trialAmount}>
                                                <input value={PricePayload?.trial_days} onChange={(e) => setPricePayload({ ...PricePayload, trial_days: e.target.value })} placeholder='00' type="number" min={0} name="" id="" />
                                                <Select
                                                    style={{ border: "1px solid #1B2948", backgroundColor: "#fff", fontSize: "15px" }}
                                                    sx={{
                                                        "& fieldset": { fontSize: "15px", border: "none" }, height: "100%", width: "91.5%"
                                                    }}
                                                    labelId="demo-select-large"
                                                    id="demo-select-large"
                                                    value={PricePayload?.trial_interval}

                                                    onChange={(e) => setPricePayload({ ...PricePayload, trial_interval: e.target.value })}


                                                >
                                                    <MenuItem style={{ fontSize: "15px" }} value="Day">Days</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Week">Week</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Month">Months</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Quarters">Quarters</MenuItem>
                                                    <MenuItem style={{ fontSize: "15px" }} value="Year">Years</MenuItem>
                                                </Select>
                                            </div>
                                            <div className={styles.EditProductInputField}>
                                                <p className={styles.infoStyle}>Trial cost</p>
                                                <input value={PricePayload?.trial_amount} onChange={(e) => setPricePayload({ ...PricePayload, trial_amount: e.target.value })} style={{ border: "1px solid #1B2948" }} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                                            </div>
                                        </div>
                                    }
                                </div>
                            }


                        </div>
                        <div className={styles.EditProductInputField3}>
                            <div className={styles.isSelected}>
                                <input value={setUpFee} checked={setUpFee} onChange={(e) => setSetupFee(!setUpFee)} type="checkbox" name="" id="" />
                                <p>Setup fee</p>
                            </div>
                            {
                                setUpFee && <div className={styles.EditProductInputField}>
                                    <p className={styles.infoStyle}>One time setup fee</p>
                                    <input value={PricePayload?.setup_fee} onChange={(e) => setPricePayload({ ...PricePayload, setup_fee: e.target.value })} style={{ border: "1px solid #1B2948" }} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                                </div>
                            }
                        </div>
                        <div className={styles.EditProductInputField2}>
                            <input value={PricePayload?.is_visible} onChange={(e) => setPricePayload({ ...PricePayload, is_visible: !PricePayload?.is_visible })} type="checkbox" name="" id="" />
                            <p>Visible</p>
                        </div>
                    </div>
                }


                <div style={{ display: "flex", gap: "30px", marginTop: '8px', width: "100%", justifyContent: "flex-end" }}>
                    <button onClick={() => setShowAddPriceModel(false)} className='closebuttn'>Close</button>
                    <button className='addbutton' onClick={AddPrice}>Add</button>
                </div>
            </div>
        </div>
    )
}

export default AddPriceModel