import React, { useEffect, useRef, useState } from 'react'
import Nav from '../../Components/Header/Nav'
import PageHeader from '../../Components/Header/PageHeader'
import PageSideBar from './PageSideBar'
import styles from "./PageBuilder.module.css"
import AddNew from './SideDrawers/AddNew'
import AddProdDrawer from './SideDrawers/AddProdDrawer'
import { Canvas, Editor, Element, Frame, useEditor, useNode } from '@craftjs/core'
import TextComponent from './TextComponent'
import { Button, Grid, Paper } from '@mui/material'
import { Container } from './Components/OuterContainer'
import DragableContainer from './Components/DragableContainer'
import { SModal } from './Components/modal'
import BGImageModel from "../CraftPage/Components/Container/BGImageModel"
import ChangeImage from "../CraftPage/Components/ChangeImage/BGImageModel"
import ChangeSlider from "../CraftPage/Components/SliderIMages/BGImageModel"
import { Video } from './Components/Video'
import Inputs from './Components/forms/input'
import Selects from './Components/forms/select'
import Radios from './Components/forms/radio'
import RadioLabel from './Components/forms/radioLabel'
import Text from "./Components/texts";
import FormContainer from "./Components/forms/form";
import FormContainer2 from "./Components/forms/form2";
import Card, { CardTop, CardBottom } from "./Components/Card";
import MButton from './Components/Button';
import ImageTag from './Components/imageTag'
import Slider from './Components/sliderBox'
import usePageBuilder from '../../Context/pageBuilder'
import SButton from './Components/buttons'
import Texts from "./Components/Text";
import GridContainer from "./Components/Grid";
import Product from "./Components/product";
import EditorWrapper from './EditorWrapper'
import { RenderNode } from './RenderNode'
import Template from './Components/Template'
import axios from 'axios'
import ChatGptModel from '../chatGptModel/ChatGptModel'
import Drawer from '../SettingDrawer/Drawer'
import Paypal from './Components/PaypalButton'
import { getPageById } from '../../Services/api'
import page3 from './page2.json'
import ResponsiveAppBar from "../../PageBuilder/CraftPage/Components/Navbars1"
import ResponsiveAppBar1 from "../../PageBuilder/CraftPage/Components/Navbars2"
import { useLocation } from 'react-router-dom'
import Loader from '../../Loader'
import Testimonial from './Components/Testimonials'
import Testimonial2 from './Components/Testimonials/Testimonial2'
import PageSetting from './Models/PageSetting'
import html2canvas from 'html2canvas';
// import ChargebeeButton from './Components/ChargebeeBUtton/ChargebeeButton'
import CustomCode from './Components/CustomCode'
function PageBuilder() {
  const { setShowProdDrawer, sliderImage, BgColor, setQuery } = usePageBuilder();
  const [JSONDATA, setJSONDATA] = useState();
  const [isLoading, setIsLoading] = useState(false)

  const [show, setShow] = useState(false);
  const [mobileView, setMobileView] = useState(100)
  const [pageDetails, setPageDetails] = useState({})
  const pageId = useLocation().state?.pageId

  // const {
  //   connectors,
  // } = useEditor();S


  const takeScreenshot = () => {
    const element = targetRef.current;

    html2canvas(element)
      .then(canvas => {
        const screenshot = canvas.toDataURL('image/png'); // Convert canvas to base64-encoded PNG image

        // Save or process the screenshot as desired
        console.log(screenshot);
      })
      .catch(error => {
        console.error('Error while taking screenshot:', error);
      });
  };


  useEffect(() => {
    getPage()
    document.title = "Page builder"
  }, [])
  const getPage = () => {
    setIsLoading(true)
    getPageById(pageId).then(resp => {
      // setShow(true)

      setIsLoading(false)

      if (resp.data.data.content) {
        setJSONDATA(resp.data.data.content)
        setPageDetails(resp.data.data)
        setShow(true)

      } else {
        setShow(true)

      }
      setIsLoading(false)


    }).catch((e) => {
      console.log('e: ', e);
      // setJSONDATA('')
      setShow(true)


    })
  }
  // console.log("http://localhost:5000/save-page", JSON.parse(JSONDATA))
  console.log("http://localhost:5000/save-page", JSONDATA)
  const targetRef = useRef(null);
  return (<>

    {/* <button onClick={takeScreenshot}>Take screenShot</button> */}
    {show ? <div className={styles.PageBuilder} >
      <Editor
        resolver={{
          TextComponent,
          Container,
          FormContainer,
          Card,
          MButton,
          CardTop,
          CardBottom,
          Text,
          AddProdDrawer,
          ImageTag,
          SButton,
          Texts,
          Video,
          Inputs,
          Selects,
          RadioLabel,
          Radios,
          Slider,
          Template,
          SModal,
          Paypal,
          Product,
          DragableContainer,
          ResponsiveAppBar1,
          ResponsiveAppBar,
          Testimonial,
          Testimonial2,
          GridContainer,
          CustomCode,
          FormContainer2



        }}
        onRender={RenderNode}
      >
        <div className={styles.PageHeader}>
          <Nav />
          <PageHeader pageId={pageId} mobileView={mobileView} setMobileView={setMobileView} pageDetails={pageDetails} />
          <BGImageModel />

          <ChangeSlider />
          <ChangeImage />
        </div>
        <div className={styles.pageSide}>
          <PageSideBar />
          <AddProdDrawer />
          <div className={styles.canvas}>
            <AddNew />

            <ChatGptModel />
            <PageSetting />

            {/* <TextComponent text="jfdkfdkf" size={30} /> */}

            <Drawer />
            <Grid container className={styles.PageBuilderBody} spacing={3}>
              <Grid item xs={11} className="page-container">
                <EditorWrapper>
                  <div className="relative flex-col flex items-center pt-8">
                    <Frame data={JSONDATA}>
                      <Element
                        canvas
                        is={Container}
                        width="100%"
                        height="70vh"
                        background={{ r: 255, g: 255, b: 255, a: 1 }}
                        padding={["0", "0", "10", "0"]}
                        custom={{ displayName: "App" }}
                      >

                      </Element>
                    </Frame>


                  </div>
                </EditorWrapper>
              </Grid>

            </Grid>
          </div>

        </div>
      </Editor>

    </div> : <>
      <Loader />
    </>}
  </>
  );
}

export default PageBuilder