/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import useProvider from '../../Context/Context'
import { RxCross2 } from 'react-icons/rx'
import styles from "./AddPrice.module.css"
import { ImInfo } from 'react-icons/im'
import { MenuItem, Select } from '@mui/material'
import { useLocation } from 'react-router'
import axios from 'axios'
import { common } from '../../BaseUrl'
import { toast } from 'react-toastify'

function EditOneTime() {
    // const prodId = useLocation().state?.id
    const { EditOnePriceModel, SetEditOnePriceModel, PricePayload, setPricePayload } = useProvider()

    //console.log("price payload=>", PricePayload)

    const AddPrice = () => {
        var payload = {}
        if (PricePayload?.price_plan == 'Onetime') {
            payload = {
                amount: PricePayload?.amount,
                compare_amount: PricePayload?.compare_amount,
                product_cost: PricePayload?.product_cost,
                is_visible: PricePayload?.is_visible,
                price_plan: PricePayload?.price_plan

            }
        }
        if (PricePayload?.price_plan == 'Subscription') {
            payload = {
                amount: PricePayload?.amount,
                compare_amount: PricePayload?.compare_amount,
                product_cost: PricePayload?.product_cost,
                is_visible: PricePayload?.is_visible,
                price_plan: PricePayload?.price_plan,
                interval: PricePayload?.interval,
                is_trial: PricePayload?.is_trial,
                trial_type: PricePayload?.trial_type,
                trial_interval: PricePayload?.trial_interval,
                trial_days: PricePayload?.trial_days,
                setup_fee: PricePayload?.setup_fee


            }
        }
        if (PricePayload?.price_plan == 'PaymentPlan') {
            payload = {
                amount: PricePayload?.amount,
                compare_amount: PricePayload?.compare_amount,
                product_cost: PricePayload?.product_cost,
                is_visible: PricePayload?.is_visible,
                price_plan: PricePayload?.price_plan,
                interval: PricePayload?.interval,
                is_trial: PricePayload?.is_trial,
                trial_type: PricePayload?.trial_type,
                trial_interval: PricePayload?.trial_interval,
                trial_days: PricePayload?.trial_days,
                setup_fee: PricePayload?.setup_fee


            }
        }
        axios({
            method: "put",
            url: `${common}/products/updatePrice/${PricePayload?._id}`,
            data: { ...payload },
            headers: {
                'Content-Type': "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            //console.log(res)
            toast.success("Price updated successfully")
            setPricePayload({
                productId: "",
                price_plan: "",
                amount: "",
                compare_amount: "",
                price_name: "",
                product_cost: "",
                interval: "",
                interval_price: "",
                is_trial: false,
                trial_type: "",
                trial_days: "",
                trial_amount: "",
                setup_fee: "",
                is_visible: false
            })
            SetEditOnePriceModel(false)

        })
    }


    return (
        <div
            onClick={() => {
                SetEditOnePriceModel(false); setPricePayload({
                    productId: "",
                    price_plan: "",
                    amount: "",
                    compare_amount: "",
                    price_name: "",
                    product_cost: "",
                    interval: "",
                    interval_price: "",
                    is_trial: false,
                    trial_type: "",
                    trial_days: "",
                    trial_amount: "",
                    setup_fee: "",
                    is_visible: false
                })
            }}
            className={`${styles.summaryModel} ${EditOnePriceModel ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Edit price</p>
                    < RxCross2 onClick={() => SetEditOnePriceModel(false)} />
                </div>
                <div>
                    <div className={styles.EditProductInputField}>
                        <p>Amount</p>
                        <input value={PricePayload?.amount} onChange={(e) => setPricePayload({ ...PricePayload, amount: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                    </div>
                    <div className={styles.EditProductInputField}>
                        <p className={styles.infoStyle}>Compare at amount <ImInfo /></p>
                        <input value={PricePayload?.compare_amount} onChange={(e) => setPricePayload({ ...PricePayload, compare_amount: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                    </div>
                    <div className={styles.EditProductInputField}>
                        <p className={styles.infoStyle}>Product cost</p>
                        <input value={PricePayload?.product_cost} onChange={(e) => setPricePayload({ ...PricePayload, product_cost: e.target.value })} placeholder='00.0 USD' type="number" min={0} name="" id="" />
                    </div>
                    <div className={styles.EditProductInputField2}>
                        <input checked={PricePayload?.is_visible} value={PricePayload.is_visible} onChange={(e) => setPricePayload({ ...PricePayload, is_visible: !PricePayload?.is_visible })} type="checkbox" name="" id="" />
                        <p>Visible</p>
                    </div>
                </div>




                <div style={{ display: "flex", gap: "30px", marginTop: '8px', width: "100%", justifyContent: "flex-end" }}>
                    <button onClick={() => {
                        SetEditOnePriceModel(false); setPricePayload({
                            productId: "",
                            price_plan: "",
                            amount: "",
                            compare_amount: "",
                            price_name: "",
                            product_cost: "",
                            interval: "",
                            interval_price: "",
                            is_trial: false,
                            trial_type: "",
                            trial_days: "",
                            trial_amount: "",
                            setup_fee: "",
                            is_visible: false
                        })
                    }} className='closebuttn'>Close</button>
                    <button className='addbutton' onClick={AddPrice}>Add</button>
                </div>
            </div>
        </div>
    )
}

export default EditOneTime