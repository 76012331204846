import React from 'react';

import { ContainerSettings } from '../../Container/ContainerSettings';

import { Resizer } from '../../Resizer';
import FormSettings from './FormSettings';
import Container from '../../DragableContainer';
import Input from '../input';
import Button from '../../buttons';
import { Element, useNode } from '@craftjs/core';

// export type ContainerProps = {
//   background: Record<'r' | 'g' | 'b' | 'a', number>;
//   color: Record<'r' | 'g' | 'b' | 'a', number>;
//   flexDirection: string;
//   alignItems: string;
//   justifyContent: string;
//   fillSpace: string;
//   width: string;
//   height: string;
//   padding: string[];
//   margin: string[];
//   marginTop: number;
//   marginLeft: number;
//   marginBottom: number;
//   marginRight: number;
//   shadow: number;
//   children: React.ReactNode;
//   radius: number;
// };
const jsonElement = {
    type: 'div',
    props: {
        className: 'my-class',
        children: [
            {
                type: 'h1',
                props: {
                    className: 'title',
                    children: 'Hello, World!'
                }
            },
            {
                type: 'p',
                props: {
                    className: 'description',
                    children: 'This is a description.'
                }
            }
        ]
    }
};
const defaultProps = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    fillSpace: 'no',
    padding: ['0', '0', '0', '0'],
    margin: ['0', '0', '0', '0'],
    background: { r: 255, g: 255, b: 255, a: 1 },
    color: { r: 0, g: 0, b: 0, a: 1 },
    shadow: 0,
    radius: 0,
    width: '100%',
    height: 'auto',
    id: 'id',
    action: '/',
    method: 'get'
};

export const FormContainer = (props) => {
    props = {
        ...defaultProps,
        ...props,
    };
    const {
        flexDirection,
        alignItems,
        justifyContent,
        fillSpace,
        background,
        color,
        padding,
        margin,
        shadow,
        radius,
        children,
        id,
        action,
        method
    } = props;

    const renderElement = (element) => {
        const { type, props } = element;

        // Render the element recursively
        const children = Array.isArray(props.children)
            ? props.children.map(renderElement)
            : props.children;

        return React.createElement(type, { ...props, children });
    };
    const {
        connectors: { connect },
        setProp,
    } = useNode();
    return (

        <Element

            ref={connect}
            bgurl="https://images.unsplash.com/photo-1688320097436-a20f0b5ba1cc?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0NTQ1NDd8MHwxfGFsbHwyfHx8fHx8Mnx8MTY4ODU0NDE4NHw&ixlib=rb-4.0.3&q=85"
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            fillSpace={'no'}
            padding={['10', '10', '10', '10']}
            margin={['0', '0', '0', '0']}
            background={{ r: 256, g: 256, b: 256, a: 1 }}
            color={{ r: 0, g: 0, b: 0, a: 1 }}
            shadow={0}
            firstShadow={0}
            secondShadow={0}
            thirdShadow={0}
            width={"100%"}
            height="500px"
            radius={10}
            id="formContainer"
            visibility={15}

            is={Container}
        >

            <Element is={Input}

                flexDirection={'row'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                margin={['0', '0', '0', '0']}
                background={{ r: 256, g: 256, b: 256, a: 1 }}
                color={{ r: 0, g: 0, b: 0, a: 1 }}
                shadow={0}
                firstShadow={0}
                secondShadow={1}
                thirdShadow={0}
                radius={20}
                width={"60%"}
                height='50px'
                name='name'
                placeholder="Email address"
                border="none"
                outline={"none"}
                padding={[10, 10, 10, 10]}

            />
            <Element is={Input}

                flexDirection={'row'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                margin={['10', '0', '0', '0']}
                background={{ r: 256, g: 256, b: 256, a: 1 }}
                color={{ r: 0, g: 0, b: 0, a: 1 }}
                shadow={0}
                firstShadow={0}
                secondShadow={1}
                thirdShadow={0}
                radius={20}
                width={"60%"}
                height='50px'
                name='name'
                placeholder="Name"
                border="none"
                outline={"none"}
                padding={[10, 10, 10, 10]}


            />
            <Element is={Input}

                flexDirection={'row'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                margin={['10', '0', '0', '0']}
                background={{ r: 256, g: 256, b: 256, a: 1 }}
                color={{ r: 0, g: 0, b: 0, a: 1 }}
                shadow={0}
                firstShadow={0}
                secondShadow={1}
                thirdShadow={0}
                radius={20}
                width={"60%"}
                height='50px'
                name='name'
                placeholder="Address"
                border="none"
                outline={"none"}
                padding={[10, 10, 10, 10]}

            />
            <Element is={Input}
                margin={['10', '0', '0', '0']}
                background={{ r: 256, g: 256, b: 256, a: 1 }}
                color={{ r: 0, g: 0, b: 0, a: 1 }}
                shadow={0}
                firstShadow={0}
                secondShadow={1}
                thirdShadow={0}
                radius={10}
                width={"60%"}
                height='150px'
                name='name'
                placeholder="Message"
                border="none"
                outline={"none"}
                padding={[10, 10, 10, 10]}

            />

            <Element
                is={Button}
                background={{ r: 100, g: 120, b: 203, a: 1 }}
                color={{ r: 256, g: 256, b: 256, a: 1 }}
                radius={"20px"}
                width={"200px"}

            />

        </Element>

    );
};

FormContainer.craft = {
    displayName: 'Form',
    props: defaultProps,
    rules: {
        canDrag: () => true,
    },
    related: {
        toolbar: FormSettings,
    },
};
export default FormContainer;
