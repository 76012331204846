import React, { useEffect, useState } from 'react'
import styles from "./AddExpences.module.css"
import useProvider from '../../../Context/Context'
import { RxCross2 } from "react-icons/rx"
import { Autocomplete, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'

function AddExpences() {
    const sourceList = [{ label: "Facebook" }, { label: "Twitter" }, { label: "Instagram" }]
    const list = ['one', 'two']
    const [expences, setExpences] = useState({
        billinFrequency: "",
        expnenseName: "",
        amount: ""
    })
    const [priceType, setPriceType] = useState("")
    const [isShow2, setIsShow2] = useState(false)
    const [sourceValue, setSourceValue] = useState('');
    const {
        AddExpencesModel, setShowAddExpencesModel,
        expencespayLLoad, setExpencsPayLoad
    } = useProvider();
    //console.log("show", expencespayLLoad)
    const handleSave = () => {
        setExpencsPayLoad([...expencespayLLoad, { ...expences }])
        setShowAddExpencesModel(false)
        setExpences({
            billinFrequency: "",
            expnenseName: "",
            amount: ""
        })
    }
    return (
        <div
            onClick={() => setShowAddExpencesModel(false)}
            className={`${styles.summaryModel} ${AddExpencesModel ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Add Expenses</p>
                    < RxCross2 onClick={() => { setShowAddExpencesModel(false); console.log('hello') }} />
                </div>
                <div className={styles.inputBody}>

                    <div className={styles.inputField2}>
                        <p>Expense Name</p>
                        {/* <Autocomplete

                            onClick={() => setIsShow2(!isShow2)}
                            disablePortal
                            className={styles.inputField}
                            id="combo-box-demo"
                            options={sourceList}
                            sx={{ width: 201.5, fontFamily:'Avenir' }}

                            value={expences?.expnenseName}
                            InputProps={{
                                classes: { notchedOutline: styles.noBorder },
                            }}
                            //   InputProps={{
                            //     endAdornment: <MdArrowDropDown />, // <== adjusted this
                            //   }}
                            onChange={(e, value) => {
                                setExpences({ ...expences, expnenseName: value?.label })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    sx={{
                                        "& fieldset": { border: "none" },
                                    }}
                                    {...params}
                                    label=""
                                />
                            )}
                        /> */}
                         <input
                            value={expences?.expnenseName}

                            onChange={(e) => setExpences({ ...expences, expnenseName: e.target.value })} type="text" />

                    </div>

                    <div className={styles.inputField}>
                        <p>Billing Frequency</p>
                        <Select
                            style={{ backgroundColor: "#fff", fontSize: "8px" }}
                            sx={{
                                "& fieldset": { border: "none", fontSize: "8px" }, height: "24px", width: 201.5
                            }}
                            labelId="demo-select-large"
                            id="demo-select-large"
                            value={expences?.billinFrequency}

                            onChange={(e) => setExpences({ ...expences, billinFrequency: e.target.value })}


                        >
                            <MenuItem style={{ fontSize: "8px" }} value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem style={{ fontSize: "8px" }} value="One-Time">One-Time</MenuItem>
                            <MenuItem style={{ fontSize: "8px" }} value="Monthly">Monthly</MenuItem>
                            <MenuItem style={{ fontSize: "8px" }} value="Yearly">Yearly</MenuItem>
                        </Select>
                    </div>

                    <div className={styles.inputField}>
                        <p>Amount ($)</p>
                        <input
                            value={expences?.amount}

                            onChange={(e) => setExpences({ ...expences, amount: e.target.value })} type="number" />
                    </div>

                </div>
                <div className={styles.closeButtons}>
                    <button className='closebuttn' onClick={() => setShowAddExpencesModel(false)}  >Close</button>
                    <button className='addbutton' onClick={handleSave} >Save</button>
                </div>
            </div>
        </div>
    );
}

export default AddExpences