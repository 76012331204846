import React from 'react';
import { ToolbarItem, ToolbarSection } from '../../Toolbar';


export const VideoSettings = () => {
  return (
    <React.Fragment>
      <ToolbarSection title="Video">
        {/* <ToolbarItem
          full={true}
          propKey="height"
          type="number"
          label="Height"
        /> */}
        <ToolbarItem
          full={true}
          propKey="width"
          type="number"
          label="Width"
        />
        <ToolbarItem
          full={true}
          propKey="link"
          type="text"
          label="Link"
        />
      </ToolbarSection>
    </React.Fragment>
  );
};
