import React, { useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import SliderSettings from "./SliderSettings"
import usePageBuilder from '../../../../Context/pageBuilder';
import Container from '../DragableContainer';
import { useNode } from '@craftjs/core';

const defaultProps = {
    showArrows: 'true',
    showIndicators: 'true'
}
function Slider(
    {
        showArrows,
        showIndicators,
        images,
        showThumbs,
        width,
        height
    }
) {
    const { sliderImage, setSliderImage } = usePageBuilder()
    console.log("sliderImage", sliderImage)

    return (

            <Carousel
            
                width={`${width}`}
                height={`${height}`}
                showArrows={showArrows === 'true' ? true : false}
                showIndicators={showIndicators === 'true' ? true : false}
                showThumbs={showThumbs === 'true' ? true : false}

            >

                {
                    sliderImage?.map((image, index) => (
                        <div>
                            <img key={index} style={{ height: `${height}`, width: `${width}` }} src={image} />

                        </div>
                    ))
                }
            </Carousel>
      
    )
}


export default Slider