import React, { useEffect, useState } from "react";
import styles from "./Setting.module.css";
import { useNavigate } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import { BsPaypal } from "react-icons/bs";
import charge from "../../assets/logo/charge.png";
import paypal from "../../assets/logo/paypal.png";
import stripe from "../../assets/logo/stripe.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { addPaymentMethod, getCredential, getSettings, settings } from "../../Services/api";
import { toast } from "react-toastify";
function loadXHR(url) {

  return new Promise(function (resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function () { reject("Network error.") };
      xhr.onload = function () {
        if (xhr.status === 200) { resolve(xhr.response) }
        else { reject("Loading error:" + xhr.statusText) }
      };
      xhr.send();
    }
    catch (err) { reject(err.message) }
  });
}
const validationSchema = yup.object().shape({
  workspace_name: yup.string().required("Name is required."),
  workspace_url: yup.string().required("URL is required."),
  phone_no: yup.number().required("Phone Number is required."),
  organization: yup.string().required("Organization is required."),
  timezone: yup.string().required("Time-zone is required."),
});
function Setting() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [settingList, setSettingList] = useState();
  const [value, setValue] = useState({});
  console.log('value: ', value);
  console.log("settingList: ", settingList);
  const [file, setFile] = useState(null);
  console.log('file: ', file);

  useEffect(() => {
    getsettingsList();
    document.title = "Settings"
  }, []);
  const getsettingsList = () => {
    
    getSettings().then((res) => {
      console.log('res: ', res);
      if (res.data) {
        setSettingList(res?.data);
        console.log(res);
        formik.setFieldValue('workspace_name', res?.data?.workspace_name)
        formik.setFieldValue('workspace_url', res?.data?.workspace_url)
        formik.setFieldValue('timezone', res?.data?.timezone)
        formik.setFieldValue('phone_no', res?.data?.phone_no)
        formik.setFieldValue('organization', res?.data?.organization)
        formik.setFieldValue('zapier', res?.data?.zapier)
      }
    });
    getCredential().then((res) => {
      console.log('res: ', res);
      if (res.status === 200) {
        let json ={}
        res.data?.data?.map((obj)=>{
          console.log('obj: ', obj);
          console.log('value: ', value);
          Object.assign(json, obj)
        })
        console.log('json: ', json);
        setValue({ ...value, ...json })

      }
    })
  };

  const formik = useFormik({
    initialValues: {
      workspace_name: "",
      workspace_url: "",
      phone_no: "",
      organization: "",
      timezone: "",
      file: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values: ", values);
      
      let formData = new FormData()
      formData.append('workspace_name', values?.workspace_name)
      formData.append('workspace_url', values?.workspace_url)
      formData.append('phone_no', values?.phone_no)
      formData.append('organization', values?.organization)
      formData.append('timezone', values?.timezone)
      if (file) {
       loadXHR(URL.createObjectURL(file)).then(result=>{
         formData.append('file', result)
         console.log('result: ', result);
         values.file = result
          settings(values)
        .then((res) => {
          console.log(res);
          if (res?.message === "Data created successfully") {
            toast.success("Settings updated successfully")
            setData(res?.data);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally((res) => { });
       })
      }else{
        settings(values)
          .then((res) => {
            console.log(res);
            if (res?.message === "Data created successfully") {
              toast.success("Settings updated successfully")
              setData(res?.data);
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally((res) => { });
      }
     
    },
  });
  const handlePaymentMethod = (method) => {
    let body = {
      payment_platform: method
    }
    if (method === 'Paypal') {
      body.clientId = value?.clientId
      body.clientSecret = value?.clientSecret
    }
    if (method === 'ChargeBee') {
      body.site = value?.site
      body.apiKey = value?.apiKey
    }
    if (method === 'Stripe') {
      body.publishKey = value?.publishKey
    }
    addPaymentMethod(body).then((response) => {
      console.log('response: ', response);
      if (response?.status === 200) {
        toast.success("Payment method updated successfully")
      }
    })
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.Funnels}>
        <div className={styles.FunnelsCreate}>
          <h1 className={styles.FunnelsHead}> Setting</h1>
          <div className={styles.Button}>
            <button>Discard</button>
            <button type="submit">Save</button>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.input}>
            <label htmlFor="workspace_name">Workspace Name </label>{" "}
            {formik.errors.workspace_name && formik.touched.workspace_name && (
              <small style={{ color: "red" }}>
                {formik.errors.workspace_name}
              </small>
            )}
            <input
              type="text"
              name="workspace_name"
              id="workspace_name"
              value={formik.values.workspace_name}
              onChange={formik.handleChange}
            />
            <label htmlFor="workspace_url">Workspace & subdomain </label>{" "}
            {formik.errors.workspace_url && formik.touched.workspace_url && (
              <small style={{ color: "red" }}>
                {formik.errors.workspace_url}
              </small>
            )}
            <div className={styles.https}>
              <p>https://</p>{" "}
              <input
                type="text"
                name="workspace_url"
                id="workspace_url"
                value={formik.values.workspace_url}
                onChange={formik.handleChange}
              />
            </div>
            <label htmlFor="phone_no">Phone number</label>{" "}
            {formik.errors.phone_no && formik.touched.phone_no && (
              <small style={{ color: "red" }}>{formik.errors.phone_no}</small>
            )}
            <input
              type="number"
              name="phone_no"
              id="phone_no"
              value={formik.values.phone_no}
              onChange={formik.handleChange}
            />
            <label htmlFor="timezone">Time zone</label>{" "}
            {formik.errors.timezone && formik.touched.timezone && (
              <small style={{ color: "red" }}>{formik.errors.timezone}</small>
            )}
            <input
              type="text"
              name="timezone"
              id="timezone"
              value={formik.values.timezone}
              onChange={formik.handleChange}
            />
            <label htmlFor="organization">Organization</label>{" "}
            {formik.errors.organization && formik.touched.organization && (
              <small style={{ color: "red" }}>
                {formik.errors.organization}
              </small>
            )}
            <input
              type="text"
              name="organization"
              id="organization"
              value={formik.values.organization}
              onChange={formik.handleChange}
            />
            <label htmlFor="zapier">Zapier</label>{" "}
            {formik.errors.zapier && formik.touched.zapier && (
              <small style={{ color: "red" }}>{formik.errors.zapier}</small>
            )}
            <input
              type="text"
              name="zapier"
              id="zapier"
              value={
                formik.values.zapier
              }
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.side}>
            <div className={styles.manager}>
              <h5>Workspace icon</h5>
            </div>
            <p>Add logo by uploading or select from cloud</p>
            <div className={styles.bar}>
              {!file ? (
                <input
                  className={styles.icon}
                  type="file"
                  name="file"
                  id="file"
                  value={formik.values.image}
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    setFile(e.target.files[0]);
                    // setFileName(file.name)
                  //   const fr = new FileReader()
                  //   fr.readAsArrayBuffer(file)
                  //   fr.onload = function () {
                  //     const blob = new Blob([fr.result])
                  //     //console.log("blob", blob)
                  //   }
                  //  URL.createObjectURL(file)
                    formik.handleChange(e);
                  }}
                />
              ) : (<>
                {/* <img src={file} alt="file" /> */}
                  {file?.name}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.payment}>
          <label>Type payment link</label>
          <div className={styles.paypal}>
            <div className={styles.payicon}>
              <img src={paypal} alt="" />
            </div>
            <div className={styles.paycontent}>
              <label htmlFor="id">ID</label>
              <input
                type="text"
                name="clientId"
                id="clientId"
                value={value?.clientId}
                onChange={(e) => setValue({ ...value, [e.target.name]: e.target.value })}
              />
              <label htmlFor="secret">Secret</label>
              <input
                type="text"
                name="clientSecret"
                id="clientSecret"
                value={value?.clientSecret}

                onChange={(e) => setValue({ ...value, [e.target.name]: e.target.value })}
                style={{ width: "310px" }}
              />
              <button type="button" onClick={() => { handlePaymentMethod("Paypal") }}>Save</button>
            </div>
          </div>
          <div className={styles.stripe}>
            <div className={styles.payicon}>
              <img src={stripe} alt="" />
            </div>
            <div className={styles.paycontent}>
              <label htmlFor="key">Publish Key</label>
              <input
                type="text"
                name="publishKey"
                id="publishKey"
                value={value?.publishKey}

                onChange={(e) => setValue({ ...value, [e.target.name]: e.target.value })}
                style={{ width: "459px" }}
              />
              <button type="button" onClick={() => { handlePaymentMethod("Stripe") }}>Save</button>
            </div>
          </div>
          <div className={styles.chargebe}>
            <div className={styles.payicon}>
              <img src={charge} alt="" />
            </div>
            <div className={styles.paycontent}>
              <label htmlFor="site">SIte</label>
              <input
                type="text"
                name="site"
                value={value?.site}

                id="site"
                onChange={(e) => setValue({ ...value, [e.target.name]: e.target.value })}
              />
              <label htmlFor="api">API Key</label>
              <input
                type="text"
                name="apiKey"
                id="apiKey"
                value={value?.apiKey}

                onChange={(e) => setValue({ ...value, [e.target.name]: e.target.value })}
                style={{ width: "310px" }}
              />
              <button type="button" onClick={() => { handlePaymentMethod("ChargeBee") }}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Setting;
