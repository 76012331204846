import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

function PageRender(props) {
    const[html, setHtml] = useState(null)
    const[css, setCss] = useState(null)

    const [searchParams, setSearchParams] = useSearchParams();
    console.log('nodedata', searchParams.get('__id'))
    useEffect(()=>{
        axios({
            method:"get",
            url:`http://localhost:5000/save-page/642baab2497ba64ab7f02ec1`,

        }).then(resp=>{
            console.log(resp.data?.html)
            setHtml(resp.data?.html)
            setCss(resp.data?.css)
        })
    },[])
  return (
    <div>
        {html&&<>
           <style dangerouslySetInnerHTML={{__html:css}} />
           <div dangerouslySetInnerHTML={{__html:html}} ></div>
        </>}
    </div>
  )
}

export default PageRender