import React from 'react';

import { ToolbarItem, ToolbarSection } from '../../Toolbar';
import ToolbarRadio from '../../Toolbar/ToolbarRadio';
import { MenuItem } from '@mui/material';
import {animateFunction} from "../buttons/ButtonSettings"

export const fonts = [
  { id: "'Oswald', sans-serif", name: "Oswald" },
  { id: "Helvetica Neue,Helvetica,Arial,sans-serif", name: "Helvetica" },
  { id: "sans-serif", name: "sans-serif" },
  { id: "Times New Roman", name: "Times New Roman" },
  { id: "Arial Black", name: "Arial Black" },
  { id: "'Avenir Book', Avenir", name: "Avenir Book" },
  { id: "'Avenir Medium', Avenir", name: "Avenir Medium" },
  { id: "'Avenir Roman', Avenir", name: "Avenir Roman" },
  { id: "'Montserrat', sans-serif", name: "Montserrat" },
  { id: "'Oswald', sans-serif", name: "Oswald" },
  { id: "'Shadows Into Light', cursive", name: "Shadows Into Light" },
  { id: "'Pacifico', cursive", name: "Pacifico" },
  { id: "Tahoma", name: "Tahoma" },
  { id: "Verdana, Geneva, sans-serif", name: "Verdana" },
  { id: "Courier New Courier, monospace", name: "Courier New Courier" },
  { id: "'Lato', sans-serif", name: "Lato" },
  { id: "'Open Sans', sans-serif", name: "Open Sans" },
  { id: "'Montserrat', sans-serif", name: "Montserrat" },
];
export const animations = [
  { id: "fade", name: "fade" },
  { id: "fade-up", name: "fade-up" },
  { id: "fade-down", name: "fade-down" },
  { id: "fade-left", name: "fade-left" },
  { id: "fade-right", name: "fade-right" },
  { id: "fade-up-right", name: "fade-up-right" },
  { id: "fade-up-left", name: "fade-up-left" },
  { id: "fade-down-right", name: "fade-down-right" },
  { id: "fade-down-left", name: "fade-down-left" },
  { id: "fade-zoom-in", name: "fade-zoom-in" },
  { id: "flip-up", name: "flip-up" },
  { id: "flip-down", name: "flip-down" },
  { id: "flip-left", name: "flip-left" },
  { id: "flip-right", name: "flip-right" },
  { id: "slide-up", name: "slide-up" },
  { id: "slide-down", name: "slide-down" },
  { id: "slide-left", name: "slide-left" },
  { id: "slide-right", name: "slide-right" },
  { id: "zoom-in", name: "zoom-in" },
  { id: "zoom-in-up", name: "zoom-in-up" },
  { id: "zoom-in-down", name: "zoom-in-down" },
  { id: "zoom-in-left", name: "zoom-in-left" },
  { id: "zoom-in-right", name: "zoom-in-right" },
  { id: "zoom-out", name: "zoom-out" },
  { id: "zoom-out-up", name: "zoom-out-up" },
  { id: "zoom-out-down", name: "zoom-out-down" },
  { id: "zoom-out-left", name: "zoom-out-left" },
  { id: "zoom-out-right", name: "zoom-out-right" },


];
export const TextSettings = () => {
  return (
    <React.Fragment>
      <ToolbarSection
        title="Add HTML"
        props={['html']}
        
      >
        <ToolbarItem propKey="html" type="text" label="HTML" />

      </ToolbarSection>
    
    </React.Fragment>
  );
};
