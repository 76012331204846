// components/user/Card.js
import React from "react";
import Text from "../Text";
import Button from "../Button";
import { Element, useNode } from "@craftjs/core";

import { Container } from "../DragableContainer";
import ImageTag from "../imageTag";
// import SButton from './buttons'
import Texts from "../texts";
import Buttons from "../buttons";
// Notice how CardTop and CardBottom do not specify the drag connector. This is because we won't be using these components as draggables; adding the drag handler would be pointless.

export const Testimonial = ({ background, padding }) => {
    const {
        connectors: { connect },
    } = useNode();
    return (
        <div ref={connect} canvas>
            <Element canvas id='container' background={{ r: 15, g: 11, b: 64, a: 1 }} is={Container} thirdShadow={20} flexDirection="column" width="100%" height="auto" radius={0} padding={[10, 10, 10, 10]}>
                <Element canvas background={{ r: 256, g: 256, b: 256, a: 0 }} id='container' alignItems='center' margin={[0, 0, 10, 0]} justifyContent='center' is={Container} flexDirection="column" width="100%" height="auto" radius={10} >
                    <Texts color={{ r: 213, g: 160, b: 15, a: 1 }} text="CUSTOMER TESTIMONIALS" margin={[30, 0, 10, 10]} fontSize={18} fontWeight="bold" />
                    <Texts color={{ r: 255, g: 255, b: 255, a: 1 }} text="What Others Are Saying About Us" margin={[0, 0, 10, 0]} fontSize={42} fontWeight="bold" />
                    <Texts color={{ r: 255, g: 255, b: 255, a: 1 }} text="Lorem ipsum dolor sit amet, consectetur adipisicing elit." margin={[0, 0, 10, 0]} fontSize={20} fontWeight="bold" />
                </Element>
                <Element canvas id='container' background={{ r: 256, g: 256, b: 256, a: 0 }} is={Container} flexDirection="row" width="100%" height="auto" radius={0} >

                    <Element background={{ r: 256, g: 256, b: 256, a: 0 }} canvas id='container' alignItems='center' is={Container} thirdShadow={15} padding={[30, 15, 10, 10]} flexDirection="column" width="33%" height="auto" radius={10} >
                        <Texts textAlign='center' color={{ r: 255, g: 255, b: 255, a: 1 }} text='"Insert hook to make them read the full testimonial"' margin={[10, 0, 10, 10]} fontSize={'26'} fontWeight="bold" />
                        <Texts textAlign='center' color={{ r: 255, g: 255, b: 255, a: 1 }} text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt." margin={[10, 10, 10, 10]} fontSize={18} fontWeight="bold" />
                        <Texts textAlign='center' color={{ r: 8, g: 60, b: 232, a: 1 }} margin={[25, 0, 25, 0]} text="-NAME" fontSize={16} />
                    </Element>
                    <Element background={{ r: 256, g: 256, b: 256, a: 0 }} canvas id='container' alignItems='center' is={Container} thirdShadow={15} padding={[30, 15, 10, 10]} flexDirection="column" width="33%" height="auto" radius={10} >
                        <Texts textAlign='center' color={{ r: 255, g: 255, b: 255, a: 1 }} text='"Insert hook to make them read the full testimonial"' margin={[10, 0, 10, 10]} fontSize={'26'} fontWeight="bold" />
                        <Texts textAlign='center' color={{ r: 255, g: 255, b: 255, a: 1 }} text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt." margin={[10, 10, 10, 10]} fontSize={18} fontWeight="bold" />
                        <Texts textAlign='center' color={{ r: 8, g: 60, b: 232, a: 1 }} margin={[25, 0, 25, 0]} text="-NAME" fontSize={16} />
                    </Element>
                    <Element background={{ r: 256, g: 256, b: 256, a: 0 }} canvas id='container' alignItems='center' is={Container} thirdShadow={15} padding={[30, 15, 10, 10]} flexDirection="column" width="33%" height="auto" radius={10} >
                        <Texts textAlign='center' color={{ r: 255, g: 255, b: 255, a: 1 }} text='"Insert hook to make them read the full testimonial"' margin={[10, 0, 10, 10]} fontSize={'26'} fontWeight="bold" />
                        <Texts textAlign='center' color={{ r: 255, g: 255, b: 255, a: 1 }} text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt." margin={[10, 10, 10, 10]} fontSize={18} fontWeight="bold" />
                        <Texts textAlign='center' color={{ r: 8, g: 60, b: 232, a: 1 }} margin={[25, 0, 25, 0]} text="-NAME" fontSize={16} />
                    </Element>
                </Element>



            </Element>
        </div>
    );
};
Testimonial.craft = {
    displayName: 'Testimonial',
    rules: {
        canDrag: () => true,
    },
};
export default Testimonial;
