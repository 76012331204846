import React, { useEffect } from "react";
import 'aos/dist/aos.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WarRoom from "./Pages/WarRoom";
import NewWarRoom from "./Pages/NewWarRoom/NewWarRoom";
import PageBuilder from "./PageBuilder/CraftPage/PageBuilder";
import AfterLogin from "./Pages/AfterLogin/AfterLogin";
import DashBoard from "./Pages/DashBoard/DashBoard";
import Login from "./Components/auth/Login";
import Nav from "./Components/Header/Nav";
import Funnels from "./Pages/Funnels/Funnels";
import Products from "./Pages/Products/Products";
import EditProduct from "./Pages/Products/EditProduct";
import { Flip, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css"
import PagesDrwer from "./Pages/NewWarRoom/PagesDrwer";
import PageRender from "./PageBuilder/Components/PageRender";
import MainPage from "./PageBuilder/Pages/MainPage";
import AOS from "aos"
import Preview from "./PageBuilder/CraftPage/Preview"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import usePageBuilder from "./Context/pageBuilder";
import Contact from "./Pages/Contact/Contact";
import TaxManagement from "./Pages/TaxManagement/TaxManagement";
import KPI from "./Pages/KPI/KPI";
import AddContact from "./Pages/Contact/AddContact";
import AddTax from "./Pages/TaxManagement/AddTax";
import EditTax from "./Pages/TaxManagement/EditTax";
import { Provider } from "react-redux";
import store from "./redux/store";
import EditContact from "./Pages/Contact/EditContact";
import Setting from "./Pages/Setting/Setting";
import Comparison from "./Pages/NewWarRoom/Comparison";

function App() {
  const { animationType } = usePageBuilder()
  useEffect(() => {
    const delay = setTimeout(() => {
      AOS.init({
        offset: 50,
        delay: 10,
        duration: 500

      });

      return () => clearTimeout(delay)
    }, 1000)
  }, [animationType])

  useEffect(() => {
    const host = window.location.host

    const arr = window.location.protocol + "//" + "pravin" +"." + window.location.host

    
    console.log("funenl name", arr)
},[])
  return (

    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Flip}

      ></ToastContainer>
      <Provider store={store}>

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/user" element={<AfterLogin />}>
            {/* <Route index element={<NewWarRoom />} /> */}
            <Route path="dashboard" element={<DashBoard />} />
            <Route path="funnels" element={<Funnels />} />
            <Route path="page-builder" element={<MainPage />} />
            <Route path="war-room" element={<WarRoom />} />
            <Route path="products" element={<Products />} />
            <Route path="kpi" element={<KPI />} />
            <Route path="contact" element={<Contact />} />
            <Route path="tax-management" element={<TaxManagement />} />
            <Route path="setting" element={<Setting />} />
            <Route path="tax-management/add-tax" element={<AddTax />} />
            <Route path="tax-management/edit-tax" element={<EditTax />} />
            <Route path="products/edit" element={<EditProduct />} />
            <Route path="contact/add-contact" element={<AddContact />} />
            <Route path="contact/edit-contact" element={<EditContact />} />


        </Route>
          <Route path="/*" element={<Preview  page_id ="2a9722b4-a324-4037-876f-c2d8abad75e3"/>} />

        <Route path="/comparison" element={<Comparison />} />

          <Route path="/war-room" element={<NewWarRoom />} />
          <Route path="/new-page" element={<PageBuilder />} />
          <Route path="/page-render" element={<PageRender />} />
        </Routes>
      </Provider>
    </div>

  );
}

export default App;
