import request from "./request";

// auth

//add page 
export const addPage = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("page-builder/create", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//update page 
export const updatePage = (data,id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("page-builder/update/"+id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//get page by id
export const getPageById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("page-builder/getPage/"+id)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//get page list
export const getPages = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("page-builder/pageList")
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getProducts = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("products/productList")
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getProduct = (Id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`products/getProduct/${Id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateProd = (Id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`products/update/${Id}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAssets = () => {
  return new Promise(async (resolve, reject) => {
    await request

      .get(`/asset/assets/Gallery?skip=0&limit=10`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// categories list
export const getPageCategories = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`page-builder/pageCategories`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


// tax
export const getTax = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("tax/taxList?skip=0&limit=100")
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getCountryList= () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`tax/countryList`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTaxId = (Id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`tax/getTax/${Id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addTax = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("tax/create", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateTax = (data,id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("tax/update/"+id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Contact
export const getContact = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("contacts/contactList",{params:data})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getContactId = (Id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`contacts/getContact/${Id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addContact = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("contacts/create", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateContact = (data,id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("contacts/update/"+id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// dashboard setting
export const settings = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("dashboard/settings", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getSettings= () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`dashboard/getsettings`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// get all urls
export const getUrls = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`page-builder/getPageUrls`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addPaymentMethod = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`payment-method/create`, body)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getTags = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("contacts/getTags")
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getCredential = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("payment-method/getPaymentCredential")
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getPublishFunnle = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("funnel/getFilteredFunnels",data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getKPIFunnle = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("dashboard/getFunnelKpis")
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getFunnlePerf = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("dashboard/getFunnelPerf/"+ data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getPageViews = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("dashboard/getPageViews/"+ data) 
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};