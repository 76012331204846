import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import useProvider from "../../Context/Context";
import { MenuItem, Select } from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import ReactApexChart from "react-apexcharts";
import { useLocation, useNavigate } from "react-router-dom";
import CreateFunne from "../NewWarRoom/models/CreateFunne";
import { getKPIFunnle } from "../../Services/api";
function DashBoard() {
  const { authToken, setAuthToken } = useProvider();
  const [select, setSelect] = useState("Last 30 days");
  const navigate = useNavigate()
  const [graph, setGraph] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [24, 25, 26, 27, 28, 29, 30, 31],
      },
    },
    series: [
      {
        name: "Last 30 days",
        data: [450, 940, 1545, 1500, 1490, 1600, 1700, 1910],
      },
      {
        name: "Previous span",
        data: [550, 840, 1645, 1400, 1490, 1650, 1600, 1900],
      },
    ],
  });
  const token = useLocation().search.split('=')?.[1]
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      console.log("/user/dashboard")
      localStorage.setItem('token', token)
    }

    navigate("/user/dashboard")

    document.title = "Dashboard-Madfunnels"
  }, [])
  const [data, setData] = useState([])
  console.log('data: ', data);
  const [funnelId, setFunnelId] = useState("1")
  console.log('funnelId: ', funnelId);
  const [selectedFunnels, setSelectedFunnels] = useState()
  const getData = () => {
    getKPIFunnle().then((res) => {
      console.log('res: ', res);
      if (res?.data?.data) {
        setData(res?.data?.data)
        setSelectedFunnels(res?.data?.data[0])
        setFunnelId(res?.data?.data[0]?._id)
      }
    })
  }
  useEffect(() => {
    getData()
  }, [''])
  return (
    <div className={styles.Funnels}>


      <h1 className={styles.FunnelsHead}>Overview</h1>
      <div className={styles.HeadContainer}>
        <div className={styles.FunnelsSearch}>
          <Select
            style={{
              backgroundColor: "#fff",
              fontSize: "13px",
              width: "100%",
            }}
            sx={{
              "& fieldset": { border: "none", fontSize: "13px" },
              height: "24px",
            }}
            labelId="demo-select-large"
            id="demo-select-large"
            value={funnelId}
            onChange={(e) => {

              console.log('e.target.value: ', e.target.value);
              setFunnelId(e.target.value)
              setSelectedFunnels(data?.filter((obj) => obj?._id === e.target.value)[0])
            }
            }
          >
            <MenuItem style={{ fontSize: "13px" }} value="1" disabled>
              Select Funnel
            </MenuItem>
            {data && data?.length > 0 && data?.map((obj => {
              return <MenuItem style={{ fontSize: "13px" }} value={obj?._id}>
                {obj?.funnel}
              </MenuItem>

            }))}

          </Select>
        </div>
        <div className={styles.FunnelsSearchD}>
          <p>Data in</p>
          <div className={styles.FunnelsSearchDrop}>
            <div className={styles.inputField}>
              <Select
                style={{
                  backgroundColor: "#fff",
                  fontSize: "13px",
                  width: "100%",
                }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "13px" },
                  height: "24px",
                }}
                labelId="demo-select-large"
                id="demo-select-large"
                value={select}
                onChange={(e) => setSelect(e.target.value)}
              >
                <MenuItem style={{ fontSize: "13px" }} value="Last 7 days">
                  Last 7 days
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Last 15 days">
                  Last 15 days
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Last 30 days">
                  Last 30 days
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>

        <div className={styles.FunnelsSearchD1}>
          <p>Comparing to</p>
          <div className={styles.FunnelsSearchDrop}>
            <div className={styles.inputField}>
              <Select
                style={{
                  backgroundColor: "#fff",
                  fontSize: "13px",
                  width: "100%",
                  color: "#000",
                }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "13px" },
                  height: "24px",
                }}
                labelId="demo-select-large"
                id="demo-select-large"
                value={select}
                label="Last 30 days"
                onChange={(e) => setSelect(e.target.value)}
              >
                <MenuItem style={{ fontSize: "13px" }} value="Last 7 days">
                  Last 7 days
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Last 15 days">
                  Last 15 days
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Last 30 days">
                  Last 30 days
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.box}>
        <div className={styles.content}>
          <div className={styles.one}>
            <p>Views</p>
            <p></p>
          </div>
          <div className={styles.two}>
            <p>{selectedFunnels?.views}</p>
            <p>{selectedFunnels?.views}</p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.one}>
            <p>Opt-ins</p>
            <p></p>
          </div>
          <div className={styles.two}>
            <p>0</p>
            <p>0</p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.one}>
            <p>Orders</p>
            <p></p>
          </div>
          <div className={styles.two}>
            <p>${selectedFunnels?.sales?.$numberDecimal}</p>
            <p>{selectedFunnels?.order}</p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.one}>
            <p>CVR</p>
            <p></p>
          </div>
          <div className={styles.two}>
            <p>{(selectedFunnels?.order * 100 / selectedFunnels?.views)?.toFixed(2)}%</p>
            <p></p>
          </div>
        </div>
      </div>
      <div className={styles.box2}>
        <div className={styles.graph}>
          <div className={styles.heading}>
            <p> All funnels & sites</p>
            <div className={styles.FunnelsSearchDrop1}>
              <div className={styles.inputField}>
                <Select
                  style={{
                    backgroundColor: "#1b2948",
                    fontSize: "13px",
                    width: "220px",
                    color: "#fff",
                    borderRadius: "10px"
                  }}
                  sx={{
                    "& fieldset": { border: "none", fontSize: "13px" },
                    height: "38px",
                  }}
                  labelId="demo-select-large"
                  id="demo-select-large"
                  label="Age"
                  value={select}
                  onChange={(e) => setSelect(e.target.value)}
                >
                  <MenuItem style={{ fontSize: "13px" }} value="Last 7 days">
                    Last 7 days
                  </MenuItem>
                  <MenuItem style={{ fontSize: "13px" }} value="Last 15 days">
                    Last 15 days
                  </MenuItem>
                  <MenuItem style={{ fontSize: "13px" }} value="Last 30 days">
                    Last 30 days
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div className={styles.line}>
            <ReactApexChart
              options={graph.options}
              series={graph.series}
              type="line"
              width="759"
              height="207"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;
