import React, { useEffect, useState } from 'react'
import styles from "./createFunne.module.css"
import useProvider from '../../../Context/Context'
import { RxCross2 } from "react-icons/rx"
import { Autocomplete, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'

function CreateFunne() {
const[funnelName, setFunneName] = useState("")
    const {
        createFunne, setCreateFunnel,
        saveFunnel
    } = useProvider();
    return (
        <div
            onClick={() => setCreateFunnel(false)}
            className={`${styles.summaryModel} ${createFunne ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Create Funnel</p>
                    < RxCross2 onClick={() => { setCreateFunnel(false); console.log('hello') }} />
                </div>
                <div className={styles.inputBody}>

                    <div className={styles.inputField}>
                        <p>Funnel Name</p>
                        <input type="text" placeholder='Funnel name' onChange={(e)=>setFunneName(e.target.value)} />
                    </div>

                    <div className={styles.closeButtons}>
                        <button className='closebuttn' onClick={() => setCreateFunnel(false)}  >Close</button>
                        <button className='addbutton' onClick={() => { saveFunnel("", funnelName, 0) }} >Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateFunne