import { useNode, useEditor, Element } from '@craftjs/core';
import React from 'react';
import ContentEditable from 'react-contenteditable';
import Text from '../../texts';
import { RadioLabel } from '../radioLabel'
import { RadioSettings } from './RadioSettings';
import { FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import Container from '../../Container';

// export const RadioLabel = (props) => {
//     const { label, textComponent, color, ...otherProps } = props;

//     const {
//         connectors: { connect },
//         setProp,
//     } = useNode();
//     return <FormControlLabel ref={connect} value="female" control={<Radio />} label={
//         <Text {...textComponent} text={label} color={props.color} />
//     } />
// }


export const Radios = ({
    fontSize,
    textAlign,
    fontWeight,
    color,
    shadow,
    text,
    margin,
    fontFamily,
    value,
    label,name,
    labelPlacement
}) => {
    const {
        connectors: { connect },
        setProp,
    } = useNode();
    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
   
    return (
        <>
            {/* <ContentEditable
            innerRef={connect}
            html={text} // innerHTML of the editable div
            disabled={!enabled}
            onChange={(e) => {
                setProp((prop) => (prop.text = e.target.value), 500);
            }} // use true to disable editing
            tagName="p" // Use a custom HTML tag (uses a div by default)
            style={{
                width: "100%",
                margin:
                    margin &&
                    margin?.length > 0 &&
                    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                color: color && `rgba(${Object.values(color)})`,
                fontSize: fontSize && `${fontSize}px`,
                textShadow: shadow && `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
                fontWeight,
                textAlign,
                fontFamily: fontFamily && fontFamily
            }}
        /> */}
            <RadioGroup
                ref={connect}
                canvas
                aria-labelledby="demo-controlled-radio-buttons-group"
                name={name}
                value={value}
                labelPlacement={labelPlacement ? labelPlacement : "right"}
                height="100"
                onChange={(e) => {
                    setProp((prop) => (prop.value = e.target.value), 500);
                }} 
            >
                <Element id="text" is={Container} canvas>
                    <RadioLabel labelPlacement={'end'}text={label} />
                </Element>
            </RadioGroup>
        </>
    );
};

Radios.craft = {
    displayName: 'Radio',
    props: {
      labelPlacement:'right',
        name: 'radioName'
    },
    related: {
        toolbar: RadioSettings,
    },
};
export default Radios