import React, { useEffect } from 'react';

import { ContainerSettings } from './ContainerSettings';

import { Resizer } from './Resizer';
import { Grid } from '@mui/material';
import usePageBuilder from '../../../../Context/pageBuilder';
import zIndex from '@mui/material/styles/zIndex';
import { width } from '@mui/system';
import { useNode } from '@craftjs/core';

// export type ContainerProps = {
//   background: Record<'r' | 'g' | 'b' | 'a', number>;
//   color: Record<'r' | 'g' | 'b' | 'a', number>;
//   flexDirection: string;
//   alignItems: string;
//   justifyContent: string;
//   fillSpace: string;
//   width: string;
//   height: string;
//   padding: string[];
//   margin: string[];
//   marginTop: number;
//   marginLeft: number;
//   marginBottom: number;
//   marginRight: number;
//   shadow: number;
//   children: React.ReactNode;
//   radius: number;
// };

const defaultProps = {
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  padding: ['0', '0', '0', '0'],
  margin: ['0', '0', '0', '0'],
  background: { r: 256, g: 256, b: 256, a: 1 },
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  firstShadow: 0,
  secondShadow: 0,
  thirdShadow: 0,
  radius: 0,
  width: '100%',
  height: 'auto'
};

export const Container = (props) => {

  const { bgChoosen, setBgChoosen, mobileView, setAnimationType } = usePageBuilder()
  props = {
    ...defaultProps,
    ...props,
  };
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    radius,
    children,
    gap,
    firstShadow,
    secondShadow,
    thirdShadow,
    bgurl,
    visibility,
    Flexproperty,
    position,
    zIndex,
    border,
    width,
    animationType, TimingFunction, animationDuration, animationDelay, animateOnce,
    bgRepeat, bgattachment
  } = props;
  console.log('props: ', mobileView, props);
  useEffect(() => {
    setAnimationType(animationType)
  }, [animationType, TimingFunction, animationDuration, animationDelay, animateOnce])
  const {
    connectors: { connect },
  } = useNode();
  return (
    <Resizer
      ref={connect}
      propKey={{ width: "width", height: "height" }}
      data-aos={`${animationType?.toString()}`}
      data-aos-offset="10"
      data-aos-delay={`${animationDelay ? animationDelay * 10 : 0}`}
      data-aos-easing={`${TimingFunction ? TimingFunction?.toString() : 'linear'}`}
      data-aos-duration={`${animationDuration ? animationDuration * 10 : 0}`}
      data-aos-once={`${animateOnce === "yes" ? true : false}`}
      style={{
        justifyContent,
        flexDirection,
        alignItems,
        background: background && `rgba(${Object.values(background)})`,
        color: color && `rgba(${Object.values(color)})`,
        padding:
          padding &&
          padding?.length >
          0 && `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
        margin:
          margin &&
          margin?.length >
          0 && `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
        boxShadow:
          shadow && shadow === 0
            ? "none"
            : `${firstShadow}px ${secondShadow}px ${thirdShadow}px ${shadow}px rgba(0, 0, 0, .4)`,
        borderRadius: radius && `${radius}px`,
        flex: fillSpace === "yes" ? 1 : "unset",
        gap: gap && `${gap}px`,
        flexWrap: Flexproperty && `${Flexproperty}`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, ${visibility * 0.01 || 0}), rgba(0, 0, 0, ${visibility * 0.01 || 0})), url(${bgurl})`,
        backgroundSize: '100% 100%',
        position: position ? `${position}` : "relative",
        zIndex: `${zIndex}`,
        border: border && border,
        backgroundAttachment: bgattachment && bgattachment,
        backgroundRepeat: bgRepeat && bgRepeat,
        backgroundPosition: "center"





      }}

      id="DragabbleContainer"
    >


      {children}

    </Resizer>
  );
};

Container.craft = {
  displayName: 'Container',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};
export default Container;
