import React from 'react';

import { ToolbarItem, ToolbarSection } from "../../Toolbar";
import ToolbarRadio from "../../Toolbar/ToolbarRadio";
import { animateFunction, animations } from "../buttons/ButtonSettings"
import { Button, MenuItem } from '@mui/material';
import usePageBuilder from '../../../../Context/pageBuilder';

export const ImageSeting = () => {

  const {showChangeImageodel, setShowChangeModel} = usePageBuilder()
  return (
    <React.Fragment>
      <ToolbarSection
        title="Dimensions"
        props={['width', 'height']}
        summary={({ width, height }) => {
          return `${width || 0} x ${height || 0}`;
        }}
      >
        <ToolbarItem propKey="width" type="text" label="Width" />
        <ToolbarItem propKey="height" type="text" label="Height" />
      </ToolbarSection>
      <ToolbarSection
        title="Animation"
        props={['buttonType', 'clickType', 'link']}
      >
        <ToolbarItem propKey="animationType" type="select" label="Animation Type" >
          {animations && animations.length > 0 && animations.map((obj) => {
            return <MenuItem style={{ zIndex: 180000 }} value={obj.id}>{obj.name}</MenuItem>
          })}
        </ToolbarItem>
        <ToolbarItem propKey="TimingFunction" type="select" label="Timing function" >
          {animateFunction && animateFunction.length > 0 && animateFunction.map((obj) => {
            return <MenuItem className='menu-items' style={{ zIndex: 180000 }} value={obj.id}>{obj.name}</MenuItem>
          })}
        </ToolbarItem>
        <ToolbarItem
          full={true}
          propKey="animationDuration"
          type="slider"
          label="Duration"
        />
        <ToolbarItem
          full={true}
          propKey="animationDelay"
          type="slider"
          label="Delay"
        />
        <ToolbarItem propKey="animateOnce" type="radio" label="Only animate once">
          <ToolbarRadio value="yes" label="Yes" />
          <ToolbarRadio value="no" label="No" />
        </ToolbarItem>
      </ToolbarSection>

      <ToolbarSection title="Decoration" props={['radius']}>
        <ToolbarItem
          full={true}
          propKey="radius"
          type="slider"
          label="Radius"
        />

      </ToolbarSection>

     
      <ToolbarSection
       title="Change Image"
        props={['width', 'height']}
        summary={({ bgurl }) => {
          return `${bgurl || ""}`;
        }}
      >

        <ToolbarItem propKey="Imgsrc" type="text" label="Change image" />
        <Button onClick={() => setShowChangeModel(!showChangeImageodel)} variant='contained' color='primary'  style={{textTransform:"capitalize"}} >Add new image</Button>
      </ToolbarSection>
    </React.Fragment>
  );
};
export default ImageSeting;