import React, { useState, useEffect } from 'react';
import styles from "./ChatGpt.module.css"
import { toast } from 'react-toastify';
import { IoMdCopy } from 'react-icons/io';
const TypingEffect = ({ text, i, allMessage }) => {
  const [displayedText, setDisplayedText] = useState('');
  const typingDelay = 50; // Adjust the typing speed here
  function copyTextToClipboard(text) {

    navigator.clipboard.writeText(text);
    toast.success("Copied")


  }

  useEffect(() => {
    let currentIndex = 0;
    let timer;

    const typeNextCharacter = () => {
      if (currentIndex < text.length - 1) {
        setDisplayedText(prevText => prevText + text[currentIndex]);
        currentIndex++;
        timer = setTimeout(typeNextCharacter, typingDelay);
      }
    };

    typeNextCharacter();

    return () => {
      clearTimeout(timer);
    };
  }, [text]);
  console.log("displayedText", displayedText)
  return <span className={styles.gptResponce} style={{ position: "relative", backgroundColor: "white", borderRadius: "5px", color: "black", textAlign: "justify", padding: "25px", width: "500px" }}>
    {i !== 0 && <IoMdCopy onClick={() => copyTextToClipboard(displayedText)} className={styles.copy} style={{ position: "absolute", right: "0", top: "2px" }} />
    }
    {displayedText}</span>;
};

export default TypingEffect;
