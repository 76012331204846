import React, { useEffect, useRef, useState } from 'react'
import styles from "./AddNew.module.css"
import usePageBuilder from '../../../Context/pageBuilder'
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material'
import { RxButton, RxCross1 } from 'react-icons/rx'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FiDelete } from 'react-icons/fi'
import { BsFillMenuButtonFill, BsPaypal, BsSendFill } from 'react-icons/bs'
import { GiFastBackwardButton, GiFastForwardButton, GiNextButton, GiPauseButton, GiPlayButton, GiPowerButton, GiPreviousButton } from 'react-icons/gi'
import { common } from "../../../BaseUrl"
import { CgPlayButton, CgPlayButtonO, CgPlayButtonR } from 'react-icons/cg'
import { MdContentCopy } from 'react-icons/md'
import { Element, useEditor } from '@craftjs/core'
import Text from '../Components/Text'
import MButton from '../Components/Button'
import SButton, { Buttons } from "../Components/buttons/index";
import ImageTag from '../Components/imageTag'
import Container from '../Components/Container'
import GridContainer from '../Components/Grid'
import Texts from '../Components/texts'
import Video from '../Components/Video'
import Inputs from '../Components/forms/input'
import Selects from '../Components/forms/select'
import Radios from '../Components/forms/radio'
import { RadioLabel } from '../Components/forms/radioLabel'
import FormContainer from '../Components/forms/form'
import FormContainer2 from '../Components/forms/form2'
import axios from 'axios'
import { toast } from 'react-toastify'
import { IoMdArrowDropdown } from 'react-icons/io'
import Card from '../Components/cards'
import Product from '../Components/product'
import Modal from '../Components/modal'
import PaypalButton from "../Components/PaypalButton"
import ResponsiveAppBar from '../Components/Navbars1'
import ResponsiveAppBar1 from '../Components/Navbars2'
import Slider from "../../CraftPage/Components/sliderBox/ShowSlider"
import SliderDrop from "../../CraftPage/Components/sliderBox"
import Testimonial from '../Components/Testimonials'
import Testimonial2 from '../Components/Testimonials/Testimonial2'
import { Icons } from '../Icons/Icons'
import zIndex from '@mui/material/styles/zIndex'
import { getPages, getProducts } from '../../../Services/api'
import { useDispatch, useSelector } from 'react-redux'
import { resetProdProps, setProductProps } from '../../../redux/action/pageBuilderAction'
import form1 from "../../../assets/form1.jpg"
import form2 from "../../../assets/form2.jpg"
import store from '../../../redux/store'
const jsonElement = {
  type: 'div',
  props: {
    className: 'my-class',
    children: [
      {
        type: 'h1',
        props: {
          className: 'title',
          children: 'Hello, World!'
        }
      },
      {
        type: 'p',
        props: {
          className: 'description',
          children: 'This is a description.'
        }
      }
    ]
  }
};


const Headers = [
  {
    text: "Add Header 1",
    fontWeight: 800,
    fontSize: 25,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  {
    text: "Add Header 2",
    fontWeight: 700,
    fontSize: 22,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  {
    text: "Add Header 3",
    fontWeight: 600,
    fontSize: 20,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  {
    text: "Add Header 4",
    fontWeight: 500,
    fontSize: 18,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  {
    text: "Add Header 5",
    fontWeight: 400,
    fontSize: 16,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
]
const Titlles = [
  {
    text: "Big Title",
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  {
    text: "CAPS TITLE",
    fontWeight: 500,
    fontSize: 20,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  {
    text: "Small Title",
    fontWeight: 600,
    fontSize: 16,
    fontFamily: 'Verdana, Geneva, Tahoma, sans-serif'
  },
  {
    text: "Elegant Title",
    fontWeight: 500,
    fontSize: 18,
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontStyle: 'italic'
  },
  {
    text: "Business Title",
    fontWeight: 400,
    fontSize: 20,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
]
const paragraph = [
  {
    text: "Helvetica Light is an easy-to-read font, with tall and narrow letters, that works well on almost every site.",
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  {
    text: "Helvetica Light is an easy-to-read font, with tall and narrow letters, that works well on almost every site.",
    fontWeight: 'normal',
    fontSize: 16,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  {
    text: "Helvetica Light is an easy-to-read font, with tall and narrow letters, that works well on almost every site.",
    fontWeight: 400,
    fontSize: 16,
    fontFamily: 'Verdana, Geneva, Tahoma, sans-serif'
  },
  {
    text: "Helvetica Light is an easy-to-read font, with tall and narrow letters, that works well on almost every site.",
    fontWeight: 'normal',
    fontSize: 15,
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontStyle: 'italic'
  },
  {
    text: "Business Title",
    fontWeight: 400,
    fontSize: 20,
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
]

function AddProdDrawer() {
  const {
    connectors, connectors: { create },
    query,
  } = useEditor();
  const buttonTo = useRef()
  const buttons = useRef()
  const headTo = useRef()
  const titleTo = useRef()
  const videoSection = useRef()
  const themedButton = useRef()
  const themeForm = useRef()
  const textIconButtons = useRef()
  const ParaTo = useRef()
  const fromMadFunnel = useRef()
  const IconButtons = useRef()
  const uploadimg = useRef()
  const FromGallery = useRef()
  const { showProdDrawer, setShowProdDrawer, prodDetails, setProddetails } = usePageBuilder()
  const [bgColor, setBgColor] = useState('#ccc')
  const [isActive, setIsActive] = useState('Header')
  const [isActiveTag, setIsActiveTag] = useState('text')
  const [images, setImages] = useState([])
  const [page, setPage] = useState(1)
  const [pageGal, setPageGal] = useState(0)
  const [pageTwo, setPageTwo] = useState(1)
  const [videoPage, setVideoPage] = useState(1)
  const [pixelImages, setPixelImages] = useState([])
  const [galleryImages, setGalleryImages] = useState([])
  const [pixelVideos, setPixelVideos] = useState([])
  const [chooosenTag, setChooosenTag] = useState('text')
  const [imageSearched, setImageSearched] = useState('')
  const [VideoSearched, setVideoSearched] = useState('')
  const [allProducts, setAllProducts] = useState([])
  const [pages, setpages] = useState([])
  const [selectedPrice, setSelectedPrice] = useState([])
  const { isPaypal, isStripe, isChargebee } = useSelector((state) => state.pageBuilder)

  console.log("pageBUilder", isPaypal, isStripe, isChargebee)
  const dispatch = useDispatch()
  // useEffect(() => {


  //   const paypal = document.getElementById('paypal-button-container')


  //   window.paypal?.Buttons({
  //     createSubscription: function (data, actions) {
  //       return actions.subscription.create({
  //         'plan_id': 'P-34804200KC570304AMR2I4TQ' // Creates the subscription
  //       });
  //     },
  //     onApprove: async function (data, actions) {
  //       console.log(data);
  //       let bodaydata = {
  //         "orderID": data.orderID,
  //         "subscriptionID": data.subscriptionID,
  //         "facilitatorAccessToken": data.facilitatorAccessToken,
  //         "paymentSource": data.paymentSource,
  //         "planId": 'P-34804200KC570304AMR2I4TQ'
  //       };
  //       const response = await fetch('http://localhost:3300/products/paypalPayment', {
  //         method: 'POST',
  //         body: JSON.stringify(bodaydata), // string or object
  //         headers: {
  //           'Content-Type': 'application/json'
  //         }
  //       });
  //       const myJson = await response.json();
  //       alert('You have successfully subscribed to ' + data.subscriptionID); // Optional message given to subscriber
  //     }
  //   }).render('#paypal-button-container');
  // }, [])


  const collectionID = 928423;
  const randomNumber = 20;
  useEffect(() => {


    genrateVideoFromPixels()
  }, [videoPage])
  useEffect(() => {
    genrateImage(randomNumber)
  }, [pageTwo])
  // useEffect(() => {
  //   if (bottom) {
  //     bottom.current.addEventListener('DOMNodeInserted', event => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
  //     });
  //   }
  // }, [])
  useEffect(() => {
    genrateImageFromPixels()
  }, [page])
  const handleSearchImage = (e) => {
    if (e.key === 'Enter') {
      genrateImageFromPixels('Searched')
    }
  }
  const genrateImage = (randomNumber) => {
    axios({
      method: "get",
      url: `https://api.unsplash.com/photos/?client_id=Pt2HN57Z_3_gBC2BboFtyUZTplMC5P4M3DkVl_peoZk&page=${pageTwo}&per_page=3`,
      headers: {
        Authorization: 'Pt2HN57Z_3_gBC2BboFtyUZTplMC5P4M3DkVl_peoZk'
      }
    })
      .then((response) => {
        // console.log("response", response)
        setImages((prevImages) => [...prevImages, ...response.data])
      }).catch(err => {

      })
  }


  const loadVideo = (e) => {
    if (e.key === 'Enter') {
      genrateVideoFromPixels('Searched')
    }
  }
  const genrateImageFromPixels = (Searched) => {
    axios({
      method: "get",
      url: `https://api.pexels.com/v1/search?query=${imageSearched?.length > 0 ? imageSearched : "nature"}&per_page=3&page=${page}`,
      headers: {
        Authorization: 'jAFhdrlE1mfAWpNK5OGVlFe8Dy2tAyJuvJKccdU74Hz3HJIDlyEjI43a'
      }
    })
      .then((response) => {
        if (Searched == 'Searched') {
          setPixelImages(response.data?.photos)
          return
        }
        console.log("response", response)
        // setImages((prevImages) => [...prevImages, ...data.images]);
        setPixelImages((prevImages) => [...prevImages, ...response.data?.photos])
      }).catch(err => {

      })
  }
  const genrateVideoFromPixels = (Searched) => {
    axios({
      method: "get",
      url: `https://api.pexels.com/videos/search?query=${VideoSearched ? VideoSearched : 'nature'}&per_page=3&page=${videoPage}`,
      headers: {
        Authorization: 'jAFhdrlE1mfAWpNK5OGVlFe8Dy2tAyJuvJKccdU74Hz3HJIDlyEjI43a'
      }
    })
      .then((response) => {
        console.log("response", response)
        if (Searched == 'Searched') {
          console.log('Searched')
          setPixelVideos(response.data?.videos)
          return
        }
        setPixelVideos((prevImages) => [...prevImages, ...response.data?.videos])
      }).catch(err => {

      })
  }

  useEffect(() => {
    getAssest()
  }, [pageGal])
  const getAssest = () => {
    axios({
      method: "get",
      url: `${common}/asset/assets/Gallery?skip=${pageGal}&limit=1`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {
      console.log("responceimages", res.data)
      setGalleryImages((prevImages) => [...prevImages, ...res.data?.data?.result])
    })
  }


  const copyImage = (url) => {
    navigator.clipboard.writeText(url);
    toast.success("Copied")
  }



  useEffect(() => {
    getProducts().then(res => {
      console.log("all products", res.data)
      setAllProducts(res.data?.data)
    })

    getPages().then(resp => {
      setpages(resp.data?.data)
    })
  }, [])

  useEffect(() => {
    console.log('productName: ', store.getState().pageBuilder);
  }, [store.getState().pageBuilder])



  const renderElement = (element) => {
    const { type, props } = element;

    // Render the element recursively
    const children = Array.isArray(props.children)
      ? props.children.map(renderElement)
      : props.children;

    return React.createElement(type, { ...props, children });
  };



  return (
    <div
      className={`${styles.addProdDrawer} ${showProdDrawer ? styles.showAddDrawer : ""
        }`}
    >
      <div className={styles.heading}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mt={-5}

        >
          <p style={{ position: "absolute", left: "10px", top: "5px" }} className={styles.menuText}>Add parts</p>

          <Tooltip
            style={{ position: "absolute", right: "10px", top: "-9px" }}
            onClick={() => setShowProdDrawer(false)}
            title={
              <React.Fragment>
                <Typography fontSize={10} color="inherit">
                  Close
                </Typography>
              </React.Fragment>
            }
            arrow
            placement="top"
          >
            <IconButton>
              <RxCross1 size={25} color="black" />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
      <div className={styles.addElementGrid}>
        <div style={{ width: "280px" }} className={styles.addElementBoxOne}>
          <Box
            className={styles.box}
            bgcolor={isActiveTag === "text" ? bgColor : "#FFFFFF"}
          >
            <p
              style={{ width: "100%" }}
              onMouseMove={() => {
                setChooosenTag("text");
                setIsActiveTag("text");
              }}
              onDragStart={() => setShowProdDrawer(false)}
            >
              Text
            </p>
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>

          <Box
            bgcolor={isActiveTag === "picture" ? bgColor : "#FFFFFF"}

            className={styles.box}

          >
            <p
              style={{ width: "100%" }}
              onMouseMove={() => {
                setChooosenTag("picture");
                setIsActiveTag("picture");
              }}
            >
              {" "}
              Pictures
            </p>
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>

          <Box

            bgcolor={isActiveTag === "buttons" ? bgColor : "#FFFFFF"}
            className={styles.box}

          >
            <p
              style={{ width: "100%" }}
              onMouseMove={() => {
                setChooosenTag("buttons");
                setIsActiveTag("buttons");
              }}
            >
              Buttons
            </p>
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>
          <Box

            bgcolor={isActiveTag === "form" ? bgColor : "#FFFFFF"}
            className={styles.box}

          >
            <p
              style={{ width: "100%" }}
              onMouseMove={() => {
                setChooosenTag("form");
                setIsActiveTag("form");
              }}
            >
              Form
            </p>
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>
          <Box
            bgcolor={isActiveTag === "section" ? bgColor : "#FFFFFF"}
            className={styles.box}

          >
            <p
              style={{ width: "100%" }}
              onMouseMove={() => {
                setChooosenTag("section");
                setIsActiveTag("section");
              }}
            >
              Section
            </p>
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>
          <Box
            bgcolor={isActiveTag === "Gallery" ? bgColor : "#FFFFFF"}

            className={styles.box}

          >
            <p style={{ width: "100%" }}
              onMouseMove={() => {
                setChooosenTag("Gallery");
                setIsActiveTag("Gallery");
              }}>  Menu & Anchor</p>
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>

          <Box
            bgcolor={isActiveTag === "testimonial" ? bgColor : "#FFFFFF"}

            className={styles.box}

          >
            <p style={{ width: "100%" }}
              onMouseMove={() => {
                setChooosenTag("testimonial");
                setIsActiveTag("testimonial");
              }}>  Testimonial</p>
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>

          <Box
            className={styles.box}
            bgcolor={isActiveTag === "iconsShape" ? bgColor : "#FFFFFF"}
          >
            <p

              onMouseMove={() => {
                setChooosenTag("iconsShape");
                setIsActiveTag("iconsShape");
              }}
            >  Icons & Shapes</p>
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>

          <Box
            bgcolor={isActiveTag === "Video" ? bgColor : "#FFFFFF"}
            className={styles.box}


          >
            <p onMouseMove={() => {
              setChooosenTag("Video");
              setIsActiveTag("Video");
            }}> Video/Music</p>
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>
          <Box
            bgcolor={isActiveTag === "products" ? bgColor : "#FFFFFF"}
            className={styles.box}


          >
            <p onMouseMove={() => {
              setChooosenTag("products");
              setIsActiveTag("products");
            }}>Product</p>
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>

          <Box
            className={styles.box}
          >
            Embedded code
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>

          <Box
            className={styles.box}
          >
            Social
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>

          <Box
            className={styles.box}

          >
            Payment
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>

          <Box
            className={styles.box}

          >
            Blog
            <AiOutlinePlusCircle className={styles.svgPlus} />
          </Box>

        </div>
        {chooosenTag === "text" && (
          <div style={{ width: "300px", gap: "5px", display: "flex" }} className={styles.addElementBox2}>
            <h1
              className={`${isActive === "Header" ? styles.activeTag : ""}`}
              onMouseMove={() => {
                let textId = document.getElementById("bubtton");
                console.log("jehfkejkjekjfkejfkj");
                setIsActive("Header");

                headTo.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              Header
            </h1>
            <p
              className={`${isActive === "title" ? styles.activeTag : ""}`}
              onMouseMove={() => {
                setIsActive("title");
                let textId = document.getElementById("bubtton");
                console.log("jehfkejkjekjfkejfkj");

                titleTo.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              {" "}
              Titles
            </p>
            <p
              className={`${isActive === "paragraph" ? styles.activeTag : ""
                }`}
              onMouseMove={() => {
                let textId = document.getElementById("bubtton");
                console.log("jehfkejkjekjfkejfkj");
                setIsActive("paragraph");
                ParaTo.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              Paragraph
            </p>
          </div>
        )}
        {chooosenTag === "iconsShape" && (
          <div style={{ width: "300px", gap: "5px", display: "flex" }} className={styles.addElementBox2}>
            {
              Icons?.map((icon, index) => (
                <i class={icon}></i>
              ))
            }
          </div>
        )}
        {chooosenTag === "Gallery" && (
          <div style={{ width: "450px", gap: "5px", display: "flex" }} className={styles.addElementBox2}>
            <div
              draggable
              onDragStart={() => setShowProdDrawer(false)}
              ref={(ref) => connectors.create(ref, <ResponsiveAppBar1 width={'300px'} height={'200px'} />)}


            >
              <div className={styles.ManuBarOne}>

                <div className={styles.menuOptionOne}>
                  <ul>
                    <li>Home</li>
                    <li>About</li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              draggable
              onDragStart={() => setShowProdDrawer(false)}
              ref={(ref) => connectors.create(ref, <ResponsiveAppBar color={'secondary'} width={'300px'} height={'200px'} />)}


            >
              <div className={styles.ManuBar}>
                <img src="https://images.pexels.com/photos/170809/pexels-photo-170809.jpeg" alt="" />
                <div className={styles.menuOptions}>
                  <ul>
                    <li>Home</li>
                    <li>About</li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {chooosenTag === "testimonial" && (

          <div style={{ width: "330px", gap: "5px", display: "flex" }} className={styles.addElementBox2}>
            <h1>Slider</h1>
            <div draggable style={{ cursor: "pointer" }}
              onDragStart={() => setShowProdDrawer(false)}
              ref={(ref) => connectors.create(ref, <SliderDrop width={'100%'} height={'500px'} showIndicators={"true"} showArrows={"true"} showThumbs={"false"} />)}

            >
              <Slider width={'300px'} height={'150px'} showIndicators={"true"} showArrows={"true"} showThumbs={"false"} />
            </div>


            <p
              className={styles.paragraph}
              key={"00"}
              style={{
                fontSize: `${23}px`,
                fontWeight: 300,
                backgroundColor: "#ccc",
                height: "100px",
                width: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
              }}
              onDragStart={() => setShowProdDrawer(false)}

              ref={(ref) => connectors.create(ref, <Testimonial2 />)

              }
            >
              Drag Testimonial2
            </p>



          </div>
        )}
        {chooosenTag === "picture" && (
          <div style={{ width: "300px", gap: "5px", display: "flex" }} className={styles.addElementBox2}>
            <p
              className={`${isActive === "Header" ? styles.activeTag : ""}`}
              onMouseMove={() => {
                let textId = document.getElementById("uploadimg");
                console.log("jehfkejkjekjfkejfkj");
                setIsActive("Header");

                uploadimg.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              From Unsplash
            </p>
            <p
              className={`${isActive === "title" ? styles.activeTag : ""}`}
              onMouseMove={() => {
                setIsActive("title");
                let textId = document.getElementById("fromMadFunnel");
                console.log("jehfkejkjekjfkejfkj");

                fromMadFunnel.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              {" "}
              From Pixels
            </p>
            <p
              className={`${isActive === "FromGallery" ? styles.activeTag : ""}`}
              onMouseMove={() => {
                setIsActive("FromGallery");



                FromGallery.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              {" "}
              Upload & Import
            </p>
          </div>
        )}
        {chooosenTag === "form" && (
          <div style={{ width: "300px", gap: "5px", display: "flex" }} className={styles.addElementBox2}>
            <p
              className={`${isActive === "Header" ? styles.activeTag : ""}`}
              onMouseMove={() => {
                setIsActive("Header");
                themeForm.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              Inputs
            </p>




          </div>
        )}
        {chooosenTag === "buttons" && (
          <div style={{ width: "300px", gap: "5px", display: "flex" }} className={styles.addElementBox2}>
            <p
              className={`${isActive === "Header" ? styles.activeTag : ""}`}
              onMouseMove={() => {
                let textId = document.getElementById("uploadimg");
                console.log("jehfkejkjekjfkejfkj");
                setIsActive("Header");

                themedButton.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              Themed butons
            </p>
            <p
              className={`${isActive === "title" ? styles.activeTag : ""}`}
              onMouseMove={() => {
                setIsActive("title");
                let textId = document.getElementById("fromMadFunnel");
                console.log("jehfkejkjekjfkejfkj");

                textIconButtons.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              {" "}
              Custom
            </p>
            <p
              className={`${isActive === "IconButtons" ? styles.activeTag : ""
                }`}
              onMouseMove={() => {
                setIsActive("IconButtons");
                let textId = document.getElementById("fromMadFunnel");
                console.log("jehfkejkjekjfkejfkj");

                IconButtons.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              {" "}
              Icon Buttons
            </p>
          </div>
        )}
        <div className={`${styles.addElementBox2} ${styles.ptag}`} style={{ width: "300px" }}>
          {chooosenTag === "text" && (
            <div id="text">
              <div id="Head" className={styles.headTags} ref={headTo}>
                <h1 style={{ marginTop: "10px", fontSize: "30px", fontWeight: "bold", borderBottom: "2px solid black" }}>Headings</h1>

                {Headers?.map((text, index) => (
                  <h1
                    className={styles.paragraph}
                    key={index}
                    style={{
                      fontStyle: "",
                      fontSize: `${text.fontSize}px`,
                      fontWeight: text.fontWeight,
                      fontFamily: 'Times New Roman',
                    }}
                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <Texts
                          canvas
                          fontStyle={text?.fontStyle}
                          fontSize={`${text.fontSize}px`}
                          fontWeight={text.fontWeight}
                          fontFamily={'Times New Roman'}
                          text={text.text}
                        ></Texts>
                      )
                    }
                  >
                    {text.text}
                  </h1>
                ))}
                {/* 
                            <h1 style={{ fontSize: '25px', fontWeight: 800 }} onDragStart={() => setShowProdDrawer(false)} ref={(ref) => connectors.create(ref, <Text fontweight={800} fontSize={25} text="Header 1" />)}>Header 1</h1>

                            <h1 style={{ fontSize: '22px', fontWeight: 700 }} onDragStart={() => setShowProdDrawer(false)} ref={(ref) => connectors.create(ref, <Text fontweight={700} fontSize={22} text="Header 2" />)}>Header 2</h1>

                            <h1 style={{ fontSize: '20px', fontWeight: 600 }} onDragStart={() => setShowProdDrawer(false)} ref={(ref) => connectors.create(ref, <Text fontweight={600} fontSize={20} text="Header 3" />)}>Header 3</h1>

                            <h1 style={{ fontSize: '18px', fontWeight: 500 }} onDragStart={() => setShowProdDrawer(false)} ref={(ref) => connectors.create(ref, <Text fontweight={500} fontSize={18} text="Header 4" />)}>Header 4</h1>

                            <h1 style={{ fontSize: '16px', fontWeight: 400 }} onDragStart={() => setShowProdDrawer(false)} ref={(ref) => connectors.create(ref, <Text fontweight={400} fontSize={16} text="Header 5" />)}>Header 5</h1> */}
              </div>

              <div id="tiitle" className={styles.titleTag} ref={titleTo}>
                <hr style={{ marginTop: "10px" }} />
                <h1 style={{ marginTop: "10px", fontSize: "30px", fontWeight: "bold", borderBottom: "2px solid black" }}>Titles</h1>

                {Titlles?.map((text, index) => (
                  // <p
                  //   className={styles.paragraph}
                  //   key={index}
                  //   style={{
                  //     fontStyle: text?.fontStyle,
                  //     fontSize: `${text.fontSize}px`,
                  //     fontWeight: text.fontWeight,
                  //     fontFamily: text.fontFamily,
                  //   }}
                  //   onDragStart={() => setShowProdDrawer(false)}
                  //   ref={(ref) =>
                  //     connectors.create(
                  //       ref,
                  //       <Text
                  //         fontStyle={text?.fontStyle}
                  //         fontweight={text.fontWeight}
                  //         fontSize={text.fontSize}
                  //         text={text.text}
                  //         fontFamily={text.fontFamily}
                  //       />
                  //     )
                  //   }
                  // >
                  //   {text.text}
                  // </p>
                  <p
                    className={styles.paragraph}
                    key={"00"}
                    style={{
                      fontStyle: "",
                      fontSize: `${text.fontSize}px`,
                      fontWeight: text.fontWeight,
                      fontFamily: 'Times New Roman',
                    }}
                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <Texts
                          canvas
                          fontStyle={text?.fontStyle}
                          fontSize={`${text.fontSize}px`}
                          fontWeight={text.fontWeight}
                          fontFamily={'Times New Roman'}
                          text={text.text}
                        ></Texts>
                      )
                    }
                  >
                    {text.text}

                  </p>
                ))}
              </div>
              <div className={styles.paraTag} id="tiitle" ref={ParaTo}>
                <hr style={{ marginTop: "10px" }} />
                <h1 style={{ marginTop: "10px", fontSize: "30px", fontWeight: "bold", borderBottom: "2px solid black" }}>
                  Paragraph
                </h1>

                {paragraph?.map((text, index) => (
                  // <p
                  //   className={styles.paragraph}
                  //   key={index}
                  //   style={{
                  //     fontStyle: text?.fontStyle,
                  //     fontSize: `${text.fontSize}px`,
                  //     fontWeight: text.fontWeight,
                  //     fontFamily: text.fontFamily,
                  //     width: "100%",
                  //   }}
                  //   onDragStart={() => setShowProdDrawer(false)}
                  //   ref={(ref) =>
                  //     connectors.create(
                  //       ref,
                  //       <Text
                  //         fontStyle={text?.fontStyle}
                  //         fontweight={text.fontWeight}
                  //         fontSize={text.fontSize}
                  //         text={text.text}
                  //         fontFamily={text.fontFamily}
                  //       />
                  //     )
                  //   }
                  // >
                  //   {text.text}
                  // </p>
                  <p
                    className={styles.paragraph}
                    key={"00"}
                    style={{
                      fontStyle: "",
                      fontSize: `${text.fontSize}px`,
                      fontWeight: text.fontWeight,
                      fontFamily: 'Times New Roman',
                    }}
                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <Texts
                          canvas
                          fontStyle={text?.fontStyle}
                          fontSize={`${text.fontSize}px`}
                          fontWeight={text.fontWeight}
                          fontFamily={'Times New Roman'}
                          text={text.text}
                        ></Texts>
                      )
                    }
                  >
                    {text.text}

                  </p>
                ))}
              </div>
            </div>
          )}
          {chooosenTag === "picture" && (
            <div id="bubtton" className={styles.imageTags} ref={uploadimg}>
              <div
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "200",
                  width: "104%",
                  padding: "10px",
                  backgroundColor: "white",
                  left: '0'
                }}
              >
                <input style={{
                  width: "100%"
                }} autoFocus value={imageSearched} onKeyDown={handleSearchImage} onChange={(e) => setImageSearched(e.target.value)} placeholder='Search image' type="text" />
              </div>

              {!(imageSearched?.length > 2) && <div
                className={styles.imageTags}
                id="uploadimg"
                style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}

              >
                <h1>From Unsplash</h1>

                {
                  images?.map(img => (
                    <div className={styles.unsplashImageDiv}>

                      <img
                        key={img.id}
                        draggable
                        onDragStart={() => setShowProdDrawer(false)}

                        ref={(ref) => connectors.create(ref, <ImageTag Imgsrc={img?.urls?.full} width={'100%'} height={'100%'} />)}
                        src={img?.urls?.thumb}
                        alt=""
                      />
                      <div className={styles.copylinkIcon}>
                        <MdContentCopy onClick={() => copyImage(img?.urls?.full)} color='gray' size={25} />
                      </div>
                    </div>
                  ))
                }

                <button className='addbutton' onClick={() => setPageTwo(prev => prev + 1)}>
                  Load More

                </button>



              </div>
              }
              <div
                style={{ marginTop: "50px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
                className={styles.imageTags}
                id="fromMadFunnel"
                ref={fromMadFunnel}
              >
                <h1>From Pixels</h1>
                {
                  pixelImages?.map(img => (

                    <div className={styles.unsplashImageDiv}>

                      <img
                        key={img.id}
                        draggable
                        onDragStart={() => setShowProdDrawer(false)}
                        ref={(ref) => connectors.create(ref, <ImageTag width={'100%'} height={'100%'} Imgsrc={img?.src?.original} />)}
                        src={img?.src?.tiny}
                        alt=""
                      />
                      <div className={styles.copylinkIcon}>
                        <MdContentCopy onClick={() => copyImage(img?.src?.original)} color='gray' size={25} />
                      </div>
                    </div>
                  ))
                }
                <button className='addbutton' onClick={() => setPage((prevPage) => prevPage + 1)}>Load more</button>
              </div>

              <div
                style={{ marginTop: "50px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
                className={styles.imageTags}
                id="fromMadFunnel"
                ref={FromGallery}
              >
                <h1>Upload & Import</h1>
                {
                  galleryImages?.map(img => (

                    <div className={styles.unsplashImageDiv}>

                      <img
                        key={img.id}
                        draggable
                        onDragStart={() => setShowProdDrawer(false)}
                        ref={(ref) => connectors.create(ref, <ImageTag width={'100%'} height={'100%'} Imgsrc={img?.url} />)}
                        src={img?.thumbUrl}
                        alt=""
                      />
                      <div className={styles.copylinkIcon}>
                        <MdContentCopy onClick={() => copyImage(img?.url)} color='gray' size={25} />
                      </div>
                    </div>
                  ))
                }
                <button className='addbutton' onClick={() => setPageGal((prevPage) => prevPage + 1)}>Load more</button>
              </div>
            </div>
          )}
          {chooosenTag === "buttons" && (
            <div id="bubtton" className={styles.imageTags} ref={buttons}>
              <div
                className={styles.imageTags}
                id="uploadimg"
                ref={themedButton}
              >
                <h3>Theme Button</h3>
                <Stack spacing={2} direction={"row"}>

                  <Button
                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <MButton
                          fontSize={20}
                          size="large"
                          text="button"
                          textColor="white"
                          color="primary"
                          variant="contained"
                        />
                      )
                    }
                    draggable
                    variant="contained"
                  >
                    contained
                  </Button>
                  <Button
                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <MButton
                          fontSize={20}
                          text="button"
                          color="secondary"
                          textColor="white"
                          size="large"
                          variant="contained"
                        />
                      )
                    }
                    draggable
                    variant="outlined"
                    color="secondary"
                  >
                    Outlined
                  </Button>
                </Stack>
                <Box sx={{ "& button": { m: 1 } }}>
                  <div>
                    <Stack spacing={2} direction={"row"}>
                      <Button
                        size="small"
                        onDragStart={() => setShowProdDrawer(false)}
                        ref={(ref) =>
                          connectors.create(
                            ref,
                            <MButton
                              fontSize={20}
                              size="small"
                              text="Small"
                              textColor="black"
                              color="primary"
                              variant="text"
                            />
                          )
                        }
                        draggable
                        variant="text"
                      >
                        Small
                      </Button>

                      <Button
                        onDragStart={() => setShowProdDrawer(false)}
                        ref={(ref) =>
                          connectors.create(
                            ref,
                            <MButton
                              fontSize={20}
                              size="medium"
                              text="medium"
                              textColor="white"
                              color="primary"
                              variant="contained"
                            />
                          )
                        }
                        draggable
                        variant="contained"
                      >
                        medium
                      </Button>

                      <Button
                        onDragStart={() => setShowProdDrawer(false)}
                        ref={(ref) =>
                          connectors.create(
                            ref,
                            <MButton
                              fontSize={20}
                              text="large"
                              color="secondary"
                              textColor="white"
                              size="large"
                              variant="contained"
                            />
                          )
                        }
                        draggable
                        variant="outlined"
                      >
                        large
                      </Button>
                    </Stack>
                  </div>



                </Box>
              </div>

              <div
                className={styles.imageTags}
                id="fromMadFunnel"
                ref={textIconButtons}
              >
                <h3>Custom Button</h3>
                <Box sx={{ "& button": { m: 1 } }}>
                  <div>

                    <Button
                      onDragStart={() => setShowProdDrawer(false)}
                      ref={(ref) =>
                        connectors.create(
                          ref,
                          <Buttons
                            fontSize={20}
                            size="large"
                            text="Change"
                            textColor="black"
                            color="primary"
                            variant="text"
                          />
                        )
                      }
                      draggable
                      variant="text"
                    >
                      Custom
                    </Button>

                    <Button
                      onDragStart={() => setShowProdDrawer(false)}
                      ref={(ref) =>
                        connectors.create(
                          ref,
                          <PaypalButton />
                        )
                      }
                      draggable
                      variant="contained"

                      endIcon={<BsPaypal />}
                    >
                      PayPalBUtton
                    </Button>


                  </div>


                </Box>

              </div>
              <div
                className={styles.imageTags}
                id="fromMadFunnel"
                ref={IconButtons}
              >
                <h3>Icon Buttons</h3>

                <Stack
                  gap={1}
                  justifyContent={"space-around"}
                  flexWrap={"wrap"}
                  direction={"row"}
                >
                  <span draggable>
                    {" "}
                    <GiPowerButton size={30} />
                  </span>
                  <span draggable>
                    {" "}
                    <GiPreviousButton draggable size={30} />
                  </span>
                  <span draggable>
                    {" "}
                    <GiNextButton draggable size={30} />
                  </span>
                  <span draggable>
                    {" "}
                    <GiFastForwardButton draggable size={30} />
                  </span>
                  <span draggable>
                    {" "}
                    <GiFastBackwardButton draggable size={30} />
                  </span>
                  <span draggable>
                    {" "}
                    <GiPauseButton draggable size={30} />
                  </span>
                </Stack>
                <Stack
                  gap={1}
                  justifyContent={"space-around"}
                  flexWrap={"wrap"}
                  direction={"row"}
                >
                  <GiPlayButton draggable size={30} />
                  <BsFillMenuButtonFill draggable size={30} />
                  <CgPlayButtonO draggable size={30} />
                  <CgPlayButtonR draggable size={30} />
                  <CgPlayButton draggable size={30} />
                  <RxButton draggable size={30} />
                </Stack>
              </div>
            </div>
          )}
          {chooosenTag === "form" && (
            <div id="bubtton" className={styles.imageTags} ref={buttons}>
              <div
                className={styles.imageTags}
                id="uploadimg"
                ref={themeForm}
              >
                <h3>Inputs</h3>
                <Stack spacing={2} direction={"row"}>
                  <Button
                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <Inputs
                          text=""
                        />
                      )
                    }
                    draggable
                    variant="text"
                  >
                    Input
                  </Button>
                  <Button
                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <RadioLabel
                          value={'male'}
                          label={"label"}
                          labelPlacement={'right'}

                        />
                      )
                    }
                    draggable
                    variant="text"
                  >
                    Radio Label
                  </Button>
                  <Button
                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <Radios
                          label={'Male'}
                          name={'gender'}
                          value={'Male'}
                        // text="enter input"
                        />
                      )
                    }
                    draggable
                    variant="text"
                  >
                    Radio
                  </Button>

                </Stack>
                <Stack spacing={2} direction={"row"}>
                  <Button
                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) => connectors.create(ref, <Element
                      canvas
                      is={FormContainer}
                      width="400px"
                      height="300px"
                      background={{ r: 255, g: 255, b: 255, a: 1 }}
                      padding={["10", "10", "10", "10"]}
                      custom={{ displayName: "Form" }}
                    ></Element>)
                    }
                    draggable
                    variant="text"
                  >
                    Form Container
                  </Button>
                  <Button
                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <Selects
                        // text="enter input"
                        />
                      )
                    }
                    draggable
                    variant="text"
                  >
                    Select
                  </Button>

                </Stack>
                <Stack>
                  <img

                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <FormContainer
                        // text="enter input"
                        />
                      )
                    }
                    draggable


                    height={"100px"} width={"100%"} style={{ marginTop: "10px" }} src={form1} alt="" />
                  <img

                    onDragStart={() => setShowProdDrawer(false)}
                    ref={(ref) =>
                      connectors.create(
                        ref,
                        <FormContainer2
                        // text="enter input"
                        />
                      )
                    }
                    draggable

                    src={form2} height={"100px"} width={"100%"} style={{ marginTop: "10px" }} alt="" />
                </Stack>
              </div>


            </div>
          )}
          {chooosenTag === "section" && (
            <div id="bubtton" className={styles.imageTags} ref={buttons}>
              <div
                className={styles.imageTags}
                id="uploadimg"
                ref={themeForm}
              >
                <h3>Sections</h3>


                <p
                  className={`${isActive === "Header" ? styles.activeTag : styles.activeTag}`}
                // onMouseMove={() => {
                //   let textId = document.getElementById("uploadimg");
                //   console.log("jehfkejkjekjfkejfkj");
                //   setIsActive("Header");

                //   uploadimg.current.scrollIntoView({
                //     behavior: "smooth",
                //     block: "start",
                //   });
                // }}
                >
                  Row Section
                </p>
                <p
                  className={`${isActive === "column" ? styles.activeTag : ""}`}
                // onMouseMove={() => {
                //   let textId = document.getElementById("uploadimg");
                //   console.log("jehfkejkjekjfkejfkj");
                //   setIsActive("Header");

                //   uploadimg.current.scrollIntoView({
                //     behavior: "smooth",
                //     block: "start",
                //   });
                // }}
                >
                  Column Section
                </p>

              </div>


            </div>
          )}
          {chooosenTag === "Video" && (
            <div id="bubtton" className={styles.imageTags} ref={buttons}>
              <div
                className={styles.imageTags}
                id="uploadimg"
                ref={themeForm}
              >
                <h3>Video/Music</h3>
                <p
                  className={`${isActive === "Header" ? styles.activeTag : styles.activeTag}`}
                // onMouseMove={() => {
                //   let textId = document.getElementById("uploadimg");
                //   console.log("jehfkejkjekjfkejfkj");
                //   setIsActive("Header");

                //   uploadimg.current.scrollIntoView({
                //     behavior: "smooth",
                //     block: "start",
                //   });
                // }}
                >
                  Videos
                </p>
                <p
                  className={`${isActive === "column" ? styles.activeTag : ""}`}
                // onMouseMove={() => {
                //   let textId = document.getElementById("uploadimg");
                //   console.log("jehfkejkjekjfkejfkj");
                //   setIsActive("Header");

                //   uploadimg.current.scrollIntoView({
                //     behavior: "smooth",
                //     block: "start",
                //   });
                // }}
                >
                  Music
                </p>

              </div>


            </div>
          )}
        </div>

        {chooosenTag === "section" && (
          <div id="text" >
            <div id="Head" className={styles.imageTags} ref={headTo}>
              <h3 style={{ marginTop: "10px" }}>Section</h3>
              <hr />



              <p
                className={styles.paragraph}
                key={"00"}
                style={{
                  fontSize: `${23}px`,
                  fontWeight: 300,
                  backgroundColor: "#ccc",
                  height: "100px",
                  width: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
                onDragStart={() => setShowProdDrawer(false)}
                ref={(ref) => connectors.create(ref, <Element
                  canvas
                  is={Container}
                  width="400px"
                  height="100px"
                  background={{ r: 256, g: 256, b: 256, a: 0 }}
                  padding={["0", "0", "0", "0"]}
                  custom={{ displayName: "Container" }}
                >

                </Element>)}
              >

                Drag
              </p>

              <div
                onDragStart={() => setShowProdDrawer(false)}
                ref={(ref) => connectors.create(ref, <Element
                  canvas
                  is={GridContainer}
                  width="400px"
                  height="100px"
                  background={{ r: 256, g: 256, b: 256, a: 0 }}
                  padding={["0", "0", "0", "0"]}
                  custom={{ displayName: "Container" }}
                >

                </Element>)}

                style={{ display: "flex", gap: "5px" }}>
                <p
                  className={styles.paragraph}
                  key={"00"}
                  style={{
                    fontSize: `${23}px`,
                    fontWeight: 300,
                    backgroundColor: "#ccc",
                    height: "100px",
                    width: "96px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}

                >


                </p>
                <p
                  className={styles.paragraph}
                  key={"00"}
                  style={{
                    fontSize: `${23}px`,
                    fontWeight: 300,
                    backgroundColor: "#ccc",
                    height: "100px",
                    width: "96px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}
                  onDragStart={() => setShowProdDrawer(false)}

                >


                </p>
                <p
                  className={styles.paragraph}
                  key={"00"}
                  style={{
                    fontSize: `${23}px`,
                    fontWeight: 300,
                    backgroundColor: "#ccc",
                    height: "100px",
                    width: "96px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}

                >


                </p>
              </div>

              <p
                className={styles.paragraph}
                key={"00"}
                style={{
                  fontSize: `${23}px`,
                  fontWeight: 300,
                  backgroundColor: "#ccc",
                  height: "100px",
                  width: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
                onDragStart={() => setShowProdDrawer(false)}

                ref={(ref) => connectors.create(ref, <Card />)

                }
              >
                Drag Card
              </p>
              <p
                className={styles.paragraph}
                key={"00"}
                style={{
                  fontSize: `${23}px`,
                  fontWeight: 300,
                  backgroundColor: "#ccc",
                  height: "100px",
                  width: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
                onDragStart={() => setShowProdDrawer(false)}

                ref={(ref) => connectors.create(ref, <Product />)

                }
              >
                Drag Product
              </p>
              <p
                className={styles.paragraph}
                key={"00"}
                style={{
                  fontSize: `${23}px`,
                  fontWeight: 300,
                  backgroundColor: "#ccc",
                  height: "100px",
                  width: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
                onDragStart={() => setShowProdDrawer(false)}

                ref={(ref) => connectors.create(ref, <Modal />)

                }
              >
                Drag Modal
              </p>
            </div>



          </div>
        )}
        {chooosenTag === "Video" && (
          <div id="text" style={{ width: "300px" }}>
            <div style={{ paddingBottom: "50px" }} id="Head" className={styles.headTags} ref={headTo}>
              <input style={{ paddingLeft: "10px", height: "30px", borderRadius: "5px" }} autoFocus value={VideoSearched} onKeyDown={loadVideo} onChange={(e) => setVideoSearched(e.target.value)} placeholder='Search Video' type="text" />

              <div
                className={`${styles.addElementBox2} ${styles.videoSection}`}
                id="fromMadFunnel"
                ref={videoSection}
                style={{ marginBottom: "30px" }}
              >

                {
                  pixelVideos?.map(video => (
                    <img
                      style={{ cursor: "pointer" }}
                      draggable
                      onDragStart={() => setShowProdDrawer(false)}
                      ref={(ref) => connectors.create(ref, <Video
                        link={video?.video_files?.[0]?.link}
                        canvas
                        height={`${100}%`}
                        width={`${100}%`}

                      />)}
                      src={video.image}
                      alt=""
                    />
                  ))
                }
                <button className='addbutton' onClick={() => setVideoPage(prev => prev + 1)}>Load More</button>
              </div>

            </div>



          </div>
        )}
        {chooosenTag === "products" && (
          <div id="text" style={{ width: "300px" }}>
            <div style={{ paddingBottom: "50px", display: "flex", flexDirection: "column", marginTop: "10px" }} id="Head" className={styles.headTags} ref={headTo}>
              <h1>Select Product</h1>
              <Select
                // disabled={selectedProd.includes(select?.id)}
                style={{ backgroundColor: "#fff", fontSize: "8px", marginTop: "10px" }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "8px" },
                  height: "24px",
                  width: "95%",
                  border: "1px solid gray"
                }}
                labelId="demo-select-large"
                id="demo-select-large"

                onChange={(e) => {
                  setProddetails(e.target.value)
                  dispatch(setProductProps({ productId: e.target.value._id, prodName: e.target.value?.name, ProdDescription: e.target.value?.description, productImage: e.target.value?.image ? e.target.value?.image : "https://www.electrosolutions.in/wp-content/uploads/2018/08/product-image-dummy.jpg" }))
                  setSelectedPrice(e.target.value?.prices)

                }}

              >

                {allProducts
                  ?.map((prod) => {
                    return (
                      <MenuItem
                        style={{ fontSize: "8px" }}
                        name={prod?.id}
                        value={prod}
                      // disabled={selectedProd?.find((prod1) => prod1.id === prod.id)}
                      >
                        {prod?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              <h1>Select Price</h1>
              <Select
                // disabled={selectedProd.includes(select?.id)}
                style={{ backgroundColor: "#fff", fontSize: "8px", marginTop: "10px" }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "8px" },
                  height: "24px",
                  width: "95%",
                  border: "1px solid gray"
                }}
                labelId="demo-select-large"
                id="demo-select-large"

                onChange={(e) => {
                  setProddetails({ ...prodDetails, price: e.target.value })
                  dispatch(setProductProps({ priceId: e.target.value?._id, priceIdStripe: e.target.value?.stripe_price_id, prodpriceId: e.target.value?.paypal_price_id, productCost: e.target.value?.amount, modeofpayment: e.target.value?.price_plan, dbPriceId: e.target.value?._id }))


                }}

              >

                {selectedPrice
                  ?.map((prod) => {
                    return (
                      <MenuItem
                        style={{ fontSize: "8px" }}
                        name={prod?.id}
                        value={prod}
                      // disabled={selectedProd?.find((prod1) => prod1.id === prod.id)}
                      >
                        {prod?.amount?.toString()}
                      </MenuItem>
                    );
                  })}
              </Select>
              <h1>Select page to redirect </h1>
              <Select
                // disabled={selectedProd.includes(select?.id)}
                style={{ backgroundColor: "#fff", fontSize: "8px", marginTop: "10px" }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "8px" },
                  height: "24px",
                  width: "95%",
                  border: "1px solid gray"
                }}
                labelId="demo-select-large"
                id="demo-select-large"
                
                onChange={(e) => {

                  dispatch(setProductProps({ redirectUrl: e.target.value?.url }))


                }}

              >

                {pages
                  ?.map((prod) => {
                    return (
                      <MenuItem
                        style={{ fontSize: "8px" }}
                        name={prod?.id}
                        value={prod}
                      // disabled={selectedProd?.find((prod1) => prod1.id === prod.id)}
                      >
                        {prod?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              {console.log("storeRoom", store.getState().pageBuilder)}
              <div>
                <FormGroup>

                  <FormControlLabel

                    control={<Checkbox


                      checked={isPaypal}

                      onClick={(e) => {

                        dispatch(setProductProps({ isPaypal: !(store.getState().pageBuilder.isPaypal) }))
                        console.log(e.target.checked)
                      }}
                    />} label="Paypal" />
                  <FormControlLabel



                    control={<Checkbox

                      checked={isStripe}
                      onChange={(e) => {

                        dispatch(setProductProps({ isStripe: e.target.checked }))

                      }}
                    />} label="Stripe" />
                  <FormControlLabel


                    control={<Checkbox

                      checked={isChargebee}
                      onChange={(e) => {

                        dispatch(setProductProps({ isChargebee: e.target.checked }))

                      }}
                    />} label="Chargebee" />
                </FormGroup>
              </div>

              <div
                className={`${styles.addElementBox2} ${styles.videoSection}`}
                id="fromMadFunnel"
                ref={videoSection}
                style={{ width: "100%", marginBottom: "30px", display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "10px" }}
              >

                <p
                  className={styles.paragraph}
                  key={"00"}
                  style={{
                    fontSize: `${23}px`,
                    fontWeight: 300,
                    backgroundColor: "#ccc",
                    height: "100px",
                    width: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    flexDirection: "column"
                  }}
                  onDragStart={() => setShowProdDrawer(false)}
                  onDrop={() => {
                    dispatch(resetProdProps())

                  }}

                  ref={(ref) => connectors.create(ref, <Product


                    productName={store.getState().pageBuilder?.prodName}
                    paypalPriceId={store.getState().pageBuilder?.prodpriceId}
                    productDes={store.getState().pageBuilder?.ProdDescription}
                    priceId={store.getState().pageBuilder?.prodpriceId}
                    price_Id={store.getState().pageBuilder?.priceId}
                    priceIdStripe={store.getState().pageBuilder?.priceIdStripe}
                    productCost={store.getState().pageBuilder?.productCost}
                    isPaypal={store.getState().pageBuilder?.isPaypal}
                    isStripe={store.getState().pageBuilder?.isStripe}
                    isChargebee={store.getState().pageBuilder?.isChargebee}
                    mode={store.getState().pageBuilder?.modeofpayment}
                    Imgsrc={store.getState().pageBuilder?.chanageImage}
                    productId={store.getState().pageBuilder?.productId}
                    dbPriceId={store.getState().pageBuilder?.dbPriceId}
                    productImage={store.getState().pageBuilder?.productImage}
                    redirectUrl={store.getState().pageBuilder?.redirectUrl}


                  />)

                  }
                >

                  <span>Paypal</span>
                  {prodDetails?.name || "Product"}
                </p>


              </div>

            </div>



          </div>
        )}


      </div>
    </div >
  );
}

export default AddProdDrawer