// components/user/Card.js
import React from "react";
import Text from "../Text";
import Button from "../Button";
import { Element, useNode } from "@craftjs/core";

import { Container } from "../Container";
import ImageTag from "../imageTag";
// import SButton from './buttons'
import Texts from "../texts";
import Buttons from "../buttons";
// Notice how CardTop and CardBottom do not specify the drag connector. This is because we won't be using these components as draggables; adding the drag handler would be pointless.

export const CardTop = ({ children }) => {
    const {
        connectors: { connect },
    } = useNode();
    return (
        <div ref={connect} className="text-only">
            {children}
        </div>
    );
};

CardTop.craft = {
    rules: {
        // Only accept Text
        canMoveIn: (incomingNodes) =>
            incomingNodes.every((incomingNode) => incomingNode.data.type === Text),
    },
};

export const CardBottom = ({ children }) => {
    const {
        connectors: { connect },
    } = useNode();
    return <div ref={connect}>{children}</div>;
};

CardBottom.craft = {
    rules: {
        // Only accept Buttons
        canMoveIn: (incomingNodes) =>
            incomingNodes.every((incomingNode) => incomingNode.data.type === Button),
    },
};

export const Card = ({ background, padding }) => {
    const {
        connectors: { connect },
    } = useNode();
    return (
        <div ref={connect} canvas>
            <Element canvas id='container' is={Container} flexDirection="column" width="300px" height="auto" radius={10} background={{ r: 240, g: 236, b: 236, a: 1 }}>
                <Element canvas id="image" is={ImageTag} height={'300px'} width = {'100%'} src={"https://images.pexels.com/photos/735174/pexels-photo-735174.jpeg"}/>
                <Texts text="Title" margin={[10,0,10,10]} fontSize={'32px'} fontWeight="bold" />
                <Texts fontFamily={"'Pacifico', cursive"} margin={[0, 0, 10, 10]} text="Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica" color='red' />
                <Element id="buttons" is={Container} canvas>
                <Buttons width = {'100%'} text="Learn more" />                  
                </Element>
            </Element>
        </div>
    );
};
Card.craft = {
    displayName: 'Cards',
    rules: {
        canDrag: () => true,
    },
};
export default Card;
