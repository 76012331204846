import React, { useEffect, useState } from 'react'
import styles from "./PageSetting.module.css"
import { RxCross2 } from "react-icons/rx"
import { MenuItem, Select } from '@mui/material'
import usePageBuilder from '../../../Context/pageBuilder'
import { fonts } from "../Components/forms/radioLabel/RadioSettings"
import { SketchPicker } from 'react-color'
function PageSetting() {
    const sourceList = [{ label: "Facebook" }, { label: "Twitter" }, { label: "Instagram" }]
  
    const {
        globalFont, setGlobalFont, pageSetting, setPageSetting,BgColor, setBgColor
    } = usePageBuilder();
    console.log("show", BgColor)
    const handleSave = () => {

    }

    const onColorChange = ({rgb}) => {
        console.log("rgba", rgb)
        setBgColor({...BgColor, ...rgb})
    }
    return (
        <div
            onClick={() => setPageSetting(false)}
            className={`${styles.summaryModel} ${pageSetting ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <h1>Page Settings</h1>
                    < RxCross2 onClick={() => { setPageSetting(false); console.log('hello') }} />
                </div>
                <div className={styles.inputBody}>


                    <div className={styles.inputField}>
                        <p>Font Family</p>
                        <Select
                            style={{ backgroundColor: "#fff", fontSize: "8px" }}
                            sx={{
                                "& fieldset": { border: "none", fontSize: "8px" }, height: "24px", width: 201.5
                            }}
                            labelId="demo-select-large"
                            id="demo-select-large"
                            value={globalFont}

                            onChange={(e) => {
                                setGlobalFont(e.target.value)
                            }}


                        >
                            <MenuItem style={{ fontSize: "8px" }} value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                fonts?.map((font, index) =>
                                (
                                    <MenuItem style={{ fontSize: "8px" }} value={font.name} >{font.name}</MenuItem>

                                ))
                            }
                        </Select>
                    </div>



                </div>
                <div className={styles.inputBody}>


                    <div className={styles.inputField}>
                        <p>Page Color</p>
                        <div>
                            <SketchPicker color={BgColor} onChange={onColorChange} />
                        </div>
                    </div>



                </div>
                <div className={styles.closeButtons}>
                    <button className='closebuttn' onClick={() => setPageSetting(false)}  >Close</button>
                    <button className='addbutton' onClick={handleSave} >Save</button>
                </div>
            </div>
        </div>
    );
}

export default PageSetting