import React from 'react';

import { ToolbarItem, ToolbarSection } from '../../Toolbar';
import ToolbarRadio from '../../Toolbar/ToolbarRadio';
import { MenuItem } from '@mui/material';
import {animateFunction} from "../buttons/ButtonSettings"
export const capitalize = (text) =>
  text[0].toUpperCase() + text.substr(1, text.length);
export const weightDescription = (weight) =>
  weight === 400 ? "Regular" : weight === 500 ? "Medium" : "Bold";
export const fonts = [
  { id: "'Oswald', sans-serif", name: "Oswald" },
  { id: "Helvetica Neue,Helvetica,Arial,sans-serif", name: "Helvetica" },
  { id: "sans-serif", name: "sans-serif" },
  { id: "Times New Roman", name: "Times New Roman" },
  { id: "Arial Black", name: "Arial Black" },
  { id: "'Avenir Book', Avenir", name: "Avenir Book" },
  { id: "'Avenir Medium', Avenir", name: "Avenir Medium" },
  { id: "'Avenir Roman', Avenir", name: "Avenir Roman" },
  { id: "'Montserrat', sans-serif", name: "Montserrat" },
  { id: "'Oswald', sans-serif", name: "Oswald" },
  { id: "'Shadows Into Light', cursive", name: "Shadows Into Light" },
  { id: "'Pacifico', cursive", name: "Pacifico" },
  { id: "Tahoma", name: "Tahoma" },
  { id: "Verdana, Geneva, sans-serif", name: "Verdana" },
  { id: "Courier New Courier, monospace", name: "Courier New Courier" },
  { id: "'Lato', sans-serif", name: "Lato" },
  { id: "'Open Sans', sans-serif", name: "Open Sans" },
  { id: "'Montserrat', sans-serif", name: "Montserrat" },
];
export const animations = [
  { id: "fade", name: "fade" },
  { id: "fade-up", name: "fade-up" },
  { id: "fade-down", name: "fade-down" },
  { id: "fade-left", name: "fade-left" },
  { id: "fade-right", name: "fade-right" },
  { id: "fade-up-right", name: "fade-up-right" },
  { id: "fade-up-left", name: "fade-up-left" },
  { id: "fade-down-right", name: "fade-down-right" },
  { id: "fade-down-left", name: "fade-down-left" },
  { id: "fade-zoom-in", name: "fade-zoom-in" },
  { id: "flip-up", name: "flip-up" },
  { id: "flip-down", name: "flip-down" },
  { id: "flip-left", name: "flip-left" },
  { id: "flip-right", name: "flip-right" },
  { id: "slide-up", name: "slide-up" },
  { id: "slide-down", name: "slide-down" },
  { id: "slide-left", name: "slide-left" },
  { id: "slide-right", name: "slide-right" },
  { id: "zoom-in", name: "zoom-in" },
  { id: "zoom-in-up", name: "zoom-in-up" },
  { id: "zoom-in-down", name: "zoom-in-down" },
  { id: "zoom-in-left", name: "zoom-in-left" },
  { id: "zoom-in-right", name: "zoom-in-right" },
  { id: "zoom-out", name: "zoom-out" },
  { id: "zoom-out-up", name: "zoom-out-up" },
  { id: "zoom-out-down", name: "zoom-out-down" },
  { id: "zoom-out-left", name: "zoom-out-left" },
  { id: "zoom-out-right", name: "zoom-out-right" },


];
export const TextSettings = () => {
  return (
    <React.Fragment>
      <ToolbarSection
        title="Typography"
        props={['fontSize', 'fontWeight', 'textAlign', 'fontFamily']}
        summary={({ fontSize, fontWeight, textAlign, fontFamily }) => {
          console.log('fontFamily: ', fontFamily);
          return `${fontSize || ''}, ${weightDescription(
            fontWeight
          )}, ${capitalize(textAlign)}`;
        }}
      >
        <ToolbarItem
          full={true}
          propKey="fontSize"
          type="slider"
          label="Font Size"
        />
        <ToolbarItem propKey="textAlign" type="radio" label="Align">
          <ToolbarRadio value="left" label="Left" />
          <ToolbarRadio value="center" label="Center" />
          <ToolbarRadio value="right" label="Right" />
          <ToolbarRadio value="justify" label="Justify" />
        </ToolbarItem>
        <ToolbarItem propKey="fontWeight" type="radio" label="Weight">
          <ToolbarRadio value="400" label="Regular" />
          <ToolbarRadio value="500" label="Medium" />
          <ToolbarRadio value="700" label="Bold" />
        </ToolbarItem>
        <ToolbarItem propKey="fontFamily" type="select" label="Font Family" title="Font Family">
          {fonts && fonts.length > 0 && fonts.map((obj) => {
            return <MenuItem style={{ zIndex: 180000 }} value={obj.id}>{obj.name}</MenuItem>
          })}
        </ToolbarItem>
      </ToolbarSection>
      <ToolbarSection
        title="Animation"
        props={['buttonType', 'clickType', 'link']}
      >
        <ToolbarItem propKey="animationType" type="select" label="Animation Type" >
          {animations && animations.length > 0 && animations.map((obj) => {
            return <MenuItem style={{ zIndex: 180000 }} value={obj.id}>{obj.name}</MenuItem>
          })}
        </ToolbarItem>
        <ToolbarItem propKey="TimingFunction" type="select" label="Timing function" >
          {animateFunction && animateFunction.length > 0 && animateFunction.map((obj) => {
            return <MenuItem className='menu-items' style={{ zIndex: 180000 }} value={obj.id}>{obj.name}</MenuItem>
          })}
        </ToolbarItem>
        <ToolbarItem
          full={true}
          propKey="animationDuration"
          type="slider"
          label="Duration"
        />
        <ToolbarItem
          full={true}
          propKey="animationDelay"
          type="slider"
          label="Delay"
        />
        <ToolbarItem propKey="animateOnce" type="radio" label="Only animate once">
          <ToolbarRadio value="yes" label="Yes" />
          <ToolbarRadio value="no" label="No" />
        </ToolbarItem>
      </ToolbarSection>
      <ToolbarSection
        title="Margin"
        props={['margin']}
        summary={({ margin }) => {
          return `${margin[0] || 0}px ${margin[1] || 0}px ${margin[2] || 0}px ${margin[3] || 0
            }px`;
        }}
      >
        <ToolbarItem propKey="margin" index={0} type="slider" label="Top" />
        <ToolbarItem propKey="margin" index={1} type="slider" label="Right" />
        <ToolbarItem propKey="margin" index={2} type="slider" label="Bottom" />
        <ToolbarItem propKey="margin" index={3} type="slider" label="Left" />
      </ToolbarSection>
      <ToolbarSection
        title="Appearance"
        props={['color', 'shadow']}
        summary={({ color, shadow }) => {
          return (
            <div className="fletext-right">
              <p
                style={{
                  color: color && `rgba(${Object.values(color)})`,
                  textShadow: `0px 0px 2px rgba(0, 0, 0, ${shadow / 100})`,
                }}
                className="text-white text-right"
              >
                T
              </p>
            </div>
          );
        }}
      >
        <ToolbarItem full={true} propKey="color" type="color" label="Text" />
        <ToolbarItem
          full={true}
          propKey="shadow"
          type="slider"
          label="Shadow"
        />
      </ToolbarSection>
    </React.Fragment>
  );
};
