import React, { useEffect, useState } from "react";
import styles from "./EditTax.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { getCountryList, getTaxId } from "../../Services/api";
import axios from "axios";
import { common } from "../../BaseUrl";
import Loader from "../../Loader";

const EditTax = () => {
  const taxId = useLocation().state?.id;
  console.log(taxId);
  const navigate = useNavigate();
  const [skipedFunnel, setSkipedFunnel] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  console.log('data: ', data);
  const [cData, setCData] = useState([]);
  console.log(data);
  const getCountryData = () =>{
    getCountryList().then(res=>{
      console.log('res: ', res);
      console.log('res?.data?.data: ', res?.data?.data);
      if (res?.data?.data?.length > 0){
        setCData(res?.data?.data)
      }
    })
  }
useEffect(()=>{
  getCountryData()
},[''])
  useEffect(() => {
    getTaxData(taxId);
  }, [skipedFunnel]);
  const getTaxData = () => {
    getTaxId(taxId).then((res) => {
      setData(res?.data?.data);
    });
  };

  const updateTax = () => {
    setLoading(true);
    axios({
      method: "put",
      url: `${common}/tax/update/${taxId}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      console.log(res);
      setData(data);
      navigate("/user/tax-management");
      // toast.success('Updated successfully')
      setLoading(false);
    });
  };
  return (
    <div className={styles.Funnels}>
      <div className={styles.FunnelsCreate}>
        <h1 className={styles.FunnelsHead}> Japan</h1>
        <div className={styles.Button}>
          <button onClick={() => navigate("/user/tax-management")}>
            Back to List
          </button>
        </div>
      </div>
      {data ? <div className={styles.content}>
        <div className={styles.subcontent}>
          <label htmlFor="state">All State</label>
          <div className={styles.FunnelsSearchDrop}>
            <div className={styles.inputField}>
              <Select
                style={{
                  backgroundColor: "#fff",
                  fontSize: "13px",
                  width: "100%",
                  color: "#000",
                  fontFamily: "Avenir",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "13px" },
                  height: "24px",
                }}
                labelId="demo-select-large"
                id="country_id"
                name="country_id"
                label="Last 30 days"
                value={data.country_id}
                onChange={(e) => setData({ ...data, country_id: e.target.value })}
              >
                {
                  cData.length > 0 && cData.map((obj) => {
                    return (<MenuItem
                      style={{
                        fontSize: "13px",
                        margin: "10px",
                        borderBottom: "1px solid #000",
                      }}
                      value={obj?._id}
                    >
                      {obj?.country_name}
                    </MenuItem>)
                  })
                }
              </Select>
            </div>
          </div>
        </div>
        <div className={styles.subcontent}>
          <label htmlFor="name">State Name</label>
          <input
            type="text"
            name="state"
            id="state"
            value={data.state}
            onChange={(e) => setData({ ...data, state: e.target.value })}
          />
        </div>
        <div className={styles.subcontent}>
          <label htmlFor="name">Tax Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        </div>
        <div className={styles.subcontent}>
          <label htmlFor="value">Tax Value</label>
          <input
            type="number"
            name="value"
            id="value"
            value={data.value}
            onChange={(e) => setData({ ...data, value: e.target.value })}
          />
          <span> &nbsp;&nbsp;%</span>
        </div>
                
        <div className={styles.subcontent}>
          <label htmlFor="state">Status</label>
          <div className={styles.FunnelsSearchDrop}>
            <div className={styles.inputField}>
              <Select
                style={{
                  backgroundColor: "#fff",
                  fontSize: "13px",
                  width: "100%",
                  color: "#000",
                  fontFamily: "Avenir",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "13px" },
                  height: "24px",
                }}
                labelId="demo-select-large"
                id="status"
                name="status"
                label="Last 30 days"
                value={data.status}
                onChange={(e) => setData({ ...data, status: e.target.value })}
                  >
                <MenuItem
                  style={{
                    fontSize: "13px",
                    margin: "10px",
                    borderBottom: "1px solid #000",
                  }}
                  value={'Active'}
                >
                  Active
                </MenuItem>
                <MenuItem
                  style={{
                    fontSize: "13px",
                    margin: "10px",
                    borderBottom: "1px solid #000",
                  }}
                  value={'Inactive'}
                >
                  Inactive
                </MenuItem>



              </Select>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "10px 0px 0px 352px",
            width: "227px",
            height: "41px",
          }}
          className={styles.Button}
        >
          {" "}
          <button
            disabled={loading}
            className="proceed-btn"
            onClick={updateTax}
          >
            {loading ? (
              <CircularProgress
                style={{
                  width: "30px",
                  color: "#fff",
                  margin: "3px 95px",
                  height: "30px",
                }}
              />
            ) : (
              "Update"
            )}
          </button>
        </div>
      </div> : <Loader />}
    </div>
  );
};

export default EditTax;
