// components/user/Button.js
import React, { useEffect, useState } from "react";
import { useNode } from "@craftjs/core";
import ContentEditable from "react-contenteditable";
import { Button } from "@mui/material";
import Container from "./DragableContainer";
export const MButton = ({ size, variant, color, children, text, fontSize, textColor }) => {
  const {
    connectors: { connect, drag },
    hasSelectedNode,
    hasDraggedNode,
    actions: { setProp },
  } = useNode((state) => ({
    hasSelectedNode: state.events.selected.size > 0,
    hasDraggedNode: state.events.dragged.size > 0,
  }));
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    !hasSelectedNode && setEditable(false);
  }, [hasSelectedNode]);
  return (
    <Container>
      <Button
        ref={(ref) => connect(drag(ref))} onClick={(e) => setEditable(true)}
        size={size}
        variant={variant}
        color={color}
        style={{width:"100%", height:"100%"}}
      >
        <ContentEditable
          disabled={!editable}
          html={text}
          onChange={(e) =>
            setProp(
              (props) =>
                (props.text = e.target.value.replace(/<\/?[^>]+(>|$)/g, ""))
            )
          }
          tagName="button"
          style={{ fontSize: `${fontSize}px`, background: "transparent", boxShadow: "nonw", border: "none", color: textColor, cursor: "pointer" }}
        />
        {children}
      </Button>
    </Container>
  );
}
export default MButton;
