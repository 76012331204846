import React, { useEffect, useState } from "react";
import styles from "./Contact.module.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { IoMdPricetags } from "react-icons/io";
import { addContact } from "../../Services/api";
import { Badge, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { GiCancel } from "react-icons/gi";
import { AiOutlinePlusCircle } from "react-icons/ai";

const validationSchema = yup.object().shape({
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  email: yup.string().email().required("Email is required."),
  phone_no: yup.number().required("Phone Number is required."),
  organization: yup.string().required("Organization is required."),
  timezone: yup.string().required("Time-zone is required."),
});
function AddContact() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tag, setTag] = useState([]);
  console.log("tag==>", tag);
  const [value, setValue] = useState("");
  console.log("value: ", value);
  console.log("data==>", data);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      organization: "",
      timezone: "",
      tags: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values: ", values);
      values.tags = tag;
      setLoading(true);
      addContact(values)
        .then((res) => {
          console.log(res);
          if (res?.message === "Data created successfully") {
            navigate("/user/contact");
            setData(res?.data);
            formik.resetForm();
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });
  const add = (e) => {
    setTag([...tag, value]);
    setValue("");
  };
  const removetag = (id) => {
    const newTag = tag.filter((item) => {
      return id != item;
    });
    setTag(newTag);
  };
  return (
    <form className="add-user-form" onSubmit={formik.handleSubmit}>
      <div className={styles.Funnels}>
        <div className={styles.FunnelsCreate}>
          <h1 className={styles.FunnelsHead}> Create New Contact</h1>
          <div className={styles.Button}>
            <button onClick={() => navigate("/user/contact")}>Discard</button>
            <button type="submit">Save</button>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.input}>
            <div className={styles.inputone}>
              <label htmlFor="first_name">
                First name &nbsp;{" "}
                {formik.errors.first_name && formik.touched.first_name && (
                  <small style={{ color: "red" }}>
                    {formik.errors.first_name}
                  </small>
                )}
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                />
              </label>
              <label htmlFor="last_name">
                Last name &nbsp;{" "}
                {formik.errors.last_name && formik.touched.last_name && (
                  <small style={{ color: "red" }}>
                    {formik.errors.last_name}
                  </small>
                )}
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                />
              </label>
            </div>
            <label htmlFor="email">
              E-mail address &nbsp;{" "}
              {formik.errors.email && formik.touched.email && (
                <small style={{ color: "red" }}>{formik.errors.email}</small>
              )}
              <input
                type="email"
                name="email"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </label>
            <label htmlFor="phone_no">
              Phone number&nbsp;{" "}
              {formik.errors.phone_no && formik.touched.phone_no && (
                <small style={{ color: "red" }}>{formik.errors.phone_no}</small>
              )}
              <input
                type="number"
                name="phone_no"
                id="phone_no"
                value={formik.values.phone_no}
                onChange={formik.handleChange}
              />
            </label>
            <label htmlFor="timezone">
              Time zone&nbsp;{" "}
              {formik.errors.timezone && formik.touched.timezone && (
                <small style={{ color: "red" }}>{formik.errors.timezone}</small>
              )}
              <input
                type="text"
                name="timezone"
                id="timezone"
                value={formik.values.timezone}
                onChange={formik.handleChange}
              />
            </label>
            <label htmlFor="organization">
              Organization&nbsp;{" "}
              {formik.errors.organization && formik.touched.organization && (
                <small style={{ color: "red" }}>
                  {formik.errors.organization}
                </small>
              )}
              <input
                type="text"
                name="organization"
                id="organization"
                value={formik.values.organization}
                onChange={formik.handleChange}
              />
            </label>
          </div>
          <div className={styles.side}>
            <div className={styles.manager}>
              <IoMdPricetags className={styles.icon} /> <h5>Tag Manager</h5>
            </div>
            <p>Add and delete tags on users</p>
            <div className={styles.tags}>
              <input
                type="text"
                name="tags"
                id="tags"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                style={{
                  border: "2px solid black",
                  borderRadius: "5px",
                  marginTop: "4px",
                }}
              />
              <AiOutlinePlusCircle onClick={add} />
            </div>
            {tag?.length > 0 &&
              tag?.map((item) => {
                return (
                  <Badge>
                    {/* {item} */}
                    {/* <button onClick={() => removetag(item)}> delete</button> */}
                    <ListItem
                      disablePadding
                      style={{
                        backgroundColor: "#eff0f8",
                        border: "1px solid #eff0f8",
                        borderRadius: "5px",
                        margin: "5px",
                      }}
                    >
                      <ListItemButton style={{ padding: "0px 3px" }}>
                        <ListItemText primary={item} />
                        <GiCancel
                          style={{ color: "#c93737", marginLeft: "3px" }}
                          onClick={() => removetag(item)}
                        />
                      </ListItemButton>
                    </ListItem>{" "}
                  </Badge>
                );
              })}{" "}
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddContact;
