import { useNode } from "@craftjs/core";
import React, { useRef, useEffect } from "react";
import { ButtonSettings } from "./ButtonSettings";
import { common } from "../../../../BaseUrl";
import usePageBuilder from "../../../../Context/pageBuilder";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Paypal({
  Product,
  prodDetails,
  paypalPriceId,
  dbPriceId,
  productId,
  mode,
  redirectUrl
}) {
  const paypal = useRef();
  const oneTime = useRef();
  const navigate = useNavigate()
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));
  const { funeelIdForPayment } = usePageBuilder()
  console.log("priceId", mode)
  useEffect(() => {
    if (mode == "Onetime") {
      window.paypal.Buttons({
        // Order is created on the server and the order id is returned
         createOrder() {
          const response =  fetch("products/paypalOrder", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // use the "body" param to optionally pass additional order information
            // like product skus and quantities
            body: JSON.stringify({
             priceId:dbPriceId
            }),
          }).then((response) => response.json())
          .then((order) => order.id);
        },
        // Finalize the transaction on the server after payer approval
        async onApprove(data) {
          let bodaydata = {
            "orderID": data.orderID,
            "subscriptionID": data.subscriptionID,
            "facilitatorAccessToken": data.facilitatorAccessToken,
            "paymentSource": data.paymentSource,
            "planId": 'P-34804200KC570304AMR2I4TQ',
            "priceId": dbPriceId,
            "productId": productId,
            "funnelId": funeelIdForPayment?funeelIdForPayment:localStorage.getItem('funelId')
          };
          const response = await fetch("products/paypalOneTimePayment", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodaydata)
          });
          const orderData = await response.json();
          // Successful capture! For dev/demo purposes:
          console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
          const transaction = orderData.purchase_units[0].payments.captures[0];
          alert(`Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`);
        }
      }).render(oneTime.current);
    }
    else {
      window.paypal.Buttons({
        createSubscription: function (data, actions) {
          return actions.subscription.create({
            'plan_id': paypalPriceId
          });
        },
        onApprove: async function (data, actions) {
          console.log(data);
          let bodaydata = {
            "orderID": data.orderID,
            "subscriptionID": data.subscriptionID,
            "facilitatorAccessToken": data.facilitatorAccessToken,
            "paymentSource": data.paymentSource,
            "planId": 'P-34804200KC570304AMR2I4TQ',
            "priceId": dbPriceId,
            "productId": productId,
            "funnelId": funeelIdForPayment?funeelIdForPayment:localStorage.getItem('funelId')
          };
          const response = await fetch(`${common}/products/paypalPayment`, {
            method: 'POST',
            body: JSON.stringify(bodaydata), // string or object
            headers: {
              'Content-Type': 'application/json'
            }
          });
          const myJson = await response.json();
          toast.success('You have successfully subscribed to ' + data.subscriptionID); // Optional message given to subscriber
          const redirectTo = redirectUrl?window.location.protocol + "//"+window.location.host + "/" + redirectUrl:window.location.href
          window.location.href = redirectTo
        }
      }).render(paypal.current);
    }

  }, []);

  return (
    <div ref={connect} >
      <div ref={paypal}></div>
      <div ref={oneTime}></div>
    </div>
  );
}

Paypal.craft = {
  displayName: "Button",
  props: {
    background: { r: 255, g: 255, b: 255, a: 0.5 },
    color: { r: 92, g: 90, b: 90, a: 1 },
    buttonStyle: "full",
    text: "Button",
    margin: ["5", "0", "5", "0"],
    textComponent: {
      // ...Text.craft.props,
      textAlign: "center",
    }
  },
  related: {
    toolbar: ButtonSettings,
  },
};