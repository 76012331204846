import React, { memo, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import { IoIosGitNetwork } from "react-icons/io";
import { CgTemplate } from "react-icons/cg";
import { BsCartCheck, BsQuestionSquareFill, BsThreeDots } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";
import { AiFillFacebook, AiFillGoogleCircle, AiFillInstagram, AiFillYoutube, AiOutlinePlusCircle } from "react-icons/ai";
import face from "../../assets/logo/face.svg"
import google from "../../assets/logo/google.svg"
import other from "../../assets/logo/other.svg"
import organic from "../../assets/logo/organic.svg"
import "./Traffic.css"
import useProvider from "../../Context/Context";
import { FaFacebook, FaGoogle, FaTiktok } from "react-icons/fa";
export default memo(({ id, data, isConnectable }) => {
  const handleDelete = () => {
    data.onDelete(id)
  };
  //console.log("new traffic data=>", data)
  const [currentPage, setCurrentPage] = useState();
  const {
    showTrafficModel,
    setTrafficPayload,
    setEditNodeId,
    setShowTrafficModel,
    setDelId,
    DelId,
    allNodes,
    trafficPayload,
  } = useProvider();
  useEffect(() => {
    if (allNodes.find((item) => item.id == id)) {
      setCurrentPage(allNodes?.filter((item) => item.id == id)[0]);
    }
    // if(allNodes.)
  }, [allNodes]);

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setDelId(id);
        }}
        onDoubleClick={() => {
          setShowTrafficModel(true);
          setEditNodeId(id);
          const savedNode = allNodes.find((item) => item.id == id);
          console.log("savedNode", savedNode);
          if (savedNode?.userData) {
            setTrafficPayload({
              ...savedNode?.userData,
              nodeId: savedNode?.id,
            });
            return;
          } else {
            setTrafficPayload({ ...trafficPayload, nodeId: savedNode?.id });
          }
        }}
      >
        <div
          className={`box-internal-wrapper ${
            id === DelId ? "box-internal-wrapper_active" : ""
          }`}
        >
          <div className="TrafficNode">
            {console.log("urrentPage?.userData: ", currentPage?.userData)}
            {data.label === "Google" && (
              <div className="traffic__Item">
                {currentPage?.userData?.tranficIcon &&
                currentPage?.userData?.tranficIcon?.key ? (
                  currentPage?.userData?.tranficIcon
                ) : (
                  <FaGoogle color="#DB4437 " />
                )}
                <p>
                  {" "}
                  {currentPage?.userData?.trafficSources
                    ? currentPage?.userData?.trafficSources
                    : data.label}
                </p>
              </div>
            )}
            {data.label === "Facebook" && (
              <div className="traffic__Item">
                {currentPage?.userData?.tranficIcon &&
                currentPage?.userData?.tranficIcon?.key ? (
                  currentPage?.userData?.tranficIcon
                ) : (
                  <AiFillFacebook color="#3b5998" />
                )}

                <p>
                  {" "}
                  {currentPage?.userData?.trafficSources
                    ? currentPage?.userData?.trafficSources
                    : data.label}
                </p>
              </div>
            )}
            {data.label === "Tik Tok" && (
              <div className="traffic__Item">
                {currentPage?.userData?.tranficIcon &&
                currentPage?.userData?.tranficIcon?.key ? (
                  currentPage?.userData?.tranficIcon
                ) : (
                  <FaTiktok color="#000000" />
                )}
                <p>
                  {" "}
                  {currentPage?.userData?.trafficSources
                    ? currentPage?.userData?.trafficSources
                    : data.label}
                </p>
              </div>
            )}
            {data.label === "You Tube" && (
              <div className="traffic__Item">
                {currentPage?.userData?.tranficIcon &&
                currentPage?.userData?.tranficIcon?.key ? (
                  currentPage?.userData?.tranficIcon
                ) : (
                  <AiFillYoutube color="#FF0000" />
                )}
                <p>
                  {" "}
                  {currentPage?.userData?.trafficSources
                    ? currentPage?.userData?.trafficSources
                    : data.label}
                </p>
              </div>
            )}
            {data.label === "Instagram" && (
              <div className="traffic__Item">
                {currentPage?.userData?.tranficIcon &&
                currentPage?.userData?.tranficIcon?.key ? (
                  currentPage?.userData?.tranficIcon
                ) : (
                  <AiFillInstagram color="#4f5bd5" />
                )}
                <p>
                  {" "}
                  {currentPage?.userData?.trafficSources
                    ? currentPage?.userData?.trafficSources
                    : data.label}
                </p>
              </div>
            )}
            {data.label === "Others" && (
              <div className="traffic__Item">
                {currentPage?.userData?.tranficIcon &&
                currentPage?.userData?.tranficIcon?.key ? (
                  currentPage?.userData?.tranficIcon
                ) : (
                  <BsQuestionSquareFill />
                )}
                <p>
                  {" "}
                  {currentPage?.userData?.trafficSources
                    ? currentPage?.userData?.trafficSources
                    : data.label}
                </p>
              </div>
            )}
            {data.label === "Organic" && (
              <div className="traffic__Item">
                {currentPage?.userData?.tranficIcon &&
                currentPage?.userData?.tranficIcon?.key ? (
                  currentPage?.userData?.tranficIcon
                ) : (
                  <img src={organic} alt="" />
                )}
                <p>
                  {" "}
                  {currentPage?.userData?.trafficSources
                    ? currentPage?.userData?.trafficSources
                    : data.label}
                </p>
              </div>
            )}

            <AiOutlinePlusCircle />
          </div>
        </div>
      </div>
      <Handle
        id="traffic_source"
        type="source"
        position={Position.Right}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
        className="trafficHandle"
      />
    </>
  );
});
