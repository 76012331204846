import axios from 'axios'
import React, { createContext, useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BaseUrl, { common } from '../BaseUrl'
import { getPageCategories, getPages, getProducts } from '../Services/api'
import { toast } from 'react-toastify'
import Aos from 'aos'

const GlobalStorage = createContext();
export const DataProvider = ({ children }) => {


    const navigate = useNavigate()
    const [authToken, setAuthToken] = useState(null)
    const [allProducts, setAllProducts] = useState([])
    const [ProdPayload, setProdPayload] = useState({
        name: "",
        description: "",
        product_type: "",
        image: ""
    })
    const [allPrice, setAllPrice] = useState([])
    const [PricePayload, setPricePayload] = useState({
        productId: "",
        price_plan: "Onetime",
        amount: "",
        compare_amount: "",
        price_name: "",
        product_cost: "",
        interval: "",
        interval_price: "",
        is_trial: false,
        trial_type: "Free",
        trial_days: "",
        trial_amount: "",
        setup_fee: "",
        is_visible: false,
        trial_interval: ""
    })
    //console.log("prod payLoad=>", ProdPayload)
    const [expencespayLLoad, setExpencsPayLoad] = useState([])
    const [openModel, setOpenModel] = useState(false)
    const [choosePagemodel, setChooseModel] = useState(false)
    const [pageColor, setPageColor] = useState("white")
    const [showNport, setShwpNport] = useState(true)
    const [fitScreen, setFitScreen] = useState(false)
    const [isNewOldData, setIsOldData] = useState(false)
    const [showCalculation, setShowCalculation] = useState(true)
    const [showTrafficModel, setShowTrafficModel] = useState(false)
    const [copyNode, setCopyNode] = useState()
    const [allFunnels, setAllFunnels] = useState([])
    const [allNodes, setAllNodes] = useState()
    const [DelId, setDelId] = useState("")
    const [editNodeId, setEditNodeId] = useState('')
    const [showSummryModel, setShowSummryModel] = useState(false)
    const [showAddProductModel, setShowAddProductModel] = useState(false)
    const [showProductModel, setShowProductModel] = useState(false);
    const [showAddTrafficModel, setShowAddTrafficModel] = useState(false);
    const [showMainTrafficModel, setShowMainTrafficModel] = useState(false);
    const [ExpencesModel, setShowExpencesModel] = useState(false);
    const [AddExpencesModel, setShowAddExpencesModel] = useState(false);
    const [SettingsModel, setShowSettingsModel] = useState(false);
    const [SettingsModel2, setShowSettingsModel2] = useState(false);
    const [createProduct, setShowCreateProduct] = useState(false);
    const [addPriceModel, setShowAddPriceModel] = useState(false);
    const [assetsModel, setShowaAssetsModel] = useState(false);
    const [AddassetsModel, setShowaAddAssetsModel] = useState(false);
    const [EditOnePriceModel, SetEditOnePriceModel] = useState(false);
    const [EditSubPriceModel, SetEditSubPriceModel] = useState(false);
    const [EditPayPriceModel, SetEditPayPriceModel] = useState(false);
    const [pageDrawer, SetPageDrawer] = useState(false);
    const [EditProdWar, SetEditProdWar] = useState(false);
    const [pageLabel, setPageLabel] = useState("")
    const [onlyShowWar, setOnlyShowWar] = useState("")
    const [selectedSummary, setSelectedSummary] = useState([])
    const [canvasSettings, setCanvasSettings] = useState({
        processingRate: 10,
        transactionFee: 1
    })
    const [edgeSelected, setEdgeSelected] = useState()
    const [genId, setGenId] = useState(0)
    const [prodCost, setProdCost] = useState(0)
    const [dropedNodeName, setDropeedNodeName] = useState("")
    const [newTrafficPayload, setNewTrafficPayload] = useState([])
    const [allAssests, setAllAssets] = useState([])
    const [allPages, setAllPages] = useState([])
    const [nodesSelected, seNodesSelected] = useState()
    const [selectedProd, setSelectedProd] = useState([{ ...allProducts?.[0], split_rate: "100" }])
    const [settingPayload, setSettingPayload] = useState({
        page_name: "",
        checkout_rate: "",
        is_home: false,
        n_port: true,
        notes: "",
        object_Name: "",
        theme_Color: "",
        url: "",
        thumnail: ""
    })

    const initTrafficValue = {
        campaignName: "",
        entryPoint: null,
        trafficSources: null,
        monthlyVisitor: 0,
        acquisitionType: "Clicks",
        cpc: 0,
        conversionStrength: 0,
    };
    const [isSimulate, setIsSimulate] = useState(false);
    const [createFunne, setCreateFunnel] = useState(false);
    const [showSimulateData, setShowSimulateData] = useState(false);
    const [simulateData, setSimulateData] = useState(null)
    const [trafficPayload, setTrafficPayload] = useState(initTrafficValue);
    const [allPageCategories, setAllPageCategories] = useState([])
    const [pageByCategory, setPageByCategory] = useState([])
    const clearFunnel = () => {
        setIsSimulate(false);
        setShowSimulateData(false);
        setSimulateData([]);
        setTrafficPayload(initTrafficValue);
        setEdgeSelected();
        setSettingPayload({
            page_name: "",
            checkout_rate: "",
            is_home: false,
            n_port: true,
            notes: "",
            object_Name: "",
            theme_Color: "",
            url: "",
            thumnail: "",
        });
        setExpencsPayLoad([]);
        setSelectedProd([{ ...allProducts?.[0], split_rate: "100" }]);
        setAllNodes()
    };
    const pathname = useLocation().pathname
    //all page Categories
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/");
        }
    }, [pathname]);

    const saveFunnel = (content, name, last_node) => {
        const { nodes, edges } = content;
        // //console.log("nodes=>", nodes, "name=>", edges)
        if (name?.length <= 0) {
            return toast.warn('Please enter funnel name')
        }

        axios({
            method: "POST",
            url: `${common}/funnel/create`,
            data: {
                content: {
                    nodes: [],
                    edges: []
                },
                name: name,
                last_node: last_node,
                status: "Draft"

            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`

            }
        }).then(responce => {
            toast.success('funnel added')
            console.log("responce=>", responce.data)
            navigate("/war-room", { state: { id: responce.data?.data?._id, isNew: true, status: responce.data?.data?.status, url: "" } });
            setCreateFunnel(false)
        })
    }

    const makeFunnelurl = (data) => {
        let pageData = data?.nodes?.find(item => item?.userData?.is_home === true)?.pageData

        const url = window.location.protocol + "//" + window.location.host + `/${pageData?.url}`
        console.log("content", pageData)

        return [url, pageData._id]

    }

    const updateFunnel = async (content, funnelId, name, lastNode, status) => {
        axios({
            method: "PUT",
            url: `${common}/funnel/update/${funnelId}`,
            data: {
                content: content,
                name: name,
                last_node: lastNode,
                status: status
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(responce => {

            toast.success("Funnel updated successfully")

        })
    }
    const PublishFunnel = async (content, funnelId, name, lastNode, status) => {


        let url = await makeFunnelurl(content)
        console.log("pagedataUrl", url)
        axios({
            method: "PUT",
            url: `${common}/funnel/update/${funnelId}`,
            data: {
                content: content,
                name: name,
                last_node: lastNode,
                status: status,
                url: url?.[0],
                homePage: url?.[1]
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(responce => {
            console.log("responce=>", responce.data?.url)
            window.open(responce.data?.data?.url, '_blank');
            if (responce.data?.data?.status == "Published ") {
                toast.success("Funnel live now")

            }
            else {
                toast.success("Funnel updated successfully")
            }
        })
    }

    return (
        <GlobalStorage.Provider
            value={{
                openModel,
                setOpenModel,
                pageColor,
                setPageColor,
                showNport,
                setShwpNport,
                fitScreen,
                setFitScreen,
                showCalculation,
                setShowCalculation,
                showTrafficModel,
                setShowTrafficModel,
                copyNode,
                setCopyNode,
                saveFunnel,
                allFunnels,
                setAllFunnels,
                DelId,
                setDelId,
                nodesSelected,
                seNodesSelected,
                settingPayload,
                setSettingPayload,
                editNodeId,
                setEditNodeId,
                allNodes,
                setAllNodes,
                allPages,
                setAllPages,
                isNewOldData,
                setIsOldData,
                updateFunnel,
                showSummryModel,
                setShowSummryModel,
                selectedSummary,
                setSelectedSummary,
                showProductModel,
                setShowProductModel,
                showAddProductModel,
                setShowAddProductModel,
                showAddTrafficModel,
                setShowAddTrafficModel,
                genId,
                setGenId,
                edgeSelected,
                setEdgeSelected,
                AddExpencesModel,
                setShowAddExpencesModel,
                ExpencesModel,
                setShowExpencesModel,
                SettingsModel,
                setShowSettingsModel,
                SettingsModel2,
                setShowSettingsModel2,
                createProduct,
                setShowCreateProduct,
                addPriceModel,
                setShowAddPriceModel,
                ProdPayload,
                setProdPayload,
                allProducts,
                setAllProducts,
                initTrafficValue,
                trafficPayload,
                setTrafficPayload,
                assetsModel,
                setShowaAssetsModel,
                AddassetsModel,
                setShowaAddAssetsModel,
                PricePayload,
                setPricePayload,
                allPrice,
                setAllPrice,
                EditOnePriceModel,
                SetEditOnePriceModel,
                EditSubPriceModel,
                SetEditSubPriceModel,
                EditPayPriceModel,
                SetEditPayPriceModel,
                EditProdWar,
                SetEditProdWar,
                pageLabel,
                setPageLabel,
                showMainTrafficModel,
                setShowMainTrafficModel,
                newTrafficPayload,
                setNewTrafficPayload,
                canvasSettings,
                setCanvasSettings,
                expencespayLLoad,
                setExpencsPayLoad,
                selectedProd,
                setSelectedProd,
                prodCost,
                setProdCost,
                allAssests,
                setAllAssets,
                dropedNodeName,
                setDropeedNodeName,
                isSimulate,
                setIsSimulate,
                showSimulateData,
                setShowSimulateData,
                simulateData,
                setSimulateData,
                onlyShowWar,
                setOnlyShowWar,
                pageDrawer,
                SetPageDrawer,
                allPageCategories,
                setAllPageCategories,
                authToken,
                setAuthToken,
                pageByCategory,
                setPageByCategory,
                clearFunnel,
                choosePagemodel, setChooseModel,
                PublishFunnel,
                createFunne, setCreateFunnel
            }}
        >
            {children}
        </GlobalStorage.Provider>
    );
}

export default function useProvider() {
    return useContext(GlobalStorage);
}
