import React, { useState } from 'react'
import useProvider from '../../Context/Context'
import styles from "./AddNewAsset.module.css"
import { RxCross2 } from 'react-icons/rx'
import { FaUpload } from 'react-icons/fa'
import axios from 'axios'
import { common } from '../../BaseUrl'
import { toast } from 'react-toastify'
function loadXHR(url) {

    return new Promise(function (resolve, reject) {
        try {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.onerror = function () { reject("Network error.") };
            xhr.onload = function () {
                if (xhr.status === 200) { resolve(xhr.response) }
                else { reject("Loading error:" + xhr.statusText) }
            };
            xhr.send();
        }
        catch (err) { reject(err.message) }
    });
}
function AddNewAsset() {
    const { AddassetsModel, setShowaAddAssetsModel } = useProvider()
    const [assetName, setAssetName] = useState("")
    const [uploadAsset, setAsset] = useState(null)
    //console.log("AddassetsModel", uploadAsset)
    const addAsset = () => {
        if (assetName.length <= 0) {
            return toast.warning("Please enter asset name")
        }
        const imageUrl = URL.createObjectURL(uploadAsset)
        loadXHR(imageUrl).then(result => {
            axios({
                method: "post",
                url: `${common}/asset/uploadAsset`,
                data: {
                    flle: result,
                    name:assetName
                },
                headers:{
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(res => {
                toast.success("Asset added succsessfully")
            }).catch(errr => {
                toast.error("Something wrong")
            })
        })

    }
    return (
        <div
            onClick={() => setShowaAddAssetsModel(false)}
            className={`${styles.summaryModel} ${AddassetsModel ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Create asset</p>
                    < RxCross2 onClick={() => setShowaAddAssetsModel(false)} />
                </div>

                <div className={styles.inputField}>
                    <p>Asset name</p>
                    <input
                        value={assetName}
                        onChange={(e) => setAssetName(e.target.value)}

                        type="text" />



                </div>
                <div className={styles.inputField2}>
                    <p style={{ marginBottom: "10px" }}>File</p>
                    <div className={styles.upButton}>
                        <div className={styles.UploadButton}>
                            <input onChange={(e) => setAsset(e.target.files[0])} type="file" name="" id="" />
                            <button > <FaUpload /> Upload File</button>
                        </div>
                        {uploadAsset ? <p>{uploadAsset?.name}</p> : <p>No file chosen</p>}
                    </div>
                </div>

                <div style={{ display: "flex", gap: "30px", marginTop: '8px', width: "100%", justifyContent: "flex-end" }}>
                    <button onClick={() => setShowaAddAssetsModel(false)} className='closebuttn'>Close</button>
                    <button onClick={addAsset} className='addbutton'>Add</button>
                </div>
            </div>
        </div>
    )
}

export default AddNewAsset