// components/user/Card.js
import React from "react";
import Text from "../Text";
import Button from "../Button";
import { Element, useNode } from "@craftjs/core";

import { Container } from "../DragableContainer";
import ImageTag from "../imageTag";
// import SButton from './buttons'
import Texts from "../texts";
import Buttons from "../buttons";
// Notice how CardTop and CardBottom do not specify the drag connector. This is because we won't be using these components as draggables; adding the drag handler would be pointless.

export const Testimonial2 = ({ background, padding }) => {
    const {
        connectors: { connect },
    } = useNode();
    return (
        <div ref={connect} canvas>
            <Element canvas id='container' background={{ r: 67, g: 56, b: 143, a: 1 }} is={Container} thirdShadow={20} flexDirection="column" width="100%" height="auto" radius={0} padding={[10, 10, 10, 10]}>
                <Element canvas background={{ r: 256, g: 256, b: 256, a: 0 }} id='container' alignItems='center' margin={[0, 0, 10, 0]} justifyContent='center' is={Container} flexDirection="column" width="100%" height="auto" radius={10} >
                    <Texts color={{ r: 57, g: 132, b: 255, a: 1 }} text="CUSTOMER TESTIMONIAL" margin={[30, 0, 10, 10]} fontSize={18} fontWeight="bold" />
                    <Texts color={{ r: 255, g: 255, b: 255, a: 1 }} text="What Others Are Saying About Us" margin={[0, 0, 10, 0]} fontSize={42} fontWeight="bold" />
                    <Texts color={{ r: 255, g: 255, b: 255, a: 1 }} text="Lorem ipsum dolor sit amet, consectetur adipisicing elit." margin={[0, 0, 10, 0]} fontSize={20} fontWeight="bold" />
                </Element>
                <Element canvas id='container' gap={10} background={{ r: 256, g: 256, b: 256, a: 0 }} is={Container} flexDirection="row" width="100%" height="auto" radius={0} >
                    <Element background={{ r: 256, g: 256, b: 256, a: 0 }} canvas id='container' alignItems='left' is={Container} thirdShadow={15} padding={[30, 15, 10, 10]} flexDirection="column" width="49.5%" height="auto" radius={10} >
                        <ImageTag src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg" height={50} width={50} radius={50} />
                        <Texts textAlign='left' color={{ r: 255, g: 255, b: 255, a: 1 }} text='"This headline gets attention"' margin={[10, 0, 10, 0]} fontSize={'26'} fontWeight="bold" />
                        <Texts textAlign='left' color={{ r: 202, g: 222, b: 255, a: 1 }} text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt." margin={[10, 10, 10, 0]} fontSize={18} fontWeight="regular" />
                        <Texts textAlign='left' color={{ r: 57, g: 132, b: 255, a: 1 }} margin={[25, 0, 25, 0]} text="-NAME" fontSize={16} />
                        <Element canvas id='container' gap={10} background={{ r: 57, g: 132, b: 255, a: 1 }} is={Container} flexDirection="row" width="100%" height="2px" radius={0} ></Element>

                    </Element>
                    <Element background={{ r: 256, g: 256, b: 256, a: 0 }} canvas id='container' alignItems='left' is={Container} thirdShadow={15} padding={[30, 15, 10, 10]} flexDirection="column" width="49.5%" height="auto" radius={10} >
                        <ImageTag src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg" height={50} width={50} radius={50} />
                        <Texts textAlign='left' color={{ r: 255, g: 255, b: 255, a: 1 }} text='"This headline gets attention"' margin={[10, 0, 10, 0]} fontSize={'26'} fontWeight="bold" />
                        <Texts textAlign='left' color={{ r: 202, g: 222, b: 255, a: 1 }} text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt." margin={[10, 10, 10, 0]} fontSize={18} fontWeight="regular" />
                        <Texts textAlign='left' color={{ r: 57, g: 132, b: 255, a: 1 }} margin={[25, 0, 25, 0]} text="-NAME" fontSize={16} />
                        <Element canvas id='container' gap={10} background={{ r: 57, g: 132, b: 255, a: 1 }} is={Container} flexDirection="row" width="100%" height="2px" radius={0} ></Element>

                    </Element>
                </Element>
                <Element canvas id='container' gap={10} background={{ r: 256, g: 256, b: 256, a: 0 }} is={Container} flexDirection="row" width="100%" height="auto" radius={0} margin={[10, 0, 0, 0]}>
                    <Element background={{ r: 256, g: 256, b: 256, a: 0 }} canvas id='container' alignItems='left' is={Container} thirdShadow={15} padding={[30, 15, 10, 10]} flexDirection="column" width="49.5%" height="auto" radius={10} >
                        <ImageTag src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg" height={50} width={50} radius={50} />
                        <Texts textAlign='left' color={{ r: 255, g: 255, b: 255, a: 1 }} text='"This headline gets attention"' margin={[10, 0, 10, 0]} fontSize={'26'} fontWeight="bold" />
                        <Texts textAlign='left' color={{ r: 202, g: 222, b: 255, a: 1 }} text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt." margin={[10, 10, 10, 0]} fontSize={18} fontWeight="regular" />
                        <Texts textAlign='left' color={{ r: 57, g: 132, b: 255, a: 1 }} margin={[25, 0, 25, 0]} text="-NAME" fontSize={16} />
                        <Element canvas id='container' gap={10} background={{ r: 57, g: 132, b: 255, a: 1 }} is={Container} flexDirection="row" width="100%" height="2px" radius={0} ></Element>
                    </Element>
                    <Element background={{ r: 256, g: 256, b: 256, a: 0 }} canvas id='container' alignItems='left' is={Container} thirdShadow={15} padding={[30, 15, 10, 10]} flexDirection="column" width="49.5%" height="auto" radius={10} >
                        <ImageTag src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg" height={50} width={50} radius={50} />
                        <Texts textAlign='left' color={{ r: 255, g: 255, b: 255, a: 1 }} text='"This headline gets attention"' margin={[10, 0, 10, 0]} fontSize={'26'} fontWeight="bold" />
                        <Texts textAlign='left' color={{ r: 202, g: 222, b: 255, a: 1 }} text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt." margin={[10, 10, 10, 0]} fontSize={18} fontWeight="regular" />
                        <Texts textAlign='left' color={{ r: 57, g: 132, b: 255, a: 1 }} margin={[25, 0, 25, 0]} text="-NAME" fontSize={16} />
                        <Element canvas id='container' gap={10} background={{ r: 57, g: 132, b: 255, a: 1 }} is={Container} flexDirection="row" width="100%" height="2px" radius={0} ></Element>

                    </Element>
                </Element>



            </Element>
        </div>
    );
};
Testimonial2.craft = {
    displayName: 'Testimonial2',
    rules: {
        canDrag: () => true,
    },
};
export default Testimonial2;
