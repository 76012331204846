import React, { useEffect } from 'react'
import Nav from '../../Components/Header/Nav'
import Side from '../../Components/Sidebar/Side'
import styles from "./AfterLogin.module.css"
import { Outlet } from 'react-router-dom'
import AddModel from '../Products/AddModel'
import AddPriceModel from '../Products/AddPriceModel'
import AssetsModel from '../Products/AssetsModel'
import AddNewAsset from '../Products/AddNewAsset'
import EditPayPrice from '../Products/EditPayPrice'
import EditSubPrice from '../Products/EditSubPrice'
import EditOneTime from '../Products/EditOneTime'
import CreateFunne from '../NewWarRoom/models/CreateFunne'

function AfterLogin() {
 
  return (
    <div>
      <AddModel/>
      <AddPriceModel/>
      <AssetsModel/>
      <AddNewAsset/>
      <EditPayPrice/>
      <EditSubPrice/>
      <EditOneTime/>
      <CreateFunne/>
        <Nav/>
        <div className={styles.DashBoard}>
            <Side/>
          <div style={{width:"100%", padding:"10px 0px 10px 12px", overflowY:"auto",maxHeight:"100vh"}}>
          <Outlet/>
          </div>
        </div>
    </div>
  )
}

export default AfterLogin