import React from 'react'
import { Text } from 'react-konva'
import Texts from './texts'

function LandingPage() {
    return (
        <div>

            <div class="w3-top">
                <div class="w3-bar w3-white w3-padding w3-card" style={{letterSpacing:"4px"}}>
                    <a href="#home" class="w3-bar-item w3-button">Gourmet au Catering</a>

                    <div class="w3-right w3-hide-small">
                        <a href="#about" class="w3-bar-item w3-button">About</a>
                        <a href="#menu" class="w3-bar-item w3-button">Menu</a>
                        <a href="#contact" class="w3-bar-item w3-button">Contact</a>
                    </div>
                </div>
            </div>
            <header class="w3-display-container w3-content w3-wide" style={{maxWidth:"1600px",minWidth:"500px"}} id="home">
                {/* <img class="w3-image" src="/w3images/hamburger.jpg" alt="Hamburger Catering" width="1600" height="800"/> */}
                <div class="w3-display-bottomleft w3-padding-large w3-opacity">
                    <h1 class="w3-xxlarge">Le Catering</h1>
                </div>
            </header>
            <div class="w3-row w3-padding-64" id="about">
                <div class="w3-col m6 w3-padding-large w3-hide-small">
                    <img src="https://img.freepik.com/premium-photo/image-colorful-galaxy-sky-generative-ai_791316-9864.jpg?w=2000" class="w3-round w3-image w3-opacity-min" alt="Table Setting"/>
                </div>

                <div class="w3-col m6 w3-padding-large">
                    <h1 class="w3-center">About Catering</h1><br/>
                        <h5 class="w3-center">Tradition since 1889</h5>
                        <p class="w3-large">The Catering was founded in blabla by Mr. Smith in lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute iruredolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.We only use <span class="w3-tag w3-light-grey">seasonal</span> ingredients.</p>
                        <p class="w3-large w3-text-grey w3-hide-medium">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
                <Texts
                text='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Praesentium, repellat? Impedit suscipit enim iusto corrupti libero voluptate optio. Harum cumque nam voluptas corporis repellendus eligendi nisi, ipsum suscipit aspernatur totam.'
                ></Texts>
            </div>

        </div>
    )
}

export default LandingPage