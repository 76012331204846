import { UserComponent, useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ButtonSettings } from './ButtonSettings';

import Text from '../texts';
import { Button } from '@mui/material';
import Container from '../DragableContainer';
import usePageBuilder from '../../../../Context/pageBuilder';

// type ButtonProps = {
//   background?: Record<'r' | 'g' | 'b' | 'a', number>;
//   color?: Record<'r' | 'g' | 'b' | 'a', number>;
//   buttonStyle?: string;
//   margin?: any[];
//   text?: string;
//   textComponent?: any;
// };

const StyledButton = styled.button`
  background: ${(props) =>
    props?.background && props.buttonStyle === "full"
      ? `rgba(${Object.values(props?.background)})`
      : "transparent"};
  border: 2px solid transparent;
  border-color: ${(props) =>
    props?.background && props.buttonStyle === "outline"
      ? `rgba(${Object.values(props.background)})`
      : "transparent"};
  margin: ${({ margin }) => margin &&
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
`;

export const Buttons = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));


  const { setOpenPopup, setAnimationType } = usePageBuilder()
  const { text, textComponent, color, buttonType, TimingFunction, animationDuration, animationType, animationDelay, animateOnce, texttranform,
    shadow,
    firstShadow,
    secondShadow,
    thirdShadow,
    radius,
    priceIdStripe,
    productId,
    dbPriceId,

    ...otherProps } = props;

  useEffect(() => {
    setAnimationType(animationType)
  }, [animationType, TimingFunction, animationDuration, animationDelay, animateOnce])

  console.log('animationDuration', radius)

  return (

    <Button

      data-aos={`${animationType?.toString()}`}
      data-aos-offset="10"
      data-aos-delay={`${animationDelay * 10}`}
      data-aos-easing={`${TimingFunction ? TimingFunction?.toString() : 'linear'}`}
      data-aos-duration={`${animationDuration * 10}`}
      data-aos-once={`${animateOnce === "yes" ? true : false}`}
      href={props.clickType === 'redirect' && props.link}
      target={props.target === 'yes' && '_blank'}
      type={buttonType}
      onClick={() => {
        if (props.clickType === 'openpopup') {
          setOpenPopup(true)
        }
        if (props.clickType === 'closepopup') {
          setOpenPopup(false)
        }
        if (props?.handleStripe) {
          props?.handleStripe()
        }
        if (props?.handleSChargebee) {
          props?.handleSChargebee()
        }
      }}
      ref={connect}
      className={cx([
        'rounded w-full px-4 py-2 aos-item',
        {
          'shadow-lg': props.buttonStyle === 'full',
        },
      ])}
      style={{
        background:
          props?.background && props.buttonStyle === "full"
            ? `rgba(${Object.values(props?.background)})`
            : "transparent",
        border: '2px solid transparent',
        borderColor:
          props?.background && props.buttonStyle === "outline"
            ? `rgba(${Object.values(props.background)})`
            : "transparent",
        margin: `${props.margin[0]}px ${props.margin[1]}px ${props.margin[2]}px ${props.margin[3]}px`,
        width: props.width && props.width,
        borderRadius: radius && `${radius}px`,
        boxShadow:
          shadow && shadow === 0
            ? "none"
            : `${firstShadow}px ${secondShadow}px ${thirdShadow}px ${shadow}px rgba(0, 0, 0, .4)`,

        textTransform: texttranform && texttranform
      }}
      {...otherProps}
    >
      <Text noContainer {...textComponent} text={text} color={props.color} />
    </Button>

  );
};

Buttons.craft = {
  displayName: "Button",
  props: {
    background: { r: 255, g: 255, b: 255, a: 0.5 },
    color: { r: 92, g: 90, b: 90, a: 1 },
    buttonStyle: "full",
    text: "Button",
    margin: ["5", "0", "5", "0"],
    textComponent: {
      // ...Text.craft.props,
      textAlign: "center",
    },
    height: "",
    width: "",
    texttranform: "none"
  },
  related: {
    toolbar: ButtonSettings,
  },
};
export default Buttons;