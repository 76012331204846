// components/user/Card.js
import React from "react";
import { Element, useNode } from "@craftjs/core";
import DragableContainer from "../DragableContainer"
import ImageTag from "../imageTag";
import Buttons from "../buttons";
// Notice how CardTop and CardBottom do not specify the drag connector. This is because we won't be using these components as draggables; adding the drag handler would be pointless.


export const Card = ({ background, padding }) => {
  const {
    connectors: { connect },
  } = useNode();
  return (
    <div ref={connect} canvas>
      <Element
        id='navbar'
        canvas
        is={DragableContainer}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        fillSpace={'no'}
        padding={['0', '0', '0', '0']}
        margin={['0', '0', '0', '0']}
        background={{ r: 0, g: 0, b: 0, a: 0 }}
        color={{ r: 0, g: 0, b: 0, a: 1 }}
        shadow={"3px"}
        firstShadow={'4px'}
        secondShadow={'4px'}
        thirdShadow={'4px'}
        radius={0}
        width={'100%'}
        height={'50px'}
        border={"1px soild black"}

      >
        <ImageTag height={"50px"} width={"50px"} borderadius={'50%'} src={'https://images.pexels.com/photos/170809/pexels-photo-170809.jpeg'} />

        <Element
          canvas
          is={DragableContainer}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          fillSpace={'no'}
          padding={['0', '0', '0', '0']}
          margin={['0', '0', '0', '0']}
          background={{ r: 0, g: 0, b: 0, a: 0 }}
          shadow={"3px"}
          firstShadow={'4px'}
          secondShadow={'4px'}
          thirdShadow={'4px'}
          radius={0}
          width={'auto'}
          height={'100%'}
          gap={30}
        >
          <Buttons
            dataaos="flip-up"
            text={'Home'}
            radius={'30px'}
            width={'100px'}
            background={{ r: 231, g: 215, b: 19, a: 1 }}
          />
          <Buttons width={'100px'}
            text={'About'}
            radius={'30px'}
            background={{ r: 231, g: 215, b: 19, a: 1 }}
          />
          <Buttons width={'130px'}
            text={'Contact Us'}
            radius={'30px'}
            background={{ r: 231, g: 215, b: 19, a: 1 }}
          />
          <Buttons width={'100px'}
            text={'Gallery'}
            radius={'30px'}
            background={{ r: 231, g: 215, b: 19, a: 1 }}
          />
          <Buttons width={'100px'}
            text={'Blog'}
            radius={'30px'}
            background={{ r: 231, g: 215, b: 19, a: 1 }}
          />
        </Element>
      </Element>
    </div>
  );
};
Card.craft = {
  displayName: 'Cards',
  rules: {
    canDrag: () => true,
  },
};
export default Card;
