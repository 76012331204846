export const SET_PRODUCT_PROPS = "SET_PRODUCT_PROPS";
export const SET_IS_EDITING = "SET_IS_EDITING"
export const CHANGE_IMAGE = "CHANGE_IMAGE"
export const RESET_PROD_PROPS = "RESET_PROD_PROPS"


export const setProductProps = data => ({
  type: SET_PRODUCT_PROPS,
  payload: data
})

export const setIsEditing = data => ({
  type: SET_IS_EDITING,
  payload: data
})
export const changeImage = data => ({
  type: CHANGE_IMAGE,
  payload: data
})
export const resetProdProps = data => ({
  type: RESET_PROD_PROPS,
  payload: data
})
