import React from 'react';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { useStoreApi, useReactFlow } from 'reactflow';
import "./WarRoom.css"
import useProvider from '../../Context/Context';

export default (props) => {
    const store = useStoreApi();
    const { zoomIn, zoomOut, setCenter } = useReactFlow();
    const {setFitScreen } = useProvider()
    const focusNode = () => {
        const { nodeInternals } = store.getState();
        const nodes = Array.from(nodeInternals).map(([, node]) => node);

        if (nodes.length > 0) {
            const node = nodes[0];

            const x = (node.position.x + window.innerWidth)*2;
            const y = node.position.y + node.height / 2;
            const zoom = 1;

            setCenter(window.innerWidth/2.1, window.innerHeight/3, { zoom, duration: 1000 });
        }
    };

    return (
        <div className='controls_body' style={{left:`${props.showAside?"17%":"24px"}`}}>


            <div onClick={zoomIn} className="custom_control_icon">
                <AiOutlineZoomIn color="black" />
            </div>
            <div onClick={zoomOut} className="custom_control_icon">
                <AiOutlineZoomOut color="black" />
            </div>
            <div onClick={focusNode} className="custom_control">
                Reset zoom
            </div>
            {/* <div onClick={()=>setFitScreen(true)} className="custom_control">
                Fit on screen
            </div> */}

        </div>
    );
};