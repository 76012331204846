import React, { useEffect, useState } from "react";
import "./EditNode.css";
import useProvider from "../../../Context/Context";
import { AiFillInfoCircle } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { ImInfo } from "react-icons/im";
import {
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { useUpdateNodeInternals } from "reactflow";
import { ORDER_FORM, SALES_PAGE } from "../../../Configs";
import { NavLink } from "react-router-dom";

function EditNode({ nodes, saveNodeData, setOpenModel, openModel, funnelName, content, genId, saveFunnel
  , onConnect }) {
  const updateNodeInternals = useUpdateNodeInternals();
  const [activeTab, setActiveTab] = useState("settings");
  const [homePage, setHomepage] = useState(false);
  const [selectedColor, setSelectedColor] = useState(-1);
  const {
    pageColor,
    setPageColor,
    showNport,
    setShwpNport,
    settingPayload,
    setSettingPayload,
    customizePayload,
    setCustomizePayload,
    optionsPayload,
    setOptionsPayload,
    editNodeId,
    setEditNodeId,
    pageLabel,
    allProducts,
    selectedProd, setSelectedProd,
    nodesSelected,
    choosePagemodel,
    setChooseModel
  } = useProvider();
  const [selectedPrice, setSelectedPrice] = useState("")
  const [rate, setRate] = useState([]);
  //console.log('rate: ', rate);
  const [ProdValue, setProdValue] = useState();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if (nodesSelected?.products && nodesSelected?.products?.length > 0){
      setSelectedProd(nodesSelected?.products);

    } else if (allProducts?.length > 0 ) {
      console.log('callallProducts: ');
      let product = allProducts[0];
      product.price =
        allProducts?.[0]?.prices?.length > 0 && allProducts?.[0].prices[0];
      product.split_rate = 100;
      // setSelectedProd([product]);
    }
  }, [allProducts?.length, nodesSelected?.products]);
  console.log('selectedProd: ', selectedProd);
  // useEffect(()=>{
  //   if(selectedProd?.length<=)
  //   setSelectedProd([...selectedProd,{...selectedProd, split_rate:100/selectedProd?.length}])
  // },[selectedProd])
  //console.log("settings payload=>", allProducts);
  const colors = [
    "#FF0000",
    "#00FFFF",
    "#0000FF",
    "#000000",
    "#800080",
    "#FFFF00",
  ];
  //console.log(selectedColor);
  // useEffect(() => {
  //   if (settingPayload) {
  //     saveNodeData(editNodeId, settingPayload);
  //   }
  // }, [settingPayload]);
  const [call, setCall] = useState(true)
  useEffect(() => {
    if (selectedProd?.length > 0) {
      let defaultPer = 100 / selectedProd?.length;
      selectedProd?.map((obj, i) => {
        obj.split_rate = Math.round(defaultPer);
        selectedProd[i] = obj;
        setSelectedProd(selectedProd);
        // document.getElementById()
      });
      setRate(selectedProd?.map((obj) => obj?.split_rate))
    }
  }, [call, selectedProd?.length]);
  console.log("selected id=>", nodesSelected)
  const addProduct = () => {
    if (selectedProd?.length === 5) {
      return;
    }
    // if (ProdValue) {
    //console.log("selected id=>", nodesSelected)
    const target = nodes?.find(item => item?.data?.label === ORDER_FORM)
    const source = nodes?.find(item => item?.data?.label === SALES_PAGE)



    allProducts?.map((product, i) => {
      if (!selectedProd?.find(obj => obj.id === product.id)) {
        let defaultPer = 100 / (selectedProd?.length + 1);
        product["split_rate"] = Math.round(defaultPer);
        let data = []
        selectedProd.map(obj => {
          obj["split_rate"] = Math.round(defaultPer);
          if (!product?.price && product?.prices?.length > 0) {
            product["price"] = product?.prices[0];
          }
          data.push(obj);
        });
        console.log('product: ', product);
        data.push(product);
        setSelectedProd(data);
        setCall(!call)
        // return
      }
    });
    // setProdValue("");

    const _id = source?.id
    //console.log("addPrd", _id)
    const params = {
      animated: false,
      source: _id,
      sourceHandle: selectedProd?.[selectedProd?.length - 1]?._id,
      target: target?.id,

    }
    onConnect(params)

    // }
  };

  const handleChange = (e, item, index) => {
    let value = +e.target.value;
    let data = selectedProd
    data[index]["split_rate"] = value;
    let allValue = 0;
    if (value <= 100) {
      data.map((obj, i) => {
        if (value === 100) {
          if (index === i) {
            document.getElementById(obj.id).value = value
            data[i]["split_rate"] = value;
          } else {
            document.getElementById(obj.id).value = 0

            data[i]["split_rate"] = 0;
          }
        } else {
          if (index === i) {
            data[i]["split_rate"] = value;
          } else {
            allValue =
              allValue +
              (data[i]?.split_rate ? data[i]?.split_rate : 0);
          }
        }
      });
      console.log('allValue: ', allValue);
      if (allValue + value > 100) {
        let defaultPer = (100 - value) / (data?.length - 1);
        console.log('defaultPer: ', defaultPer);
        data.map((obj, i) => {
          if (index !== i) {
            data[i]["split_rate"] = Math.round(defaultPer);
            document.getElementById(obj.id).value = Math.round(defaultPer);
          }
        });
      }

      //  setSelectedProd([]);

      setTimeout(() => {

        setSelectedProd(data);
        let ele = document.getElementById(selectedProd[index]?.id);
        ele.value = value;

        //       setTimeout(()=>{
        //  console.log("ele: ", ele);
        //  if (ele) {
        //    ele.focus();
        //    ele.value = value;
        //  }
        //       },100)

      }, 1);
    }
  };
  const handleDelete = (id) => {
    let defaultPer = 100 / (selectedProd?.length - 1);
    let data = [];
    selectedProd
      .filter((item) => {
        return item.id !== id;
      })
      .map((obj) => {
        obj["split_rate"] = Math.round(defaultPer);
        data.push(obj);
      });
    setSelectedProd(data);
  };

  return (
    <div
      className={`modal ${openModel ? "showModel" : ""}`}
      onClick={() => setOpenModel(false)}
    >
      <div onClick={(e) => e.stopPropagation()} className="modal-content">
        <div className="top_head">
          <div className="modal-tabs">
            <button
              className={`modal-tab ${activeTab === "settings" ? "active" : ""
                }`}
              onClick={() => handleTabClick("settings")}
            >
              Settings
            </button>
            <button
              className={`modal-tab ${activeTab === "options" ? "active" : ""}`}
              onClick={() => handleTabClick("options")}
            >
              Options
            </button>
            <button
              className={`modal-tab ${activeTab === "customize" ? "active" : ""
                }`}
              onClick={() => handleTabClick("customize")}
            >
              Customize
            </button>
            <button
              className={`modal-tab`}
              onClick={() => setChooseModel(true)}
            >
              Add Page
            </button>
            {/* <NavLink
              to={{
                pathname:`/page-render/${nodesSelected?.[0]?.pageId}`,
                userProps:{nodedata:nodesSelected}
              }}
              target="_blank"
              className={`modal-tab ${activeTab === "customize" ? "active" : ""
                }`}

            >
              Preview
            </NavLink> */}
          </div>
          <div className="closeCross">
            <RxCross2 onClick={() => setOpenModel(false)} />
          </div>
        </div>

        <div className="modal-body">
          {activeTab === "settings" && (
            <div>
              <div className="InputFields">
                <p>Page Name</p>
                <input
                  value={settingPayload?.page_name}
                  onChange={(e) =>
                    setSettingPayload({
                      ...settingPayload,
                      page_name: e.target.value,
                    })
                  }
                  type="text"
                  name=""
                  id=""
                />
              </div>
              <div className="InputFields">
                <p> Checkout Rate (%)</p>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Industry Average Conversion Rate Data
                      </Typography>
                    </React.Fragment>
                  }
                  arrow
                  placement="top"
                >
                  <IconButton>
                    <ImInfo
                      // style={{
                      //   position: "absolute",
                      //   left: "42.5%",
                      //   cursor: "pointer",
                      // }}
                      size={15}
                    />
                  </IconButton>
                </Tooltip>
                <input
                  value={settingPayload?.checkout_rate}
                  onChange={(e) =>
                  {
                    console.log('e.target.value',e.target.value)
                    setSettingPayload({
                      ...settingPayload,
                      checkout_rate: e.target.value <= 100 ? e.target.value : 100,
                    })
                  }
                  }
                  max={100}
                  type="number"
                  name=""
                  id=""
                  pattern="[1-9]"
                />
              </div>
              <div className="InputFields">
                <p>Home Page</p>

                <div>
                  <div
                    className={`checkbox1 ${settingPayload?.is_home && "checkbox--on"
                      }`}
                    onClick={() => {
                      setSettingPayload({
                        ...settingPayload,
                        is_home: !settingPayload?.is_home,
                      });
                    }}
                  >
                    <div className="checkbox__ball"></div>
                    <span className="checkbox__text">
                      {settingPayload?.is_home ? "ON" : "OFF"}
                    </span>
                  </div>
                </div>
              </div>
              {pageLabel === SALES_PAGE && (
                <div className="Products_in_war">
                  <h2>Products</h2>
                  <div className="allSelectValueSplit">
                    <p style={{ marginRight: "5px", fontSize: "15px", marginTop: "20px" }}>
                      Sales split
                    </p>
                    {selectedProd?.map((item, index) => {
                      //console.log("selectedProd: ", selectedProd);
                      return (
                        <div className="slaesNumber">
                          <p>{index + 1}</p>
                          <div className="InputPercentage">
                            <input
                              onChange={(e) => handleChange(e, item, index)}
                              value={item?.split_rate}
                              type="number"
                              pattern="[1-9]"
                              id={item.id}
                              autoFocus
                            />
                            <p>%</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="allSelectValue">
                    {selectedProd?.map((select, index) => {
                      return (
                        <div className="innerSelect">
                          <p>{index + 1}</p>
                          <Select
                            // disabled={selectedProd.includes(select?.id)}
                            style={{ backgroundColor: "#fff", fontSize: "8px" }}
                            sx={{
                              "& fieldset": { border: "none", fontSize: "8px" },
                              height: "24px",
                              width: 201.5,
                            }}
                            labelId="demo-select-large"
                            id="demo-select-large"
                            value={select.id}
                            onChange={(e) => {
                              let split_rate = select?.split_rate ? select?.split_rate : 0;
                              let product = allProducts?.filter(
                                (obj) => obj?.id === e.target.value
                              )[0];
                              product["split_rate"] = split_rate;
                              let data = selectedProd;
                              let reload = []
                              selectedProd?.map((obj, i) => {
                                reload.push({})
                              })
                              setSelectedProd(reload);
                              data[index] = product;
                              setTimeout(() => {
                                setSelectedProd(data);
                              }, 50);
                              //console.log("selectedProd1: ", data);
                            }
                            }
                          >

                            {allProducts
                              ?.filter((elem) => {
                                return !selectedProd?.find((element) => {
                                  // return elem?.id === element?.id;
                                  return false;
                                });
                              })
                              ?.map((prod) => {
                                return (
                                  <MenuItem
                                    style={{ fontSize: "8px" }}
                                    name={prod?.id}
                                    value={prod?.id}
                                    disabled={selectedProd?.find((prod1) => prod1.id === prod.id)}
                                  >
                                    {prod?.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>

                          <Select
                            // disabled={selectedProd.includes(select?.id)}
                            style={{ backgroundColor: "#fff", fontSize: "8px" }}
                            sx={{
                              "& fieldset": { border: "none", fontSize: "8px" },
                              height: "24px",
                              width: 201.5,
                            }}
                            labelId="demo-select-large"
                            id="demo-select-large"
                            value={select?.price ? select?.price : ""}
                            onChange={(e, value) => {
                              const prod = selectedProd?.find(product => product?.id === select?.id)
                              // setSelectedProd(prev=> prev.find())
                              console.log("demo-select-large", prod)
                              setSelectedProd(prevItems => {
                                const item = prevItems.find(item => item.id === select?.id)
                                const updatedItem = { ...item, price: e.target.value }
                                const updatedItems = prevItems.map(item => (item.id === select.id ? updatedItem : item))
                                return updatedItems
                              })
                            }}

                          >

                            {select?.prices
                              ?.map((prod) => {
                                return (
                                  <MenuItem
                                    style={{ fontSize: "8px" }}
                                    name={prod?.id}
                                    value={prod}
                                  // disabled={selectedProd?.find((prod1) => prod1.id === prod.id)}
                                  >
                                    {`${prod?.price_plan}($`}{`${prod?.amount})`}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {selectedProd?.length !== 1 && (
                            <Tooltip
                              style={{ padding: "2px" }}
                              title={
                                <React.Fragment>
                                  <Typography fontSize={10} color="inherit">
                                    Delete
                                  </Typography>
                                </React.Fragment>
                              }
                              arrow
                              placement="top"
                            >
                              <IconButton>
                                <RxCross2
                                  onClick={() => handleDelete(select?.id)}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      );
                    })}
                    {selectedProd?.length <= allProducts?.length - 1 &&
                      selectedProd?.length <= 4 && (
                        <button onClick={addProduct}>Add</button>
                      )}
                  </div>
                </div>
              )}

              <div className="modal-footer">
                <button
                  onClick={() => setOpenModel(false)}
                  className="modal-close-button"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    saveNodeData(editNodeId, settingPayload, selectedProd);
                    setOpenModel(false)
                  }}
                  className="modal-close-button2"
                >
                  Save
                </button>
              </div>
            </div>
          )}
          {activeTab === "options" && (
            <div>
              <div className="InputFields2">
                <p>Show N Port</p>
                <div>
                  <div
                    className={`checkbox ${settingPayload?.n_port && "checkbox--on"
                      }`}
                    onClick={() =>
                      setSettingPayload({
                        ...settingPayload,
                        n_port: !settingPayload?.n_port,
                      })
                    }
                  >
                    <div className="checkbox__ball"></div>
                    <span className="checkbox__text">
                      {settingPayload?.n_port ? "ON" : "OFF"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="InputFields">
                <p>Notes</p>
                <textarea
                  value={settingPayload?.notes}
                  onChange={(e) =>
                    setSettingPayload({
                      ...settingPayload,
                      notes: e.target.value,
                    })
                  }
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => setOpenModel(false)}
                  className="modal-close-button"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    saveNodeData(editNodeId, settingPayload, selectedProd);
                    setOpenModel(false)
                  }}
                  className="modal-close-button2"
                >
                  Save
                </button>
              </div>
            </div>
          )}
          {activeTab === "customize" && (
            <div>
              <div className="customize">
                <p>Object Name</p>
                <input
                  value={settingPayload?.object_Name}
                  onChange={(e) =>
                    setSettingPayload({
                      ...settingPayload,
                      object_Name: e.target.value,
                    })
                  }
                  type="text"
                  name=""
                  id=""
                />

                <p>URL</p>

                <input
                  value={settingPayload?.url}
                  onChange={(e) =>
                    setSettingPayload({
                      ...settingPayload,
                      url: e.target.value,
                    })
                  }
                  placeholder="http://"
                  type="text"
                />
                <p></p>
                <input className="filetype" type="file" name="" id="" />

                <p>Thumbnail Library</p>
                <input
                  value={settingPayload?.thumnail}
                  onChange={(e) =>
                    setSettingPayload({
                      ...settingPayload,
                      thumnail: e.target.value,
                    })
                  }
                  placeholder="Search image"
                  type="text"
                  name=""
                  id=""
                />
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => setOpenModel(false)}
                  className="modal-close-button"
                >
                  Cancel
                </button>
                <button
                  className="modal-close-button2"
                  onClick={() => {
                    saveNodeData(editNodeId, settingPayload, selectedProd);

                    setOpenModel(false);
                    setSettingPayload(null);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditNode;
