import React, { useEffect } from 'react';

import { ContainerSettings } from './ContainerSettings';

import { Resizer } from './Resizer';
import { Grid } from '@mui/material';
import usePageBuilder from '../../../../Context/pageBuilder';
import zIndex from '@mui/material/styles/zIndex';
import { width } from '@mui/system';
import { useEditor, useEditorStore, useNode } from '@craftjs/core';

// export type ContainerProps = {
//   background: Record<'r' | 'g' | 'b' | 'a', number>;
//   color: Record<'r' | 'g' | 'b' | 'a', number>;
//   flexDirection: string;
//   alignItems: string;
//   justifyContent: string;
//   fillSpace: string;
//   width: string;
//   height: string;
//   padding: string[];
//   margin: string[];
//   marginTop: number;
//   marginLeft: number;
//   marginBottom: number;
//   marginRight: number;
//   shadow: number;
//   children: React.ReactNode;
//   radius: number;
// };

const defaultProps = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  padding: ['0', '0', '0', '0'],
  margin: ['0', '0', '0', '0'],
  background: { r: 256, g: 256, b: 256, a: 1 },
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  firstShadow: 0,
  secondShadow: 0,
  thirdShadow: 0,
  radius: 0,
  width: '100%',
  height: 'auto',

};

export const Container = (props) => {

  const { bgChoosen, setBgChoosen, mobileView, setOpenPopup, setAnimationType, BgColor, setBgColor } = usePageBuilder()
  props = {
    ...defaultProps,
    ...props,


  };

  const {
    connectors: { connect, drag },
    hasSelectedNode,
    hasDraggedNode,
    selected,
    actions
} = useNode((state) => ({
    hasSelectedNode: state.events.selected.size > 0,
    hasDraggedNode: state.events.dragged.size > 0,
    selected: state,
}));
const { query: {node} } = useEditor();



  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    radius,
    children,
    gap,
    firstShadow,
    secondShadow,
    thirdShadow,
    bgurl,
    visibility,
    Flexproperty,
    position,
    zIndex,
    border,
    minHeight,
    animationType, TimingFunction, animationDuration, animationDelay, animateOnce,
    height,
    bgattachment,
    bgRepeat
  } = props;
  console.log('props: ', background);

  useEffect(() => {
    setAnimationType(animationType)
  }, [animationType, TimingFunction, animationDuration, animationDelay, animateOnce])
  return (
    <Resizer
    
      propKey={{ width: "width", height: "height" }}

      style={{
        justifyContent,
        flexDirection,
        alignItems,
        background: `rgba(${Object.values(background)})`,
        color: color && `rgba(${Object.values(color)})`,
        padding:
          padding &&
          padding?.length >
          0 && `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
        margin:
          margin &&
          margin?.length >
          0 && `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
        boxShadow:
          shadow && shadow === 0
            ? "none"
            : `${firstShadow}px ${secondShadow}px ${thirdShadow}px ${shadow}px rgba(0, 0, 0, .4)`,
        borderRadius: radius && `${radius}px`,
        flex: fillSpace === "yes" ? 1 : "unset",
        gap: gap ? `${gap}px` : '2px',
        flexWrap: Flexproperty ? `${Flexproperty}` : 'wrap',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, ${visibility * 0.01 || 0}), rgba(0, 0, 0, ${visibility * 0.01 || 0})), url(${bgurl})`,
        backgroundSize: '100% 100%',
        position: position ? `${position}` : "relative",
        zIndex: `${zIndex}`,
        border: border && "1px dashed blue",
        minHeight: {minHeight},
        height: {height},
        backgroundAttachment:bgattachment&&bgattachment,
        backgroundRepeat:bgRepeat&&bgRepeat,
        backgroundPosition:"center"





      }}

      id="OuterContainer"
    >


      {children}

    </Resizer>
  );
};

Container.craft = {
  displayName: 'Outer',
  props: defaultProps,
  rules: {
    canDrag: () => true
  },
  related: {
    toolbar: ContainerSettings,
  },
};
export default Container;
