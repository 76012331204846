// components/user/Card.js
import React, { useEffect, useRef, useState } from "react";
import Text from "../Text";
import Button from "../buttons";
import { Element, useEditor, useNode } from "@craftjs/core";

import { Container } from "../DragableContainer";
import ImageTag from "../imageTag";
// import SButton from './buttons'
import Texts from "../texts";
import Buttons from "../buttons";
import { ButtonSettings } from "./ProdSettings";
import usePageBuilder from "../../../../Context/pageBuilder";
import Paypal from "../PaypalButton";
import Stripe from "../StripeButton";
import { common } from "../../../../BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import store from "../../../../redux/store";
import ContentEditable from "react-contenteditable";
// Notice how CardTop and CardBottom do not specify the drag connector. This is because we won't be using these components as draggables; adding the drag handler would be pointless.
var prodInfo = ""
export const CardTop = ({ children }) => {
    const {
        connectors: { connect },
    } = useNode();
    return (
        <div ref={connect} className="text-only">
            {children}
        </div>
    );
};

CardTop.craft = {
    rules: {
        // Only accept Text
        canMoveIn: (incomingNodes) =>
            incomingNodes.every((incomingNode) => incomingNode.data.type === Text),
    },
};

export const CardBottom = ({ children }) => {
    const {
        connectors: { connect },
    } = useNode();
    return <div ref={connect}>{children}</div>;
};

CardBottom.craft = {
    rules: {
        // Only accept Buttons
        canMoveIn: (incomingNodes) =>
            incomingNodes.every((incomingNode) => incomingNode.data.type === Button),
    },
};

export const Product = ({
    Products,
    text,
    productName,
    priceId,
    productDes,
    productCost,
    isChargebee,
    isStripe,
    isPaypal,
    priceIdStripe,
    price_Id,
    mode,
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    padding,
    margin,
    background,
    color,
    shadow,
    firstShadow,
    secondShadow,
    thirdShadow,
    radius,
    width,
    height,
    position,
    Imgsrc,
    fontWeight,
    fontSize,
    productId,
    dbPriceId,
    paypalPriceId,
    productImage,
    redirectUrl
}) => {

    const {
        connectors: { connect, drag },
        hasSelectedNode,
        hasDraggedNode,
        selected,
        actions
    } = useNode((state) => ({
        hasSelectedNode: state.events.selected.size > 0,
        hasDraggedNode: state.events.dragged.size > 0,
        selected: state,
    }));


    console.log("selectedproductId", productId)
    const { prodDetails, setProddetails,isChargebeeshow,setIsChargebee,funeelIdForPayment} = usePageBuilder()
    const paypal = useRef();
    const handleStripe = (id, modeofpayment,dbPriceId,productId) => {
       const redirectTo = redirectUrl?window.location.protocol + "//"+window.location.host + "/" + redirectUrl:window.location.href
        axios({
            method: "post",
            url: `${common}/products/stripeCheckoutSession`,
            data: {
                priceId: [id],
                success_url: redirectTo,
                mode: modeofpayment,
                price:dbPriceId,
                productId:productId,
                funnelId:funeelIdForPayment?funeelIdForPayment:localStorage.getItem('funelId')
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(resp => {
            window.open(resp.data?.data?.url, "");

        }).catch(err => {

            toast.error(err?.message)
        })
    }
    const handleSChargebee = () => {
        setIsChargebee(!isChargebeeshow)
    }
 
    return (

        <div canvas ref={connect} style={{ width: "100%" }} >
        <Element canvas id='Product' is={Container} position={position} thirdShadow={20} background={background} flexDirection={flexDirection} width={width} height={height} radius={10} padding={[10, 10, 10, 10]}>
            <Element canvas id='container'
                is={Container}
                flexDirection={'row'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                fillSpace={'no'}
                padding={['0', '0', '0', '0']}
                margin={['0', '0', '0', '0']}
                background={{ r: 256, g: 256, b: 256, a: 1 }}
                color={{ r: 0, g: 0, b: 0, a: 1 }}
                shadow={0}
                firstShadow={0}
                secondShadow={0}
                thirdShadow={0}
                width={"30%"}
                height="auto"
                radius={10}

            >
                <Element canvas id="image" is={ImageTag} height={'300px'} width="100%" Imgsrc={productImage?productImage:Imgsrc} />
            </Element>
            <Element canvas
                id='container'
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                fillSpace={'no'}
                margin={['0', '0', '0', '0']}
                background={{ r: 256, g: 256, b: 256, a: 1 }}
                color={{ r: 0, g: 0, b: 0, a: 1 }}
                shadow={0}
                firstShadow={0}
                secondShadow={0}
                thirdShadow={0}
                radius={10}
                is={Container} padding={['30', '15', '10', '10']} flexDirection="column" width={"70%"} height="auto" >

                <Texts
                    fontSize={'30'}
                    textAlign={'left'}
                    color={{ r: 92, g: 90, b: 90, a: 1 }}
                    margin={[0, 0, 0, 0]}
                    shadow={0}
                    text={productName ? productName : store.getState().pageBuilder.prodName}
                    fontWeight={"bold"} />

                <Texts text={`${productCost ? productCost : store.getState().pageBuilder?.productCost}$`} margin={[10, 10, 10, 10]} fontSize={'30px'} fontWeight="bold" />
                <Texts fontFamily={"'Pacifico', cursive"} margin={[0, 0, 25, 10]} text={productDes ? productDes : store.getState().pageBuilder?.ProdDescription} color='red' />

                {/* <Element id="buttons" is={Container} canvas> */}
                <Element canvas id='container' is={Container} padding={[30, 15, 10, 10]} flexDirection="column" width="70%" height="auto" radius={10}>
                    {(isPaypal ? isPaypal == true : store.getState().pageBuilder?.isPaypal) && <Paypal 
                    redirectUrl = {redirectUrl}
                    dbPriceId={dbPriceId ? dbPriceId : store.getState().pageBuilder.dbPriceId}
                    mode ={mode?mode:store.getState().pageBuilder?.modeofpayment}  paypalPriceId={paypalPriceId ? paypalPriceId : store.getState().pageBuilder.prodpriceId} 
                    
                    productId={productId ? productId : store.getState().pageBuilder.productId}/>}

                    {(isStripe ? isStripe ==true: store.getState().pageBuilder.isStripe) && <Button   dbPriceId={dbPriceId ? dbPriceId : store.getState().pageBuilder.dbPriceId} priceIdStripe={priceIdStripe ? priceIdStripe : store.getState().pageBuilder.priceIdStripe} productId={productId ? productId : store.getState().pageBuilder.productId} variant="contained" text={"Pay by Stripe"} handleStripe={() => { handleStripe(priceIdStripe, mode,dbPriceId,productId) }} />}

                    {/* {(isChargebee ? isChargebee : store.getState().pageBuilder.isChargebee) && <Button price_Id={price_Id ? price_Id : store.getState().pageBuilder.price_Id} variant="contained" text={"Pay by Chargebee"} handleSChargebee={() => { handleSChargebee() }} />} */}

                   
                </Element>
                {/* </Element> */}

            </Element>

        </Element>
    </div>

    );
};


Product.craft = {
    displayName: 'Product',
    name: "Product",
    rules: {
        canDrag: () => true,
    },
    props: {
        productImage:store.getState().pageBuilder?.productImage,
        paypalPriceId:store.getState().pageBuilder?.prodpriceId,
        productName: store.getState().pageBuilder?.prodName,
        productDes: store.getState().pageBuilder?.ProdDescription,
        priceId: store.getState().pageBuilder?.priceId,
        dbPriceId: store.getState().pageBuilder?.dbPriceId,
        price_Id: store.getState().pageBuilder?.priceId,
        productId: store.getState().pageBuilder?.productId,
        priceIdStripe: store.getState().pageBuilder?.priceIdStripe,
        productCost: store.getState().pageBuilder?.productCost,
        isPaypal: store.getState().pageBuilder?.isPaypal,
        isStripe: store.getState().pageBuilder?.isStripe,
        productId: store.getState().pageBuilder?.productId,
        isChargebee: store.getState().pageBuilder?.isChargebee,
        mode: store.getState().pageBuilder?.modeofpayment,
        Imgsrc: store.getState().pageBuilder?.chanageImage,
        redirectUrl: store.getState().pageBuilder?.redirectUrl,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        fillSpace: 'no',
        padding: ['0', '0', '0', '0'],
        margin: ['0', '0', '0', '0'],
        background: { r: 256, g: 256, b: 256, a: 1 },
        color: { r: 0, g: 0, b: 0, a: 1 },
        shadow: 0,
        firstShadow: 0,
        secondShadow: 0,
        thirdShadow: 0,
        radius: 0,
        width: '100%',
        height: 'auto'

    },
    related: {
        toolbar: ButtonSettings
    }

};
export default Product;
