// components/user/Card.js
import React from "react";
import Text from "./Text";
import Button from "./Button";
import { Element, useNode } from "@craftjs/core";

import { Container } from "./Container";
// import ImageTag from "./";
import SButton from './buttons'
import Texts from "./texts";
// Notice how CardTop and CardBottom do not specify the drag connector. This is because we won't be using these components as draggables; adding the drag handler would be pointless.

export const CardTop = ({ children }) => {
  const {
    connectors: { connect },
  } = useNode();
  return (
    <div ref={connect} className="text-only">
      {children}
    </div>
  );
};

CardTop.craft = {
  rules: {
    // Only accept Text
    canMoveIn: (incomingNodes) =>
      incomingNodes.every((incomingNode) => incomingNode.data.type === Text),
  },
};

export const CardBottom = ({ children }) => {
  const {
    connectors: { connect },
  } = useNode();
  return <div ref={connect}>{children}</div>;
};

CardBottom.craft = {
  rules: {
    // Only accept Buttons
    canMoveIn: (incomingNodes) =>
      incomingNodes.every((incomingNode) => incomingNode.data.type === Button),
  },
};

export const Card = ({ background, padding }) => {
  return (
    <>
    <Container background={background} padding={padding}>
      {/* <Element id="text" is={CardTop} canvas> */}
        {" "}
        {/* // Canvas Node of type CardTop */}
        {/* <Text text="Title" fontSize={20} /> */}
        {/* <ImageTag/> */}
      {/* </Element> */}
      {/* <Element id="buttons" is={CardBottom} canvas> */}
        {" "}
        {/* // Canvas Node of type CardBottom */}
        {/* <SButton /> */}
        {/* <Texts text="default text for settings" color='red' /> */}
        {/* <Button size="small" text="Learn more" /> */}
      {/* </Element> */}
    </Container>
    </>
  );
};

 export default Card;
