import React from 'react';

import { ToolbarItem, ToolbarSection } from '../../../Toolbar';
import ToolbarRadio from '../../../Toolbar/ToolbarRadio';
import { MenuItem } from '@mui/material';
export const capitalize = (text) =>
  text[0].toUpperCase() + text.substr(1, text.length);
export const weightDescription = (weight) =>
  weight === 400 ? "Regular" : weight === 500 ? "Medium" : "Bold";
export const fonts = [
  { id: "'Oswald', sans-serif", name: "Oswald" },
  { id: "Helvetica Neue,Helvetica,Arial,sans-serif", name: "Helvetica" },
  { id: "sans-serif", name: "sans-serif" },
  { id: "Times New Roman", name: "Times New Roman" },
  { id: "Arial Black", name: "Arial Black" },
  { id: "'Avenir Book', Avenir", name: "Avenir Book" },
  { id: "'Avenir Medium', Avenir", name: "Avenir Medium" },
  { id: "'Avenir Roman', Avenir", name: "Avenir Roman" },
  { id: "'Montserrat', sans-serif", name: "Montserrat" },
  { id: "'Oswald', sans-serif", name: "Oswald" },
  { id: "'Shadows Into Light', cursive", name: "Shadows Into Light" },
  { id: "'Pacifico', cursive", name: "Pacifico" },
  { id: "Tahoma", name: "Tahoma" },
  { id: "Verdana, Geneva, sans-serif", name: "Verdana" },
  { id: "Courier New Courier, monospace", name: "Courier New Courier" },
  { id: "'Lato', sans-serif", name: "Lato" },
  { id: "'Open Sans', sans-serif", name: "Open Sans" },
  { id: "'Montserrat', sans-serif", name: "Montserrat" },
];
export const RadioSettings = () => {
  return (
      <React.Fragment>
        <ToolbarSection title="Settings">
          {/* <ToolbarItem
          full={true}
          propKey="height"
          type="number"
          label="Height"
        /> */}
          {/* <ToolbarItem
            full={true}
            propKey="label"
            type="text"
            label="Label"
          />
          <ToolbarItem
            full={true}
            propKey="name"
            type="text"
            label="Name"
          /> */}
        <ToolbarItem propKey="labelPlacement" type="radio" label="Label Placement">
          <ToolbarRadio value="start" label="Start" />
          <ToolbarRadio value="end" label="End" />
          <ToolbarRadio value="top" label="Top" />
          <ToolbarRadio value="bottom" label="Bottom" />
        </ToolbarItem>
        <ToolbarItem
          full={true}
          propKey="name"
          type="text"
          label="Name"
        />
        </ToolbarSection>
      </React.Fragment>
  );
};
