import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

export const ToolbarDropdown = ({ title, value, onChange, children }) => {
  return (
    <FormControl>
      <InputLabel>{title}</InputLabel>
      <Select className='menu-items' label={title} value={value} onChange={(e) => onChange(e.target.value)}>
        {children}
      </Select>
    </FormControl>
  );
};
export default ToolbarDropdown;
