import React, { useEffect, useState } from "react";
import styles from "./TraficModel.module.css";
import { RxCross1 } from "react-icons/rx";
import { MdArrowDropDown } from "react-icons/md";
import useProvider from "../../../Context/Context";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
function AddMainTraffic() {
  const [isShow2, setIsShow2] = useState(false);
  const[trafficInputs, setTrafficInput] = useState({})
  const {
    initTrafficValue,
    trafficPayload,
    setTrafficPayload,editNodeId,
    showMainTrafficModel, setShowMainTrafficModel,
    nodesSelected,
    newTrafficPayload, setNewTrafficPayload
  } = useProvider();
  //console.log('editNodeId: ', trafficInputs);
  const initValue = {
    campaignName: "",
    entryPoint: null,
    trafficSources: null,
    monthlyVisitor: 0,
    acquisitionType: null,
    cpc: 0,
    conversionStrength: 0,
  };
  const handleSave = ()=>{
    setNewTrafficPayload([...newTrafficPayload, trafficInputs])
    setShowMainTrafficModel(false)
  }
  //console.log("trafficPayload: ", newTrafficPayload);
  const sourceList = [
    { label: "Facebook" },
    { label: "Google" },
    { label: "Organic" },
    { label: "Others" },
  ];
  const entryList = [
    { label: "Traffic Entry 1" },
    { label: "Traffic Entry 2" },
    { label: "Traffic Entry 3" },
    { label: "Traffic Entry 4" },
  ];
  const acquisitionList = [{ label: "Clicks" }];
  //console.log("nodesSelected: ", nodesSelected);
  const [sourceValue, setSourceValue] = useState("");
  //console.log("sourceValue: ", sourceValue);

  return (
    <div
      onClick={() => setShowMainTrafficModel(false)}
      className={`${styles.traficmodelContainer} ${
        showMainTrafficModel ? styles.showTrafficModel : ""
      }`}
    >
      <div onClick={(e) => e.stopPropagation()} className={styles.trafficBody}>
        <div className={styles.trafficBodyHead}>
          <p>Add Traffic Source</p>
          <RxCross1 onClick={() => setShowMainTrafficModel(false)} />
        </div>
        <div className={styles.inputFields}>
          <p>Campaign Name</p>
          <input
          
            value={trafficInputs?.campaignName}
            onChange={(e) =>
              setTrafficInput({
                ...trafficInputs,
                campaignName: e.target.value,
              })
            }
            className={styles.inputField}
            type="text"
            name="campaignName"
            id="campaignName"
          />
        </div>
        <div className={styles.inputFields}>
          <p>Entry Point</p>
          <Autocomplete
            onClick={() => setIsShow2(!isShow2)}
          
            className={styles.inputField}
            id="combo-box-demo"
            options={entryList}
            sx={{ width: 300,fontFamily:'Avenir' }}
            onChange={(e, value) => {
                setTrafficInput({
                ...trafficInputs,
                entryPoint: value ? value?.label : null,
              });
            }}
            value={
                trafficInputs?.entryPoint
                ? { label: trafficInputs?.entryPoint }
                : null
            }
            InputProps={{
              classes: { notchedOutline: styles.noBorder },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                }}
                {...params}
                label=""
              />
            )}
          />
        </div>
        <div className={styles.inputFields}>
          <p>Traffic Sources</p>
          <Autocomplete
            onClick={() => setIsShow2(!isShow2)}
            
            className={styles.inputField}
            id="combo-box-demo"
            options={sourceList}
            sx={{ width: 300 }}
            onChange={(e, value) => {
                setTrafficInput({
                ...trafficInputs,
                trafficSources: value ? value?.label : null,
              });
            }}
            value={
                trafficInputs?.trafficSources
                ? { label: trafficInputs?.trafficSources }
                : null
            }
            InputProps={{
              classes: { notchedOutline: styles.noBorder },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                }}
                {...params}
                label=""
              />
            )}
          />
        </div>
        <div className={styles.inputFields}>
          <p>Monthly Visitor</p>
          <input
            value={trafficInputs?.monthlyVisitor}
            onChange={(e) =>
                setTrafficInput({
                ...trafficInputs,
                monthlyVisitor: Number(e.target.value),
              })
            }
            type="number"
            min={0}
            className={styles.inputField}
            name="monthlyVisitor"
            id="monthlyVisitor"
          />
        </div>
        <div className={styles.inputFields}>
          <p>Acquisition Type</p>
          <Autocomplete
            onClick={() => setIsShow2(!isShow2)}
            disablePortal
            className={styles.inputField}
            id="combo-box-demo"
            options={acquisitionList}
            sx={{ width: 300 }}
            onChange={(e, value) => {
                setTrafficInput({
                ...trafficInputs,
                acquisitionType: value ? value?.label : null,
              });
            }}
            value={
                trafficInputs?.acquisitionType
                ? { label: trafficInputs?.acquisitionType }
                : null
            }
            InputProps={{
              classes: { notchedOutline: styles.noBorder },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                }}
                {...params}
                label=""
              />
            )}
          />
        </div>
        <div className={styles.inputFields}>
          <p>CPC ($)</p>
          <input
            value={trafficInputs?.cpc}
            onChange={(e) =>
                setTrafficInput({
                ...trafficInputs,
                cpc: e.target.value,
              })
            }
            className={styles.inputField}
            type="number"
            min={0}
            name="cpc"
            id="cpc"
          />
        </div>
        <div className={styles.inputFields}>
          <p>Conversion Strength (%)</p>
          <input
            value={trafficInputs?.conversionStrength}
            onChange={(e) =>
                setTrafficInput({
                ...trafficInputs,
                conversionStrength: e.target.value,
              })
            }
            className={styles.inputField}
            type="number"
            min={0}
            name="conversionStrength"
            id="conversionStrength"
          />
        </div>
        <div className={styles.cancelSaveButton}>
          <button
            onClick={() => {setShowMainTrafficModel(false);}}
            className={styles.cancel}
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className={styles.save}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddMainTraffic;
