import React, { useState } from "react";
import "./DropDown.css";
import { IoIosArrowDropdownCircle, IoMdArrowDropdown } from "react-icons/io";
import { AiFillSetting, AiOutlinePlusCircle } from "react-icons/ai";
import { AiFillPlusCircle } from "react-icons/ai";
import useProvider from "../../../Context/Context";

function Dropdown({ options, selectedOption, prod_cost }) {
  
  const [isOpen, setIsOpen] = useState(false);
  const {
    showProductModel,
    setShowProductModel,
    setShowAddTrafficModel,
    ExpencesModel, setShowExpencesModel,
    prodCost, setProdCost
  } = useProvider();

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
    //
  };
//
  return (
    <div className="dropdown">
      {/* Dropdown trigger */}

      <button className="dropdown__trigger_button">
        {" "}
        <span style={{ width: "100%" }} onClick={handleDropdownClick}>
          {selectedOption}
        </span>
        <span>
          {selectedOption === "Sales" && (
            <AiFillSetting
              onClick={() => setShowProductModel(!showProductModel)}
            />
          )}
          {selectedOption === "Expenses" && (
            <AiFillSetting
              onClick={() => setShowExpencesModel(!ExpencesModel)}
              className={`${ExpencesModel ? "svgrotate" : ""}`}
            />
          )}
          {selectedOption === "Traffic Sources" && (
            <AiFillSetting
              onClick={() => setShowAddTrafficModel(true)}
              className={`${isOpen ? "svgrotate" : ""}`}
            />
          )}
        </span>
      </button>

      {/* Dropdown options */}
      <div
        className={`dropdown__options ${
          isOpen ? "dropdown__options__show" : ""
        }`}
      >
        <div className="DropDownInner">
          {options.map((option, index) => {
            return (
              <li key={option.value} className={`dropdown__option`}>
                <span style={{ paddingLeft: "7px" }}> {option.label}</span>

                <span style={{ paddingRight: "7px" }}>
                  $
                  {option.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </li>
            );
          })}
          <li className="dropdown__option">
            <span style={{ paddingLeft: "7px", fontWeight: "bold" }}>
              Total
            </span>
            <span style={{ paddingRight: "7px", fontWeight: "bold" }}>
              $
              {options?.length > 0
                ? options
                    .reduce((value, curr) => value + Number(curr?.value), 0)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "0"}
            </span>
          </li>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
