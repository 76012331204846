import React, { useEffect, useState } from 'react'
import styles from "./AddModel.module.css"
import useProvider from '../../Context/Context'
import { RxCross2 } from "react-icons/rx"
import { Autocomplete, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios'
import { common } from '../../BaseUrl'
import { getProduct, updateProd } from '../../Services/api'
import { toast } from 'react-toastify'

function EditProdWar() {


    const {
        EditProdWar, SetEditProdWar, ProdPayload, setProdPayload, setShowProductModel,
    onlyShowWar,setOnlyShowWar
    } = useProvider();
    const [priceType, setPriceType] = useState("")

    const updateProd = () => {
        axios({
            method: "put",
            url: `${common}/products/update/${ProdPayload?._id}`,
            data: { name: ProdPayload?.name },
            headers: {
                'Content-Type': "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            getProduct(ProdPayload?._id).then(res => {
                setProdPayload(res.data.data)
            })
            toast.success('Updated successfully')
            SetEditProdWar(false)
            setProdPayload({
                name: "",
                description: "",
                product_type: "",
                image: ""
            })
            setOnlyShowWar("")
            
            // setShowProductModel(false)
            
        })
    }
    
    console.log("onlyShowWar", onlyShowWar)

    return (
        <div
            onClick={() => {
                SetEditProdWar(false);
                 setProdPayload({
                    name: "",
                    description: "",
                    product_type: "",
                    image: ""
                });setOnlyShowWar("")
            }}
            className={`${styles.summaryModelWar} ${EditProdWar ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Edit Product</p>
                    < RxCross2 onClick={() => { SetEditProdWar(false); setProdPayload({
                name: "",
                description: "",
                product_type: "",
                image: ""
            });setOnlyShowWar("")
             }} />
                </div>
                <div className={styles.inputBody}>
                    <div className={styles.inputField}>
                        <p>Product Name</p>
                        <input value={ProdPayload?.name} onChange={(e) => setProdPayload({ ...ProdPayload, name: e.target.value })} type="text" />
                    </div>
                    <div className={styles.inputField2}>
                        <p>Product Type</p>
                        <input style={{marginTop:"10px"}} disabled value={onlyShowWar?.price?.priceType} onChange={(e) => setProdPayload({ ...ProdPayload, name: e.target.value })} type="text" />
                      
                    </div>
                    <div className={styles.inputField}>
                        <p>Product Price ($)</p>
                        <input tyle={{fontSize:"10px"}} disabled value={onlyShowWar?.price?.amount} onChange={(e) => setProdPayload({ ...ProdPayload, product_cost: e.target.value })}
                            type="number" />
                    </div>
                    <div className={styles.inputField}>
                        <p>Price Type</p>
                        
                        <input style={{fontSize:"10px"}} disabled value={onlyShowWar?.price?.price_plan} onChange={(e) => setProdPayload({ ...ProdPayload, name: e.target.value })} type="text" />
                    </div>
                    {/* {priceType === 'Monthly' && <div className={styles.inputField}>
                        <p>Stick Rate</p>
                        <input disabled value={ProdPayload?.stick_rate} onChange={(e) => setProdPayload({ ...ProdPayload, stick_rate: e.target.value })} type="number" />
                    </div>} */}
                    <div className={styles.inputField}>
                        <p>Product Cost ($)</p>
                        <input tyle={{fontSize:"10px"}} disabled value={onlyShowWar?.price?.product_cost} onChange={(e) => setProdPayload({ ...ProdPayload, product_cost: e.target.value })} type="number" />
                    </div>
                </div>
                <div className={styles.closeButtons}>
                    <button className='closebuttn' onClick={() => {
                        SetEditProdWar(false); setProdPayload({
                            name: "",
                            description: "",
                            product_type: "",
                            image: ""
                        });setOnlyShowWar("")
                    }}  >Close</button>
                    <button className='addbutton' onClick={updateProd} >Update</button>
                </div>
            </div>
        </div>
    );
}

export default EditProdWar