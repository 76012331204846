import { Element, useNode } from '@craftjs/core'
import React from 'react'
import Container from '../DragableContainer'

function GridContainer() {
    const {
        connectors: { connect },
    } = useNode();
    return (

        <Element

            ref={connect}
            canvas
            is={Container}
            id='GridContaier'
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-bewteen'}
            fillSpace={'no'}
            padding={['5', '5', '5', '5']}
            margin={['0', '0', '0', '0']}
            background={{ r: 256, g: 256, b: 256, a: 1 }}
            color={{ r: 0, g: 0, b: 0, a: 1 }}
            shadow={0}
            firstShadow={0}
            secondShadow={0}
            thirdShadow={1}
            radius={0}
            width={'500px'}
        >
            <Element
                canvas
                id='GridContaier1'
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                fillSpace={'no'}
                padding={['5', '5', '5', '5']}
                width={'33%'}
                height={'100px'}
                thirdShadow={1}

                is={Container}>

            </Element>
            <Element
                canvas
                id='GridContaier2'
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                fillSpace={'no'}
                padding={['5', '5', '5', '5']}
                width={'33%'}
                thirdShadow={1}

                height={'100px'}
                is={Container}>

            </Element>
            <Element
                canvas
                id='GridContaier3'
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                fillSpace={'no'}
                padding={['5', '5', '5', '5']}
                width={'33%'}
                height={'100px'}
                thirdShadow={1}

                is={Container}>

            </Element>
        </Element>

    )
}

export default GridContainer