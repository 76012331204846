import React from 'react'
import styles from "./ChatGpt.module.css"
import ChatGpt from './ChatGpt'
import usePageBuilder from '../../Context/pageBuilder'

function ChatGptModel() {
    const{ChatGptModel, setShowChatGptModel} = usePageBuilder()
    return (
        <div onClick={()=>setShowChatGptModel(false)} className={`${styles.ChatGpt} ${ChatGptModel?styles.showChatGpt:""}`}>
            <ChatGpt />
        </div>
    )
}

export default ChatGptModel