import { useNode } from '@craftjs/core';
// import { withStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@mui/material/styles';
import React from 'react';

import { ToolbarDropdown } from './ToolbarDropdown';
import { ToolbarTextInput } from './ToolbarTextInput';
import { Grid, RadioGroup, Slider } from '@mui/material';
import styled from '@emotion/styled';
import ToolbarMultiInput from './ToolbarMultiInput';
import usePageBuilder from '../../../Context/pageBuilder';

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const SliderStyled = styled(Slider)({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '5px 0',
    width: '100%',
    valueLabelDisplay:"on"
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -7,
    marginLeft: -7,
    '&:focus,&:hover,&$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 11px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
  valueLabelDisplay:"on"
  
});

// export type ToolbarItemProps = {
//   prefix?: string;
//   label?: string;
//   full?: boolean;
//   propKey?: string;
//   index?: number;
//   children?: React.ReactNode;
//   type: string;
//   onChange?: (value: any) => any;
// };
export const ToolbarItem = ({
  full = false,
  propKey,
  type,
  onChange,
  index,
  background,
  ...props
}) => {
  const {
    actions: { setProp },
    propValue,
  } = useNode((node) => ({
    propValue: node.data.props[propKey],
  }));
  const value = Array.isArray(propValue) ? propValue[index] : propValue;
  console.log('propValue: ', propValue);
 const {bgChoosen} = usePageBuilder()
  return (
    <Grid item xs={full ? 12 : 12} zIndex={180000}>
      <div className="mb-2">
        {['text', 'color', 'bg', 'number','Imgsrc'].includes(type) ? (
          <ToolbarTextInput
            {...props}
            type={type}
            propKey={propKey}
            bgChoosen={bgChoosen}
            value={value}
            onChange={(value) => {
              setProp((props) => {
                if (Array.isArray(propValue)) {
                  props[propKey][index] = onChange ? onChange(value) : value;
                } else {
                  props[propKey] = onChange ? onChange(value) : value;
                }
              }, 500);
            }}
          />
        ) : type === 'slider' ? (
          <>
            {props.label ? (
              <h4 className="text-sm text-light-gray-2">{props.label}</h4>
            ) : null}
            <SliderStyled
              value={parseInt(value) || 0}
              onChange={
                ((_, value) => {
                  setProp((props) => {
                    if (Array.isArray(propValue)) {
                      props[propKey][index] = onChange
                        ? onChange(value)
                        : value;
                    } else {
                      props[propKey] = onChange ? onChange(value) : value;
                    }
                  }, 1000);
                })
              }
              valueLabelDisplay='on'
              // valueLabelFormat={value=><div>{value*100}</div>}
            />
          </>
        ) : type === 'radio' ? (
          <>
            {props.label ? (
              <h4 className="text-sm text-light-gray-2">{props.label}</h4>
            ) : null}
            <RadioGroup
              value={value || 0}
              onChange={(e) => {
                const value = e.target.value;
                setProp((props) => {
                  props[propKey] = onChange ? onChange(value) : value;
                });
              }}
            >
              {props.children}
            </RadioGroup>
          </>
        ) : type === 'select' ? (
          <>
            {props.label ? (
              <h4 style={{zIndex:190000}} className="text-sm mb-2 text-light-gray-2">{props.label}</h4>
            ) : null}

            <ToolbarDropdown
              value={value || ''}
              onChange={(value) =>
                setProp(
                  (props) =>
                    (props[propKey] = onChange ? onChange(value) : value)
                )
              }
              {...props}
            />
          </>
        ) : type === 'multiInput' ? (
          <>
            {props.label ? (
              <h4 className="text-sm text-light-gray-2">{props.label}</h4>
            ) : null}

            <ToolbarMultiInput
              value={value || ''}
              list={propValue}
              propKey
                      index
              onChange={(value) =>
                setProp(
                  (props) =>
                    (props[propKey] = onChange ? onChange(value) : value)
                )
              }
              {...props}
            />
          </>
        ) : null}
      </div>
    </Grid>
  );
};
export default ToolbarItem;