import React, { useEffect, useState } from 'react'
import styles from "./ChoosePage.module.css"
import useProvider from '../../../Context/Context'
import { RxCross2 } from "react-icons/rx"
import axios from 'axios'
import { common } from '../../../BaseUrl'
import { MenuItem, Select } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

const pages = [
    {
        id: 1,
        name: "Home",
        thumbnail: "https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg"
    },
    {
        id: 2,
        name: "Login",
        thumbnail: "https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg"
    },
    {
        id: 3,
        name: "Contact us",
        thumbnail: "https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg"
    },
]
function ChoosePage({ setNodes, Nodes }) {
    const [categoriesPage, setCategories] = useState([])
    const [choosen, setchoosen] = useState()
    const funnelId = useLocation().state.id

    console.log("funneId",funnelId)
    const {
        choosePagemodel, setChooseModel,
        allPageCategories, nodesSelected, allNodes, setAllNodes
    } = useProvider();
    const [select, setSelect] = useState(allPageCategories?.[0])

    
    console.log("AllNodes from choose", allPageCategories)
    const chosePage = (value) => {
        if (value) {
            let findindex = allNodes?.findIndex(item => item?.pageId === nodesSelected?.[0]?.pageId)
            
            allNodes[findindex].pageData = value
            // setNodes(allNodes.filter(item => item?.pageId === nodesSelected?.[0]?.pageId ? { ...item, pageData: value } : ""))
            // console.log("AllNodes from choose", Nodes)
            setNodes(allNodes)

            axios({
                method:"post",
                url:`${common}/page-builder/addPageToFunnel`,
                data:{
                    funnelId:funnelId,
                    page_id:value?._id
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(resp=>{
                toast.success("Page Added")
                setChooseModel(false)
            }).catch(err=>{
                if(err)
                {
                    toast.error("Page already added in another funnel")
                }
            })
            console.log("AllNodes from choose", Nodes)
        }

    }

    useEffect(() => {
        handlePageTye()
    }, [select])
    const handlePageTye = () => {
        axios({
            method: "post",
            url: `${common}/page-builder/getfilterPages`,
            data: {
                categoryId: select?._id
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(resp => {
            setCategories(resp.data?.data)
            console.log("resp.data", resp.data)

        })

    }
    return (
        <div
            onClick={() => setChooseModel(false)}
            className={`${styles.summaryModel} ${choosePagemodel ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p style={{fontSize:"25px"}}>Select page</p>
                    <Select
                        style={{ backgroundColor: "#fff", fontSize: "13px", width: "200px" }}
                        sx={{
                            "& fieldset": { border: "none", fontSize: "13px" }, height: "24px", width: "200px"
                        }}
                        labelId="demo-select-large"
                        id="demo-select-large"
                        value={select}

                        onChange={(e, value) => setSelect(e.target.value)}


                    >
                        <MenuItem style={{ fontSize: "13px" }} value="">
                            <em>None</em>
                        </MenuItem>
                        {
                            allPageCategories?.map((item, index) => (
                                <MenuItem key={item?.id} style={{ fontSize: "13px" }} value={item}>
                                    {item.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    < RxCross2 onClick={() => { setChooseModel(false) }} />
                </div>

                <div className={styles.internalBox}>
                    {
                        categoriesPage?.map((page, index) => (
                            <div className={styles.ChoosePageImages}>
                                <img key={page.id} src={page.thumbnail} alt={page.name} />
                                <div className={styles.chooseButton}>
                                    <button onClick={() => chosePage(page)}>{page?.name}</button>
                                </div>
                            </div>
                        ))
                    }
                </div>


            </div>
        </div>
    );
}

export default ChoosePage