import React, { useState } from 'react'
import styles from "./Drawer.module.css"
import { MdOutlineArrowLeft } from 'react-icons/md'
import { Sidebar } from '../CraftPage/Sidebar'
import { SidebarDiv } from '../CraftPage/Sidebar'
import useProvider from '../../Context/pageBuilder'

function Drawer() {
    const { isOpen, setIsopen } = useProvider()
    return (
        <div className={`${styles.DrawerMain} ${isOpen ? styles.openDrawer : ""}`}>
            <div className={styles.drawerBody}>

                <Sidebar/>
                <div className={styles.closeDrawer}>
                    <MdOutlineArrowLeft onClick={(e)=>{e.stopPropagation();setIsopen(!isOpen)}} className={isOpen ? styles.rotatesVG : ""} color='white' size={20} />
                </div>
            </div>

        </div>
    )
}

export default Drawer