import React, { useEffect, useState } from "react";
import styles from "./Summary.module.css";
import useProvider from "../../../Context/Context";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
const list = [
  {
    name: "EPC",
  },
  {
    name: "CPL",
  },
  {
    name: "CPA",
  },
  {
    name: "AOV",
  },
  {
    name: "ROAS",
  },
  {
    name: "REVENUE (Monthly)",
  },
  {
    name: "PROFIT (Monthly)",
  },
  {
    name: "REVENUE (Yearly)",
  },
  {
    name: "PROFIT (Yearly)",
  },
];
function SummaryModel(props) {
  const {
    showSummryModel,
    setShowSummryModel,
    selectedSummary,
    setSelectedSummary,
  } = useProvider();
  const [selectedSummaryData, setSelectedSummaryData] = useState([]);
  useEffect(() => {
    setSelectedSummaryData(selectedSummary);
  }, [selectedSummary]);
  const handleChange = (e) => {
    //console.log("selecetd summary", selectedSummary);
    if (!selectedSummary.includes(e.target.value)) {
      setSelectedSummary([...selectedSummary, e.target.value]);
    } else {
      setSelectedSummary(
        selectedSummary.filter((obj) => obj !== e.target.value)
      );
    }
  };
  //console.log("selecetd summary", selectedSummary);
  return (
    <div
      onClick={() => setShowSummryModel(false)}
      className={`${styles.summaryModel} ${
        showSummryModel ? styles.showSummryModel : ""
      }`}
    >
      <div onClick={(e) => e.stopPropagation()} className={styles.summaryBody}>
        <h1>Summary Options</h1>
        {list?.map((item, index) => {
          return (
            <div key={index} className={styles.listItems}>
              <div className={styles.listItemsleft}>
                <GiHamburgerMenu />
                <p>{item.name}</p>
              </div>
              <input
                name={item.name}
                value={item.name}
                checked={selectedSummaryData.includes(item.name)}
                onChange={handleChange}
                type="checkbox"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SummaryModel;
