import React, { useEffect, useState } from 'react'
import styles from "./Expences.module.css"
import useProvider from '../../../Context/Context'
import { RxCross2 } from "react-icons/rx"
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { MdEdit } from 'react-icons/md'
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),

];
function ExpencesModel(props) {

    const {
        ExpencesModel, setShowExpencesModel,
        setShowAddExpencesModel,
        expencespayLLoad
    } = useProvider();
    const handleEdit = (id) => {

    }
    return (
        <div
            onClick={() => setShowExpencesModel(false)}
            className={`${styles.summaryModel} ${ExpencesModel ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Expenses</p>
                    < RxCross2 onClick={() => setShowExpencesModel(false)} />
                </div>
                <Table sx={{ minWidth: 250 }} style={{ backgroundColor: "#fff", borderRadius: "5px" }} aria-label="simple table">
                    <TableHead style={{ textAlign: "center" }}>
                        <TableRow>
                            <TableCell align='left' style={{fontSize:"10px", fontWeight:"bold"}}>	Expenses Name  </TableCell>
                            <TableCell align="left" style={{fontSize:"10px", fontWeight:"bold"}}>Type </TableCell>
                            <TableCell align="left" style={{fontSize:"10px", fontWeight:"bold"}}>Amount</TableCell>
                            <TableCell align="left" style={{fontSize:"10px", fontWeight:"bold"}}></TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {expencespayLLoad?.map((row, index) => (
                            <TableRow
                                key={row.name}

                            >
                                <TableCell align='left' component="th" scope="row">
                                    {row?.expnenseName}
                                </TableCell>
                                <TableCell align="left">{row?.billinFrequency}</TableCell>
                                <TableCell align="left">{row?.amount}</TableCell>
                                <TableCell align="left" style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                    <Tooltip
                                        style={{ padding: "0px" }}
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">
                                                    Edit
                                                </Typography>

                                            </React.Fragment>
                                        }
                                        arrow
                                        placement="top"
                                    >
                                        <IconButton>
                                            <MdEdit
                                                size={20}
                                            //  onClick={() => handleEdit(prod?._id)}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        style={{ padding: "0px" }}
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">
                                                    Edit
                                                </Typography>

                                            </React.Fragment>
                                        }
                                        arrow
                                        placement="top"
                                    >
                                        <IconButton>
                                            <RxCross2
                                                size={20}
                                                onClick={() => handleEdit(row)}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div style={{ display: "flex", gap: "30px", marginTop: '8px', width: "100%", justifyContent: "flex-end" }}>
                    <button onClick={() => setShowExpencesModel(false)} className='closebuttn'>Close</button>
                    <button onClick={() => setShowAddExpencesModel(true)} className='addbutton'>Add</button>
                </div>
            </div>
        </div>
    );
}

export default ExpencesModel