import { CHANGE_IMAGE, RESET_PROD_PROPS, SET_IS_EDITING, SET_PRODUCT_PROPS } from "../action/pageBuilderAction";

const inialstate = {
  prodName: "",
  ProdDescription: null,
  prodpriceId: null,
  priceId: null,
  productCost: null,
  priceIdStripe: null,
  isPaypal: false,
  isStripe: false,
  isChargebee: false,
  modeofpayment:null,
  chanageImage:"",
  productId:null,
  dbPriceId:null,
  productImage:"",
  redirectUrl:""
};

export const pageBuilderReducer = (state = inialstate, { payload, type }) => {
  if (type === SET_PRODUCT_PROPS) {
    return {
      ...state, ...payload
    };
  }
  if(type === SET_IS_EDITING)
  {
    return {
      ...state, payload
    }
  }
  if(type === CHANGE_IMAGE)
  {
    return {
      ...state, payload
    }
  }
  if(type === RESET_PROD_PROPS)
  {
    return {
      ...state, inialstate
    }
  }

  return state;
};
