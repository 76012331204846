import React, { useEffect, useState } from 'react'
import useProvider from '../../Context/Context'
import styles from "./AssetModel.module.css"
import { RxCross2 } from 'react-icons/rx'
import { getAssets } from '../../Services/api'
import { toast } from 'react-toastify'
const assets = [
    {
        _id: 1,
        name: "image 1",
        url: "https://thumbs.dreamstime.com/b/beautiful-rain-forest-ang-ka-nature-trail-doi-inthanon-national-park-thailand-36703721.jpg"
    },
    {
        _id: 2,
        name: "image 2",
        url: "https://cdn.pixabay.com/photo/2018/01/12/10/19/fantasy-3077928__480.jpg"
    },
    {
        _id: 3,
        name: "image 3",
        url: "https://thumbs.dreamstime.com/b/beautiful-rain-forest-ang-ka-nature-trail-doi-inthanon-national-park-thailand-36703721.jpg"
    },
]
function AssetsModel() {
    const [allSelected, setAllSelected] = useState([])
    const [masterCheck, setMasterCheck] = useState(false)
    const { assetsModel, setShowaAssetsModel, AddassetsModel, setShowaAddAssetsModel, allAssests, setAllAssets } = useProvider()
    //console.log("all assets=>", allSelected)
    useEffect(() => {
        getAssets().then(resp => {
            setAllAssets(resp.data.data)
        }).catch(err => {
            toast.error("Something Wrong")
        })
    }, [assetsModel, AddassetsModel])
    const handleAllSelect = (e) => {
        setMasterCheck(e.target.checked)
        if(!e.target.checked)
        {
            setAllSelected([])
        }
        else{
            setAllSelected(assets)
        }

    }

    const handleSelect = (e, asset) => {
        if (e.target.checked) {
            setAllSelected([...allSelected, { ...asset }])
        }
        else {
            setAllSelected(allSelected.filter(item => {
                return item._id != asset?._id
            }))
        }
        //console.log(e)
    }


    useEffect(() => {
        if (allSelected?.length === assets?.length) {
            setMasterCheck(true)
        }
        else {
            setMasterCheck(false)
        }
    }, [allSelected])
    return (
        <div
            onClick={() => setShowaAssetsModel(false)}
            className={`${styles.summaryModel} ${assetsModel ? styles.showSummryModel : ""
                }`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={styles.summaryBody}
            >
                <div className={styles.modelHead}>
                    <p>Add Asset</p>
                    < RxCross2 onClick={() => setShowaAssetsModel(false)} />
                </div>

                <div className={styles.contentBody}>
                    <button onClick={() => setShowaAddAssetsModel(true)} className={styles.addAssets}>New Digital Assets</button>
                    <div style={{ marginBottom: "30px" }} className={styles.allCheck}>
                        <input checked={masterCheck} onChange={(e) => handleAllSelect(e)} type="checkbox" />
                        <div className={styles.checkAllInner}>
                            <h3>Digital Assets</h3>
                            <p>Select or Dselect all</p>
                        </div>
                    </div>
                    {
                        assets?.map(asset => {
                            return (
                                <div style={{ marginTop: "10px" }} key={asset.id} className={styles.allCheck}>
                                    <input id={asset._id} checked={masterCheck?masterCheck:false} onChange={(e) => handleSelect(e, asset)} type="checkbox" />
                                    <div className={styles.checkAllInner}>
                                        <h3>Digital Assets</h3>
                                        <p>Select or Dselect all</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div style={{ display: "flex", gap: "30px", marginTop: '30px', width: "100%", justifyContent: "flex-end" }}>
                    <button onClick={() => setShowaAssetsModel(false)} className='closebuttn'>Close</button>
                    <button className='addbutton'>Add</button>
                </div>
            </div>
        </div>
    )
}

export default AssetsModel