import React, { useEffect, useState } from "react";
import styles from "./Product.module.css";
import useProvider from "../../../Context/Context";
import { RxCross2 } from "react-icons/rx";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ImCopy, ImInfo } from "react-icons/im";
import { MdEdit } from "react-icons/md";
import { getProduct, getProducts } from "../../../Services/api";
import { toast } from "react-toastify";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
];
function SummaryModel(props) {
  const {
    showProductModel,
    setShowProductModel,
    setShowAddProductModel,
    allProducts,
    SetEditProdWar,
    setProdPayload,
    setAllProducts,
    EditProdWar,
    allNodes,
    onlyShowWar,
    setOnlyShowWar,
  } = useProvider();

  const [productList, setProductList] = useState([]);
  useEffect(() => {
    allNodes?.map((prod, index) => {
      if (prod?.products?.length > 0) {
        setProductList(prod?.products);
      }
    });
  }, [allNodes]);
  const handleEdit = (id, prod) => {
    SetEditProdWar(true);
    getProduct(id)
      .then((res) => {
        //console.log(res)
        setProdPayload(res.data.data);
        setOnlyShowWar(prod);
      })
      .catch((error) => {
        toast.error("Something Wrong while fetching products");
      });
  };

  console.log("prod added", allNodes);
  return (
    <div
      onClick={() => setShowProductModel(false)}
      className={`${styles.summaryModel} ${
        showProductModel ? styles.showSummryModel : ""
      }`}
    >
      <div onClick={(e) => e.stopPropagation()} className={styles.summaryBody}>
        <div className={styles.modelHead}>
          <p>All products</p>
          <RxCross2 onClick={() => setShowProductModel(false)} />
        </div>
        <Table
          sx={{ minWidth: 250 }}
          style={{ backgroundColor: "#fff", borderRadius: "5px" }}
          aria-label="simple table"
        >
          <TableHead style={{ textAlign: "center" }}>
            <TableRow>
              <TableCell
                style={{ fontSize: "10px", fontWeight: "bold" }}
                align="left"
              >
                Product Name{" "}
              </TableCell>
              <TableCell
                style={{ fontSize: "10px", fontWeight: "bold" }}
                align="left"
              >
                Type
              </TableCell>
              <TableCell
                style={{ fontSize: "10px", fontWeight: "bold" }}
                align="left"
              >
                Price
              </TableCell>
              <TableCell
                style={{ fontSize: "10px", fontWeight: "bold" }}
                align="left"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productList?.map((item, i) => (
              <TableRow key={i}>
                <TableCell
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    fontWeight: "500",
                  }}
                  align="left"
                  component="th"
                  scope="row"
                >
                  {item?.name}
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    fontWeight: "500",
                  }}
                  align="left"
                >
                  {item?.type}
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    fontWeight: "500",
                  }}
                  align="left"
                >
                  {item?.price?.amount}
                </TableCell>
                <TableCell
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    fontWeight: "500",
                  }}
                  align="left"
                  className={styles?.svgIcons}
                >
                  <Tooltip
                    onClick={() => handleEdit(item?._id, item)}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">Edit</Typography>
                      </React.Fragment>
                    }
                    arrow
                    placement="top"
                  >
                    <IconButton>
                      <MdEdit />
                    </IconButton>
                  </Tooltip>

                  {/* <Tooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit">
                            Delete
                          </Typography>

                        </React.Fragment>
                      }
                      arrow
                      placement="top"
                    >
                      <IconButton>
                        <RxCross2 />
                      </IconButton>
                    </Tooltip> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            gap: "30px",
            marginTop: "8px",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={() => setShowProductModel(false)}
            className="closebuttn"
          >
            Close
          </button>
          <button
            onClick={() => setShowProductModel(false)}
            // onClick={() => { setShowAddProductModel(true) }}
            className="addbutton"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default SummaryModel;
