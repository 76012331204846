import { useNode, useEditor } from '@craftjs/core';
import React from 'react';
import ContentEditable from 'react-contenteditable';

import { TextSettings } from '../../texts/TextSettings';
import { MenuItem, Select } from '@mui/material';
import { SelectSettings } from './SelectSettings';


export const Selects = ({
    fontSize,
    textAlign,
    fontWeight,
    color,
    shadow,
    text,
    margin,
    fontFamily,
    list,
    name
}) => {
    console.log('lists: ', list);
    const {
        connectors: { connect },
        setProp,
    } = useNode();
    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    return (
        <>
            <Select ref={connect} value={text}
            name={name}
                onChange={(e) => {
                    setProp((prop) => (prop.text = e.target.value), 500);
                }}
                style={{
                    width: "100%",
                    margin:
                        margin &&
                        margin?.length > 0 &&
                        `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                    color: color && `rgba(${Object.values(color)})`,
                    fontSize: fontSize && `${fontSize}px`,
                    textShadow: shadow && `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
                    fontWeight,
                    textAlign,
                    fontFamily: fontFamily && fontFamily
                }}


            >
                {list && typeof list === 'object' && list?.map((obj)=>{
                   return <MenuItem value={obj}>{obj}</MenuItem>

                })}
            </Select>
        </>
    );
};

Selects.craft = {
    displayName: 'Select',
    props: {
        text: 'Text',
        name:'name',
        list:['Item 1', 'Item 2'],
    },
    related: {
        toolbar: SelectSettings,
    },
};
export default Selects