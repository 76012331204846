// components/user/Button.js
import React, { useEffect, useState } from "react";
import { useNode } from "@craftjs/core";

import Container from "../DragableContainer";
import ImageSetting from "./ImageSetting"
import usePageBuilder from "../../../../Context/pageBuilder";


export const ImageTag = ({
    src, height, displayValue, width, radius,
    animationType, TimingFunction, animationDuration, animationDelay, animateOnce, imgChoosen, Imgsrc
}) => {


    const {
        connectors: { connect, drag },
        hasSelectedNode,
        hasDraggedNode,
        selected,
        actions: { setProp },
    } = useNode((state) => ({
        hasSelectedNode: state.events.selected.size > 0,
        hasDraggedNode: state.events.dragged.size > 0,
        selected: state,
    }));
        console.log("selected",selected)
    const { setOpenPopup, setAnimationType } = usePageBuilder()
    useEffect(() => {
        setAnimationType(animationType)
    }, [animationType, TimingFunction, animationDuration, animationDelay, animateOnce])
    return (
        <img
            canvas
            ref={connect}
            propKey={{ width: "width", height: "height" }}
            data-aos={`${animationType?.toString()}`}
            data-aos-offset="10"
            data-aos-delay={`${animationDelay * 10}`}
            data-aos-easing={`${TimingFunction ? TimingFunction?.toString() : 'linear'}`}
            data-aos-duration={`${animationDuration * 10}`}
            data-aos-once={`${animateOnce === "yes" ? true : false}`} style={{ display: "flex", width: width ? width : "100%", height: height ? height : "100%", borderRadius: radius ? `${radius}px` : "0px" }} src={Imgsrc ? Imgsrc : imgChoosen} />




    );
}


ImageTag.craft = {
    displayName: 'Image',
    props: {
        fontSize: '15',
        textAlign: 'left',
        fontWeight: '500',
        color: { r: 92, g: 90, b: 90, a: 1 },
        margin: [0, 0, 0, 0],
        shadow: 0,
        text: 'Text',
        displayValue: 'No',
    },
    related: {
        toolbar: ImageSetting,
    },
};

export default ImageTag;