import { useNode } from '@craftjs/core';
import { Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
export const ToolbarMultiInput = ({ title, text, value, onChange, children, list, propKey, index }) => {
  console.log('text: ', text);
  console.log('list: ', list);
  const [data,setData] = useState([])
  console.log('data: ', data);
  const creatableRef = useRef(null);
  const [newValue,setNewValue] = useState('')
  const {
    actions: { setProp },
    ...propValue
  } = useNode();
  console.log('propValue: ', propValue);
  console.log('typeof list: ', list, typeof list);
useEffect(()=>{
  console.log('list: ', list);
  if (Array.isArray(list) && typeof list === 'object') {

console.log("in");
    setData(list)
  }
},[list?.length])
  const handleDelete = (id) =>{
    onChange(data?.filter((obj)=>obj !== id))
  }
  return (
    <FormControl>
      <InputLabel>{title}</InputLabel>
      <input type="text" value={newValue} onChange={(e)=>{
        console.log('e: ', e.target.value);
        
        setNewValue(e.target.value)}} onKeyDown={(e) => {
        if (e.key === 'Enter') {
          // onChange((e.target).value);
          console.log('e.target.value: ', data, [...data, newValue]);
          // console.log('data?.push(e.target.value): ', data?.push(e.target.value.toString()));
          // let newData = data?.push(e.target.value)
          onChange([...data,newValue])
          setData([...data, newValue])
          setNewValue('')

          // setProp(
          //   (props) =>
          //     (props[propKey] = onChange ? onChange(value) : value)
          // )
          // setProp((props) => {
            // if (Array.isArray(propValue)) {
            // props[propKey][index] = data?.push(e.target.value.toString());
            // } else {
              // props[propKey] = onChange ? onChange(value) : value;
            // }
          // }, 500);
        }
      }} />
      {/* <Select label={title} value={value} onChange={(e) => onChange(e.target.value)}>
        {children}
      </Select> */}
      {/* <CreatableSelect ref={creatableRef} value={data} isMulti options={data} /> */}
      {data && data?.length > 0  && data?.map((obj)=>{
        return <Chip label={obj} variant="outlined" onDelete={()=>handleDelete(obj)} />
      })}
    </FormControl>
  );
};
export default ToolbarMultiInput;
