import React, { useEffect, useState } from "react";
import styles from "./TraficModel.module.css";
import { RxCross1 } from "react-icons/rx";
import { MdArrowDropDown, MdOutlineMarkEmailUnread } from "react-icons/md";
import useProvider from "../../../Context/Context";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";
import { FaCookieBite, FaFacebook, FaGoogle, FaTiktok } from "react-icons/fa";
import { DiBingSmall } from "react-icons/di";
import { RiArticleLine } from "react-icons/ri";
import { AiFillFacebook, AiFillInstagram, AiFillYoutube, AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai";
import { SiGoogleads, SiReactrouter } from "react-icons/si";
import { BsQuestionSquareFill } from "react-icons/bs";
function TrafficModel({ nodes, saveNodeData }) {
  //console.log('nodes: ', nodes);
  const [isShow, setIsShow] = useState(false);
  const [isShow2, setIsShow2] = useState(false);
  const [isShow3, setIsShow3] = useState(false);
  const {
    initTrafficValue,
    trafficPayload,
    setTrafficPayload, editNodeId,
    showTrafficModel,
    setShowTrafficModel,
    nodesSelected,
  } = useProvider();
  //console.log('editNodeId: ', editNodeId);
  const initValue = {
    campaignName: "",
    entryPoint: null,
    trafficSources: null,
    monthlyVisitor: 0,
    acquisitionType: null,
    cpc: 0,
    conversionStrength: 0,
  };
  //console.log("trafficPayload: ", trafficPayload);
  const sourceList = [
    { label: "Tik Tok", icon: <FaTiktok color="yellow" /> },
    { label: "Affliates", icon: <RiArticleLine color="yellow" /> },
    { label: "Articles", icon: <FaCookieBite color="" /> },
    { label: "Bing", icon: <DiBingSmall color="#008373 " /> },
    { label: "Email", icon: <AiOutlineMail color="#c71610" /> },
    { label: "Email Ads", icon: <MdOutlineMarkEmailUnread color="#c71610" /> },
    { label: "Facebook", icon: <AiFillFacebook color="#3b5998 " /> },
    { label: "Google", icon: <FaGoogle color="#DB4437 " /> },
    { label: "Google Ads", icon: <SiGoogleads color="#0F9D58" /> },
    { label: "CPA Network", icon: <SiReactrouter /> },
    { label: "Instagram", icon: <AiFillInstagram color="#4f5bd5 " /> },
    { label: "Instagram Ads", icon: <AiFillInstagram /> },
    { label: "WhatsApp", icon: <AiOutlineWhatsApp color="#25D366" /> },
    { label: "WhatsApp Ads", icon: <AiOutlineWhatsApp /> },
    { label: "YouTube", icon: <AiFillYoutube color="#FF0000 " /> },
    { label: "YouTube Ads", icon: <AiFillYoutube /> },
  ];
  const entryList = [
    { label: "Traffic Entry 1" },
    { label: "Traffic Entry 2" },
    { label: "Traffic Entry 3" },
    { label: "Traffic Entry 4" },
  ];
  const acquisitionList = [{ label: "Clicks" }];
  //console.log("nodesSelected: ", trafficPayload);
  const [sourceValue, setSourceValue] = useState("");
  // console.log("sourceValue: ", trafficPayload);
  useEffect(() => {
    if (nodesSelected && nodesSelected?.length > 0) {
      const label =
        setTrafficPayload({
          ...trafficPayload,
          trafficSources: nodesSelected[0]?.data?.label,
          tranficIcon: nodesSelected[0]?.data?.label === 'Facebook' ? <FaFacebook color="#3b5998 " /> : nodesSelected[0]?.data?.label === 'Tik Tok' ? <FaTiktok color="black" /> : nodesSelected[0]?.data?.label === 'You Tube' ? <AiFillYoutube color="#FF0000" /> : nodesSelected[0]?.data?.label === 'Instagram' ? <AiOutlineWhatsApp color="#25D366" /> : nodesSelected[0]?.data?.label === 'Google' ? <FaGoogle color="#DB4437 " /> : <BsQuestionSquareFill />
        });
    }
  }, [nodesSelected, showTrafficModel]);
  return (
    <div
      onClick={() => setShowTrafficModel(false)}
      className={`${styles.traficmodelContainer} ${
        showTrafficModel ? styles.showTrafficModel : ""
      }`}
    >
      <div onClick={(e) => e.stopPropagation()} className={styles.trafficBody}>
        <div className={styles.trafficBodyHead}>
          <p>Add Traffic Source</p>
          <RxCross1 onClick={() => setShowTrafficModel(false)} />
        </div>
        <div className={styles.inputFields}>
          <p>Campaign Name</p>
          <input
            value={trafficPayload?.campaignName}
            onChange={(e) =>
              setTrafficPayload({
                ...trafficPayload,
                campaignName: e.target.value,
              })
            }
            className={styles.inputField}
            type="text"
            name="campaignName"
            id="campaignName"
          />
        </div>
        {/* <div className={styles.inputFields}>
          <p>Entry Point</p>
          <Autocomplete
            onClick={() => setIsShow2(!isShow2)}
            disablePortal
            className={styles.inputField}
            id="combo-box-demo"
            options={entryList}
            sx={{ width: 300 }}
            onChange={(e, value) => {
              setTrafficPayload({
                ...trafficPayload,
                entryPoint: value ? value.label : null,
              });
            }}
            value={
              trafficPayload?.entryPoint
                ? { label: trafficPayload?.entryPoint }
                : null
            }
            InputProps={{
              classes: { notchedOutline: styles.noBorder },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                }}
                {...params}
                label=""
              />
            )}
          />
        </div> */}
        <div className={styles.inputFields}>
          <p>Traffic Sources</p>
          {
            <Autocomplete
              onClick={() => setIsShow2(!isShow2)}
              disablePortal
              className={styles.inputField}
              id="combo-box-demo"
              options={sourceList}
              sx={{ width: 300 }}
              // disabled={trafficPayload?.trafficSources !== 'Others'}
              onChange={(e, value) => {
                setTrafficPayload({
                  ...trafficPayload,
                  trafficSources: value
                    ? value?.label
                    : trafficPayload?.trafficSources,
                  tranficIcon: value?.icon,
                });
              }}
              value={
                trafficPayload?.trafficSources && trafficPayload?.trafficSources
              }
              InputProps={{
                classes: { notchedOutline: styles.noBorder },
              }}
              renderOption={(props, option) => 
               { console.log('props: ', props)
               return <Box
                  component="li"
                  gap={1}
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.icon}
                  {option.label}
                </Box>}
              }
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  {...params}
                  label=""
                />
              )}
            />
          }
        </div>
        <div className={styles.inputFields}>
          <p>Monthly Visitor</p>
          <input
            value={trafficPayload?.monthlyVisitor}
            onChange={(e) =>
              setTrafficPayload({
                ...trafficPayload,
                monthlyVisitor: e.target.value,
              })
            }
            type="number"
            // min={0}
            className={styles.inputField}
            name="monthlyVisitor"
            id="monthlyVisitor"
          />
        </div>
        <div className={styles.inputFields}>
          <p>Acquisition Type</p>
          <input
            value={trafficPayload?.acquisitionType}
            defaultValue={trafficPayload?.acquisitionType}
            disabled
            // onChange={(e) =>
            //   setTrafficPayload({
            //     ...trafficPayload,
            //     cpc: e.target.value,
            //   })
            // }
            className={styles.inputField}
            // type="number"
            min={1}
            name="cpc"
            id="cpc"
          />
          {/* <
            onClick={() => setIsShow2(!isShow2)}
            disablePortal
            className={styles.inputField}
            id="combo-box-demo"
            options={acquisitionList}
            sx={{ width: 300 }}
            onChange={(e, value) => {
              setTrafficPayload({
                ...trafficPayload,
                acquisitionType: value ? value.label : null,
              });
            }}
            value={
              trafficPayload?.acquisitionType
                ? { label: trafficPayload?.acquisitionType }
                : null
            }
            InputProps={{
              classes: { notchedOutline: styles.noBorder },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                }}
                {...params}
                label=""
              />
            )}
          /> */}
        </div>
        <div className={styles.inputFields}>
          <p>CPC ($)</p>
          <input
            value={trafficPayload?.cpc}
            onChange={(e) =>
              setTrafficPayload({
                ...trafficPayload,
                cpc: e.target.value,
              })
            }
            className={styles.inputField}
            type="number"
            min={0}
            name="cpc"
            id="cpc"
          />
        </div>
        {/* <div className={styles.inputFields}>
          <p>Conversion Strength (%)</p>
          <input
            value={trafficPayload?.conversionStrength}
            onChange={(e) =>
              setTrafficPayload({
                ...trafficPayload,
                conversionStrength: e.target.value,
              })
            }
            className={styles.inputField}
            type="number"
            min={1}
            name="conversionStrength"
            id="conversionStrength"
          />
        </div> */}
        <div className={styles.cancelSaveButton}>
          <button
            onClick={() => {
              setShowTrafficModel(false);
              setTrafficPayload(initTrafficValue);
            }}
            className={styles.cancel}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setShowTrafficModel(false);
              // setNodes(
              //   nodes.map((item) =>
              //     item.id == nodeId ? { ...item, userData: data } : item
              //   )
              // );
              saveNodeData(editNodeId, trafficPayload);
              setTrafficPayload(initTrafficValue);
            }}
            className={styles.save}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default TrafficModel;
