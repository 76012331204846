import React, { useEffect, useState } from "react";
import { AiFillSetting, AiOutlineSearch } from "react-icons/ai";
import styles from "./TaxManagement.module.css";
import { useNavigate } from "react-router-dom";
import { FaCircle, FaEdit } from "react-icons/fa";
import { getTax, getTaxId } from "../../Services/api";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
import axios from "axios";
import useProvider from "../../Context/Context";
import { common } from "../../BaseUrl";

function TaxManagement() {
  const navigate = useNavigate();
  const [skipedFunnel, setSkipedFunnel] = useState(1);
  const [taxList, setTaxList] = useState([]);
  console.log("TaxList==>", taxList);

  useEffect(() => {
    getTaxData();
    document.title = "Tax Management"
  }, [skipedFunnel]);
  const getTaxData = () => {
    getTax().then((res) => {
      if (res.status) {
        setTaxList(res?.data?.data?.results);
        console.log(res);
      }
    });
  };
  return (
    <div className={styles.Funnels}>
      <h1 className={styles.FunnelsHead}>Tax Management</h1>
      <div className={styles.HeadContainer}>
        <div className={styles.FunnelsSearch}>
          <input type="text" placeholder="Search by keyword" name="" id="" />
          <AiOutlineSearch />
        </div>
        <div className={styles.headButton}>
          <button onClick={() => navigate("/user/tax-management/add-tax")}>
            Add New
          </button>
        </div>
        {/* <div className={styles.FunnelsSearchDrop}>
          <div className={styles.inputField}>
            <Select
              style={{
                backgroundColor: "#fff",
                fontSize: "13px",
                width: "100%",
              }}
              sx={{
                "& fieldset": { border: "none", fontSize: "13px" },
                height: "24px",
              }}
              labelId="demo-select-large"
              id="demo-select-large"
              value={select}
              onChange={(e) => setSelect(e.target.value)}
            >
              <MenuItem style={{ fontSize: "13px" }} value="">
                <em>None</em>
              </MenuItem>
              <MenuItem style={{ fontSize: "13px" }} value="One-Tome">
                One-Tome
              </MenuItem>
              <MenuItem style={{ fontSize: "13px" }} value="Monthly">
                Monthly
              </MenuItem>
              <MenuItem style={{ fontSize: "13px" }} value="Yearly">
                Yearly
              </MenuItem>
            </Select>
          </div>
        </div>
        <div
          onClick={() => setShowCreateProduct(true)}
          className={styles.headButton}
        >
          <button>Add New Contact</button>
        </div> */}
      </div>
      <div>
        <div className={styles.tableHead}>
          <p>Country</p>
          <p>State</p>
          <p>Tax name</p>
          <p>Tax Value</p>
          <p>Status </p>
          <p></p>
        </div>
        {taxList?.map((item) => {
          return <ProductsCard getTaxData={getTaxData} key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
}

export default TaxManagement;

const ProductsCard = ({ item, getTaxData }) => {
  const navigate = useNavigate();
  const { setData } = useProvider();
  const deleteTax = (id) => {
    axios({
      method: "delete",
      url: `${common}/tax/delete/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      toast.success("Deleted successfully");
      getTaxData()
    });
  };

  return (
    <div className={styles.tableRow}>
      {/* <div>
        <p>flag</p>
      </div> */}
      
      <p>{item?.country && item?.country?.length > 0 ?   item?.country[0]?.country_name : ""}</p>
      <p>{item.state}</p>

      <p>{item.name}</p>

      <p>{item.value}</p>

      <p><span>{item.status==="Active"?<FaCircle style={{color: "green",}} />:<FaCircle style={{color: "red",}} />}</span> &nbsp;{item.status}</p>

      {/* <p>{item?.amount}</p> */}
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <FaEdit
          onClick={() =>
            navigate("/user/tax-management/edit-tax", {
              state: { id: item._id },
            })
          }
        />
        <RiDeleteBinLine onClick={() => deleteTax(item._id)} />
      </div>
    </div>
  );
};
