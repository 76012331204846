import React from 'react';

import { ToolbarItem, ToolbarSection } from "../../Toolbar";
import ToolbarRadio from "../../Toolbar/ToolbarRadio";
import { Button } from '@mui/material';
import usePageBuilder from '../../../../Context/pageBuilder';

export const ContainerSettings = () => {
    const { showAddSlider,setShowAddSlider } = usePageBuilder()
    return (
        <React.Fragment>
            <ToolbarSection title="Slider Settings">
                <ToolbarItem
                    propKey="showArrows"
                    type="radio"
                    label="Show Arrows"
                >
                    <ToolbarRadio value={true} label="Yes" />
                    <ToolbarRadio value={false} label="No" />
                </ToolbarItem>
                <ToolbarItem
                    propKey="showIndicators"
                    type="radio"
                    label="Show Dotts"
                >
                    <ToolbarRadio value={true} label="Yes" />
                    <ToolbarRadio value={false} label="No" />
                </ToolbarItem>

                <ToolbarItem
                    propKey="showThumbs"
                    type="radio"
                    label="Show Thumbs"
                >
                    <ToolbarRadio value={true} label="Yes" />
                    <ToolbarRadio value={false} label="No" />
                    
                </ToolbarItem>

                <Button onClick={() => setShowAddSlider(!showAddSlider)} variant='outline' >Add Image</Button>
            </ToolbarSection>
        </React.Fragment>
    );
};
export default ContainerSettings;