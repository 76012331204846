import styled from '@emotion/styled';
import { TextField, InputAdornment } from '@mui/material';
// import { makeStyles } from "@mui/styles";
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ChromePicker } from 'react-color';
import usePageBuilder from '../../../Context/pageBuilder';
import store from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { setProductProps } from '../../../redux/action/pageBuilderAction';
// import { styled } from 'styled-components';

const useStyles ={
  root: {
    padding: 0,
    width: '100%',
    // background:"#efeff1",
    borderRadius: '100px',
    border: 'none',
    margin: 0,
    marginTop: 7,
    position: 'relative',
  },
  input: {
    background: '#efeff1',
    borderRadius: '100px',
    fontSize: '12px',
    paddingLeft: '28px',
    paddingBottom: '8px',
    paddingTop: '8px',
    margin: 0,
  }, // a style rule
  // notchedOutline: {
  //   borderColor:'transparent',
  //   borderRadius: "100px"
  // }
};

const useLabelStyles = {
  root: {
    color: 'rgb(128,128,128)',
  },
  formControl: {
    fontSize: '18px',
    borderRadius: '100px',
    paddingLeft: '0px',
    paddingTop: '3px',
    marginBottom: '3px',
    position: 'relative',
    left: '-12px',
  }, // a style rule
}

// export type ToolbarTextInputProps = {
//   prefix?: string;
//   label?: string;
//   type: string;
//   onChange?: (value: any) => void;
//   value?: any;
// };
export const ToolbarTextInput = ({
  onChange,
  value,
  prefix,
  label,
  type,
  propKey,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(value);
  console.log('internalValue: ', internalValue,propKey);
  const [active, setActive] = useState(false);
  const Dispatch = useDispatch()
  console.log('value: ', value);
  // const classes = useStyles({});
  // const labelClasses = useLabelStyles({});
  const { bgChoosen, setBgChoosen,setImgChoosen,imgChoosen } = usePageBuilder()
  console.log('bgChoosen: ', bgChoosen);
  useEffect(()=>{
    if (propKey === 'bgurl' && bgChoosen){
      setInternalValue(bgChoosen)
      onChange(bgChoosen);
      setBgChoosen(null)
    }else{
      setInternalValue(value)
    }
  }, [bgChoosen])
  
  useEffect(()=>{
    if (propKey === 'Imgsrc' && store.getState().pageBuilder.chanageImage){
      setInternalValue(store.getState().pageBuilder.chanageImage)
      onChange(store.getState().pageBuilder.chanageImage);
    
    }else{
      setInternalValue(value)
    }
  }, [store.getState().pageBuilder.chanageImage, propKey])
  useEffect(()=>{
    if (propKey === 'src' && imgChoosen){
      setInternalValue(imgChoosen)
      onChange(imgChoosen);
      setImgChoosen(null)
    }else{
      setInternalValue(value)
    }
  }, [imgChoosen])
  useEffect(() => {
    let val = value;
    if (type === 'color' || type === 'bg')
      val = `rgba(${Object.values(value)})`;
    setInternalValue(val);
  }, [value, type]);

  return (
    <div
      style={{ width: '100%', position: 'relative' }}
      onClick={() => {
        setActive(true);
      }}
    >
      {(type === 'color' || type === 'bg') && active ? (
        <div
          className="absolute"
          style={{
            zIndex: 99999,
            top: 'calc(100% + 10px)',
            left: '-5%',
          }}
        >
          <div
            className="fixed top-0 left-0 w-full h-full cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setActive(false);
            }}
          ></div>
          <ChromePicker
            color={value}
            onChange={(color) => {
              onChange(color.rgb);
            }}
          />
        </div>
      ) : null}
      <TextField
      
        label={label.slice(0,10)}
        style={{ margin: 0, width: '100%', maxWidth:"100%", overflow:"hidden" }}
        value={internalValue}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onChange((e.target).value);
          }
        }}
        onChange={(e) => {
          console.log('e: ', e);
          setInternalValue(e.target.value);
        }}
        margin="dense"
        variant="filled"
        InputProps={{
          classes: useStyles,
          disableUnderline: true,
          startAdornment: ['color', 'bg'].includes(type) ? (
            <InputAdornment
              position="start"
              style={{
                position: 'absolute',
                marginTop: '2px',
                marginRight: '8px',
              }}
            >
              <div
                className="w-2 h-2 inline-block rounded-full relative"
                style={{
                  left: '15px',
                  background: internalValue,
                }}
              />
            </InputAdornment>
          ) : null,
        }}
        InputLabelProps={{
          classes: {
            ...useLabelStyles,
          },
          shrink: true,
        }}
        {...props}
      />
    </div>
  );
};
export default ToolbarTextInput;
