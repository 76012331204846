import React from 'react'
import styles from "./Side.module.css"
import vec from "../../assets/logo/Vector.svg"
import vec1 from "../../assets/logo/Vector (1).svg"
import vec2 from "../../assets/logo/Vector (2).svg"
import vec3 from "../../assets/logo/Vector (3).svg"
import vec4 from "../../assets/logo/Vector (4).svg"
import vec5 from "../../assets/logo/Vector (5).svg"
import vec6 from "../../assets/logo/Vector (6).svg"
import vec7 from "../../assets/logo/Vector (7).svg"
import { useLocation, useNavigate } from 'react-router-dom'
import { IoIosFunnel } from 'react-icons/io'
import { BiGitCompare } from 'react-icons/bi'
function Side() {
  const pathname = useLocation().pathname
  const navigate = useNavigate()
  return (
    <div className={styles.sidebar}>
      <div onClick={() => navigate('/user/dashboard')} className={`${styles.routes} ${pathname === '/user/dashboard' ? styles.activePath : ""}`}>
        <img src={vec} alt="" />
        <p>Dashboard</p>
      </div>
      <div onClick={() => navigate('/user/funnels')} className={`${styles.routes} ${pathname === '/user/funnels' ? styles.activePath : ""}`}>
        <IoIosFunnel size={23} />
        <p>Funnel</p>
      </div>
      <div onClick={() => navigate('/comparison')} className={`${styles.routes} ${pathname === '/comparison' ? styles.activePath : ""}`}>
        <BiGitCompare size={23} />
        <p>Comparison</p>
      </div>
      <div onClick={() => navigate('/user/products')} className={`${styles.routes} ${pathname === '/user/products' || pathname === '/user/products/edit' ? styles.activePath : ""}`} >
        <img src={vec2} alt="" />
        <p>Product</p>
      </div>
      <div onClick={() => navigate('/user/page-builder')} className={`${styles.routes} ${pathname === '/user/page-builder' ? styles.activePath : ""}`}>
        <img src={vec3} alt="" />
        <p>Page Builder</p>
      </div>
      <div onClick={() => navigate('/user/kpi')} className={`${styles.routes} ${pathname === '/user/kpi' ? styles.activePath : ""}`}>
        <img src={vec4} alt="" />
        <p>KPI</p>
      </div>
      <div onClick={() => navigate('/user/contact')} className={`${styles.routes} ${pathname === '/user/contact' ? styles.activePath : ""}`}>
        <img src={vec5} alt="" />
        <p>Contact</p>
      </div>
      <div onClick={() => navigate('/user/tax-management')} className={`${styles.routes} ${pathname === '/user/tax-management' ? styles.activePath : ""}`}>
        <img src={vec6} alt="" />
        <p>Tax Management</p>
      </div>
      <div onClick={() => navigate('/user/setting')} className={`${styles.routes} ${pathname === '/user/setting' ? styles.activePath : ""}`}>
        <img src={vec7} alt="" />
        <p>Setting</p>
      </div>
    </div>
  )
}

export default Side