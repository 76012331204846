import React from 'react'
import styles from "./Nav.module.css"
import { AiOutlineBell } from "react-icons/ai";
import logo from "../../assets/logo.png"
import logo1 from "../../assets/modiji.png"
import { useNavigate } from 'react-router-dom';
import { useEditor } from '@craftjs/core';
import usePageBuilder from '../../Context/pageBuilder';
import axios from 'axios';

function Nav() {

  const navigate = useNavigate()
  const logOut = () => {
    localStorage.clear()
    
    window.location.href = "http://dogsandbitches.net"
  }

 
  return (
    <div className={styles.navbar}>
      <img onClick={() => navigate("/user/funnels")} className={styles.logo} src={logo} alt="" />
      <div className={styles.navbarRight}>
        <AiOutlineBell />
        <img src={logo1} alt="" />
        <button onClick={logOut}>Logout</button>
      </div>
    </div>
  )
}

export default Nav