import React, { useEffect, useState } from 'react'
import styles from "./Funnels.module.css"
import { AiFillSetting, AiOutlineSearch } from 'react-icons/ai'
import { TiArrowSortedDown } from 'react-icons/ti'
import modiji from "../../assets/modiji.png"
import { BsBarChart } from 'react-icons/bs'
import { HiArrowUpCircle } from "react-icons/hi2"
import { RiDeleteBin6Fill } from "react-icons/ri"
import { FaEdit } from 'react-icons/fa'
import useProvider from '../../Context/Context'
import { useNavigate } from 'react-router-dom'
import { IconButton, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material'
import Pagination from '@mui/material/Pagination';
import { common } from '../../BaseUrl'
import axios from 'axios'
import { getKPIFunnle, getPageCategories, getPages } from '../../Services/api'
import { toast } from "react-toastify"

function Funnels() {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const [skipedFunnel, setSkipedFunnel] = useState(0)
  const [funnelLimit, setFunnelLimit] = useState(6)
  const [kpis, setKpis] = useState([])
  const [count, setCount] = useState(0)
  const { allFunnels, setIsOldData, setAllFunnels, clearFunnel, setAllPages, setAllPageCategories, createFunne, setCreateFunnel } =
    useProvider();
  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
    //console.log(isOpen)
  };


  useEffect(() => {
    getAllFunnels()
    getKPIFunnle().then(resp => {
      setKpis(resp.data?.data)
    }).catch(err => {
      console.log("error", err)
    })
    document.title = "Funnels"
  }, [skipedFunnel])

  console.log("all kpi", kpis)

  const mergedArray = allFunnels.map(item1 => {
    const item2 = kpis.find(item2 => item2._id === item1._id);

    if (item2) {
      return { ...item1, ...item2 };
    }

    return item1;
  });
  const getAllFunnels = () => {
    axios({
      method: "GET",
      url: `${common}/funnel/warroomList?skip=${skipedFunnel}&limit=${funnelLimit}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(responce => {
      //console.log("responce=>", responce.data)
      setAllFunnels(responce.data?.data?.results)
      setCount(responce.data?.data?.count)
    })
  }
  useEffect(() => {
    getPages().then(res => {
      //console.log(res.data.data)
      setAllPages(res.data?.data)
      localStorage.setItem('allpages', JSON.stringify(res.data.data))
    })
  }, [])

  const DeleteFunnel = (id) => {
    axios({
      method: "delete",
      url: `${common}/funnel/delete/${id}`,
      headers: {
        'Content-Type': "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {

      toast.success('Deleted successfully')

      getAllFunnels()

    })
  }
  console.log("window.innerWidth", funnelLimit)
  return (
    <div className={styles.Funnels}>
      <h1 className={styles.FunnelsHead}>Funnels</h1>
      <div className={styles.HeadContainer}>
        <div className={styles.FunnelsSearch}>
          <input type="text" placeholder='Search by keyword' name="" id="" />
          <AiOutlineSearch />
        </div>
        <div className={styles.FunnelsSearchDrop}>
          <div className={styles.inputField}>

            <Select
              style={{ backgroundColor: "#fff", fontSize: "13px", width: "100%" }}
              sx={{
                "& fieldset": { border: "none", fontSize: "13px" }, height: "24px"
              }}
              labelId="demo-select-large"
              id="demo-select-large"
            // value={select}

            // onChange={(e) => setSelect(e.target.value)}


            >
              <MenuItem style={{ fontSize: "13px" }} value="">
                <em>None</em>
              </MenuItem>
              <MenuItem style={{ fontSize: "13px" }} value="One-Tome">One-Tome</MenuItem>
              <MenuItem style={{ fontSize: "13px" }} value="Monthly">Monthly</MenuItem>
              <MenuItem style={{ fontSize: "13px" }} value="Yearly">Yearly</MenuItem>
            </Select>
          </div>

        </div>
        <div onClick={() => {
          setCreateFunnel(true)
          clearFunnel();

        }} className={styles.headButton}>
          <button>Create New Funnel</button>
        </div>
      </div>
      <div className={styles.cardContainer}>
        {
          mergedArray?.map((funnel, index) => {
            console.log("funnel kpi", funnel)
            return <FunnelCard setIsOldData={setIsOldData} funnel={funnel} id={funnel._id} DeleteFunnel={DeleteFunnel} />
          })
        }
      </div>

      {count >= 6 && <Stack alignItems={'center'} justifyContent={'center'} mt={2}>
        <Pagination onChange={(e, value) => setSkipedFunnel((value * funnelLimit) - funnelLimit)} count={Math.ceil(count / 6)} color="primary" />
      </Stack>}

    </div>
  )
}

export default Funnels


const FunnelCard = (props) => {
  const navigate = useNavigate();
  const { clearFunnel } = useProvider();
  const hnadleNavigate = (id) => {
    props.setIsOldData(true)
    clearFunnel();
    navigate("/war-room", { state: { id: id, isNew: true, status: props?.funnel?.status, url: props.funnel?.url } });
  }


  return (
    <div className={styles.funnelCard}>
      <div className={styles.cardHead}>
        <p>{props?.funnel.name}</p>
        <img src={modiji} alt="" />
      </div>
      <div className={styles.cardBar}>
        <h1>In 30 days</h1>

      </div>

      <div className={styles.cardGrid}>
        <p>Views</p>
        <div className={styles.cardMiddle}>
          <HiArrowUpCircle className={styles.upgrade} color='green' style={{opacity:props?.funnel?.status === "Draft" ?".3":"1"}} size={15} />
          {props?.funnel?.status === "Draft" ? <p style={{color:"gray", opacity:".5"}}>0</p> : <p>{props.funnel?.views}</p>}
        </div>
        <Tooltip

          title={
            <React.Fragment>
              <Typography fontSize={10} color="inherit">
                Views
              </Typography>
            </React.Fragment>
          }
          arrow
          placement="right"
        >
          <IconButton>
            <BsBarChart />
          </IconButton>
        </Tooltip>
        <p>Opt-ins</p>
        <div className={styles.cardMiddle}>
          <HiArrowUpCircle className={styles.downgrade} color='red' style={{opacity:props?.funnel?.status === "Draft" ?".3":"1"}} size={15} />
          {props?.funnel?.status === "Draft" ? <p style={{color:"gray", opacity:".5"}}>0</p> :<p>0</p>}
        </div>
        <Tooltip
          onClick={(e) => { e.stopPropagation(); hnadleNavigate(props.id) }}
          title={
            <React.Fragment>
              <Typography fontSize={10} color="inherit">
                Edit
              </Typography>
            </React.Fragment>
          }
          arrow
          placement="right"
        >
          <IconButton>
            <FaEdit />
          </IconButton>
        </Tooltip>
        <p>Orders</p>
        <div className={styles.cardMiddle}>

          <HiArrowUpCircle className={styles.downgrade} style={{opacity:props?.funnel?.status === "Draft" ?".3":"1"}} size={15} />
          {props?.funnel?.status === "Draft" ? <p style={{color:"gray", opacity:".5"}}>0</p> :<p>{props.funnel?.order}</p>}
        </div>
        <Tooltip

          title={
            <React.Fragment>
              <Typography fontSize={10} color="inherit">
                Settings
              </Typography>
            </React.Fragment>
          }
          arrow
          placement="right"
        >
          <IconButton>
            <AiFillSetting />
          </IconButton>
        </Tooltip>
        <p>CVR</p>
        <div className={styles.cardMiddle}>
          <HiArrowUpCircle className={styles.upgrade} style={{opacity:props?.funnel?.status === "Draft" ?".3":"1"}} color='green' size={15} />
          {props?.funnel?.status === "Draft" ? <p style={{color:"gray", opacity:".5"}}>0</p> :<p>{(props.funnel?.order * 100 / props.funnel?.views)?.toFixed(2)}%</p>}
        </div>
        <Tooltip

          onClick={(e) => { e.stopPropagation(); props?.DeleteFunnel(props?.funnel?._id) }}
          title={
            <React.Fragment>
              <Typography fontSize={10} color="inherit">
                Delete
              </Typography>
            </React.Fragment>
          }
          arrow
          placement="right"
        >
          <IconButton>
            < RiDeleteBin6Fill />
          </IconButton>
        </Tooltip>

      </div>
    </div>
  )
}