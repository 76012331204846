import { useNode, useEditor } from '@craftjs/core';
import { ROOT_NODE } from '@craftjs/utils';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { BiMove } from 'react-icons/bi';
import { BsArrowUp } from 'react-icons/bs';
import { FiDelete, FiSettings } from 'react-icons/fi';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Sidebar } from './Sidebar';
import { AiFillSetting } from 'react-icons/ai';
import { HiPencil } from "react-icons/hi"
import usePageBuilder from '../../Context/pageBuilder';
import { MdDelete } from 'react-icons/md';

const IndicatorDiv = styled.div`
  height: 30px;
  margin-top: -29px;
  font-size: 12px;
  line-height: 12px;
  background:#1f2948;
  border-radius: 5px;
  color:#fff;
  svg {
    fill: #fff;
    width: 15px;
    height: 15px;
  }
`;

const Btn = styled.a`
  padding: 0 0px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  > div {
    position: relative;
    top: -50%;
    left: -50%;
  }
`;

export const RenderNode = ({ render }) => {
  const { id } = useNode();
  const { ChatGptModel, setShowChatGptModel, setIsopen, isOpen, sourceId, setSourceId } = usePageBuilder()

  const { actions, query, isActive, selectedNodeId } = useEditor((state, query) => ({
    isActive: query.getEvent('selected').contains(id),
    selectedNodeId: state.events.selected
  }));

  useEffect(() => {
    console.log("actions.history()", actions)
  }, [])
  const {
    isHover,
    dom,
    name,
    moveable,
    deletable,
    connectors: { drag },
    parent,
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
  }));

  const currentRef = useRef();

  useEffect(() => {
    if (dom) {
      if (isActive || isHover) dom.classList.add('component-selected');
      else dom.classList.remove('component-selected');
    }
  }, [dom, isActive, isHover]);

  const getPos = useCallback((dom) => {
    const { top, left, bottom } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0 };
    return {
      top: `${top > 0 ? top : bottom}px`,
      left: `${left}px`,
    };
  }, []);

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef;

    if (!currentDOM) return;
    const { top, left } = getPos(dom);
    currentDOM.style.top = top;
    currentDOM.style.left = left;
  }, [dom, getPos]);
  const [domReady, setDomReady] = React.useState(false);

  React.useEffect(() => {
    setDomReady(true);
  });
  let ele = document.querySelector(".craftjs-renderer");
  useEffect(() => {
    document
      .querySelector('.craftjs-renderer')
      .addEventListener('scroll', scroll);

    return () => {
      document
        .querySelector('.craftjs-renderer')
        ?.removeEventListener('scroll', scroll);
    };
  }, [scroll]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // setOpen(true);
    setIsopen(!isOpen)
  };

  const handleClose = () => {
    setOpen(false);
  };
  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  const handleGPTModel = () => {

    let allNodes = query.getNodes()


    const element = query.getNodes()
    console.log('selectedNodeId', element?.[selectedNodeId.keys().next()?.value]?.data.props.text, typeof (selectedNodeId))
    setShowChatGptModel(!ChatGptModel);
    setSourceId(element?.[selectedNodeId.keys().next()?.value]?.data.props.text)
    console.log('sourceId', sourceId)
  }
  return (
    <>
      {(isActive) && domReady && ele
        ? ReactDOM.createPortal(
          <IndicatorDiv
            ref={currentRef}
            className="px-2 py-2 text-white bg-primary fixed flex items-center"
            style={{
              left: getPos(dom).left,
              top: getPos(dom).top,
              zIndex: 9999,
            }}
          >
            <h2 className="flex-1 mr-4">{name}</h2>
            {moveable ? (
              <Btn className="mr-2 cursor-move" ref={drag}>
                <Tooltip

                  title={
                    <React.Fragment>
                      <Typography fontSize={10} color="inherit">
                        Move
                      </Typography>
                    </React.Fragment>
                  }
                  arrow
                  placement="bottom"
                >
                  <IconButton>
                    <BiMove fill="black" />
                  </IconButton>
                </Tooltip>
              </Btn>
            ) : null}
            {id !== ROOT_NODE && (
              <Btn
                className="mr-2 cursor-pointer"
                onClick={() => {
                  actions.selectNode(parent);
                }}
              >
                <Tooltip

                  title={
                    <React.Fragment>
                      <Typography fontSize={10} color="inherit">
                        Up
                      </Typography>
                    </React.Fragment>
                  }
                  arrow
                  placement="bottom"
                >
                  <IconButton>
                    <BsArrowUp fill="black" />
                  </IconButton>
                </Tooltip>
              </Btn>
            )}



            {deletable ? (
              <Btn
                className="mr-2 cursor-pointer"
                onMouseDown={(e) => {
                  e.stopPropagation();
                  actions.delete(id);
                }}
              >
                <Tooltip

                  title={
                    <React.Fragment>
                      <Typography fontSize={10} color="inherit">
                        Delete
                      </Typography>
                    </React.Fragment>
                  }
                  arrow
                  placement="bottom"
                >
                  <IconButton>
                    <MdDelete fill="black" />
                  </IconButton>
                </Tooltip>
              </Btn>
            ) : null}
            {(
              <Btn
                className="cursor-pointer"
                onClick={handleClickOpen}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  // actions.delete(id);
                }}
              >
                <Tooltip

                  title={
                    <React.Fragment>
                      <Typography fontSize={10} color="inherit">
                        Settings
                      </Typography>
                    </React.Fragment>
                  }
                  arrow
                  placement="bottom"
                >
                  <IconButton>
                    <AiFillSetting fill="black" />
                  </IconButton>
                </Tooltip>
              </Btn>
            )}


            <Tooltip
              onClick={() => handleGPTModel()}
              title={
                <React.Fragment>
                  <Typography fontSize={10} color="inherit">
                    AI Search
                  </Typography>
                </React.Fragment>
              }
              arrow
              placement="bottom"
            >
              <IconButton>
                <HiPencil />
              </IconButton>
            </Tooltip>

          </IndicatorDiv>,
          document.querySelector(".page-container")
        )
        : null

      }

      <Dialog
        style={{ zIndex: "170000" }}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Settings
        </DialogTitle>
        <DialogContent onClick={(e) => e.stopPropagation()}>
          <Sidebar />

        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
      {render}
    </>
  );
};
