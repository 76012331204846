import React from 'react';

import { ToolbarItem, ToolbarSection } from '../../../Toolbar';
import ToolbarRadio from '../../../Toolbar/ToolbarRadio';
import { MenuItem } from '@mui/material';
export const capitalize = (text) =>
  text[0].toUpperCase() + text.substr(1, text.length);
export const weightDescription = (weight) =>
  weight === 400 ? "Regular" : weight === 500 ? "Medium" : "Bold";

export const SelectSettings = (props) => {
  console.log('props: ', props);
  return (
    <React.Fragment>
     
      <ToolbarSection
        title="Option"
        props={['list','name']}
        summary={({list }) => {
          console.log('list: ', list);

          // return 
        }}
      >
        <ToolbarItem propKey="list" type="multiInput" label="Add Option" />
        <ToolbarItem
          full={true}
          propKey="name"
          type="text"
          label="Name"
        />
      </ToolbarSection>
    </React.Fragment>
  );
};
