import React, { useEffect, useState } from "react";
import { Element, useNode } from "@craftjs/core";
import ContentEditable from "react-contenteditable";
import { Button } from "@mui/material";
import { Container } from '../Components/Container'
import LandingPage from "./LandingPage"
import { Video } from '../Components/Video'
import Text from "./Text";
import Texts from "./texts";
export const Template = ({ size, variant, color, children, text, fontSize, textColor }) => {
    const {
        connectors: { connect, drag },
        hasSelectedNode,
        hasDraggedNode,
        actions: { setProp },
    } = useNode((state) => ({
        hasSelectedNode: state.events.selected.size > 0,
        hasDraggedNode: state.events.dragged.size > 0,
    }));
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        !hasSelectedNode && setEditable(false);
    }, [hasSelectedNode]);
    return (
        <Container>
            <Texts

                text="hello bhai log "
            />
            <Video
                canvas
                height={`${500}px`}
                width={`${100}%`}
                link="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                alt=""
            />


        </Container>
    );
}
export default Template;
;
