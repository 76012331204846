import { useNode, useEditor } from '@craftjs/core';
import React from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';

import { VideoSettings } from './VideoSettings';
import { Resizer } from '../Resizer';

const YoutubeDiv = styled.div`
  width: 100%;
  height: 100%;
  > div {
    height: 100%;
  }
  iframe {
    pointer-events: ${(props) => (props.enabled ? 'none' : 'auto')};
    // width:100%!important;
    // height:100%!important;
  }
`;

export const Video = (props) => {
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { videoId, link, height, width, src } = props;
  console.log("video link", link)

  return (
    <Resizer
      propKey={{ width: width, height: "height" }}
    >


      <video width={width}
        controls={false}
        autoPlay={true}
        ref={connect}
        loop
        enabled={enabled}
        style={{
          height: height,
          width: width
        }}
        src={link}
      // height={height}
      >


        <source src={link} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

    </Resizer>
  );
};

Video.craft = {
  displayName: 'Video',
  props: {
    width: '300',
    link: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
  },
  related: {
    toolbar: VideoSettings,
  },
};
export default Video