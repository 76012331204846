import axios from 'axios'
import React, { createContext, useState, useContext, useEffect } from 'react'


const Storage = createContext();
export const PageDataProvider = ({ children }) => {
    const [showAddDrawer, setShowAddDrawer] = useState(false)
    const [showProdDrawer, setShowProdDrawer] = useState(false)
    const [showSectionDrawer, setSectionProdDrawer] = useState(false)
    const [ChatGptModel, setShowChatGptModel] = useState(false)
    const [showBgModel, setShowBgModel] = useState(false)
    const [showChangeImageodel,setShowChangeModel] = useState(false)
    const [showAddSlider,setShowAddSlider] = useState(false)
    const [bgChoosen, setBgChoosen] = useState(null)
    const [imgChoosen, setImgChoosen] = useState(null)
    const [query, setQuery] = useState()
    const [isOpen, setIsopen] = useState(false)
    const [isOldPage, setIsOldPage] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const [pageSetting, setPageSetting] = useState(false)
    const [isChargebeeshow,setIsChargebee] = useState(false)
    const [funeelIdForPayment,setFuneelIdForPayment] = useState("")
    const [AllPagesMain, setAllPagesMain] = useState([])
    const [globalFont, setGlobalFont] = useState("")
    const [animationType, setAnimationType] = useState("fade")
    const [sourceId, setSourceId] = useState();
    const [mobileView, setMobileView] = useState("100%")
    const [prodDetails, setProddetails] = useState()
    const [BgColor, setBgColor] = useState({
        r: '256',
        g: '256',
        b: '256',
        a: '1',
    })
    const [sliderImage, setSliderImage] = useState(["https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg", "https://page-builder.fra1.digitaloceanspaces.com/1685705959629-wp4596228-ocean-mountain-hd-wallpapers.jpg", "https://images.unsplash.com/photo-1659259540528-0240ad70e92a?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0NTQ1NDd8MHwxfGFsbHwxMnx8fHx8fDJ8fDE2ODYwNDg2Mzd8&ixlib=rb-4.0.3&q=85"])

    return (
        <Storage.Provider
            value={{
                showAddDrawer, setShowAddDrawer,
                showProdDrawer, setShowProdDrawer,
                showSectionDrawer, setSectionProdDrawer,
                query, setQuery,
                ChatGptModel, setShowChatGptModel,
                showBgModel,
                setShowBgModel,
                bgChoosen, setBgChoosen,
                isOpen, setIsopen,
                openPopup,setOpenPopup,
                sliderImage, setSliderImage,
                mobileView, setMobileView,
                sourceId, setSourceId,
                animationType, setAnimationType,
                isOldPage, setIsOldPage,
                AllPagesMain, setAllPagesMain,
                globalFont, setGlobalFont,
                pageSetting, setPageSetting,
                BgColor, setBgColor,
                prodDetails, setProddetails,
                showChangeImageodel,setShowChangeModel,
                showAddSlider,setShowAddSlider,
                imgChoosen, setImgChoosen,
                isChargebeeshow,setIsChargebee,
                funeelIdForPayment,setFuneelIdForPayment

            }}
        >
            {children}
        </Storage.Provider>
    );
}

export default function usePageBuilder() {
    return useContext(Storage);
}
