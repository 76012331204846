import React, { useEffect, useState } from 'react'
import styles from "./MainPage.module.css"
import { AiFillDelete, AiFillSetting, AiOutlineSearch } from 'react-icons/ai'
import { TiArrowSortedDown } from 'react-icons/ti'
import modiji from "../../assets/modiji.png"
import { BsBarChart } from 'react-icons/bs'
import { HiArrowUpCircle } from "react-icons/hi2"
import { RiDeleteBin6Fill } from "react-icons/ri"
import { FaEdit } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { MenuItem, Select, Stack } from '@mui/material'
import Pagination from '@mui/material/Pagination';
import { common } from '../../BaseUrl'
import axios from 'axios'
import { getPageCategories, getPages } from '../../Services/api'
import { toast } from "react-toastify"
import usePageBuilder from '../../Context/pageBuilder'
import { MdDelete } from 'react-icons/md'
import Loader from '../../Loader'

function MainPage() {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [skipedFunnel, setSkipedFunnel] = useState(0)
    const [funnelLimit, setFunnelLimit] = useState(window.innerWidth > 2000 ? 15 : 9)
    const [page, setPage] = useState(1)
    const { isOldPage, setIsOldPage, setAllFunnels, clearFunnel, AllPagesMain, setAllPagesMain, setAllPageCategories } =
        usePageBuilder();
    const handleDropdownClick = () => {
        setIsOpen(!isOpen);
        //console.log(isOpen)
    };



    useEffect(() => {
        getPages().then(res => {
            // console.log(res.data.data)
            setIsLoading(false)
            setAllPagesMain(res.data?.data)

        })
        document.title = "Pages"
    }, [])

    const deleteFunel = (id) => {
            axios({
              method: "delete",
              url: `${common}/page-builder/delete/${id}`,
              headers: {
                'Content-Type': "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            }).then(res => {
        
              toast.success('Deleted successfully')
        
              getPages().then(res => {
                // console.log(res.data.data)
                setIsLoading(false)
                setAllPagesMain(res.data?.data)
    
            }).catch(err=>{
                
            })
        
            })
          
    }
    console.log("window.innerWidth", AllPagesMain)
    return (
        <div className={styles.Funnels}>
            <h1 className={styles.FunnelsHead}>Pages</h1>
            <div className={styles.HeadContainer}>
                <div className={styles.FunnelsSearch}>
                    <input type="text" placeholder='Search by keyword' name="" id="" />
                    <AiOutlineSearch />
                </div>

                <div onClick={() => {
                    navigate('/new-page');

                }} className={styles.headButton}>
                    <button>Create New Page</button>
                </div>
            </div>
            <div className={styles.cardContainer}>
                {
                    !isLoading ? (
                        <>
                            {
                                AllPagesMain?.slice(page * 12 - 12, page * 12)?.map((funnel, index) => {
                                    return <FunnelCard deleteFunel={deleteFunel} funnel={funnel} id={funnel._id} />
                                })
                            }
                        </>
                    ) : (
                        <>
                            <Loader />
                        </>
                    )
                }
            </div>

            <Stack alignItems={'center'} justifyContent={'center'} mt={2}>
                <Pagination onChange={(e, value) => setPage(value)} count={Math.ceil(AllPagesMain?.length / 12)} color="primary" />
            </Stack>

        </div>
    )
}

export default MainPage


const FunnelCard = (props) => {
    console.log("propsvalue", props)
    const navigate = useNavigate();
    const { isOldPage, setIsOldPage } = usePageBuilder();
    const hnadleNavigate = (id) => {
        setIsOldPage(true)
        navigate("/new-page", { state: { pageId: id } });
    }


    return (
        <div key={props.id} className={styles.funnelCard}>
            <div className={styles.cardHead}>
                <img src="https://images.unsplash.com/photo-1674574124349-0928f4b2bce3?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0NTQ1NDd8MXwxfGFsbHwxfHx8fHx8Mnx8MTY4NjgwMzQ5MHw&ixlib=rb-4.0.3&q=85" alt="" />
                <div className={styles.chooseBtn}>
                    <button onClick={() => { hnadleNavigate(props.id) }} >{props?.funnel?.name}</button>
                    <p>{props.name}</p>
                    <MdDelete className={styles.dangour} onClick={() => props.deleteFunel(props.id)} style={{ position: "absolute", right: "5px", top: "10px", opacity: .8 }} color='white' size={25} />
                </div>


            </div>

        </div>
    )
}