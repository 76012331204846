import React, { useEffect, useState } from 'react'
import { AiFillSetting, AiOutlineSearch } from 'react-icons/ai'
import { TiArrowSortedDown } from 'react-icons/ti'
import styles from "./Products.module.css"
import useProvider from '../../Context/Context'
import { useNavigate } from 'react-router-dom'
import { IconButton, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import img from "../../assets/modiji.png"
import { FaEdit, FaStripeS } from 'react-icons/fa'
import { RiDeleteBinLine } from 'react-icons/ri'
import { common } from '../../BaseUrl'
import { toast } from 'react-toastify'
import axios from 'axios'
import { getProduct, getProducts } from '../../Services/api'
import Loader from '../../Loader'
import { BsFillLightningChargeFill, BsPaypal } from "react-icons/bs"
function Products() {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const [select, setSelect] = useState("")
  const [isLoading, setIsloading] = useState(false)

  const { setShowCreateProduct, allProducts, setAllProducts,setProdPayload,setAllPrice } = useProvider()
  //console.log("all prod=>", allProducts)
  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
    //console.log(isOpen)
  };
  useEffect(() => {
    setIsloading(true)
    getProducts().then(res => {
      setAllProducts(res.data?.data)
      setIsloading(false)
      
    })

    document.title = "Products"
  }, [])

  useEffect(() => {
    setProdPayload({
      name: "",
      description: "",
      product_type: "",
      image: ""
    })
  }, [])
  const deteleProd = (id) => {
    axios({
      method: "delete",
      url: `${common}/products/delete/${id}`,
      headers: {
        'Content-Type': "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {

      toast.success('Deleted successfully')

      getProducts().then(res => {
        //console.log(res)
        setProdPayload(res.data.data)
        setAllPrice(res.data.data.prices)
        setAllProducts(res.data?.data)
      }).catch(error => {
        toast.error("Something Wrong while fetching products")
      })

    })
  }
  return (
    <div className={styles.Funnels}>
      <h1 className={styles.FunnelsHead}>Funnels</h1>
      <div className={styles.HeadContainer}>
        <div className={styles.FunnelsSearch}>
          <input type="text" placeholder='Search by keyword' name="" id="" />
          <AiOutlineSearch />
        </div>
        <div className={styles.FunnelsSearchDrop}>
          <div className={styles.inputField}>

            <Select
              style={{ backgroundColor: "#fff", fontSize: "13px", width: "100%" }}
              sx={{
                "& fieldset": { border: "none", fontSize: "13px" }, height: "24px"
              }}
              labelId="demo-select-large"
              id="demo-select-large"
              value={select}

              onChange={(e) => setSelect(e.target.value)}


            >
              <MenuItem style={{ fontSize: "13px" }} value="">
                <em>None</em>
              </MenuItem>
              <MenuItem style={{ fontSize: "13px" }} value="One-Tome">One-Tome</MenuItem>
              <MenuItem style={{ fontSize: "13px" }} value="Monthly">Monthly</MenuItem>
              <MenuItem style={{ fontSize: "13px" }} value="Yearly">Yearly</MenuItem>
            </Select>
          </div>

        </div>
        <div onClick={() => setShowCreateProduct(true)} className={styles.headButton}>
          <button>Create New Product</button>
        </div>
      </div>
      {
        isLoading ? <Loader /> : <div>
          <div className={styles.tableHead}>
            <p>Product name</p>
            <p>Status</p>
            <p>Price</p>
            <p></p>

          </div>
          {
            allProducts?.map(item => {
              return <ProductsCard key={item.id} item={item} deteleProd = {deteleProd}/>
              
            })
          }
        </div>
      }
    </div>
  )
}

export default Products


const ProductsCard = ({ item ,deteleProd}) => {
  const navigate = useNavigate()
  const { setProdPayload, setAllPrice } = useProvider()
 

  const addToPayMethod = (id) => {
    axios({
      method: "post",
      url: `${common}/products/addProductToPaypal`,
      data: {
        productId: id
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(resp => {
      toast.success("Product added successfully")
      getProducts().then(res => {
        //console.log(res)
        setProdPayload(res.data.data)
        setAllPrice(res.data.data.prices)
      }).catch(error => {
        toast.error("Something Wrong while fetching products")
      })
    }).catch(err => {

      toast.error(err?.message)
    })
  }
  const addToStripeMethod = (id) => {
    axios({
      method: "post",
      url: `${common}/products/addProductToStripe`,
      data: {
        productId: id
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(resp => {
      toast.success("Product added successfully")
      getProducts().then(res => {
        //console.log(res)
        setProdPayload(res.data.data)
        setAllPrice(res.data.data.prices)
      }).catch(error => {
        toast.error("Something Wrong while fetching products")
      })
    }).catch(err => {

      toast.error(err?.message)
    })
  }
  const addToChargebeeMethod = (id) => {
    axios({
      method: "post",
      url: `${common}/products/addProductToChargeBee`,
      data: {
        productId: id
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(resp => {
      toast.success("Product added successfully")
      getProducts().then(res => {
        //console.log(res)
        setProdPayload(res.data.data)
        setAllPrice(res.data.data.prices)
      }).catch(error => {
        toast.error("Something Wrong while fetching products")
      })
    }).catch(err => {

      toast.error(err?.message)
    })
  }
  return (
    <div className={styles.tableRow}>
      <div className={styles.imageAndName}>
        <img src={item?.image ? item?.image : 'https://www.electrosolutions.in/wp-content/uploads/2018/08/product-image-dummy.jpg'} alt="" />
        <p>{item.name}</p>
      </div>

      <div>
        <p>Active</p>
      </div>
      <p>{item?.amount}</p>
      <div style={{ display: "flex", gap: "0px", alignItems: "center" }}>
        <Tooltip
          onClick={() => {
            if (!(item?.paypal_prod_id)) {
              addToPayMethod(item._id)
            }
          }}
          title={
            <React.Fragment>
              <Typography fontSize={10} color="inherit">
                Paypal
              </Typography>
            </React.Fragment>
          }
          arrow
          placement="top"
        >
          <IconButton>

            <BsPaypal color={item?.paypal_prod_id ? "#3b7bbf" : "gray"} className={item?.paypal_prod_id ? "no-drop-cursor" : ""} size={20} />
          </IconButton>
        </Tooltip>
        <Tooltip
          onClick={() => {
            if (!(item?.stripe_prod_id)) {
              addToStripeMethod(item._id)
            }
          }}
          title={
            <React.Fragment>
              <Typography fontSize={10} >
                Stripe
              </Typography>
            </React.Fragment>
          }
          arrow
          placement="top"
        >
          <IconButton>

            <FaStripeS color={item?.stripe_prod_id ? "#3b7bbf" : "gray"} className={item?.stripe_prod_id ? "no-drop-cursor" : ""} size={20} />

          </IconButton>
        </Tooltip>
        <Tooltip
          onClick={() => addToChargebeeMethod(item._id)}
          title={
            <React.Fragment>
              <Typography fontSize={10} color="inherit">
                Chargebee
              </Typography>
            </React.Fragment>
          }
          arrow
          placement="top"
        >
          <IconButton>


            <BsFillLightningChargeFill color={item?.chargebee_prod_id ? "rgb(255, 51, 0)" : "gray"} size={20} />

          </IconButton>
        </Tooltip>


        <FaEdit onClick={() => navigate('/user/products/edit', { state: { id: item._id } })} />
        <RiDeleteBinLine onClick={() => deteleProd(item._id)} />

      </div>

    </div>
  )
}