import React, { useEffect, useState } from "react";
import { AiFillSetting, AiOutlineSearch } from "react-icons/ai";
import styles from "./Contact.module.css";
import { useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { getContact, getTags } from "../../Services/api";
function Contact() {
  const navigate = useNavigate();
  const [select, setSelect] = useState(null);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(100);
  const [keywords, setKeywords] = useState([]);
  console.log('keywords: ', keywords);
  const [contactList, setContactList] = useState([]);
  const [tags, setTags] = useState([]);
  console.log('tags: ', tags);
  console.log("ContactList==>", contactList);

  useEffect(() => {
    getContactData();
  }, [skip, limit]);
  const getContactData = () => {
    const obj = {};
    obj.skip = skip + 1;
    obj.limit = limit;
    if (keywords) obj.keywords = keywords.toString();
    getContact(obj).then((res) => {
      if (res?.data?.message) {
        setContactList(res?.data?.data?.results);
        console.log(res);
      }
    });
  };
  const getTagsData = () => {
    getTags().then((res) => {
      console.log('res: ', res);
      if (res.data?.data) {
        setTags(res.data?.data)
      }
    })
  }
  useEffect(() => {
    getTagsData()
    document.title = "Contact"
  }, [''])
  useEffect(() => {
    let timeout;
    if (keywords) {
      console.log("keywords", keywords);
      timeout = setTimeout(() => {
        getContactData();
      }, 800);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [keywords?.length]);
  return (
    <div className={styles.Funnels}>
      <h1 className={styles.FunnelsHead}>Contact</h1>
      <div className={styles.HeadContainer}>
        <div className={styles.FunnelsSearch}>
          <input
            type="text"
            placeholder="Search by keyword"
            value={keywords}
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
            name=""
            id=""
          />
          <AiOutlineSearch />
        </div>
        <div className={styles.FunnelsSearchD}>
          <p>Sort by</p>
          <div className={styles.FunnelsSearchDrop}>
            <div className={styles.inputField}>
              <Select
                style={{
                  backgroundColor: "#fff",
                  fontSize: "13px",
                  width: "100%",
                }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "13px" },
                  height: "24px",
                }}
                labelId="demo-select-large"
                id="demo-select-large"
                value={select}
                onChange={(e) => setSelect(e.target.value)}
              >
                <MenuItem style={{ fontSize: "13px" }} value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="One-Tome">
                  One-Tome
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Monthly">
                  Monthly
                </MenuItem>
                <MenuItem style={{ fontSize: "13px" }} value="Yearly">
                  Yearly
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <div className={styles.headButton}>
          <button onClick={() => navigate("/user/contact/add-contact")}>
            Add New Contact
          </button>
        </div>
      </div>
      <div className={styles.HeadContainer2}>
        <div className={styles.FunnelsSearchD}>
          <p>Sort by</p>
          <div className={styles.FunnelsSearchDrop2}>
            <div className={styles.inputField}>
              <Select
                multiple
                style={{
                  backgroundColor: "#fff",
                  fontSize: "13px",
                  width: "100%",
                }}
                sx={{
                  "& fieldset": { border: "none", fontSize: "13px" },
                  height: "24px",
                }}
                labelId="demo-select-large"
                id="demo-select-large"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              >
                {tags && tags?.length > 0 && tags.map((tsg) => {
                  if (typeof (tsg) === "string") {
                    return (<MenuItem style={{ fontSize: "13px" }} value={tsg}>
                      {tsg}
                    </MenuItem>)
                  }
                })}
              </Select>
            </div>
          </div>
        </div>

      </div>
      <div>
        <div className={styles.tableHead}>
          <p>Name</p>
          <p>Email</p>
          <p>Organization</p>
          <p>Phone</p>
          <p>Time zone</p>
          <p></p>
        </div>
        {contactList?.map((item) => {
          return <ProductsCard key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
}

export default Contact;

const ProductsCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.tableRow}>
      <p>
        {`${item.first_name}`} {` ${item.last_name}`}
      </p>

      <p>{item.email}</p>

      <p>{item.organization}</p>

      <p>{item.phone_no}</p>

      <p>{item.timezone}</p>

      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <FaEdit
          onClick={() =>
            navigate("/user/contact/edit-contact", { state: { id: item._id } })
          }
        />
      </div>
    </div>
  );
};
