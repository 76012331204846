import * as React from 'react';

import { BsArrowLeft, BsFillGrid3X3GapFill } from 'react-icons/bs';
import styles from "./Nav.module.css"
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { AiFillSetting } from 'react-icons/ai';
import { ImCopy } from 'react-icons/im';
import { BiRedo, BiUndo } from 'react-icons/bi';
import { MdOutlineFileDownload } from 'react-icons/md';
import { RxMobile } from 'react-icons/rx';
import { GoDeviceDesktop } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import usePageBuilder from '../../Context/pageBuilder';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEditor } from '@craftjs/core';
import { SERVER_URL } from '../../Configs';
import { addPage, getPageCategories, getUrls, updatePage } from '../../Services/api';
import useProvider from '../../Context/Context';
const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];
function PageHeader({ pageId, pageDetails }) {
    const { enabled, canUndo, canRedo, actions, query } = useEditor((state, query) => ({
        enabled: state.options.enabled,
        canUndo: query.history.canUndo(),
        canRedo: query.history.canRedo(),
    }));

    const navigate = useNavigate()
    const [pageName, setPageName] = React.useState(pageDetails?.name)
    const { setMobileView, pageSetting, setPageSetting } = usePageBuilder()
    const { setAllPageCategories, allPageCategories } = useProvider()
    const [urls, setUrls] = React.useState([])
    const [NewUrl, seNewUrl] = React.useState(pageDetails?.url)
    const [errors, setError] = React.useState(null)
    console.log('allPageCategories: ', pageId);
    console.log("const { query } = useEditor()", query?.serialize())
    const [categoryId, setCategoryId] = React.useState(pageDetails?.categoryId)


    React.useEffect(() => {
        getUrls().then(resp => {
            setUrls(resp.data.data)
        }).catch(err => {
            toast.error(err?.message)
        })
    },[])
    console.log("PageData", urls)
    const generateURL = (text) => {
        const formattedText = text.trim().replace(/\s+/g, '-');
        return formattedText.toLowerCase();
    };
    const handleInputChange = (event) => {
        const value = event.target.value;
        const newUrls = generateURL(value)
        setPageName(value);
        seNewUrl(newUrls)

        if (!urls?.includes(newUrls)) {
            // String matches an element in the array, no error
            setError(null);
        } else {
            // String does not match, set error message
            setError('Url exist');
        }
    };
    const savePage = () => {

        if(errors)
        {
            return toast.warn("Page url exist")
        }
        const PageData = query?.serialize();
        localStorage.setItem("pageData", PageData)
       console.log("pageName",pageName, pageName?.length)
        if (!pageName) {
            return toast.warn("Please Provide a name")
        }
        if (PageData) {
            addPage({
                "name": pageName,
                "categoryId": categoryId,
                "content": PageData,
                "url":NewUrl
            }).then(resp => {
                console.log('resp: ', resp);
                console.log("http://localhost:5000/save-page", resp)
                toast.success("Page saved successfully")
            })
        }
    }
    React.useEffect(() => {
        getPageCategories().then(responce => {
            console.log("pagestype", responce.data.data)
            if (responce.data.data) setAllPageCategories(responce.data?.data)
        }).catch(err => {
            if (err) {
                toast.error("Server error")
            }
        })
    }, [])
    const updatePage1 = () => {
        const PageData = query?.serialize();
        console.log("PageData", PageData)
        if (PageData) {
            updatePage1("ae84b194-4e24-4f05-ae38-ae6aca0a78bd").then(resp => {
                console.log('resp: ', resp);
                console.log("http://localhost:5000/save-page", resp)
                toast.success("Page Updated successfully")
            })
        }
    }
    return (
        <div className={`${styles.navbar} ${styles.boxshadowNav}`}>
            <Box display={'flex'} ml={'20px'} gap={'10px'} alignItems={'center'}>
                <BsArrowLeft size={25} onClick={() => navigate("/user/funnels")} />
                <div>
                    <input placeholder='Page name here' defaultValue={pageName} onChange={(e) => handleInputChange(e)} className={styles.searchInput} type="text" />
                    {errors && <p style={{ color: "red", fontSize: "10px" }}>{errors}</p>}
                </div>

                <select placeholder='Select page category'
                    value={categoryId} onChange={((e) => {
                        setCategoryId(e.target.value)
                    })}>
                    <option value={''} selected disabled>Select Page Category</option>
                    {allPageCategories && allPageCategories?.map((obj) => {
                        return <option value={obj._id}><img src={obj.icon} height="50" /> {obj.name}</option>
                    })}
                </select>

            </Box>
            <Box >
                <div className="war_room_head_right">
                    <Tooltip
                        onClick={() => setPageSetting(!pageSetting)}
                        title={
                            <React.Fragment>
                                <Typography fontSize={10} color="inherit">
                                    Settings
                                </Typography>
                            </React.Fragment>
                        }
                        arrow
                        placement="bottom"
                    >
                        <IconButton>
                            <AiFillSetting color="#1B2948" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={
                            <React.Fragment>
                                <Typography fontSize={10} color="inherit">
                                    Download
                                </Typography>
                            </React.Fragment>
                        }
                        arrow
                        placement="bottom"
                    >
                        <IconButton>
                            <MdOutlineFileDownload color="#1B2948" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title={
                            <React.Fragment>
                                <Typography fontSize={10} color="inherit">
                                    Undo
                                </Typography>
                            </React.Fragment>
                        }
                        arrow
                        placement="bottom"
                    >
                        <IconButton disabled={!canUndo} onClick={() => actions.history.undo()}>
                            <BiUndo color="#1B2948" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={
                            <React.Fragment >
                                <Typography fontSize={10}>
                                    Redo
                                </Typography>
                            </React.Fragment>
                        }
                        arrow
                        placement="bottom"
                    >
                        <IconButton disabled={!canRedo} onClick={() => actions.history.redo()}>
                            <BiRedo color="#1B2948" />
                        </IconButton>
                    </Tooltip>




                    <Tooltip
                        onClick={() => setMobileView("100%")}
                        title={
                            <React.Fragment>
                                <Typography fontSize={10} color="inherit">
                                    Desktop
                                </Typography>
                            </React.Fragment>
                        }
                        arrow
                        placement="bottom"
                    >
                        <IconButton>
                            <GoDeviceDesktop color='black' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        onClick={() => setMobileView("30%")}
                        title={
                            <React.Fragment>
                                <Typography fontSize={10} color="inherit">
                                    Mobile
                                </Typography>
                            </React.Fragment>
                        }
                        arrow
                        placement="bottom"
                    >
                        <IconButton>
                            <RxMobile color='black' />
                        </IconButton>
                    </Tooltip>












                    <button className="war_room_head_right_button"
                        onClick={() => {
                            actions.setOptions((options) => (options.enabled = !enabled));
                        }}
                    >{enabled ? "Preview" : "Edit"}</button>

                    {pageId ? <button
                        className="war_room_head_right_button"
                        onClick={
                            () => {
                                const PageData = query?.serialize();

                                updatePage({
                                    "name": pageName,
                                    "categoryId": categoryId,
                                    "content": PageData,
                                    "url":NewUrl
                                }, pageId).then(res => {
                                    toast.success("Page updated successfully")
                                }).catch(err => {
                                    if (err) {
                                        toast.error("Something went wrong")
                                    }
                                })
                            }
                        }

                    >
                        Update
                    </button> : <button
                        className="war_room_head_right_button"
                        onClick={savePage}


                    >
                        Save
                    </button>}

                </div>
            </Box>
        </div>
    );
}


export default PageHeader;