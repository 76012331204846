const SERVER_URL = 
"https://app.madfunnels.com/";
// "http://67.207.79.43:3300";
const SALES_PAGE = "Sales Page";
const ORDER_FORM = "Order Form";

module.exports = {
  SERVER_URL,
  SALES_PAGE,
  ORDER_FORM,
};
