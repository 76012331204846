// components/user/Text.js
import React, { useEffect, useState } from "react";
// import { FormControl, FormLabel, Slider, Typography } from "'@mui/material";
import FormControl from '@mui/material/FormControl'
import { useNode } from "@craftjs/core";
import ContentEditable from "react-contenteditable";
import FormLabel from '@mui/material/FormLabel';
import Slider from '@mui/material/Slider';

export const TextComponent = ({ text, fontSize }) => {
 const {
   connectors: { connect, drag },
   hasSelectedNode,
   hasDraggedNode,
   actions: { setProp },
 } = useNode((state) => ({
   hasSelectedNode: state.events.selected.size > 0,
   hasDraggedNode: state.events.dragged.size > 0,
 }));

 const [editable, setEditable] = useState(false);

 useEffect(() => {
   !hasSelectedNode && setEditable(false);
 }, [hasSelectedNode]);

  return (
    <div ref={(ref) => connect(drag(ref))} onClick={(e) => setEditable(true)}>
      <ContentEditable
        disabled={!editable}
        html={text}
        onChange={(e) =>
          setProp(
            (props) =>
              (props.text = e.target.value.replace(/<\/?[^>]+(>|$)/g, ""))
          )
        }
        tagName="p"
        style={{ fontSize: `${fontSize}px` }}
      />
       {
        hasSelectedNode && (
          <FormControl className="text-additional-settings" size="small">
            <FormLabel component="legend">Font size</FormLabel>
            <Slider
              defaultValue={fontSize}
              step={1}
              min={7}
              max={50}
              valueLabelDisplay="auto"
              onChange={(_, value) => {
                setProp(props => props.fontSize = value);
              }}
            />
          </FormControl>
        )}
    </div>
  );
};
 export default TextComponent