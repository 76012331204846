import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BiCodeAlt } from "react-icons/bi";
import face from "../../assets/logo/face.svg"
import google from "../../assets/logo/google.svg"
import other from "../../assets/logo/other.svg"
import organic from "../../assets/logo/organic.svg"
import code from "../../assets/logo/code.svg"
import pageTemp from "../../assets/logo/pageTemp.svg"
import page from "../../assets/logo/pages.svg"
import action from "../../assets/logo/action.svg"
import email from "../../assets/logo/email.svg"
import share from "../../assets/logo/shareLink.svg"
import cal from "../../assets/logo/calender.svg"
import { getPageCategories, getPages } from "../../Services/api";
import useProvider from "../../Context/Context";
import "./RightSideBar/RightSide.css"
import { MdOutlineArrowLeft, MdOutlineMarkEmailUnread } from "react-icons/md";
import { AiFillFacebook, AiFillYoutube, AiOutlineInstagram, AiOutlinePlusCircle } from "react-icons/ai";
// import {  } from "react-icons/bs";
import { FaCookieBite } from "react-icons/fa";
import { DiBingSmall } from "react-icons/di";
import { RiArticleLine } from "react-icons/ri";
import { AiFillInstagram, AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai";
import { SiGoogleads, SiReactrouter } from "react-icons/si";
import { BsQuestionSquareFill } from "react-icons/bs";
import { FaFacebook, FaGoogle, FaTiktok } from "react-icons/fa";
import PagesDrwer from "./PagesDrwer";
import axios from "axios";
import { common } from "../../BaseUrl";
import { toast } from "react-toastify";
const pageIcons = ['Opt-in', 'Welcome', 'Thank you', 'Content', 'Sales Page', 'Order form', 'Upsell', 'Downsell', 'Survey', 'Webinar', 'Application', 'Calendar']
const sourceList = [
  { label: "Tik Tok", icon: <FaTiktok color="black" /> },
  { label: "Facebook", icon: <AiFillFacebook color="black " /> },
  { label: "Google", icon: <FaGoogle color="black " /> },
  { label: "Instagram", icon: <AiFillInstagram color="black " /> },
  { label: "You Tube", icon: <AiFillYoutube color="black " /> },
  { label: "Others", icon: <BsQuestionSquareFill color="black" /> },
  { label: "Organic", icon: <img src={organic} alt="" /> },
];
const Sidebar = (props) => {
  const { allPages, allPageCategories, pageDrawer, SetPageDrawer, pageByCategory, setPageByCategory } = useProvider()

  const [showAside2, setShwoAside2] = useState(false)
  const [ShowTraffic, setShwoTraffic] = useState(false)
  const [ShowAction, setShwoAction] = useState(false)
  const onDragStart = (event, nodeType, nodeName, id, icon) => {
    const data = {
      type: nodeType,
      name: nodeName,
      id: id,
      icon: icon
    };
    event.dataTransfer.setData("application/reactflow", JSON.stringify(data));
    event.dataTransfer.effectAllowed = "move";
  };

  console.log("set side bar=>", allPageCategories)


  return (

    <>

      <div className={`right__sidebar ${props.showAside ? "showSideBar" : ""}`}>




        <div onClick={() => props.setShwoAside(!props.showAside)} className="showSide">
          <MdOutlineArrowLeft className={props.showAside ? "rotate" : ""} />
        </div>
        <div className='sideBarInner'>




          <div onClick={() => { setShwoTraffic(!ShowTraffic); setShwoAside2(false); setShwoAction(false) }} className="warRoomSideHead">
            <img src={code} alt="" />
            <p>Traffic from</p>
          </div>

          {/* traffics */}
          <div className={`pageTypeSideBar ${ShowTraffic ? "showpageTypeSideBar" : ""}`}>


            {/* <div
      className="trafficsNode"
      onDragStart={(event) => onDragStart(event, "trafficNode", "Facebook")}
      draggable
    >
      <AiFillFacebook color="black" />
      <p>Facebook</p>
      <AiOutlinePlusCircle className="trafficHandlePlus" />
    </div>
    <div
      className="trafficsNode"
      onDragStart={(event) => onDragStart(event, "trafficNode", "Tik Tok")}
      draggable
    >
      <FaTiktok color="black" />
      <p>Tik Tok</p>
      <AiOutlinePlusCircle className="trafficHandlePlus" />
    </div>
    <div
      className="trafficsNode"
      onDragStart={(event) => onDragStart(event, "trafficNode", "You Tube")}
      draggable
    >
      <AiFillYoutube color="black" />
      <p>You Tube</p>
      <AiOutlinePlusCircle className="trafficHandlePlus" />
    </div>
    <div
      className="trafficsNode"
      onDragStart={(event) => onDragStart(event, "trafficNode", "Instagram")}
      draggable
    >
      <AiOutlineInstagram color="black" />
      <p>Instagram</p>
      <AiOutlinePlusCircle className="trafficHandlePlus" />
    </div>
    <div
      className="trafficsNode"
      onDragStart={(event) => onDragStart(event, "trafficNode", "Google")}
      draggable
    >
      <FaGoogle color="black" />
      <p>Google</p>
      <AiOutlinePlusCircle className="trafficHandlePlus" />
    </div> */}
            {
              sourceList?.map((trafic, index) => {
                return (
                  <div

                    style={{ marginBottom: index === sourceList?.length - 1 ? '5px' : "" }} key={index}
                    className="trafficsNode"
                    onDragStart={(event) => onDragStart(event, "trafficNode", trafic.label, index, trafic?.icon)}
                    draggable
                  >
                    {trafic.icon}
                    <p>{trafic.label}</p>
                    <AiOutlinePlusCircle className="trafficHandlePlus" />
                  </div>
                )
              })
            }

            {/* <div
      className="trafficsNode"
      onDragStart={(event) => onDragStart(event, "trafficNode", "Organic")}
      draggable
    >
      <img src={organic} alt="" />
      <p>Organic</p>
      <AiOutlinePlusCircle className="trafficHandlePlus" />
    </div> */}


          </div>


          <div onClick={() => { setShwoAside2(!showAside2); setShwoAction(false); setShwoTraffic(false) }} className="warRoomSideHead">
            <img src={page} alt="" />
            <p className="headingside">Pages</p>
          </div>
          {/* pages */}
          <div className={`pageTypeSideBar ${showAside2 ? "showpageTypeSideBar" : ""}`}>
            {/* <div onClick={() => setShwoAside2(false)} className="backIcon">
      <RxCross1 size={25} />
      <div className='closeIconTag'>
        <img src={page} alt="" />
        <p>Pages</p>
      </div>
    </div> */}
            <div className="trafficContainer">
              {
                allPageCategories?.map((page, index) => {
                  return (
                    <div

                      className="traffics"
                      onDragStart={(event) => onDragStart(event, "pageNode", page.name, page._id, page?.icon)}
                      draggable
                      style={{backgroundImage:`url(${page?.icon})`, backgroundRepeat:"no-repeat", backgroundPosition:"center", justifyContent:"flex-end"}}
                    >
                      {/* <img src={page?.icon} alt="" /> */}
                      <p style={{paddingBottom:"2px"}}>{page?.name}</p>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div onClick={() => { setShwoAction(!ShowAction); setShwoTraffic(false); setShwoAside2(false) }} className="warRoomSideHead">
            <img src={action} alt="" />
            <p className="headingside">Actions</p>
          </div>
          {/* actions */}
          <div className={`pageTypeSideBar ${ShowAction ? "showpageTypeSideBar" : ""}`}>
            {/* <div onClick={() => setShwoAction(false)} className="backIcon">
      <RxCross1 size={25} />
      <div className='closeIconTag'>
        <img src={action} alt="" />
        <p>Actions</p>
      </div>
    </div> */}
            <div className="trafficContainer">
              <div
                className="traffics"
                onDragStart={(event) => onDragStart(event, "actionNode", "Send e-mail")}
                draggable
              >
                <img src={email} alt="" />
                <p>Send e-mail</p>
              </div>
              <div
                className="traffics"
                onDragStart={(event) => onDragStart(event, "actionNode", "Share link")}
                draggable
              >
                <img src={share} alt="" />
                <p>Share link</p>
              </div>
              <div
                className="traffics"
                onDragStart={(event) => onDragStart(event, "actionNode", "Set up Calendar")}
                draggable
              >
                <img src={cal} alt="" />
                <p>Set up <br /> Calendar</p>
              </div>
            </div>
          </div>



        </div>

      </div>
    </>
  );
};

export default Sidebar;
