import React, { useEffect, useState } from "react";
import styles from "./BGModel.module.css";
import { RxCross2 } from "react-icons/rx";
import usePageBuilder from "../../../../Context/pageBuilder";
import axios from "axios";
import { common } from "../../../../BaseUrl";
import { MdContentCopy } from "react-icons/md";

function SummaryModel(props) {

    const [isActive, setIsActive] = useState('Gallary')
    const {
        showBgModel,
        setShowBgModel,
        bgChoosen, setBgChoosen,
        sliderImage, setSliderImage
    } = usePageBuilder();
    const [images, setImages] = useState([])
    const [page, setPage] = useState(1)
    const [pageTwo, setPageTwo] = useState(1)
    const [videoPage, setVideoPage] = useState(1)
    const [pixelImages, setPixelImages] = useState([])
    const [galleryImages, setGalleryImages] = useState([])
    const [pixelVideos, setPixelVideos] = useState([])
    const [chooosenTag, setChooosenTag] = useState('text')
    const [imageSearched, setImageSearched] = useState('')
    const [VideoSearched, setVideoSearched] = useState('')
    const [pageGal, setPageGal] = useState(1)
    const collectionID = 928423;
    const randomNumber = 20;
    useEffect(() => {
        genrateVideoFromPixels()
    }, [videoPage])
    useEffect(() => {
        genrateImage(randomNumber)
    }, [pageTwo])
    // useEffect(() => {
    //   if (bottom) {
    //     bottom.current.addEventListener('DOMNodeInserted', event => {
    //       const { currentTarget: target } = event;
    //       target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    //     });
    //   }
    // }, [])
    useEffect(() => {
        genrateImageFromPixels()
    }, [page])
    const handleSearchImage = (e, which) => {
        if (e.key === 'Enter', which === 'Pixels') {
            genrateImageFromPixels('Searched')
        }
    }
    const genrateImage = (randomNumber) => {
        axios({
            method: "get",
            url: `https://api.unsplash.com/photos/?client_id=Pt2HN57Z_3_gBC2BboFtyUZTplMC5P4M3DkVl_peoZk&page=${pageTwo}&per_page=3`,
            headers: {
                Authorization: 'Pt2HN57Z_3_gBC2BboFtyUZTplMC5P4M3DkVl_peoZk'
            }
        })
            .then((response) => {
                // console.log("response", response)
                setImages((prevImages) => [...prevImages, ...response.data])
            }).catch(err => {

            })
    }


    const loadVideo = (e) => {
        if (e.key === 'Enter') {
            genrateVideoFromPixels('Searched')
        }
    }
    const genrateImageFromPixels = (Searched) => {
        axios({
            method: "get",
            url: `https://api.pexels.com/v1/search?query=${imageSearched?.length > 0 ? imageSearched : "nature"}&per_page=3&page=${page}`,
            headers: {
                Authorization: 'jAFhdrlE1mfAWpNK5OGVlFe8Dy2tAyJuvJKccdU74Hz3HJIDlyEjI43a'
            }
        })
            .then((response) => {
                if (Searched == 'Searched') {
                    setPixelImages(response.data?.photos)
                    return
                }
                console.log("response", response)
                // setImages((prevImages) => [...prevImages, ...data.images]);
                setPixelImages((prevImages) => [...prevImages, ...response.data?.photos])
            }).catch(err => {

            })
    }
    const genrateVideoFromPixels = (Searched) => {
        axios({
            method: "get",
            url: `https://api.pexels.com/videos/search?query=${VideoSearched ? VideoSearched : 'nature'}&per_page=3&page=${videoPage}`,
            headers: {
                Authorization: 'jAFhdrlE1mfAWpNK5OGVlFe8Dy2tAyJuvJKccdU74Hz3HJIDlyEjI43a'
            }
        })
            .then((response) => {
                console.log("response", response)
                if (Searched == 'Searched') {
                    console.log('Searched')
                    setPixelVideos(response.data?.videos)
                    return
                }
                setPixelVideos((prevImages) => [...prevImages, ...response.data?.videos])
            }).catch(err => {

            })
    }

    useEffect(() => {
        getAssest()
    }, [pageGal])
    const getAssest = () => {
        axios({
            method: "get",
            url: `${common}/asset/assets/Gallery?skip=0&limit=${pageGal}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            console.log("responceimages", res.data)
            setGalleryImages(res.data?.data?.result)
        })
    }

    const chooseImage = (url) => {

        setBgChoosen(url)
        navigator.clipboard.writeText(url)
     
    }
    return (
        <div
            onClick={() => setShowBgModel(false)}
            className={`${styles.summaryModel} ${showBgModel ? styles.showSummryModel : ""
                }`}
        >

            <div onClick={(e) => e.stopPropagation()} className={styles.summaryBody}>

                <div className={styles.modelHead}>
                    <h1>Background images</h1>
                    < RxCross2 onClick={() => setShowBgModel(false)} />
                </div>

                <div className={styles.fromWhere}>
                    <button onClick={() => setIsActive('Gallary')} className={isActive === 'Gallary' ? "addbutton" : "closebuttn"} > Gallery</button>
                    <button onClick={() => setIsActive('Pixels')} className={isActive === 'Pixels' ? "addbutton" : "closebuttn"} > Pixels</button>
                    <button onClick={() => setIsActive('Unspalsh')} className={isActive === 'Unspalsh' ? "addbutton" : "closebuttn"} > Unspalsh</button>
                </div>


                {
                    isActive === 'Unspalsh' && <div className={styles.imageLists}>
                        <input type="text" placeholder="Seacrh Image" name="" id="" value={imageSearched} onKeyDown={(e) => handleSearchImage(e, 'Gallary')} onChange={(e) => setImageSearched(e.target.value)} />
                        <div className={styles.images}>
                            {
                                images?.map(img => (
                                    <div className={styles.unsplashImageDiv}>

                                        <img
                                            key={img.id}
                                            draggable
                                            src={img?.urls?.thumb}
                                            alt=""
                                        />
                                        <div className={styles.copylinkIcon}>
                                            <button onClick={() => chooseImage(img?.urls?.full)} className={`closebuttn ${styles.chooseButton}`}>Choose</button>
                                        </div>
                                    </div>
                                ))
                            }
                            <button onClick={() => setPageTwo(prev => prev + 1)} className="addbutton">Load More</button>
                        </div>
                    </div>
                }
                {
                    isActive === 'Pixels' && <div className={styles.imageLists}>
                        <input type="text" placeholder="Seacrh Image" name="" id="" value={imageSearched} onKeyDown={(e) => handleSearchImage(e, 'Pixels')} onChange={(e) => setImageSearched(e.target.value)} />
                        <div className={styles.images}>
                            {
                                pixelImages?.map(img => (
                                    <div className={styles.unsplashImageDiv}>

                                        <img
                                            key={img.id}
                                            draggable

                                            src={img?.src?.tiny}
                                            alt=""
                                        />
                                        <div className={styles.copylinkIcon}>
                                            <button onClick={() => chooseImage(img?.src?.original)} className={`closebuttn ${styles.chooseButton}`}>Choose</button>
                                        </div>
                                    </div>
                                ))
                            }
                            <button onClick={() => setPage((prevPage) => prevPage + 1)} className="addbutton">Load More</button>
                        </div>
                    </div>
                }
                {
                    isActive === 'Gallary' && <div className={styles.imageLists}>
                        <input type="text" placeholder="Seacrh Image" name="" id="" value={imageSearched} onKeyDown={(e) => handleSearchImage(e, 'Pixels')} onChange={(e) => setImageSearched(e.target.value)} />
                        <div className={styles.images}>
                            {
                                galleryImages?.map(img => (
                                    <div className={styles.unsplashImageDiv}>

                                        <img
                                            key={img.id}
                                            draggable
                                            src={img?.thumbUrl}
                                            alt=""
                                        />
                                        <div className={styles.copylinkIcon}>
                                            <button onClick={() => chooseImage(img?.url)} className={`closebuttn ${styles.chooseButton}`}>Choose</button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <button onClick={() => setPageGal((prevPage) => prevPage + 1)} className="addbutton">Load More</button>
                    </div>
                }

            </div>
        </div>
    );
}

export default SummaryModel;
